/* eslint-disable react/jsx-pascal-case */
type Props = {
  must: boolean
}

const A_IconMust = ({ must }: Props) => {
  return (
    <>
      {must ? (
        <span style={{ color: 'red' }}>
          <b>*</b>
        </span>
      ) : (
        ''
      )}
    </>
  )
}
export default A_IconMust
