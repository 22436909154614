import { Reducer } from 'redux'
import produce from 'immer'
import { searchJokenKura } from 'types/SearchTypes'
import { getNumberFromString } from 'utils/dateUtil'
import { convertNumber } from 'utils/numberUtil'
import { F_NUM, F_STR } from 'assets/FormatNumbers'
import { responseSummaryJokenKura } from 'types/ResponseTypes'
import { isJochoSpecialUser } from 'utils/booleanUtil'

/**
 * メイン参照テーブルはJTD368
 */
export type SearchJokenKuraState = {
  searchJoken: searchJokenKura // 販売条件照会画面検索情報
  searchedJoken: searchJokenKura // 販売条件照会画面検索情報(明細表示中)
  SIIRE_ERROR: boolean // 仕入先コードが取得できているか
  BUSHO_ERROR: boolean // 課コードが取得できているか
  TOKUI_ERROR: boolean // 得意先コードが取得できているか
  TANTO_ERROR: boolean // 担当者コードが取得できているか
  ADD_TANTO_ERROR: boolean // 入力担当者コードが取得できているか
  message: string // メッセージ
  detailView: boolean // 明細画面表示中か
  initMishoninCheckbox: boolean // 初期状態未承認チェックボックス
  initShoninCheckbox: boolean // 初期状態承認チェックボックス
  initHininCheckbox: boolean // 初期状態否認チェックボックス
  initJochoMishoninCheckbox: boolean // 初期状態上長未承認チェックボックス
  initJochoShoninCheckbox: boolean // 初期状態上長承認チェックボックス
  initHininMikakuninCheckbox: boolean // 初期状態メーカー否認　未確認チェックボックス
  initJochoHininMikakuninCheckbox: boolean // 初期状態上長否認　未確認チェックボックス
  initHininkakuninzumiCheckbox: boolean // 初期状態メーカー否認　確認済チェックボックス
  initJochoHininKakuninzumiCheckbox: boolean // 初期状態上長否認　確認済チェックボックス
  isNotDeleteMessage: boolean // 検索後にメッセージを消さないフラグ
}

const initJoken = {
  JOKEN_KANRI_NO: F_STR, //	条件管理番号
  KEN_NM: F_STR, // 件名
  SIIRE_CD: F_NUM, // 仕入先コード
  SIIRE_NM: F_STR, // 仕入先名
  JIGYO_CD: F_NUM, // 事業所コード
  JIGYO_NM: F_STR, // 事業所名
  BUSHO_CD: F_NUM, // 課コード
  BUSHO_NM: F_STR, // 課名
  TOKUI_CD: F_NUM, // 得意先コード
  TOKUI_NM: F_STR, // 得意先名
  // TANTO_CD: F_NUM, // 担当者コード
  // TANTO_NM: F_STR, // 担当者名
  TEKIYO_START_DT: F_NUM, // 適用期間開始
  TEKIYO_END_DT: F_NUM, // 適用期間終了
  ADD_TANTO_CD: F_NUM, // 入力担当者コード
  ADD_TANTO_NM: F_STR, // 入力担当者名
  mishoninCheckbox: false, // 未承認チェックボックス
  shoninCheckbox: false, // 承認チェックボックス
  hininCheckbox: false, // 否認チェックボックス
  jochoMishoninCheckbox: false, // 上長未承認チェックボックス
  jochoShoninCheckbox: false, // 上長承認チェックボックス
  hininMikakuninCheckbox: false, // メーカー否認　未確認チェックボックス
  jochoHininMikakuninCheckbox: false, // 上長否認　未確認チェックボックス
  hininKakuninzumiCheckbox: false, // メーカー否認　確認済チェックボックス
  jochoHininKakuninzumiCheckbox: false, // 上長否認　確認済チェックボックス
}

/**
 * 初期状態
 * 入力区分は販売条件とする
 * コード値は-1とする
 */
const initialState: SearchJokenKuraState = {
  searchJoken: initJoken, // 販売条件照会画面検索情報
  searchedJoken: initJoken, // 販売条件照会画面検索情報(明細表示中)
  SIIRE_ERROR: false, // 仕入先コードが取得できているか
  BUSHO_ERROR: false, // 課コードが取得できているか
  TOKUI_ERROR: false, // 得意先コードが取得できているか
  TANTO_ERROR: false, // 担当者コードが取得できているか
  ADD_TANTO_ERROR: false, // 入力担当者コードが取得できているか
  message: F_STR, // メッセージ
  detailView: false, // 明細画面表示中か
  initMishoninCheckbox: false, // 初期状態未承認チェックボックス
  initShoninCheckbox: false, // 初期状態承認チェックボックス
  initHininCheckbox: false, // 初期状態否認チェックボックス
  initJochoShoninCheckbox: false, // 初期状態上長承認チェックボックス
  initJochoMishoninCheckbox: false, // 初期状態上長未承認チェックボックス
  initHininMikakuninCheckbox: false, // 初期状態メーカー否認　未確認チェックボックス
  initJochoHininMikakuninCheckbox: false, // 初期状態上長否認　未確認チェックボックス
  initHininkakuninzumiCheckbox: false, // 初期状態メーカー否認　確認済チェックボックス
  initJochoHininKakuninzumiCheckbox: false, // 初期状態上長否認　確認済チェックボックス
  isNotDeleteMessage: false, // 検索後にメッセージを消さないフラグ
}

export type SearchJokenKuraType =
  | 'SearchJokenKuraChangeJokenKanriNo' // 条件管理番号を変更する処理
  | 'SearchJokenKuraChangeTekiyoStartDate' // 適用期間開始を変更する処理
  | 'SearchJokenKuraChangeTekiyoEndDate' // 適用期間終了を変更する処理
  // | 'SearchJokenChangeTokuisakiCode' // 得意先コードを変更する処理
  // | 'SearchJokenChangeTokuisakiError' // 得意先取得判定を変更する処理
  // | 'SearchJokenDeleteTokuisaki' // 得意先情報を初期化する処理
  | 'SearchJokenKuraChangeKenName' // 件名を変更する処理
  | 'SearchJokenKuraChangeJigyoshoCode' // 事業所コードを変更する処理
  | 'SearchJokenKuraChangeInitCode' // ユーザー所属部署ごとの初期値入力処理
  | 'SearchJokenKuraChangeBushoCode' // 課(部署)コードを変更する処理
  | 'SearchJokenKuraChangeBushoError' // 課(部署)取得判定を変更する処理
  | 'SearchJokenKuraChangeShiiresakiCode' // 仕入先コードを変更する処理
  // | 'SearchJokenChangeTantoCode' // 担当者コードを変更する処理
  // | 'SearchJokenChangeTantoError' // 担当者取得判定を変更する処理
  // | 'SearchJokenDeleteTantoInfo' // 担当者情報をすべて初期化する処理
  | 'SearchJokenKuraChangeAddTantoCode' // 入力担当者コードを変更する処理
  | 'SearchJokenKuraChangeAddTantoError' // 入力担当者取得判定を変更する処理
  | 'SearchJokenKuraFormat' // 条件検索情報を初期化する処理
  | 'SearchJokenKuraAllFormat' // 条件検索情報を完全に初期化する処理
  | 'SearchJokenKuraChangeMessage' // メッセージを変更する処理
  | 'SearchJokenKuraChangeDetailView' // 明細画面表示を変更する処理
  | 'SearchJokenKuraOpenDetail' // 販売条件照会画面検索情報(明細表示中)を変更する処理
  | 'SearchJokenKuraCloseDetail' // 販売条件照会画面検索情報(明細表示中)を変更する処理
  | 'SearchJokenKuraChangeMishoninCheckbox' // 未承認チェックボックスを変更する処理
  | 'SearchJokenKuraChangeShoninCheckbox' // 承認チェックボックスを変更する処理
  | 'SearchJokenKuraChangeHininCheckbox' // 否認チェックボックスを変更する処理
  | 'SearchJokenKuraChangeKakunintaisyoCheckbox' // 確認対象表示状態にする処理
  | 'SearchJokenKuraChangeInitCheckbox' // 初期状態のチェックボックスを変更する処理
  | 'SearchJokenKuraChangeIsDeleteMessage' // 検索後にメッセージを消さないフラグを変更する処理
  | 'SearchJokenKuraChangeJochoShoninCheckbox' // 上長承認チェックボックスを変更する処理
  | 'SearchJokenKuraChangeJochoMishoninCheckbox' // 上長未承認チェックボックスを変更する処理
  | 'SearchJokenKuraChangeHininMikakuninCheckbox' // メーカー否認　未確認チェックボックスを変更する処理
  | 'SearchJokenKuraChangeJochoHininMikakuninCheckbox' // 上長否認　未確認チェックボックスを変更する処理
  | 'SearchJokenKuraChangeHininkakuninzumiCheckbox' // メーカー否認　確認済チェックボックスを変更する処理
  | 'SearchJokenKuraChangeJochoHininkakuninzumiCheckbox' // 上長否認　確認済チェックボックスを変更する処理
  | 'SearchJokenKuraFormatExceptJotai' // 状態以外の条件検索情報を初期化する処理
  | 'dummy'

export type SearchJokenKuraAction = {
  type: SearchJokenKuraType
  jokenKanriNo?: string
  tokuisakiCode?: number
  kenName?: string
  jigyoshoCode?: number
  bushoCode?: number
  shiiresakiCode?: number
  tantoCode?: number
  error?: boolean
  message?: string
  detailView?: boolean // 明細画面表示中か
  joken?: responseSummaryJokenKura // 販売条件照会画面検索情報
  tekiyoStartDate?: string // 日付変更値(文字列型)
  tekiyoEndDate?: string // 日付変更値(文字列型)
  kakuninzumiCheckbox?: boolean
  isNotDeleteMessage?: boolean // 検索後にメッセージを消さないフラグ
  isSuperUser?: boolean // スーパーユーザーかどうか
  isJocho?: boolean // 上長かどうか
  isKoiki?: boolean // 広域量販部かどうか
  initJotaiKbn?: string // ログイン時の状態チェックボックスを設定するための区分。1：ジャペル（一般・スーパーユーザー）、2：ジャペル（上長）、3：仕入先
}

export const SearchJokenKuraReducer: Reducer<SearchJokenKuraState, SearchJokenKuraAction> = produce((draft: SearchJokenKuraState, action: SearchJokenKuraAction) => {
  switch (action.type) {
    // 上長否認　確認済チェックボックスを変更する処理 //===========================//
    case 'SearchJokenKuraChangeJochoHininkakuninzumiCheckbox': {
      draft.searchJoken.jochoHininKakuninzumiCheckbox = !draft.searchJoken.jochoHininKakuninzumiCheckbox
      return
    }

    // メーカー否認　確認済チェックボックスを変更する処理 //===========================//
    case 'SearchJokenKuraChangeHininkakuninzumiCheckbox': {
      draft.searchJoken.hininKakuninzumiCheckbox = !draft.searchJoken.hininKakuninzumiCheckbox
      return
    }

    // 上長否認　未確認チェックボックスを変更する処理 //===========================//
    case 'SearchJokenKuraChangeJochoHininMikakuninCheckbox': {
      draft.searchJoken.jochoHininMikakuninCheckbox = !draft.searchJoken.jochoHininMikakuninCheckbox
      draft.searchJoken.jochoHininKakuninzumiCheckbox = !draft.searchJoken.jochoHininKakuninzumiCheckbox
      return
    }

    // メーカー否認　未確認チェックボックスを変更する処理 //===========================//
    case 'SearchJokenKuraChangeHininMikakuninCheckbox': {
      draft.searchJoken.hininMikakuninCheckbox = !draft.searchJoken.hininMikakuninCheckbox
      return
    }

    // 上長未承認チェックボックスを変更する処理 //===========================//
    case 'SearchJokenKuraChangeJochoMishoninCheckbox': {
      draft.searchJoken.jochoMishoninCheckbox = !draft.searchJoken.jochoMishoninCheckbox
      return
    }

    // 上長承認チェックボックスを変更する処理 //===========================//
    case 'SearchJokenKuraChangeJochoShoninCheckbox': {
      draft.searchJoken.jochoShoninCheckbox = !draft.searchJoken.jochoShoninCheckbox
      return
    }

    // 検索後にメッセージを消さないフラグを変更する処理 //===========================//
    case 'SearchJokenKuraChangeIsDeleteMessage': {
      draft.isNotDeleteMessage = action.isNotDeleteMessage!
      return
    }

    // 初期状態のチェックボックスを変更する処理 //===========================//
    case 'SearchJokenKuraChangeInitCheckbox': {
      draft.initMishoninCheckbox = draft.searchJoken.mishoninCheckbox
      draft.initShoninCheckbox = draft.searchJoken.shoninCheckbox
      draft.initHininCheckbox = draft.searchJoken.hininCheckbox
      draft.initJochoMishoninCheckbox = draft.searchJoken.jochoMishoninCheckbox
      draft.initHininMikakuninCheckbox = draft.searchJoken.hininMikakuninCheckbox
      draft.initJochoHininMikakuninCheckbox = draft.searchJoken.jochoHininMikakuninCheckbox
      draft.initHininkakuninzumiCheckbox = draft.searchJoken.hininKakuninzumiCheckbox
      draft.initJochoHininKakuninzumiCheckbox = draft.searchJoken.jochoHininKakuninzumiCheckbox
      return
    }

    // 確認対象表示状態にする処理 //===========================//
    case 'SearchJokenKuraChangeKakunintaisyoCheckbox': {
      switch (action.initJotaiKbn) {
        case '1': // ジャペル（一般・スーパーユーザー）
          draft.searchJoken.mishoninCheckbox = true
          draft.searchJoken.hininMikakuninCheckbox = true
          break
        case '2': // ジャペル（上長）
          draft.searchJoken.jochoMishoninCheckbox = true
          break
        case '3': // 仕入先
          draft.searchJoken.mishoninCheckbox = true
          break
      }
      return
    }

    // 否認チェックボックスを変更する処理 //===========================//
    case 'SearchJokenKuraChangeHininCheckbox': {
      draft.searchJoken.hininCheckbox = !draft.searchJoken.hininCheckbox
      return
    }

    // 承認チェックボックスを変更する処理 //===========================//
    case 'SearchJokenKuraChangeShoninCheckbox': {
      draft.searchJoken.shoninCheckbox = !draft.searchJoken.shoninCheckbox
      return
    }

    // 未承認チェックボックスを変更する処理 //===========================//
    case 'SearchJokenKuraChangeMishoninCheckbox': {
      draft.searchJoken.mishoninCheckbox = !draft.searchJoken.mishoninCheckbox
      return
    }

    // 適用期間終了を変更する処理 //===========================//
    case 'SearchJokenKuraChangeTekiyoEndDate': {
      draft.searchJoken.TEKIYO_END_DT = getNumberFromString(action.tekiyoEndDate!)
      return
    }

    // 適用期間開始を変更する処理 //===========================//
    case 'SearchJokenKuraChangeTekiyoStartDate': {
      draft.searchJoken.TEKIYO_START_DT = getNumberFromString(action.tekiyoStartDate!)
      return
    }

    // 明細画面表示を変更する処理 //===========================//
    case 'SearchJokenKuraChangeDetailView': {
      draft.detailView = action.detailView!
      return
    }

    // 販売条件照会画面検索情報(明細表示中)を変更する処理 ※仮処理 //===========================//
    case 'SearchJokenKuraOpenDetail': {
      const joken = action.joken!
      draft.searchedJoken = draft.searchJoken
      draft.searchJoken = {
        JOKEN_KANRI_NO: joken.JOKEN_KANRI_NO, //	条件管理番号
        KEN_NM: joken.KEN_NM, // 件名
        SIIRE_CD: joken.SIIRE_CD, // 仕入先コード
        SIIRE_NM: joken.SIIRE_NM, // 仕入先名
        JIGYO_CD: joken.JIGYO_CD, // 事業所コード
        JIGYO_NM: joken.JIGYO_NM, // 事業所名
        BUSHO_CD: joken.BUSHO_CD, // 課コード
        BUSHO_NM: joken.BUSHO_NM, // 課名
        TOKUI_CD: joken.TOKUI_CD, // 得意先コード
        TOKUI_NM: joken.TOKUI_NM, // 得意先名
        // TANTO_CD: joken.TANTO_CD, // 担当者コード
        // TANTO_NM: joken.TANTO_NM, // 担当者名
        TEKIYO_START_DT: joken.TEKIYO_START_DT, // 適用期間開始
        TEKIYO_END_DT: joken.TEKIYO_END_DT, // 適用期間終了
        ADD_TANTO_CD: joken.ADD_TANTO_CD, // 入力担当者コード
        ADD_TANTO_NM: joken.ADD_TANTO_NM, // 入力担当者名
        mishoninCheckbox: joken.JOTAI_KBN_0 === 1, // 未承認チェックボックス
        shoninCheckbox: joken.JOTAI_KBN_1 === 1, // 承認チェックボックス
        hininCheckbox: joken.JOTAI_KBN_2 === 1, // 否認チェックボックス
        jochoMishoninCheckbox: joken.JOCHO_JOTAI_KBN_0 === 1, // 上長未承認チェックボックス
        jochoShoninCheckbox: joken.JOCHO_JOTAI_KBN_1 === 1, // 上長未承認チェックボックス
        hininMikakuninCheckbox: joken.JOTAI_KBN_2_1 === 1, // メーカー否認　未確認チェックボックス
        jochoHininMikakuninCheckbox: joken.JOCHO_JOTAI_KBN_2_1 === 1, // 上長否認　未確認チェックボックス
        hininKakuninzumiCheckbox: joken.JOTAI_KBN_2_2 === 1, // メーカー否認　確認済チェックボックス
        jochoHininKakuninzumiCheckbox: joken.JOCHO_JOTAI_KBN_2_2 === 1, // 上長否認　確認済チェックボックス
      }
      draft.detailView = true
      return
    }

    // 販売条件照会画面検索情報(明細表示中)を変更する処理 ※仮処理 //===========================//
    case 'SearchJokenKuraCloseDetail': {
      draft.searchJoken = draft.searchedJoken
      draft.detailView = false
      return
    }

    // 条件管理番号 //===========================//
    case 'SearchJokenKuraChangeJokenKanriNo': {
      if (!action.jokenKanriNo) {
        draft.searchJoken.JOKEN_KANRI_NO = initialState.searchJoken.JOKEN_KANRI_NO
        return
      }
      draft.searchJoken.JOKEN_KANRI_NO = convertNumber(action.jokenKanriNo!, 10).toString()
      return
    }
    // // 得意先処理 //===========================//
    // case 'SearchJokenChangeTokuisakiCode': {
    //   draft.searchJoken.TOKUI_CD = convertNumber(action.tokuisakiCode!, 6)
    //   return
    // }
    // case 'SearchJokenChangeTokuisakiError': {
    //   draft.TOKUI_ERROR = action.error!
    //   return
    // }

    // 件名 //===========================//
    case 'SearchJokenKuraChangeKenName': {
      draft.searchJoken.KEN_NM = action.kenName!
      return
    }
    // 事業所処理 //===========================//
    case 'SearchJokenKuraChangeJigyoshoCode': {
      draft.searchJoken.JIGYO_CD = convertNumber(action.jigyoshoCode!, 2)
      return
    }
    // ユーザー所属部署ごとの初期値入力処理 //===========================//
    case 'SearchJokenKuraChangeInitCode': {
      const JIGYO_CD = convertNumber(action.jigyoshoCode!, 2)
      const TANTO_CD = convertNumber(action.tantoCode!, 6)
      const isSuperUser = action.isSuperUser!
      const isJocho = action.isJocho!
      const isKoiki = action.isKoiki!
      if (isSuperUser) {
        draft.searchJoken.JIGYO_CD = JIGYO_CD // 特定の部署の人の事業所の初期値は自分の事業所を選択
      } else {
        if (!isJocho) draft.searchJoken.ADD_TANTO_CD = TANTO_CD // 一般ユーザーの入力担当者の初期値は自分
      }
      if (isJocho && !isKoiki) {
        if (!isJochoSpecialUser(JIGYO_CD)) draft.searchJoken.JIGYO_CD = JIGYO_CD // 上長かつ関西か名古屋以外かつ広域量販部ではない事業所の初期値は自分の事業所
      }
      return
    }

    // 課(部署)処理 //===========================//
    case 'SearchJokenKuraChangeBushoCode': {
      draft.searchJoken.BUSHO_CD = convertNumber(action.bushoCode!, 1)
      return
    }
    case 'SearchJokenKuraChangeBushoError': {
      draft.BUSHO_ERROR = action.error!
      return
    }

    // // 担当者処理 //===========================//
    // case 'SearchJokenChangeTantoCode': {
    //   draft.searchJoken.TANTO_CD = convertNumber(action.tantoCode!, 6)
    //   return
    // }
    // case 'SearchJokenChangeTantoError': {
    //   draft.TANTO_ERROR = action.error!
    //   return
    // }
    // case 'SearchJokenDeleteTantoInfo': {
    //   draft.searchJoken.TANTO_CD = initialState.searchJoken.TANTO_CD
    //   draft.searchJoken.TANTO_NM = initialState.searchJoken.TANTO_NM
    //   draft.TANTO_ERROR = initialState.TANTO_ERROR
    //   return
    // }

    // 仕入先処理 //===========================//
    case 'SearchJokenKuraChangeShiiresakiCode': {
      draft.searchJoken.SIIRE_CD = convertNumber(action.shiiresakiCode!, 4)
      return
    }

    // 入力担当者処理 //===========================//
    case 'SearchJokenKuraChangeAddTantoCode': {
      draft.searchJoken.ADD_TANTO_CD = convertNumber(action.tantoCode!, 6)
      return
    }
    case 'SearchJokenKuraChangeAddTantoError': {
      draft.ADD_TANTO_ERROR = action.error!
      return
    }

    // 検索条件以外の条件初期化 //===========================//
    case 'SearchJokenKuraFormatExceptJotai': {
      draft.searchJoken = {
        ...initialState.searchJoken,
        mishoninCheckbox: draft.searchJoken.mishoninCheckbox,
        shoninCheckbox: draft.searchJoken.shoninCheckbox,
        hininCheckbox: draft.searchJoken.hininCheckbox,
        jochoMishoninCheckbox: draft.searchJoken.jochoMishoninCheckbox,
        hininMikakuninCheckbox: draft.searchJoken.hininMikakuninCheckbox,
        jochoHininMikakuninCheckbox: draft.searchJoken.jochoHininMikakuninCheckbox,
        hininKakuninzumiCheckbox: draft.searchJoken.hininKakuninzumiCheckbox,
        jochoHininKakuninzumiCheckbox: draft.searchJoken.jochoHininKakuninzumiCheckbox,
      }
      return
    }

    // 条件初期化 //===========================//
    case 'SearchJokenKuraFormat': {
      draft.searchJoken = {
        ...initialState.searchJoken,
        mishoninCheckbox: draft.initMishoninCheckbox,
        shoninCheckbox: draft.initShoninCheckbox,
        hininCheckbox: draft.initHininCheckbox,
        jochoMishoninCheckbox: draft.initJochoMishoninCheckbox,
        hininMikakuninCheckbox: draft.initHininMikakuninCheckbox,
        jochoHininMikakuninCheckbox: draft.initJochoHininMikakuninCheckbox,
        hininKakuninzumiCheckbox: draft.initHininkakuninzumiCheckbox,
        jochoHininKakuninzumiCheckbox: draft.initJochoHininKakuninzumiCheckbox,
      }
      draft.searchedJoken = {
        ...initialState.searchedJoken,
        mishoninCheckbox: draft.initMishoninCheckbox,
        shoninCheckbox: draft.initShoninCheckbox,
        hininCheckbox: draft.initHininCheckbox,
        jochoMishoninCheckbox: draft.initJochoMishoninCheckbox,
        hininMikakuninCheckbox: draft.initHininMikakuninCheckbox,
        jochoHininMikakuninCheckbox: draft.initJochoHininMikakuninCheckbox,
        hininKakuninzumiCheckbox: draft.initHininkakuninzumiCheckbox,
        jochoHininKakuninzumiCheckbox: draft.initJochoHininKakuninzumiCheckbox,
      }
      draft.detailView = initialState.detailView
      return
    }
    // 条件完全初期化 //===========================//
    case 'SearchJokenKuraAllFormat': {
      for (const key in draft) {
        const tempKey: keyof SearchJokenKuraState = key as keyof SearchJokenKuraState
        ;(draft as any)[tempKey] = initialState[tempKey]
      }
      return
    }
    // メッセージ変更 //===========================//
    case 'SearchJokenKuraChangeMessage': {
      draft.message = action.message!
      return
    }
  }
}, initialState)
