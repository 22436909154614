const getWidth = <T>(arr: { variable: string; label: string; width: number }[]): { width: T; label: T } => {
  let max = 0
  let keys: string[] = []
  arr.forEach((r) => {
    max += r.width
    keys.push(r.variable)
  })
  let width: any = {}
  let label: any = {}
  for (let i = 0; i < arr.length; i++) {
    width = { ...width, [keys[i]]: arr[i].width / max }
    label = { ...label, [keys[i]]: arr[i].label }
  }
  return {
    width: width,
    label: label,
  }
}

/**
 * 販売条件サマリテーブル
 */
export const jokenTableSummary = getWidth<{
  NO: number | string
  CHECK: number | string
  JOTAI: number | string
  JOCHO_JOTAI: number | string
  KANRI_NO: number | string
  KEN: number | string
  STRT_DT: number | string
  END_DT: number | string
  JIGYO: number | string
  ZENKOKU: number | string
  BUSHO: number | string
  TOKUI: number | string
  SIIRE: number | string
  TANTO: number | string
  ADD_TANTO: number | string
}>([
  { variable: 'NO', label: 'NO', width: 1.5 },
  { variable: 'CHECK', label: 'CHECK', width: 1 },
  { variable: 'JOTAI', label: '仕入先', width: 3 },
  { variable: 'JOCHO_JOTAI', label: '上長', width: 3 },
  { variable: 'KANRI_NO', label: '管理NO', width: 3.5 },
  { variable: 'KEN', label: '件名', width: 5 },
  { variable: 'STRT_DT', label: '期間(開始)', width: 3 },
  { variable: 'END_DT', label: '期間(終了)', width: 3 },
  { variable: 'JIGYO', label: '事業所', width: 2.5 },
  { variable: 'ZENKOKU', label: '全国', width: 2 },
  { variable: 'BUSHO', label: '課', width: 3 },
  { variable: 'TOKUI', label: '得意先名', width: 5 },
  { variable: 'SIIRE', label: '仕入先名', width: 5 },
  { variable: 'TANTO', label: '担当者', width: 3 },
  { variable: 'ADD_TANTO', label: '入力担当者', width: 3 },
])
/**
 * 販売条件明細テーブル
 */
export const jokenTableDetail = getWidth<{
  NO: number | string
  CHECK: number | string
  JOTAI: number | string
  JOCHO_JOTAI: number | string
  JAN_CD: number | string
  JP_CD: number | string
  SHOHIN_KIKAKU: number | string
  SIIRE_TAN: number | string
  NET: number | string
  MEISAI_BIKO: number | string
  HININ_RIYU: number | string
  JOCHO_HININ_RIYU: number | string
}>([
  { variable: 'NO', label: 'NO', width: 1 },
  { variable: 'CHECK', label: 'CHECK', width: 1 },
  { variable: 'JOTAI', label: '仕入先', width: 1 },
  { variable: 'JOCHO_JOTAI', label: '上長', width: 1 },
  { variable: 'JAN_CD', label: 'JANコード', width: 3 },
  { variable: 'JP_CD', label: 'JPコード', width: 1 },
  { variable: 'SHOHIN_KIKAKU', label: '品名／規格', width: 3 },
  { variable: 'SIIRE_TAN', label: '仕入単価', width: 1 },
  { variable: 'NET', label: '個人Net', width: 1 },
  { variable: 'MEISAI_BIKO', label: '明細備考', width: 4 },
  { variable: 'HININ_RIYU', label: '否認理由', width: 4 },
  { variable: 'JOCHO_HININ_RIYU', label: '上長否認理由', width: 4 },
])

/**
 * 倉出条件サマリテーブル
 */
export const jokenKuraTableSummary = getWidth<{
  NO: number | string
  CHECK: number | string
  JOTAI: number | string
  JOCHO_JOTAI: number | string
  KANRI_NO: number | string
  KEN: number | string
  STRT_DT: number | string
  END_DT: number | string
  JIGYO: number | string
  ZENKOKU: number | string
  BUSHO: number | string
  JOGAI_TOKUI: number | string
  SIIRE: number | string
  // TANTO: number | string
  ADD_TANTO: number | string
  CYOKUSO_JOGAI: number | string
}>([
  { variable: 'NO', label: 'NO', width: 1.5 },
  { variable: 'CHECK', label: 'CHECK', width: 1 },
  { variable: 'JOTAI', label: '仕入先', width: 3 },
  { variable: 'JOCHO_JOTAI', label: '上長', width: 3 },
  { variable: 'KANRI_NO', label: '管理NO', width: 3.5 },
  { variable: 'KEN', label: '件名', width: 5 },
  { variable: 'STRT_DT', label: '期間(開始)', width: 3 },
  { variable: 'END_DT', label: '期間(終了)', width: 3 },
  { variable: 'JIGYO', label: '事業所', width: 2.5 },
  { variable: 'ZENKOKU', label: '全国', width: 2 },
  { variable: 'BUSHO', label: '課', width: 3 },
  { variable: 'JOGAI_TOKUI', label: '除外(得意先)', width: 5 },
  { variable: 'SIIRE', label: '仕入先名', width: 5 },
  // { variable: 'TANTO', label: '担当者', width: 3 },
  { variable: 'ADD_TANTO', label: '入力担当者', width: 3 },
  { variable: 'CYOKUSO_JOGAI', label: '除外(直送)', width: 3 },
])
/**
 * 倉出条件明細テーブル
 */
export const jokenKuraTableDetail = getWidth<{
  NO: number | string
  CHECK: number | string
  JOTAI: number | string
  JOCHO_JOTAI: number | string
  JAN_CD: number | string
  JP_CD: number | string
  SHOHIN_KIKAKU: number | string
  SIIRE_TAN: number | string
  NET: number | string
  MEISAI_BIKO: number | string
  HININ_RIYU: number | string
  JOCHO_HININ_RIYU: number | string
}>([
  { variable: 'NO', label: 'NO', width: 1 },
  { variable: 'CHECK', label: 'CHECK', width: 1 },
  { variable: 'JOTAI', label: '仕入先', width: 1 },
  { variable: 'JOCHO_JOTAI', label: '上長', width: 1 },
  { variable: 'JAN_CD', label: 'JANコード', width: 3 },
  { variable: 'JP_CD', label: 'JPコード', width: 1 },
  { variable: 'SHOHIN_KIKAKU', label: '品名／規格', width: 3 },
  { variable: 'SIIRE_TAN', label: '仕入単価', width: 1 },
  { variable: 'NET', label: '倉出Net', width: 1 },
  { variable: 'MEISAI_BIKO', label: '明細備考', width: 4 },
  { variable: 'HININ_RIYU', label: '否認理由', width: 4 },
  { variable: 'JOCHO_HININ_RIYU', label: '上長否認理由', width: 4 },
])

/**
 * 率条件テーブル
 */
export const jokenRitsuTable = getWidth<{
  NO: number | string
  CHECK: number | string
  JOTAI: number | string
  JOCHO_JOTAI: number | string
  STRT_DT: number | string
  END_DT: number | string
  TOKUI_NM: number | string
  SIIRE_NM: number | string
  JOGAI_ITEM: number | string
  NYURYOKU: number | string
  HASU: number | string
  SEISAN_START: number | string
  CYCLE: number | string
  FIRST_SEISAN: number | string
  SEISAN_NAIYO: number | string
  CYOKU: number | string
  HENPIN: number | string
  ADD_TANTO_NM: number | string
  HININ_RIYU: number | string
  JOCHO_HININ_RIYU: number | string
}>([
  { variable: 'NO', label: 'NO', width: 1.5 },
  { variable: 'CHECK', label: 'CHECK', width: 1 },
  { variable: 'JOTAI', label: '仕入先', width: 1.5 },
  { variable: 'JOCHO_JOTAI', label: '上長', width: 2 },
  { variable: 'STRT_DT', label: '年月(開始)', width: 2 },
  { variable: 'END_DT', label: '年月(終了)', width: 2 },
  { variable: 'TOKUI_NM', label: '得意先名', width: 3 },
  { variable: 'SIIRE_NM', label: '仕入先名', width: 3 },
  { variable: 'JOGAI_ITEM', label: '除外', width: 1.5 },
  { variable: 'NYURYOKU', label: '入力率', width: 2 },
  { variable: 'HASU', label: '端数', width: 2 },
  { variable: 'SEISAN_START', label: '精算開始', width: 2 },
  { variable: 'CYCLE', label: 'サイクル', width: 2 },
  { variable: 'FIRST_SEISAN', label: '初回精算', width: 2 },
  { variable: 'SEISAN_NAIYO', label: '精算内容', width: 4 },
  { variable: 'CYOKU', label: '直', width: 1 },
  { variable: 'HENPIN', label: '返', width: 1 },
  { variable: 'ADD_TANTO_NM', label: '入力担当者名', width: 3 },
  { variable: 'HININ_RIYU', label: '否認理由', width: 8 },
  { variable: 'JOCHO_HININ_RIYU', label: '上長否認理由', width: 8 },
])

/**
 * ユーザー登録サマリテーブル
 */
export const userTableSummary = getWidth<{
  CHECK: number | string
  SIIRE_CD: number | string
  SIIRE_NM: number | string
  MAIL: number | string
  NAME: number | string
}>([
  { variable: 'CHECK', label: 'CHECK', width: 1 },
  { variable: 'SIIRE_CD', label: '仕入先コード', width: 3 },
  { variable: 'SIIRE_NM', label: '仕入先名', width: 5 },
  { variable: 'MAIL', label: 'メールアドレス', width: 7 },
  { variable: 'NAME', label: '氏名', width: 5 },
])
/**
 * ユーザー登録明細テーブル
 */
export const userTableDetail = getWidth<{
  CHECK: number | string
  TOKUI_CD: number | string
  TOKUI_NM: number | string
  MAIL: number | string
  NAME: number | string
}>([
  { variable: 'CHECK', label: 'CHECK', width: 1 },
  { variable: 'TOKUI_CD', label: '得意先コード', width: 3 },
  { variable: 'TOKUI_NM', label: '得意先名', width: 5 },
  { variable: 'MAIL', label: 'メールアドレス', width: 7 },
  { variable: 'NAME', label: '氏名', width: 5 },
])

/**
 * 除外アイテム照会テーブル
 */
export const jogaiItemTable = getWidth<{
  JAN_CD: number | string
  JP_CD: number | string
  SHOHIN_KIKAKU: number | string
}>([
  { variable: 'JAN_CD', label: 'JANコード', width: 7 },
  { variable: 'JP_CD', label: 'JPコード', width: 4 },
  { variable: 'SHOHIN_KIKAKU', label: '品名／規格', width: 28 },
])

/**
 * 除外得意先照会テーブル
 */
export const jogaiTokuiTable = getWidth<{
  TOKUI_CD: number | string
  TOKUI_NM: number | string
}>([
  { variable: 'TOKUI_CD', label: '得意先コード', width: 7 },
  { variable: 'TOKUI_NM', label: '得意先名', width: 28 },
])
/**
 * ログテーブル
 */
export const logTable = getWidth<{
  ADD_YMD: number | string
  ADD_HNS: number | string
  TOROKU_KBN: number | string
  JIGYO_CD: number | string
  JIGYO_NM: number | string
  SIIRE_CD: number | string
  SIIRE_NM: number | string
  TOKUI_CD: number | string
  TOKUI_NM: number | string
  MAIL: number | string
  NAME: number | string
  TANTO_CD: number | string
  TANTO_NM: number | string
}>([
  { variable: 'ADD_YMD', label: '日付', width: 2 },
  { variable: 'ADD_HNS', label: '時間', width: 1.5 },
  { variable: 'TOROKU_KBN', label: '区分', width: 1 },
  { variable: 'JIGYO_CD', label: '事業所CD', width: 1 },
  { variable: 'JIGYO_NM', label: '事業所名', width: 1 },
  { variable: 'SIIRE_CD', label: '仕入先CD', width: 1 },
  { variable: 'SIIRE_NM', label: '仕入先名', width: 4 },
  { variable: 'MAIL', label: 'メールアドレス', width: 4 },
  { variable: 'NAME', label: '氏名', width: 2 },
  { variable: 'TANTO_CD', label: '担当者CD', width: 1 },
  { variable: 'TANTO_NM', label: '担当者名', width: 2 },
  { variable: 'TOKUI_CD', label: '得意先CD', width: 1 },
  { variable: 'TOKUI_NM', label: '得意先名', width: 4 },
])

/**
 * 手入力分照会サマリテーブル
 */
export const tenyuryokuTableSummary = getWidth<{
  NO: number | string
  CHECK: number | string
  JOTAI: number | string
  JOCHO_JOTAI: number | string
  DENPYO_NO: number | string
  SYORI_YMD: number | string
  SEISAN_NAIYO: number | string
  JIGYO_NM: number | string
  SIIRE_NM: number | string
  TANTO_NM: number | string
  SEISAN_TOTAL_GK: number | string
  existFile: number | string
  BIKOU: number | string
}>([
  { variable: 'NO', label: 'NO', width: 1.5 },
  { variable: 'CHECK', label: 'CHECK', width: 1 },
  { variable: 'JOTAI', label: '仕入先', width: 2 },
  { variable: 'JOCHO_JOTAI', label: '上長', width: 2 },
  { variable: 'DENPYO_NO', label: '処理NO', width: 3 },
  { variable: 'SYORI_YMD', label: '処理票日付', width: 2.5 },
  { variable: 'SEISAN_NAIYO', label: '件名', width: 6.5 },
  { variable: 'JIGYO_NM', label: '事業所', width: 2 },
  { variable: 'SIIRE_NM', label: '仕入先名', width: 3.5 },
  { variable: 'TANTO_NM', label: '担当者名', width: 2.5 },
  { variable: 'SEISAN_TOTAL_GK', label: '精算金額', width: 3.5 },
  { variable: 'existFile', label: 'ﾌｧｲﾙ', width: 1.5 },
  { variable: 'BIKOU', label: '備考', width: 3 },
])

/**
 * 手入力分照会明細テーブル
 */
export const tenyuryokuTableDetail = getWidth<{
  NO: number | string
  CHECK: number | string
  JOTAI: number | string
  JOCHO_JOTAI: number | string
  TOKUI_CD: number | string
  TOKUI_NM: number | string
  BUMON_CD: number | string
  BUMON_NM: number | string
  JAN_CD: number | string
  SHOHIN_KIKAKU: number | string
  SEISAN_SU: number | string
  SIIRE_TANKA: number | string
  KURAIRI_NET: number | string
  KOJIN_NET: number | string
  SEISAN_TANKA: number | string
  SEISAN_GK: number | string
  HININ_RIYU: number | string
  JOCHO_HININ_RIYU: number | string
}>([
  { variable: 'NO', label: 'NO', width: 1.5 },
  { variable: 'CHECK', label: 'CHECK', width: 1 },
  { variable: 'JOTAI', label: '仕入先', width: 2 },
  { variable: 'JOCHO_JOTAI', label: '上長', width: 2 },
  { variable: 'TOKUI_CD', label: '得意先CD', width: 2 },
  { variable: 'TOKUI_NM', label: '得意先名', width: 6 },
  { variable: 'BUMON_CD', label: '部門CD', width: 2 },
  { variable: 'BUMON_NM', label: '部門名', width: 3 },
  { variable: 'JAN_CD', label: 'JANｺｰﾄﾞ', width: 3.7 },
  { variable: 'SHOHIN_KIKAKU', label: '品名規格', width: 6 },
  { variable: 'SEISAN_GK', label: '合計金額', width: 2 },
  { variable: 'SEISAN_SU', label: '数量', width: 2 },
  { variable: 'SIIRE_TANKA', label: '仕入単価', width: 2 },
  { variable: 'KURAIRI_NET', label: '倉入NET', width: 2 },
  { variable: 'KOJIN_NET', label: '個人NET', width: 2 },
  { variable: 'SEISAN_TANKA', label: '単価', width: 2 },
  { variable: 'HININ_RIYU', label: '否認理由', width: 6 },
  { variable: 'JOCHO_HININ_RIYU', label: '上長否認理由', width: 6 },
])

const getColor = (alert: string) => {
  switch (alert) {
    case 'UPD':
      return '#99FFFF'
    case 'ALERT':
      return 'red'
  }
  return ''
}

/**
 * テーブル要素のスタイルを取得する関数
 */
export const getStyle = (width: number | string, header: boolean = false, alert: string = ''): React.CSSProperties => {
  const baseStyle = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    borderWidth: 1,
    borderColor: 'black',
    borderStyle: 'solid',
    padding: 3,
    width: (width as number) * 100 + '%',
    // minWidth: width*100 + '%',
    // maxWidth: width*100 + '%',
    height: 30,
  }
  if (header) return { ...baseStyle, backgroundColor: '#66FF99' } as React.CSSProperties
  if (alert !== '') return { ...baseStyle, maxWidth: '0', backgroundColor: getColor(alert) } as React.CSSProperties
  return { ...baseStyle, maxWidth: '0', backgroundColor: '#00FF00!important' } as React.CSSProperties
}
