import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import { TableContainer } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { jogaiTokuiTable } from 'assets/TableHeader'
import M_TableCell from 'components/1_Atoms/2_Molecules/M_TableCellBig'
import { organismLog } from 'utils/logUtil'
import { tokuisaki } from 'types/ReferTypes'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(1),
    },
    table: {
      backgroundColor: '#00FFFF',
    },
    container: {
      maxHeight: 415,
    },
  }),
)

const width = jogaiTokuiTable.width
const label = jogaiTokuiTable.label

const O_TableTokuiCode = () => {
  useEffect(() => organismLog('O_TableTokuiCode'))
  const classes = useStyles()
  const modalTokui = useSelector((state) => state.ModalTokuiReducer.modalTokui)
  const tokuiInfo = modalTokui.tokuiInfo

  return (
    <TableContainer className={classes.container}>
      <Table stickyHeader size="small" aria-label="customized table">
        <TableHead>
          <TableRow hover role="checkbox" tabIndex={-1} className={classes.table}>
            <M_TableCell category="data" header={true} align={'left'} width={width.TOKUI_CD} title={label.TOKUI_CD} data={label.TOKUI_CD} />
            <M_TableCell category="data" header={true} align={'left'} width={width.TOKUI_NM} title={label.TOKUI_NM} data={label.TOKUI_NM} />
          </TableRow>
        </TableHead>
        <TableBody>
          {tokuiInfo.map((tokui: tokuisaki, index) => {
            const isChecked = true
            const TOKUI_NM = tokui.TOKUI_NM
            return (
              <TableRow hover role="checkbox" aria-checked={isChecked} tabIndex={-1} key={index} selected={isChecked}>
                <M_TableCell category="data" align={'left'} width={width.TOKUI_CD} title={tokui.TOKUI_CD} data={tokui.TOKUI_CD} />
                <M_TableCell category="data" alert={TOKUI_NM === ' ' ? 'ALERT' : ''} align={'left'} width={width.TOKUI_NM} title={TOKUI_NM} data={TOKUI_NM} />
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default React.memo(O_TableTokuiCode)
