/**
 * JOM170から取得するファイル名一覧
 * ※コメントのファイル名は変わる可能性があります。
 */
export const fileCode = {
  HANBAI_CSV: 33, // 【JS3】販売条件CSV
  RITSU_CSV: 34, // 【JS3】率条件CSV
  RITSU_JOGAI_CSV: 20, // 【精算金】除外アイテムCSV
  TENYURYOKU_CSV: 38, // 【JS3】手入力分照会CSV
  KURADASHI_CSV: 42, // 【JS3】販売条件(事業所)CSV
}
