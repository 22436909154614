import React, { useEffect, useRef, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import A_IconMust from '../A_IconMust'
import { moleculeLog } from 'utils/logUtil'
import TextField from '@material-ui/core/TextField'
import { HtmlToolTip } from 'assets/ToolTips'

type Props = {
  key?: number
  tab?: number
  must?: boolean
  type?: string
  label: string
  valueCode?: string
  max?: number
  change?: (e: React.ChangeEvent<HTMLInputElement>) => void
  blurFunc?: (e: React.FocusEvent<HTMLInputElement>) => void
  register?: {
    onKeyDown({ key }: { key: string }): void
    ref(element: HTMLInputElement | null): void
  }
  disabled?: boolean
  sm1?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  sm2?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
}

const WIDTH = '90%' // 入力フォームの幅
const HEIGHT = '27px' // 入力フォームの高さ

const M_FieldTextNew = ({
  key = 0, //
  tab = -1,
  type = 'text',
  must = false,
  label,
  max = 40,
  valueCode,
  change = undefined,
  blurFunc = undefined,
  register,
  disabled = false,
  sm1 = label.length > 4 ? 3 : 3,
  sm2 = label.length > 4 ? 9 : 9,
}: Props) => {
  // blurでreducerを変更する場合について
  // chageValueで入力中の値を保持、フォーカスアウトでreducerを更新
  // この入力フォーム以外でreducerが更新されてもchangeValueを変えないと入力フォームの値が変わらないので
  // reducerの値とprevValueRefに持たせた前の値と比較して、変わっていたらchageValueを更新
  const [changeValue, setValue] = useState(valueCode)
  const inputRef = useRef<HTMLInputElement | null>()
  const prevValueRef = useRef<string | undefined>(valueCode)
  useEffect(() => {
    moleculeLog('M_FieldTextNew')
    if (prevValueRef.current !== valueCode) {
      setValue(valueCode)
      prevValueRef.current = valueCode
    }
  })
  const isBlur = blurFunc !== undefined
  return (
    <Grid container justify="flex-start">
      <Grid item xs={12} sm={sm1}>
        <HtmlToolTip title={<Typography variant="body2">{`${valueCode}`}</Typography>} placement="bottom-start">
          <Typography variant="body1" display="block" gutterBottom>
            {label}
            <A_IconMust must={must} />
          </Typography>
        </HtmlToolTip>
      </Grid>
      <Grid item xs={12} sm={sm2}>
        <TextField
          inputRef={inputRef}
          key={key} //
          tabIndex={tab}
          type={type}
          value={isBlur ? changeValue : valueCode}
          // maxLength={max}
          inputProps={{
            maxlength: max,
            style: {
              // width: WIDTH,
              height: HEIGHT,
              paddingTop: 0,
              paddingBottom: 0,
              backgroundColor: disabled ? '#D8D8D8' : '#FFFFFF',
            },
          }}
          style={{ width: WIDTH }}
          onChange={
            isBlur
              ? (e) => {
                  setValue(e.target.value)
                }
              : change
          }
          onBlur={blurFunc}
          onKeyDown={(e) => {
            if (e.key === 'Enter') inputRef.current?.blur()
          }}
          {...register}
          variant="outlined"
          size="small"
          disabled={disabled}
        />
      </Grid>
    </Grid>
  )
}
export default React.memo(M_FieldTextNew)
