import React, { useEffect } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import TableRow from '@material-ui/core/TableRow'
import { tenyuryokuTableDetail } from 'assets/TableHeader'
import M_TableCell from 'components/1_Atoms/2_Molecules/M_TableCellHead'
import { organismLog } from 'utils/logUtil'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
    },
    table: {
      // backgroundColor: '#66FF99',
    },
    container: {
      maxHeight: 600,
    },
  }),
)

const width = tenyuryokuTableDetail.width
const label = tenyuryokuTableDetail.label

type Props = {
  click: (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.MouseEvent<HTMLTableRowElement, MouseEvent>, isChecked: boolean) => void
  isChecked: boolean
}

const O_TableHeaderTenyuryokuDetail = ({ click, isChecked }: Props) => {
  useEffect(() => organismLog('O_TableHeaderTenyuryokuDetail'))
  const classes = useStyles()
  const userKind = useSelector((state) => state.LoginReducer.userKind)
  const isJocho = useSelector((state) => state.LoginReducer.isJocho)

  return (
    <TableRow
      hover //
      onClick={(e) => click(e, isChecked)}
      className={classes.table}
    >
      {/* No */}
      <M_TableCell category="number" data={'NO'} width={width.NO} />
      {/* チェックボックス */}
      <M_TableCell category="checkbox" isChecked={isChecked} width={width.CHECK} />
      {/* 状態 */}
      <M_TableCell category="data" width={width.JOTAI} title={label.JOTAI} data={label.JOTAI} />
      {/* 上長承認状態 */ userKind === 0 ? <M_TableCell category="data" width={width.JOCHO_JOTAI} title={label.JOCHO_JOTAI} data={label.JOCHO_JOTAI} /> : <></>}
      {/* 得意先コード */}
      <M_TableCell category="data" width={width.TOKUI_CD} title={label.TOKUI_CD} data={label.TOKUI_CD} />
      {/* 得意先名 */}
      <M_TableCell category="data" width={width.TOKUI_NM} title={label.TOKUI_NM} data={label.TOKUI_NM} />
      {/* 部門コード */ userKind === 0 ? <M_TableCell category="data" width={width.BUMON_CD} title={label.BUMON_CD} data={label.BUMON_CD} /> : <></>}
      {/* 部門名 */ userKind === 0 ? <M_TableCell category="data" width={width.BUMON_NM} title={label.BUMON_NM} data={label.BUMON_NM} /> : <></>}
      {/* 商品コード */}
      <M_TableCell category="data" width={width.JAN_CD} title={label.JAN_CD} data={label.JAN_CD} />
      {/* 品名規格 */}
      <M_TableCell category="data" width={width.SHOHIN_KIKAKU} title={label.SHOHIN_KIKAKU} data={label.SHOHIN_KIKAKU} />
      {/* 合計金額 */}
      <M_TableCell category="data" width={width.SEISAN_GK} title={label.SEISAN_GK} data={label.SEISAN_GK} />
      {/* 数量 */}
      <M_TableCell category="data" width={width.SEISAN_SU} title={label.SEISAN_SU} data={label.SEISAN_SU} />
      {/* 仕入単価 */}
      <M_TableCell category="data" width={width.SIIRE_TANKA} title={label.SIIRE_TANKA} data={label.SIIRE_TANKA} />
      {/* 倉入NET */}
      <M_TableCell category="data" width={width.KURAIRI_NET} title={label.KURAIRI_NET} data={label.KURAIRI_NET} />
      {/* 個人NET */}
      <M_TableCell category="data" width={width.KOJIN_NET} title={label.KOJIN_NET} data={label.KOJIN_NET} />
      {/* 単価 */}
      <M_TableCell category="data" width={width.SEISAN_TANKA} title={label.SEISAN_TANKA} data={label.SEISAN_TANKA} />
      {/* 否認理由 */}
      {/* 否認理由・上長否認理由 */ isJocho ? <M_TableCell category="data" width={width.JOCHO_HININ_RIYU} title={label.JOCHO_HININ_RIYU} data={label.JOCHO_HININ_RIYU} /> : <M_TableCell category="data" width={width.HININ_RIYU} title={label.HININ_RIYU} data={label.HININ_RIYU} />}
    </TableRow>
  )
}

export default React.memo(O_TableHeaderTenyuryokuDetail)
