/* eslint-disable react/jsx-pascal-case */
import React, { useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import A_SelectBox from 'components/1_Atoms/A_SelectBox'
import A_IconMust from '../A_IconMust'
import { moleculeLog } from 'utils/logUtil'
import { select } from 'types/SearchTypes'
// import { SingleValue } from 'react-select'

type Props = {
  label: string
  must?: boolean
  valueCode: string
  valueSet: select[]
  event?: (targetValue: string) => void
  disabled?: boolean
  lib?: boolean
}

const M_FieldSelect = ({ label, must = false, valueCode, valueSet, event = () => {}, lib = true, disabled = false }: Props) => {
  useEffect(() => moleculeLog('M_FieldSelect'))
  const newSelect: select = { value: '', label: '' }
  const selected: select =
    valueSet.find((data) => {
      return data.value === valueCode
    }) ?? newSelect

  return (
    <Grid container justify="flex-start" alignItems="center">
      <Grid item xs={12} sm={3}>
        <Typography variant="body1" display="block">
          {label}
          <A_IconMust must={must} />
        </Typography>
      </Grid>
      <Grid item xs={12} sm={9} justify="flex-end">
        <A_SelectBox valueSet={valueSet} defValue={selected ? selected.value : ''} event={event} lib={lib} disabled={disabled} />
      </Grid>
    </Grid>
  )
}

export default React.memo(M_FieldSelect)
