import React, { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import { Card, CardHeader, CardContent, CardActions } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import M_ButtonSearch from 'components/1_Atoms/2_Molecules/M_ButtonSearch'
import * as LoginAction from 'actions/LoginAction' // 共通部分が多いActionはActionCreaterにまとめる
import * as PageAction from 'actions/PageAction'
import A_Message from 'components/1_Atoms/A_Message'
import { commonMsg } from 'assets/MessageCode'
import { organismGroupLog } from 'utils/logUtil'
import TextField from '@material-ui/core/TextField'
import { search2 } from 'utils/apiUtil'
import { useHistory } from 'react-router'

const WIDTH = 500
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      alignContent: 'center',
      alignItems: 'center',
    },
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      width: WIDTH,
      margin: `${theme.spacing(0)} auto`,
    },
    header: {
      width: WIDTH,
      textAlign: 'center',
      background: '#212121',
      color: '#fff',
    },
    card: {
      width: WIDTH,
      marginTop: theme.spacing(10),
    },
  }),
)

const O_SearchBoxCreatePassword = ({ title }: { title: string }) => {
  useEffect(() => organismGroupLog('O_SearchBoxCreatePassword'), [])
  const classes = useStyles()
  const dispatch = useDispatch()
  const message = useSelector((state) => state.LoginReducer.message)
  const inputMail = useSelector((state) => state.LoginReducer.inputMail)
  const isCreatedPassword = useSelector((state) => state.LoginReducer.isCreatedPassword)
  const loading = useSelector((state) => state.PageReducer.loading)
  const history = useHistory()

  const onChangeInputMail = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    dispatch(LoginAction.changeInputMail(e.target.value))
  }, [])

  const onClickLogin = useCallback(async () => {
    if (loading) return false
    dispatch(PageAction.changeLoading(true))
    await search2<{ inputMail: string }, any>(
      '/postCreatePassword',
      { inputMail: inputMail },
      (res) => {
        dispatch(LoginAction.changeMessage(res.viewMsg))
        if (!res.error) {
          dispatch(LoginAction.changeIsCreatedPassword(true))
        }
        dispatch(PageAction.changeLoading(false))
      },
      () => dispatch(LoginAction.changeMessage(commonMsg.ERROR_LOGIN_MSG)),
      () => dispatch(PageAction.changeLoading(false)),
    )
    return true
  }, [inputMail, loading])

  const onClickBack = useCallback(() => {
    history.push('/')
    return true
  }, [])

  return (
    <form className={classes.container} noValidate autoComplete="off">
      <Card className={classes.card} onSubmit={onClickLogin}>
        <CardHeader className={classes.header} title={title} />
        <CardContent>
          {isCreatedPassword ? null : (
            <div>
              <TextField
                key={1} //
                tabIndex={1}
                className={'input-numeric'}
                // type="number"
                inputProps={{ minLength: 0, maxLength: 90, pattern: '[0-9]' }}
                style={{ width: '100%' }}
                variant="outlined"
                value={inputMail}
                onChange={onChangeInputMail}
                onBlur={(e) => e.preventDefault()}
                autoFocus={true}
                placeholder="メールアドレスを入力してください。"
                size="small"
              />
            </div>
          )}
        </CardContent>
        <CardActions>
          <Grid container className={classes.root} justify={'center'}>
            <Grid item xs={8}>
              <A_Message color="red" message={message ? message : '　'} align={'center'} />
            </Grid>
            <Grid item xs={8}>
              {isCreatedPassword ? <M_ButtonSearch label="戻る" onClick={onClickBack} /> : <M_ButtonSearch label="送信" onClick={onClickLogin} />}
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </form>
  )
}
export default React.memo(O_SearchBoxCreatePassword)
