import React, { useEffect } from 'react'
// import { makeStyles, createStyles } from '@material-ui/core/styles'
import { responseDetailUser } from 'types/ResponseTypes'
import { userTableDetail } from 'assets/TableHeader'
import M_TableCell from 'components/1_Atoms/2_Molecules/M_TableCell'
import { organismLog } from 'utils/logUtil'
import { useDetail } from 'hooks/useUserTorokuTable'

const width = userTableDetail.width
const manualMode = process.env.REACT_APP_MANUAL_MODE === '1'

type Props = {
  detail: responseDetailUser
  isChecked: boolean
  // no: number
}

// const useStyles = makeStyles(() =>
//   createStyles({
//     trail: {
//       backgroundColor: '#FFCCCC!important',
//     },
//   }),
// )

const O_TableRowUserDetail = ({ detail, isChecked }: Props) => {
  useEffect(() => organismLog('O_TableRowUserDetail'), [])
  // const classes = useStyles()
  const [handleRowClick, changeTokuiCd, submitTokui, changeMail, submitMail] = useDetail(detail, isChecked)
  const isInit = detail.TOKUI_CD === 0

  return (
    <>
      {/* No */}
      {/* <M_TableCell category="number" data={no} width={width.NO} /> */}
      {/* チェックボックス */}
      <M_TableCell category="checkbox" isChecked={isChecked} width={width.CHECK} click={handleRowClick} />
      {/* 得意先コード */}
      <M_TableCell
        category={isInit ? 'data' : 'data'} //
        width={width.TOKUI_CD}
        align={'left'}
        title={isInit ? '' : detail.INP_TOKUI_CD}
        data={isInit ? '' : detail.INP_TOKUI_CD}
        change={changeTokuiCd}
        disabled={!isChecked || isInit}
        submit={submitTokui}
        caption={false}
        blackout={manualMode}
      />
      {/* 得意先名 */}
      <M_TableCell
        category="data" //
        width={width.TOKUI_NM}
        align={'left'}
        title={detail.TOKUI_NM}
        data={detail.TOKUI_NM}
        blackout={manualMode}
      />
      {/* メールアドレス */}
      <M_TableCell
        category="formString" //
        width={width.MAIL}
        align={'left'}
        title={detail.INP_MAIL}
        data={detail.INP_MAIL}
        change={changeMail}
        disabled={!isChecked}
        submit={submitMail}
        blackout={manualMode}
      />
      {/* 氏名 */}
      <M_TableCell
        category="data" //
        width={width.NAME}
        align={'left'}
        title={detail.NAME}
        data={detail.NAME}
        blackout={manualMode}
      />
    </>
  )
}

export default React.memo(O_TableRowUserDetail)
