/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useQuery } from 'react-query'
import { TableVirtuoso } from 'react-virtuoso'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import O_TableBarTenyuryokuDetail from 'components/3_Organisms/O_TableBarTenyuryokuDetail'
import O_TableRowTenyuryokuDetail from 'components/3_Organisms/O_TableRowTenyuryokuDetail'
import O_TableHeaderTenyuryokuDetail from 'components/3_Organisms/O_TableHeaderTenyuryokuDetail'
import O_FileUpload from 'components/3_Organisms/Groups/O_FileUpload'
import { useFetcherDetail, useInitProcessDetail, useFile, useExpand } from 'hooks/useTenyuryoku'
import { useButtonsDetail, useCheckListDetail, useSearchedFormatDetail, useGetIsHiddenPaginationDetail } from 'hooks/useTenyuryokuTable'
import { usePageDetail } from 'hooks/useTable'
import { useLoading, usePageMessage } from 'hooks/usePage'
import { organismGroupLog } from 'utils/logUtil'
import { isUniqueTenyuryokuDetail } from 'utils/booleanUtil'
import { responseDetailTenyuryoku } from 'types/ResponseTypes'
import { useChangeTableHeightNew } from 'hooks/useCommon'
import { commonMsg } from 'assets/MessageCode'
import { useSelector } from 'react-redux'
import { s3FileInfo } from 'types/SearchTypes'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
    },
    container: {
      maxHeight: 600,
    },
    renkeizumi: {
      backgroundColor: '#D8D8D8',
    },
    selectedRenkeizumi: {
      backgroundColor: '#AAAAAA',
    },
  }),
)

const O_TableTenyuryokuDetail = () => {
  const location = useLocation()
  const initProcess = useInitProcessDetail(location.pathname, useHistory())
  useEffect(() => {
    organismGroupLog('O_TableTenyuryokuDetail')
    initProcess()
  })
  //検索ボックス開閉状態の取得
  const [expand, changeExpand] = useExpand()
  useEffect(() => {
    // 初期表示は検索条件表示。リロードのためここで設定
    changeExpand(true)
  }, [])
  const classes = useStyles()
  const [checkListDetail, checkListDetailAll, allCheck] = useCheckListDetail()
  const [page, perPage] = usePageDetail()
  const searchedFormat = useSearchedFormatDetail()
  const fetcher = useFetcherDetail()
  const [_, changeFile] = useFile()
  const [changePageErrorMessage] = usePageMessage()
  const isJocho = useSelector((state) => state.LoginReducer.isJocho)

  const [tableHeight, setTableHeight] = useState(268)
  const [changeTableHeight, getNewTableHeight] = useChangeTableHeightNew(tableHeight, setTableHeight)

  useEffect(() => {
    // ウィンドウサイズ変更時にchangeTableHeightを呼び出すようにリスナーに登録
    window.removeEventListener('resize', changeTableHeight)
    window.addEventListener('resize', changeTableHeight)
    return () => {
      window.removeEventListener('resize', changeTableHeight)
    }
  }, [tableHeight])

  // SWR情報の取得
  const clickNo = useSelector((state) => state.TableTenyuryokuReducer.clickNo)
  const { data, refetch, isLoading, isFetching, error } = useQuery(['postTenyuryokuDetail', clickNo, page, perPage], fetcher)
  const [loding, changeLoading] = useLoading()
  useEffect(() => {
    // 描画時にテーブルの高さを調整
    // 条件開閉したときアニメーションでレイアウトが変わるので、tableHeightの値が変わらなくなるまで0.2秒間隔で処理を実行
    let oldTableHeight = -1
    if (!(isLoading || isFetching)) {
      // ロード中、フェッチ中はテーブルを表示しないので、ロードとフェッチが終わったときにテーブルの高さを設定
      const timerId = setInterval(() => {
        if (oldTableHeight !== tableHeight) {
          const nowTableHeight = getNewTableHeight()
          if (oldTableHeight === nowTableHeight) {
            changeTableHeight()
            clearInterval(timerId)
          } else {
            oldTableHeight = getNewTableHeight()
          }
        } else {
          oldTableHeight = getNewTableHeight()
        }
      }, 200)
    }
  }, [expand, isLoading || isFetching])

  useEffect(() => {
    if (isLoading || isFetching) {
      changeLoading(true)
    }
    if (!(isLoading || isFetching) && loding) {
      changeLoading(false)
    }
  }, [isLoading, isFetching])
  const isHiddenPagination = useGetIsHiddenPaginationDetail()
  const info: { table: responseDetailTenyuryoku[]; allCount: number; file: s3FileInfo[] } = data?.body
  const [_2, userKind, checkedList, clickShonin, clickHinin, clickKakuninzumi, clickJochoShonin, clickJochoHinin, clickCSV, clickBack] = useButtonsDetail(useHistory(), info ? info.table.length : 0, info ? info.allCount : 0)

  const detailList = data?.body?.table
  const file = info?.file
  useEffect(() => {
    if (file) {
      changeFile(file)
    }
  }, [file])

  const getIsAllChecked = () => {
    if (!detailList) return false
    if (detailList.length === 0 || checkListDetail.length === 0) return false
    return detailList.length === checkListDetail.length
  }
  const getIsChecked = (detail: responseDetailTenyuryoku) => {
    if (getIsAllChecked()) return true
    if (checkListDetail.length <= 0) return false
    return checkListDetail.some((r) => isUniqueTenyuryokuDetail(r, detail))
  }
  const table = (props: any) => <Table {...props} style={{ width: window.innerWidth, borderCollapse: 'separate' }} size="small" aria-label="purchases" />
  const tableHead = (props: any) => <TableHead {...props} id={'headerElement'} />
  const tableRow = (props: any) => {
    let isChecked = getIsChecked(props.item)
    let isCheckedTemp = isChecked
    let setClass = undefined
    if (userKind === 1 && props.item.RENKEI_KBN === 1) {
      if (isCheckedTemp) {
        setClass = classes.selectedRenkeizumi
        isCheckedTemp = false
      } else {
        setClass = classes.renkeizumi
      }
    }
    return <TableRow hover role="checkbox" {...props} selected={isCheckedTemp} className={setClass} />
  }
  const getComponent = useCallback(() => {
    return {
      Scroller: TableContainer,
      // Scroller: React.forwardRef((props, ref) => <TableContainer component={Paper} {...props} className={classes.container} ref={ref} />),
      Table: table,
      TableHead: tableHead,
      TableRow: tableRow,
      // TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
      TableBody: TableBody,
    }
  }, [checkListDetail.length])
  // /**
  //  * useQueryでデータ取得中に変更が必要なreducerの値を変更する
  //  */
  // const changeReducerInSearching = () => {
  //     if(isLoading || isFetching){
  //       // useQueryでデータ取得中の場合
  //       // reducerがロード状態でない場合、ロード状態にする
  //       if(!loding) changeLoading(true)
  //     } else {
  //       // useQueryでデータ取得済の場合
  //       // reducerがロード状態の場合、reducerのロード状態を終わらせる
  //       if(!isFetching && loding) changeLoading(false)
  //     }
  // }

  if (isLoading) {
    searchedFormat()
    // changeReducerInSearching()
    return <div>Loading...</div>
  }
  if (isFetching) {
    searchedFormat()
    // changeReducerInSearching()
    return <div>検索中...</div>
  }
  if (error) {
    changePageErrorMessage(commonMsg.ERROR_MSG)
    return <></>
  }
  // changeReducerInSearching()
  const list = detailList

  const getTableBar = () => {
    return (
      <O_TableBarTenyuryokuDetail
        count={info.allCount} //
        checkedList={checkedList}
        userKind={userKind}
        clickShonin={clickShonin}
        clickHinin={clickHinin}
        clickKakuninzumi={clickKakuninzumi}
        clickJochoShonin={clickJochoShonin}
        clickJochoHinin={clickJochoHinin}
        clickCSV={clickCSV}
        clickBack={clickBack}
        refetch={refetch}
      />
    )
  }

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item style={{ width: '100%' }}>
          {getTableBar()}
        </Grid>
        <Grid item style={{ width: '100%' }}>
          <TableVirtuoso
            style={{ height: tableHeight }} // 仮想リスト(表示範囲250以降はスクロール毎に描画する)
            data={list}
            components={getComponent()}
            fixedHeaderContent={() => <O_TableHeaderTenyuryokuDetail click={allCheck(detailList, getIsAllChecked())} isChecked={getIsAllChecked()} />}
            itemContent={(index, detail) => {
              const no = isHiddenPagination ? index + 1 : index + page * perPage + 1
              const isChecked = getIsChecked(detail)
              const checkShohin = checkListDetailAll.find((r) => isUniqueTenyuryokuDetail(r, detail))
              let HININ_RIYU = ''
              if (checkShohin) {
                HININ_RIYU = isJocho ? checkShohin.JOCHO_HININ_RIYU : checkShohin.HININ_RIYU
              } else {
                HININ_RIYU = isJocho ? detail.JOCHO_HININ_RIYU : detail.HININ_RIYU
              }
              return (
                <O_TableRowTenyuryokuDetail
                  detail={detail} //
                  isChecked={isChecked}
                  HININ_RIYU={HININ_RIYU}
                  no={no}
                />
              )
            }}
          />
        </Grid>
        <Grid item style={{ width: '100%' }} id={'toolbarElement'}>
          {getTableBar()}
        </Grid>
      </Grid>
      {/* ファイル取込画面のモーダル */}
      <O_FileUpload INP_KBN={3} refetch={refetch} />
      <div style={{ visibility: 'hidden', position: 'fixed', bottom: 0, height: '1.5em' }} id={'bottomElement'}></div>
    </div>
  )
}

export default React.memo(O_TableTenyuryokuDetail)
