/* eslint-disable react/jsx-pascal-case */
import React, { useEffect } from 'react'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import Radio from '@material-ui/core/Radio'
import Checkbox from '@material-ui/core/Checkbox'
import { HtmlToolTip } from 'assets/ToolTips'
import { getStyle } from 'assets/TableHeader'
import { moleculeLog } from 'utils/logUtil'

const typoVariant = 'button'

type Props = {
  category: 'number' | 'radio' | 'checkbox' | 'data'
  header?: boolean
  alert?: string
  caption?: boolean
  title?: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal
  data?: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal
  isChecked?: boolean
  width?: number | string
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify' | undefined
  click?: React.MouseEventHandler<HTMLSpanElement> | undefined
}

const M_TableCellBig = ({
  category, //
  header = false,
  alert = '',
  caption = true,
  title,
  data,
  isChecked,
  width = 30,
  align = 'center',
  click = () => {},
}: Props) => {
  useEffect(() => moleculeLog('M_TableCell'))

  if (category === 'number') {
    return (
      <TableCell style={getStyle(width, header, alert)} align={align}>
        <Typography variant={typoVariant}>{data}</Typography>
      </TableCell>
    )
  }
  if (category === 'radio') {
    return (
      <TableCell style={getStyle(width, header, alert)} padding="checkbox" align={align}>
        <Radio checked={isChecked} />
      </TableCell>
    )
  }
  if (category === 'checkbox') {
    return (
      <TableCell style={getStyle(width, header, alert)} padding="checkbox" align={align}>
        <Checkbox checked={isChecked} />
      </TableCell>
    )
  }
  return (
    <TableCell style={getStyle(width, header, alert)} align={align}>
      {caption ? (
        <HtmlToolTip
          title={
            <Typography variant={typoVariant} color="inherit">
              {title}
            </Typography>
          }
        >
          <Typography variant={typoVariant} onClick={click}>
            {data}
          </Typography>
        </HtmlToolTip>
      ) : (
        <Typography variant={typoVariant} onClick={click}>
          {data}
        </Typography>
      )}
    </TableCell>
  )
}

export default React.memo(M_TableCellBig)
