/* eslint-disable react/jsx-pascal-case */
import { memo, useEffect, useRef } from 'react'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import Radio from '@material-ui/core/Radio'
import Checkbox from '@material-ui/core/Checkbox'
import { HtmlToolTip } from 'assets/ToolTips'
import { getStyle } from 'assets/TableHeader'
import { moleculeLog } from 'utils/logUtil'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { fixNullString } from 'utils/stringUtil'

type Props = {
  category: 'number' | 'radio' | 'checkbox' | 'data' | 'form' | 'formNumber' | 'formString'
  size?: 'caption' | 'button'
  caption?: boolean
  title?: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal
  data?: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal
  isChecked?: boolean
  width?: number | string
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify' | undefined
  click?: React.MouseEventHandler<HTMLSpanElement> | undefined
  change?: (e: React.ChangeEvent<HTMLInputElement>) => void | undefined
  disabled?: boolean
  submit?: (e: React.KeyboardEvent<HTMLInputElement>) => Promise<boolean> | void
  focus?: boolean
  blackout?: boolean // マニュアル作成用に非表示対象を黒塗りする処理
}

const useStyles = makeStyles(() =>
  createStyles({
    hidden: {
      backgroundColor: 'inherit',
    },
  }),
)

const M_TableCell = ({
  category, //
  size = 'caption',
  caption = true,
  title,
  data,
  isChecked,
  width = 30,
  align = 'center',
  click = () => {},
  change = () => {},
  disabled = false,
  submit = (e) => {
    e.preventDefault()
  },
  focus = false,
  blackout = false,
}: Props) => {
  useEffect(() => moleculeLog('M_TableCell'), [])
  useEffect(() => {
    if (focus) {
      ref.current?.focus()
    }
  }, [focus])
  const classes = useStyles()
  const max = 100
  const ref = useRef<HTMLInputElement>(null)

  if (category === 'number') {
    return (
      <TableCell style={getStyle(width)} align={align}>
        <Typography variant={size}>{data}</Typography>
      </TableCell>
    )
  }
  if (category === 'radio') {
    return (
      <TableCell style={getStyle(width)} padding="checkbox" align={align}>
        <Radio checked={isChecked} />
      </TableCell>
    )
  }
  if (category === 'checkbox') {
    return (
      <TableCell style={getStyle(width)} padding="checkbox" align={align}>
        <Checkbox checked={isChecked} onClick={click} disabled={disabled} />
      </TableCell>
    )
  }
  if (category === 'form') {
    return (
      <TableCell style={getStyle(width)} padding="checkbox" align={align}>
        {caption ? (
          <HtmlToolTip
            title={
              <Typography variant={size} color="inherit">
                {title}
              </Typography>
            }
            classes={title ? {} : { tooltip: classes.hidden }}
          >
            <input
              type={'text'}
              value={`${fixNullString(data as any)}`}
              maxLength={max}
              style={{ width: '100%' }}
              disabled={disabled}
              onChange={change}
              // {...register}
            />
          </HtmlToolTip>
        ) : (
          <input
            type={'text'}
            value={`${fixNullString(data as any)}`}
            maxLength={max}
            style={{ width: '100%' }}
            disabled={disabled}
            onChange={change}
            // {...register}
          />
        )}
      </TableCell>
    )
  }
  if (category === 'formNumber' || category === 'formString') {
    return (
      <TableCell style={getStyle(width)} padding="checkbox" align={align}>
        {caption ? (
          <HtmlToolTip
            title={
              <Typography variant={size} color="inherit">
                {title}
              </Typography>
            }
            classes={title ? {} : { tooltip: classes.hidden }}
          >
            <input
              ref={ref}
              type={category === 'formNumber' ? 'number' : 'text'} //
              value={`${fixNullString(data as any)}`}
              maxLength={max}
              style={{ width: '100%' }}
              disabled={disabled}
              onChange={change}
              onKeyDown={(e) => submit(e)}
              // autoFocus={focus} // autoFocusが聞かないのでuseEffectとrefで対応
            />
          </HtmlToolTip>
        ) : (
          <input
            ref={ref}
            type={category === 'formNumber' ? 'number' : 'text'} //
            value={`${fixNullString(data as any)}`}
            maxLength={max}
            style={{ width: '100%' }}
            disabled={disabled}
            onChange={change}
            onKeyDown={submit}
            // autoFocus={focus} // autoFocusが聞かないのでuseEffectとrefで対応
          />
        )}
      </TableCell>
    )
  }
  return (
    <TableCell style={getStyle(width)} align={align}>
      {caption ? (
        <HtmlToolTip
          title={
            <Typography variant={size} color="inherit" style={{ whiteSpace: 'pre-wrap' }}>
              {title}
            </Typography>
          }
        >
          <Typography variant={size} onClick={click} style={blackout ? { backgroundColor: 'black' } : {}}>
            {data}
          </Typography>
        </HtmlToolTip>
      ) : (
        <Typography variant={size} onClick={click}>
          {data}
        </Typography>
      )}
    </TableCell>
  )
}

export default memo(M_TableCell)
