import { SearchJokenKuraAction } from 'reducers/SearchReducers/SearchJokenKuraReducer'
import { responseSummaryJokenKura } from 'types/ResponseTypes'
import { fixNullValue } from 'utils/numberUtil'

// 上長否認　確認済チェックボックスを変更する処理 //================================//
export const changeJochoHininkakuninzumiCheckbox = (): SearchJokenKuraAction => {
  return {
    type: 'SearchJokenKuraChangeJochoHininkakuninzumiCheckbox',
  }
}

// メーカー否認　確認済チェックボックスを変更する処理 //================================//
export const changeHininkakuninzumiCheckbox = (): SearchJokenKuraAction => {
  return {
    type: 'SearchJokenKuraChangeHininkakuninzumiCheckbox',
  }
}

// 上長否認　未確認チェックボックスを変更する処理 //================================//
export const changeJochoHininMikakuninCheckbox = (): SearchJokenKuraAction => {
  return {
    type: 'SearchJokenKuraChangeJochoHininMikakuninCheckbox',
  }
}

// メーカー否認　未確認チェックボックスを変更する処理 //================================//
export const changeHininMikakuninCheckbox = (): SearchJokenKuraAction => {
  return {
    type: 'SearchJokenKuraChangeHininMikakuninCheckbox',
  }
}

// 上長未承認チェックボックスを変更する処理 //================================//
export const changeJochoMishoninCheckbox = (): SearchJokenKuraAction => {
  return {
    type: 'SearchJokenKuraChangeJochoMishoninCheckbox',
  }
}

// 上長承認チェックボックスを変更する処理 //================================//
export const changeJochoShoninCheckbox = (): SearchJokenKuraAction => {
  return {
    type: 'SearchJokenKuraChangeJochoShoninCheckbox',
  }
}

// 検索後にメッセージを消さないフラグを変更する処理 //===========================//
export const changeIsDeleteMessage = (isNotDeleteMessage: boolean): SearchJokenKuraAction => {
  return {
    type: 'SearchJokenKuraChangeIsDeleteMessage',
    isNotDeleteMessage: isNotDeleteMessage,
  }
}

// 初期状態のチェックボックスを変更する処理 //===========================//
export const changeInitCheckbox = (): SearchJokenKuraAction => {
  return {
    type: 'SearchJokenKuraChangeInitCheckbox',
  }
}

// 確認対象表示状態にする処理 //===========================//
export const checkKakuinTaisyo = (initJotaiKbn: string): SearchJokenKuraAction => {
  return {
    type: 'SearchJokenKuraChangeKakunintaisyoCheckbox',
    initJotaiKbn: initJotaiKbn,
  }
}

// 否認チェックボックスフォーム処理 //================================//
export const changeHininCheckbox = (): SearchJokenKuraAction => {
  return {
    type: 'SearchJokenKuraChangeHininCheckbox',
  }
}

// 承認チェックボックスフォーム処理 //================================//
export const changeShoninCheckbox = (): SearchJokenKuraAction => {
  return {
    type: 'SearchJokenKuraChangeShoninCheckbox',
  }
}

// 未承認チェックボックスフォーム処理 //================================//
export const changeMishoninCheckbox = (): SearchJokenKuraAction => {
  return {
    type: 'SearchJokenKuraChangeMishoninCheckbox',
  }
}

// 適用期間終了フォーム処理 //================================//
export const changeTekiyoEndDate = (tekiyoEndDate: string): SearchJokenKuraAction => {
  return {
    type: 'SearchJokenKuraChangeTekiyoEndDate',
    tekiyoEndDate: tekiyoEndDate,
  }
}

// 適用期間開始フォーム処理 //================================//
export const changeTekiyoStartDate = (tekiyoStartDate: string): SearchJokenKuraAction => {
  return {
    type: 'SearchJokenKuraChangeTekiyoStartDate',
    tekiyoStartDate: tekiyoStartDate,
  }
}

// 明細画面表示を変更する処理 //================================//
export const changeDetailView = (detailView: boolean): SearchJokenKuraAction => {
  return {
    type: 'SearchJokenKuraChangeDetailView',
    detailView: detailView,
  }
}

// 販売条件照会画面検索情報(明細表示中)を変更する処理 ※仮処理 //================================//
export const openDetail = (joken: responseSummaryJokenKura): SearchJokenKuraAction => {
  return {
    type: 'SearchJokenKuraOpenDetail',
    joken: joken,
  }
}

// 販売条件照会画面検索情報(明細表示中)を変更する処理 ※仮処理 //================================//
export const closeDetail = (): SearchJokenKuraAction => {
  return {
    type: 'SearchJokenKuraCloseDetail',
  }
}

// 管理Noフォーム処理 //================================//
export const changeJokenKanriNo = (jokenKanriNo: string): SearchJokenKuraAction => {
  return {
    type: 'SearchJokenKuraChangeJokenKanriNo',
    jokenKanriNo: jokenKanriNo,
  }
}

// // 得意先フォーム処理 //================================//
// export const changeTokuisakiCode = (tokuisakiCode: string): SearchJokenKuraAction => {
//   return {
//     type: 'SearchJokenKuraChangeTokuisakiCode',
//     tokuisakiCode: fixNullValue(tokuisakiCode),
//   }
// }
// export const changeTokuisakiError = (error: boolean): SearchJokenKuraAction => {
//   return {
//     type: 'SearchJokenKuraChangeTokuisakiError',
//     error: error,
//   }
// }
// export const deleteTokuisaki = (): SearchJokenKuraAction => {
//   return {
//     type: 'SearchJokenKuraDeleteTokuisaki',
//   }
// }

// 件名フォーム処理 //================================//
export const changeKenName = (kenName: string): SearchJokenKuraAction => {
  return {
    type: 'SearchJokenKuraChangeKenName',
    kenName: kenName,
  }
}

// 事業所フォーム処理 //================================//
export const changeJigyoshoCode = (jigyoshoCode: string): SearchJokenKuraAction => {
  return {
    type: 'SearchJokenKuraChangeJigyoshoCode',
    jigyoshoCode: fixNullValue(jigyoshoCode),
  }
}
// 初期フォームセット //================================//
export const setInitCode = (jigyoshoCode: number, tantoCode: number, isSuperUser: boolean, isJocho: boolean, isKoiki: boolean): SearchJokenKuraAction => {
  return {
    type: 'SearchJokenKuraChangeInitCode',
    jigyoshoCode: jigyoshoCode,
    tantoCode: tantoCode,
    isSuperUser: isSuperUser,
    isJocho: isJocho,
    isKoiki: isKoiki,
  }
}

// 課フォーム処理 //================================//
export const changeBushoCode = (bushoCode: string): SearchJokenKuraAction => {
  return {
    type: 'SearchJokenKuraChangeBushoCode',
    bushoCode: fixNullValue(bushoCode),
  }
}
export const changeBushoError = (error: boolean): SearchJokenKuraAction => {
  return {
    type: 'SearchJokenKuraChangeBushoError',
    error: error,
  }
}

// 仕入先フォーム処理 //================================//
export const changeShiiresakiCode = (shiiresakiCode: string): SearchJokenKuraAction => {
  return {
    type: 'SearchJokenKuraChangeShiiresakiCode',
    shiiresakiCode: fixNullValue(shiiresakiCode),
  }
}

// // 担当者フォーム処理 //================================//
// export const changeTantoCode = (tantoCode: string): SearchJokenKuraAction => {
//   return {
//     type: 'SearchJokenKuraChangeTantoCode',
//     tantoCode: fixNullValue(tantoCode),
//   }
// }
// export const changeTantoError = (error: boolean): SearchJokenKuraAction => {
//   return {
//     type: 'SearchJokenKuraChangeTantoError',
//     error: error,
//   }
// }
// export const deleteTanto = (): SearchJokenKuraAction => {
//   return {
//     type: 'SearchJokenKuraDeleteTantoInfo',
//   }
// }

// 入力担当者フォーム処理 //================================//
export const changeAddTantoCode = (tantoCode: string): SearchJokenKuraAction => {
  return {
    type: 'SearchJokenKuraChangeAddTantoCode',
    tantoCode: fixNullValue(tantoCode),
  }
}
export const changeAddTantoError = (error: boolean): SearchJokenKuraAction => {
  return {
    type: 'SearchJokenKuraChangeAddTantoError',
    error: error,
  }
}

// 状態以外の条件初期化 //===========================//
export const formatExceptJotai = (): SearchJokenKuraAction => {
  return {
    type: 'SearchJokenKuraFormatExceptJotai',
  }
}

// 条件初期化 //===========================//
export const format = (): SearchJokenKuraAction => {
  return {
    type: 'SearchJokenKuraFormat',
  }
}

// 条件完全初期化 //===========================//
export const allFormat = (): SearchJokenKuraAction => {
  return {
    type: 'SearchJokenKuraAllFormat',
  }
}
