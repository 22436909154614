import { ModalShohinAction } from 'reducers/ModalShohinReducer'
import { responseJokenRitsuJogaiItem } from 'types/ResponseTypes'

// モーダルを開く処理 //================================//
export const open = (shohinInfo: responseJokenRitsuJogaiItem[]): ModalShohinAction => {
  return {
    type: 'ModalShohinOpen',
    shohinInfo: shohinInfo,
  }
}

// モーダルを閉じる処理 //================================//
export const close = (): ModalShohinAction => {
  return {
    type: 'ModalShohinClose',
  }
}

//  //================================//
export const changeMessage = (message: string): ModalShohinAction => {
  return {
    type: 'ModalShohinChangeMessage',
    message: message,
  }
}

// ローディング処理を変更する処理 //================================//
export const changeLoading = (loading: boolean): ModalShohinAction => {
  return {
    type: 'ModalShohinChangeLoading',
    loading: loading,
  }
}
// 条件初期化 //================================//
export const format = (): ModalShohinAction => {
  return {
    type: 'ModalShohinFormat',
  }
}
