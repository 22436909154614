import React, { useEffect } from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { atomLog } from 'utils/logUtil'

type Props = {
  key?: number
  tab?: number
  label: string
  check?: boolean
  position?: 'start' | 'end' | 'top' | 'bottom'
  change: (e: React.ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
}

const A_FieldCheckBoxRight = ({
  key = 0, //
  tab = -1,
  label,
  check = false,
  position = 'start',
  change,
  disabled = false,
}: Props) => {
  useEffect(() => atomLog('A_FieldCheckBoxRight'))
  if (position === 'start') {
    return (
      <FormControlLabel
        key={key} //
        tabIndex={tab}
        value={check}
        style={{ marginTop: 0, marginBottom: 5, marginLeft: 0, marginRight: 0, padding: 0 }}
        control={
          <Checkbox
            checked={check}
            style={{ backgroundColor: 'white', margin: 0, padding: 0 }} //
            color="primary"
            onChange={change}
          />
        }
        label={label}
        labelPlacement={position}
        disabled={disabled}
      />
    )
  }
  return (
    <FormControlLabel
      key={key} //
      tabIndex={tab}
      value={check}
      style={{ marginTop: 0, marginBottom: 5, marginLeft: 0, marginRight: 0, padding: 0 }}
      control={
        <Checkbox
          checked={check}
          style={{ backgroundColor: 'white', margin: 0, padding: 0 }} //
          color="primary"
          onChange={change}
        />
      }
      label={label}
      labelPlacement={position}
      disabled={disabled}
    />
  )
}
export default React.memo(A_FieldCheckBoxRight)
