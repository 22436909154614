import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { numberF, voidF } from 'types/FunctionTypes'
import * as SearchAction from 'actions/SearchActions/SearchJokenAction'
import * as SearchJokenKuraAction from 'actions/SearchActions/SearchJokenKuraAction'
import * as SearchJokenRitsuAction from 'actions/SearchActions/SearchJokenRitsuAction'
import * as SearchUserAction from 'actions/SearchActions/SearchUserAction'
import * as SearchLogAction from 'actions/SearchActions/SearchLogAction'
import * as SearchTenyuryokuAction from 'actions/SearchActions/SearchTenyuryokuAction'
import * as TableAction from 'actions/TableActions/TableAction'
import * as TableJokenAction from 'actions/TableActions/TableJokenAction'
import * as TableJokenKuraAction from 'actions/TableActions/TableJokenKuraAction'
import * as TableJokenRitsuAction from 'actions/TableActions/TableJokenRitsuAction'
import * as TableUserAction from 'actions/TableActions/TableUserAction'
import * as TableLogAction from 'actions/TableActions/TableLogAction'
import * as TableTenyuryokuAction from 'actions/TableActions/TableTenyuryokuAction'
import * as LoginAction from 'actions/LoginAction'
import * as ModalFileAction from 'actions/ModalFileAction'
import * as ModalShohinAction from 'actions/ModalShohinAction'
import * as ModalUserAction from 'actions/ModalUserAction'
import * as PageAction from 'actions/PageAction'
import * as TableCommonAction from 'actions/TableActions/TableAction'

// すべてのreducerを初期化 //================================//
export const useInitAllReducer = (): [voidF] => {
  const dispatch = useDispatch()
  const initAllReducer = useCallback(() => {
    dispatch(SearchAction.allFormat())
    dispatch(SearchJokenKuraAction.allFormat())
    dispatch(SearchJokenRitsuAction.allFormat())
    dispatch(SearchUserAction.format())
    dispatch(SearchLogAction.format())
    dispatch(SearchTenyuryokuAction.allFormat())
    dispatch(TableAction.allFormat())
    dispatch(TableJokenAction.format())
    dispatch(TableJokenKuraAction.format())
    dispatch(TableJokenRitsuAction.format())
    dispatch(TableUserAction.format())
    dispatch(TableLogAction.format())
    dispatch(LoginAction.format())
    dispatch(ModalFileAction.format())
    dispatch(ModalShohinAction.format())
    dispatch(ModalUserAction.format())
    dispatch(PageAction.format())
  }, [])
  return [initAllReducer]
}

// 仮想テーブルの高さを画面の下までに設定 //================================//
export const useChangeTableHeight = (tableHeight: number, currentInnerHeight: number, previousInnerHeight: number, setTableHeight: React.Dispatch<React.SetStateAction<number>>) => {
  const changeTableHeight = useCallback(() => {
    if (currentInnerHeight > previousInnerHeight) {
      const diff = currentInnerHeight - previousInnerHeight
      setTableHeight(tableHeight + diff)
    } else if (document.documentElement.scrollHeight !== currentInnerHeight) {
      const diff = document.documentElement.scrollHeight - currentInnerHeight
      setTableHeight(tableHeight - diff)
    } else if (currentInnerHeight < previousInnerHeight) {
      const diff = previousInnerHeight - currentInnerHeight
      setTableHeight(tableHeight - diff)
    }
  }, [tableHeight, currentInnerHeight, previousInnerHeight])
  return [changeTableHeight]
}

// 現在と変更前のinnerHeightを設定 ※仮想テーブルの高さ設定に使用 //================================//
export const useChangeInnerHeight = (currentInnerHeight: number, setCurrentInnerHeight: React.Dispatch<React.SetStateAction<number>>, setPreviousInnerHeight: React.Dispatch<React.SetStateAction<number>>) => {
  const changeInnerHeight = useCallback(() => {
    // ウィンドウサイズ変更時に新しいinnreHeightと前のinnerHeightを更新
    if (currentInnerHeight !== window.innerHeight) {
      setPreviousInnerHeight(currentInnerHeight)
      setCurrentInnerHeight(window.innerHeight)
    }
  }, [window.innerHeight, currentInnerHeight])
  return [changeInnerHeight]
}

// 仮想テーブルの高さを画面の下までに設定。手入力画面で使用している。他画面の実装よりシンプルなのでこっちのほうがよさそう //================================//
export const useChangeTableHeightNew = (tableHeight: number, setTableHeight: React.Dispatch<React.SetStateAction<number>>): [voidF, numberF] => {
  // 新しいtableHeightを取得する
  const getNewTableHeight = () => {
    const el = document.getElementById('headerElement')
    const el2 = document.getElementById('bottomElement')
    const el3 = document.getElementById('toolbarElement')
    if (el && el2 && el3) {
      // テーブルの高さを画面上部から画面下固定の要素までの距離-画面上部からテーブル要素までの距離-ツールバーの高さを取得
      const newTableHeight = Math.ceil(el2.getBoundingClientRect().top - el.getBoundingClientRect().top - el3.clientHeight)
      return newTableHeight
    }
    return 0
  }

  const changeTableHeight = useCallback(() => {
    const newTableHeight = getNewTableHeight()
    if (newTableHeight > 0 && tableHeight !== newTableHeight) setTableHeight(newTableHeight)
  }, [tableHeight])
  return [changeTableHeight, getNewTableHeight]
}

// サイドメニュー、ヘッダータイトルを押したときの処理
export const useSetChoice = (history: any, location: any): [number, (selected: boolean, path: string) => void] => {
  const dispatch = useDispatch()
  const userKind = useSelector((state) => state.LoginReducer.userKind)
  const loading = useSelector((state) => state.PageReducer.loading)
  const TANTO_CD = useSelector((state) => state.LoginReducer.TANTO_CD)
  const keyInfo = useSelector((state) => state.LoginReducer.keyInfo)
  const NAME = useSelector((state) => state.LoginReducer.NAME)
  const isSuperUser = useSelector((state) => state.LoginReducer.isSuperUser)
  const isJocho = useSelector((state) => state.LoginReducer.isJocho)
  const isKoiki = useSelector((state) => state.LoginReducer.isKoiki)

  const setChoice = useCallback(
    (selected: boolean, path: string) => {
      if (!selected && !loading) {
        dispatch(PageAction.changeLoading(true))
        dispatch({ type: 'ModalShohinFormat' })
        switch (location.pathname) {
          case '/hanJoken':
            dispatch(SearchAction.format())
            if (userKind === 0) {
              // ジャペルユーザーの場合
              dispatch(SearchAction.setInitCode(keyInfo[0].JIGYO_CD, TANTO_CD, isSuperUser, isJocho, isKoiki)) // 所属部署に合わせた初期値をセット
            }
            dispatch(TableJokenAction.format())
            break
          case '/kuraJoken':
            dispatch(SearchJokenKuraAction.format())
            if (userKind === 0) {
              // ジャペルユーザーの場合
              dispatch(SearchJokenKuraAction.setInitCode(keyInfo[0].JIGYO_CD, TANTO_CD, isSuperUser, isJocho, isKoiki)) // 所属部署に合わせた初期値をセット
            }
            dispatch(TableJokenKuraAction.format())
            break
          case '/ritsuJoken':
            dispatch(SearchJokenRitsuAction.format())
            if (userKind === 0) {
              // ジャペルユーザーの場合
              dispatch(SearchJokenRitsuAction.setInitCode(keyInfo[0].JIGYO_CD, TANTO_CD, isSuperUser, isJocho, isKoiki)) // 所属部署に合わせた初期値をセット
            }
            dispatch(TableJokenRitsuAction.format())
            break
          case '/userToroku':
            dispatch(SearchUserAction.format())
            dispatch(TableUserAction.format())
            break
          case '/log':
            dispatch(SearchLogAction.format())
            dispatch(TableLogAction.format())
            break
          case '/tenyuryoku':
            dispatch(SearchTenyuryokuAction.format())
            if (userKind === 0) {
              // ジャペルユーザーの場合
              dispatch(SearchTenyuryokuAction.setInitCode(keyInfo[0].JIGYO_CD, TANTO_CD, isSuperUser, isJocho, isKoiki)) // 所属部署に合わせた初期値をセット
            }
            dispatch(TableTenyuryokuAction.format())
            break
        }
        dispatch(TableCommonAction.format())
        dispatch(PageAction.format())
        if (path === '/changePassword') {
          // パスワード変更画面の氏名にユーザー名を設定
          dispatch(LoginAction.changeInputUserName(NAME))
        }
        history.push(path)
      }
    },
    [loading],
  )
  return [userKind, setChoice]
}

// サマリ画面初期表示時に処理 サマリ画面に来たらブラウザバックで明細に戻れなくするため
export const useFormatDetail = (path: string): [voidF] => {
  const dispatch = useDispatch()
  const searchJokenDetailView = useSelector((state) => state.SearchJokenReducer.detailView)
  const searchJokenKuraDetailView = useSelector((state) => state.SearchJokenKuraReducer.detailView)
  const searchUserDetailView = useSelector((state) => state.SearchUserReducer.detailView)
  const searchTenyuryokuDetailView = useSelector((state) => state.SearchTenyuryokuReducer.detailView)
  const userKind = useSelector((state) => state.LoginReducer.userKind)
  const TANTO_CD = useSelector((state) => state.LoginReducer.TANTO_CD)
  const keyInfo = useSelector((state) => state.LoginReducer.keyInfo)
  const isSuperUser = useSelector((state) => state.LoginReducer.isSuperUser)
  const isJocho = useSelector((state) => state.LoginReducer.isJocho)
  const isKoiki = useSelector((state) => state.LoginReducer.isKoiki)

  const formatDetail = useCallback(() => {
    switch (path) {
      case '/hanJoken':
        // ブラウザバックで明細から戻った場合、明細を閉じる処理を実行
        if (searchJokenDetailView) {
          dispatch(SearchAction.closeDetail())
          dispatch(TableJokenAction.format())
          if (userKind === 0) {
            // ジャペルユーザーの場合
            dispatch(SearchAction.setInitCode(keyInfo[0].JIGYO_CD, TANTO_CD, isSuperUser, isJocho, isKoiki)) // 所属部署に合わせた初期値をセット
          }
        }
        break
      case '/kuraJoken':
        // ブラウザバックで明細から戻った場合、明細を閉じる処理を実行
        if (searchJokenKuraDetailView) {
          dispatch(SearchJokenKuraAction.closeDetail())
          dispatch(TableJokenKuraAction.format())
          if (userKind === 0) {
            // ジャペルユーザーの場合
            dispatch(SearchJokenKuraAction.setInitCode(keyInfo[0].JIGYO_CD, TANTO_CD, isSuperUser, isJocho, isKoiki)) // 所属部署に合わせた初期値をセット
          }
        }
        break
      case '/userToroku':
        // ブラウザバックで明細から戻った場合、明細を閉じる処理を実行
        if (searchUserDetailView) {
          dispatch(SearchUserAction.closeDetail())
          dispatch(TableUserAction.format())
        }
        break
      case '/tenyuryoku':
        // ブラウザバックで明細から戻った場合、明細を閉じる処理を実行
        if (searchTenyuryokuDetailView) {
          dispatch(SearchTenyuryokuAction.closeDetail())
          dispatch(TableTenyuryokuAction.format())
        }
        break
    }
    // サイドバー、ヘッダー以外でほかのメニューに飛んだあと戻ってきた場合、
    // 検索条件が残るため初期化する
    if (path !== '/hanJoken' && searchJokenDetailView) {
      dispatch(SearchAction.format())
      if (userKind === 0) {
        // ジャペルユーザーの場合
        dispatch(SearchAction.setInitCode(keyInfo[0].JIGYO_CD, TANTO_CD, isSuperUser, isJocho, isKoiki)) // 所属部署に合わせた初期値をセット
      }
      dispatch(TableJokenAction.format())
    }
    if (path !== '/kuraJoken' && searchJokenKuraDetailView) {
      dispatch(SearchAction.format())
      if (userKind === 0) {
        // ジャペルユーザーの場合
        dispatch(SearchJokenKuraAction.setInitCode(keyInfo[0].JIGYO_CD, TANTO_CD, isSuperUser, isJocho, isKoiki)) // 所属部署に合わせた初期値をセット
      }
      dispatch(TableJokenKuraAction.format())
    }
    if (path !== '/ritsuJoken') {
      dispatch(SearchJokenRitsuAction.format())
      if (userKind === 0) {
        // ジャペルユーザーの場合
        dispatch(SearchJokenRitsuAction.setInitCode(keyInfo[0].JIGYO_CD, TANTO_CD, isSuperUser, isJocho, isKoiki)) // 所属部署に合わせた初期値をセット
      }
    }
    if (path !== '/userToroku' && searchUserDetailView) {
      dispatch(SearchUserAction.format())
      dispatch(TableUserAction.format())
    }
    if (path !== '/log') {
      dispatch(SearchLogAction.format())
      dispatch(SearchLogAction.changeJigyoshoCode(String(keyInfo[0].JIGYO_CD)))
      dispatch(TableLogAction.format())
    }
    if (path !== '/tenyuryoku' && searchTenyuryokuDetailView) {
      dispatch(SearchTenyuryokuAction.format())
      if (userKind === 0) {
        // ジャペルユーザーの場合
        dispatch(SearchTenyuryokuAction.setInitCode(keyInfo[0].JIGYO_CD, TANTO_CD, isSuperUser, isJocho, isKoiki)) // 所属部署に合わせた初期値をセット
      }
      dispatch(TableTenyuryokuAction.format())
    }
    // 明細のページを初期化
    dispatch(TableCommonAction.formatPageDetail())
    // ページ毎で検索する画面以外はtableReducerを初期化
    if (path !== '/log' && path !== '/hanJoken' && path !== '/kuraJoken' && path !== '/tenyuryoku' && path !== '/ritsuJoken') dispatch(TableCommonAction.format())
    dispatch(PageAction.format())
  }, [searchJokenDetailView, searchUserDetailView])
  return [formatDetail]
}
