import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as LoginAction from 'actions/LoginAction' // 共通部分が多いActionはActionCreaterにまとめる
import * as PageAction from 'actions/PageAction' // 共通部分が多いActionはActionCreaterにまとめる
import * as SearchAction from 'actions/SearchActions/SearchJokenAction' // 共通部分が多いActionはActionCreaterにまとめる
import * as SearchRitsuAction from 'actions/SearchActions/SearchJokenRitsuAction' // 共通部分が多いActionはActionCreaterにまとめる
import * as SearchTenyuryokuAction from 'actions/SearchActions/SearchTenyuryokuAction' // 共通部分が多いActionはActionCreaterにまとめる
import * as SearchJokenKuraAction from 'actions/SearchActions/SearchJokenKuraAction' // 共通部分が多いActionはActionCreaterにまとめる
import * as TableCommonAction from 'actions/TableActions/TableAction'
import { search2 } from 'utils/apiUtil'
import { inputF, voidF, voidNumberF } from 'types/FunctionTypes'
import { commonMsg } from 'assets/MessageCode'

// ユーザーの種類(0: ジャペル, 1: 仕入先) //================================//
export const useUser = (): number => {
  return useSelector((state) => state.LoginReducer.userKind)
}

// マニュアルのPDFのページ数 //================================//
export const useManualNumPage = (): [number, voidNumberF] => {
  const dispatch = useDispatch()
  const manualNumPages = useSelector((state) => state.LoginReducer.manualNumPages)
  const changeManualNumPages = useCallback(
    (manualNumPages: number) => {
      dispatch(LoginAction.loginChangeManualNumPages(manualNumPages))
    },
    [manualNumPages],
  )

  return [manualNumPages, changeManualNumPages]
}

/**
 * ログイン情報取得
 *   ユーザーコード(メールアドレス、担当者コード)
 *   パスワード
 *   ログインボタン押下処理
 *   ローディング状態
 *   ログインメッセージ
 * @returns
 */
export const useLogin = (history: any): [string, inputF, string, inputF, voidF, boolean, string] => {
  const dispatch = useDispatch()
  const USER_CD = useSelector((state) => state.LoginReducer.USER_CD)
  const changeUserCode = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault()
      dispatch(LoginAction.changeUserCode(e.target.value))
    },
    [USER_CD],
  )
  const [PASSWORD, setPass] = useState('') // パスワードはStoreに貯めない
  const changePassword = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault()
      setPass(e.target.value)
    },
    [PASSWORD],
  )
  const login = useCallback(async () => {
    dispatch(PageAction.changeLoading(true))
    search2<{ ID: string; PASWARD: string }, any>(
      '/postLogin',
      { ID: USER_CD, PASWARD: PASSWORD },
      (res) => {
        dispatch(LoginAction.changeMessage(res.viewMsg))
        if (res.error) return
        const data = res.body
        dispatch(LoginAction.changeLoginKbn(data.loginKbn))
        dispatch(LoginAction.loginChangeUserKind(data.userKind))
        if (data.loginKbn > 0) {
          // ログイン成功
          dispatch(
            LoginAction.changeInfo({
              user_name: data.NAME,
              mail: data.MAIL,
              keyInfo: data.keyInfo,
              TANTO_CD: data.TANTO_CD,
              isJocho: data.isJocho, // 上長かどうか
              isKoiki: data.isKoiki, // 広域量販部かどうか
              isSuperUser: data.isSuperUser, // スーパーユーザーかどうか
              resetButton: data.resetButton,
              userTorokuMessage: data.userTorokuMessage,
            }),
          )
          switch (data.userKind) {
            case 0:
              // ジャペルログイン時
              dispatch(SearchAction.checkKakuinTaisyo(data.isJocho ? '2' : '1'))
              dispatch(SearchRitsuAction.checkKakuinTaisyo(data.isJocho ? '2' : '1'))
              dispatch(SearchTenyuryokuAction.checkKakuinTaisyo(data.isJocho ? '2' : '1'))
              dispatch(SearchJokenKuraAction.checkKakuinTaisyo(data.isJocho ? '2' : '1'))
              dispatch(SearchAction.setInitCode(data.keyInfo[0].JIGYO_CD, data.TANTO_CD, data.isSuperUser, data.isJocho, data.isKoiki)) // 所属部署に合わせた初期値をセット
              dispatch(SearchTenyuryokuAction.setInitCode(data.keyInfo[0].JIGYO_CD, data.TANTO_CD, data.isSuperUser, data.isJocho, data.isKoiki)) // 所属部署に合わせた初期値をセット
              dispatch(SearchJokenKuraAction.setInitCode(data.keyInfo[0].JIGYO_CD, data.TANTO_CD, data.isSuperUser, data.isJocho, data.isKoiki)) // 所属部署に合わせた初期値をセット
              break
            case 1:
              // 仕入先ログイン時
              dispatch(SearchAction.checkKakuinTaisyo('3'))
              dispatch(SearchRitsuAction.checkKakuinTaisyo('3'))
              dispatch(SearchTenyuryokuAction.checkKakuinTaisyo('3'))
              dispatch(SearchJokenKuraAction.checkKakuinTaisyo('3'))
              dispatch(TableCommonAction.format())
              break
          }
          dispatch(SearchAction.changeInitCheckbox())
          dispatch(SearchRitsuAction.changeInitCheckbox())
          dispatch(SearchTenyuryokuAction.changeInitCheckbox())
          dispatch(SearchJokenKuraAction.changeInitCheckbox())
          switch (data.loginKbn) {
            case 1:
              // 仮ログイン状態
              history.push('/changePassword') //パスワード変更画面に遷移
              break
            case 2:
              // 本ログイン状態
              history.push('/hanJoken') // 販売条件照会画面に遷移
              break
          }
        }
        dispatch(PageAction.changeLoading(true))
      },
      () => dispatch(LoginAction.changeMessage(commonMsg.ERROR_LOGIN_MSG)),
      () => dispatch(PageAction.changeLoading(false)),
    )
  }, [USER_CD, PASSWORD])
  const loading = useSelector((state) => state.PageReducer.loading)
  const message = useSelector((state) => state.LoginReducer.message)

  return [USER_CD, changeUserCode, PASSWORD, changePassword, login, loading, message]
}

// 異常発生時にログイン画面に戻す処理 //================================//
export const useErrorLogout = (history: any): [voidF] => {
  const dispatch = useDispatch()
  const backLogin = useCallback(() => {
    dispatch(LoginAction.changeInitMessageFlg(false))
    dispatch(LoginAction.changeMessage(commonMsg.ERROR_LOGIN_MSG))
    history.push('/') //ログイン画面に遷移
  }, [])
  return [backLogin]
}
