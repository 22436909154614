import React, { useEffect } from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import M_FieldText from 'components/1_Atoms/2_Molecules/M_FieldTextNew'
import M_FieldDateSet from 'components/1_Atoms/2_Molecules/M_FieldDateSetNew'
import M_FieldSelect from 'components/1_Atoms/2_Molecules/M_FieldSelectNew'
import M_ButtonSubmit from 'components/1_Atoms/2_Molecules/M_ButtonSubmit'
import { organismGroupLog } from 'utils/logUtil'
import { baseInfo, s3FileInfo } from 'types/SearchTypes'
import { useSyoriYmd, useShiiresaki, useJigyosho, useTanto, useDenpyoNo, useSeisanNaiyo, useShohin, useFile, useSearch, useExpand } from 'hooks/useTenyuryoku'
import M_FieldJotai from 'components/1_Atoms/2_Molecules/M_FieldJotai'
import Typography from '@material-ui/core/Typography'
import M_TextFile from 'components/1_Atoms/2_Molecules/M_TextFile'
import { postFetcher } from 'SWRProvider'
import { commonMsg, msgCode } from 'assets/MessageCode'
import { useQuery } from 'react-query'
import { Collapse } from '@material-ui/core'

const manualMode = process.env.REACT_APP_MANUAL_MODE === '1'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      // height: window.innerHeight * 0.3,
    },
    grid: {
      flexGrow: 1,
    },
    box: {
      textAlign: 'left',
    },
    impotant: {
      color: '#FF0000',
    },
  }),
)

type Props = {
  info: baseInfo
}

const O_SearchBoxTenyuryokuShiiresaki = ({ info: info }: Props) => {
  useEffect(() => organismGroupLog('O_SearchBoxTenyuryokuShiiresaki'))
  const classes = useStyles()
  // const dispatch = useDispatch()

  const [SYORI_YMD_FROM, SYORI_YMD_TO, changeSyoriYmdFrom, changeSyoriYmdTo] = useSyoriYmd()
  const [SIIRE_CD, changeSiire] = useShiiresaki()
  const [JIGYO_CD, changeJigyo] = useJigyosho()
  const [TANTO_CD, changeTanto] = useTanto()
  const [DENPYO_NO, changeDenpyoNo] = useDenpyoNo()
  const [SEISAN_NAIYO, changeSeisanNaiyo] = useSeisanNaiyo()
  const [mishonin, shonin, hinin, _JochoShonin, _jochoMishonin, _hininMikakunin, _jochoHininMikakunin, _hininkakuninzumi, _jochoHininKakuninzumi, changeMishonin, changeShonin, changeHinin, _changeJochoShonin, _changeJochoMishonin, _changeHininMikakunin, _changeJochoHininMikakunin, _changeHininkakuninzumi, _changeJochoHininkakuninzumi] = useShohin()
  const [file, _changeFile] = useFile()
  const [detailView, clickReset, clickInputCSV] = useSearch()

  // 検索条件初期化ボタンの文言を取得
  const fetcher = postFetcher('/getMessage', msgCode.SHIIRESAKI_RESET_BUTTON)
  const { data, isFetching, isLoading, error } = useQuery(['postResetButtonWordShiiresaki', []], fetcher)
  const resetButton = error ? commonMsg.ERROR_MSG : isFetching || isLoading ? 'Loading...' : data.body

  const getTextOrText = (label: string, valueCode: string, change?: (e: React.ChangeEvent<HTMLInputElement>) => void, blur?: (e: React.FocusEvent<HTMLInputElement>) => void, type: string = 'text', sm1?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12, sm2?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12) => {
    if (detailView) return <M_FieldText label={label} valueCode={valueCode} disabled={true} type={type} sm1={sm1} sm2={sm2} />
    return <M_FieldText label={label} valueCode={valueCode} max={40} change={change} blurFunc={blur} type={type} sm1={sm1} sm2={sm2} />
  }

  const [expand] = useExpand()

  return (
    <div className={classes.root}>
      <Collapse in={expand} timeout="auto">
        <Grid container spacing={1}>
          {/* //////////////////// 1 行目 //////////////////// */}
          <Grid item xs={12} sm={4} className={classes.grid}>
            {/* ==== 処理票日付 ==== */}
            <M_FieldDateSet
              key1={1} //
              label="処理票日付"
              value1={SYORI_YMD_FROM}
              blurFunc1={changeSyoriYmdFrom}
              focus={false}
              disabled1={detailView}
              key2={2}
              value2={SYORI_YMD_TO}
              blurFunc2={changeSyoriYmdTo}
              disabled2={detailView}
              type="date"
            />
          </Grid>
          <Grid item xs={12} sm={4} className={classes.grid}>
            {/* ==== 仕入先 ==== */}
            <M_FieldSelect //
              label="仕入先"
              valueCode={SIIRE_CD}
              valueSet={info.siireList}
              event={changeSiire}
              disabled={detailView}
              blackout={manualMode}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            {/* -------- [ 確認対象表示ボタン ] -------- */}
            <Grid container justifyContent="flex-end">
              <M_ButtonSubmit label={'CSV登録'} event={clickInputCSV} disabled={false} />
            </Grid>
          </Grid>

          {/* //////////////////// 2 行目 //////////////////// */}
          <Grid item xs={12} sm={4} className={classes.grid}>
            {/* ==== 事業所 ==== */}
            <M_FieldSelect //
              label="事業所"
              valueCode={JIGYO_CD}
              valueSet={info.jigyoList}
              event={changeJigyo}
              disabled={detailView}
              blackout={manualMode}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            {/* ==== 担当者 ==== */}
            <M_FieldSelect //
              label="担当者"
              valueCode={TANTO_CD}
              valueSet={info.tantoList}
              event={changeTanto}
              disabled={detailView}
              blackout={manualMode}
            />
          </Grid>
          <Grid item xs={12} sm={4} className={classes.grid}>
            <Grid container justifyContent="flex-end">
              {detailView ? null : <M_ButtonSubmit label={resetButton} event={clickReset} disabled={detailView} />}
            </Grid>
          </Grid>

          {/* //////////////////// 3 行目 //////////////////// */}
          <Grid item xs={12} sm={4} className={classes.grid}>
            {/* ==== 処理No ==== */}
            {getTextOrText('処理No', DENPYO_NO, undefined, changeDenpyoNo, 'number')}
          </Grid>
          <Grid item xs={12} sm={6}>
            {/* ==== 件名 ==== */}
            {getTextOrText('件名', SEISAN_NAIYO, undefined, changeSeisanNaiyo, 'text', 2, 10)}
          </Grid>
          <Grid item xs={12} sm={2}></Grid>

          {/* //////////////////// 5 行目 //////////////////// */}
          <Grid item xs={12} sm={4} className={classes.grid}>
            {/* ==== 状態 ==== */}
            {<M_FieldJotai label={'状態'} check1={mishonin} check2={shonin} check3={hinin} event1={changeMishonin} event2={changeShonin} event3={changeHinin} disabled={detailView} />}
          </Grid>
          <Grid item xs={12} sm={4}></Grid>
          <Grid item xs={12} sm={4}>
            {detailView ? (
              <>
                <Typography variant="body1" display="block" gutterBottom>
                  {'添付ファイル'}
                </Typography>
                <Grid container justifyContent="flex-start">
                  {file.map((r: s3FileInfo) => {
                    return <M_TextFile file={r} DENPYO_NO={DENPYO_NO} delDisabled={true} />
                  })}
                </Grid>
              </>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Collapse>
    </div>
  )
}
export default React.memo(O_SearchBoxTenyuryokuShiiresaki)
