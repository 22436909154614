import { Reducer } from 'redux'
import produce from 'immer'
import { modalShohin } from 'types/ModalTypes'
import { F_STR } from 'assets/FormatNumbers'
import { responseJokenRitsuJogaiItem } from 'types/ResponseTypes'

export type ModalShohinState = {
  modalShohin: modalShohin // 商品コード貼付画面情報
  loading: boolean // ローディング状態
  message: string // 商品コード貼付画面のメッセージ
}

const initialState: ModalShohinState = {
  modalShohin: {
    openShohin: false, // 商品コード貼付画面を開いているかどうか(商品)
    shohinInfo: [], // マスタ取得情報(商品)
  },
  loading: false, // ローディング状態
  message: F_STR, // 商品コード貼付画面のメッセージ
}

export type ModalShohinType =
  | 'ModalShohinOpen' // モーダルを開く処理
  | 'ModalShohinClose' // モーダルを閉じる処理
  | 'ModalShohinChangeLoading' // ローディング状態を変更する処理
  | 'ModalShohinFormat' // 商品コード貼付画面情報を初期化する処理
  | 'ModalShohinChangeMessage' // 商品コード貼付画面のメッセージを変更する処理
  | 'dummy'

export type ModalShohinAction = {
  type: ModalShohinType
  shohinInfo?: responseJokenRitsuJogaiItem[]
  loading?: boolean // ローディング状態
  message?: string // 商品コード貼付画面のメッセージ
}

export const ModalShohinReducer: Reducer<ModalShohinState, ModalShohinAction> = produce((draft: ModalShohinState, action: ModalShohinAction) => {
  switch (action.type) {
    case 'ModalShohinOpen': {
      draft.modalShohin.openShohin = true
      draft.modalShohin.shohinInfo = action.shohinInfo!
      return
    }
    case 'ModalShohinClose': {
      draft.modalShohin.openShohin = false
      draft.modalShohin.shohinInfo = []
      draft.message = F_STR
      return
    }
    // 条件初期化 //===========================//
    case 'ModalShohinFormat': {
      for (const key in draft) {
        const tempKey: keyof ModalShohinState = key as keyof ModalShohinState
        ;(draft as any)[tempKey] = initialState[tempKey]
      }
      return
    }
    // メッセージ変更 //===========================//
    case 'ModalShohinChangeMessage': {
      draft.message = action.message!
      return
    }
    // ローディング処理 //===========================//
    case 'ModalShohinChangeLoading': {
      draft.loading = action.loading!
      return
    }
  }
}, initialState)
