/* eslint-disable react/jsx-pascal-case */
import React, { useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import A_SelectBoxNewNew from 'components/1_Atoms/A_SelectBoxNewNew'
import A_IconMust from '../A_IconMust'
import { moleculeLog } from 'utils/logUtil'
import { select } from 'types/SearchTypes'
import { SingleValue } from 'react-select'
import { HtmlToolTip } from 'assets/ToolTips'

type option = { value: string; label: string }
type Props = {
  label: string
  must?: boolean
  valueSet1: option[]
  valueSet2: option[]
  valueSet3: option[]
  defValue1: string
  defValue2: string
  defValue3: string
  event1: (targetValue: SingleValue<select>) => void
  event2: (targetValue: SingleValue<select>) => void
  event3: (targetValue: SingleValue<select>) => void
  disabled1?: boolean
  disabled2?: boolean
  disabled3?: boolean
  sm1?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  sm2_1?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  sm2_2?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  sm2_3?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  lib?: boolean
}

const M_FieldSelectSeisan = ({ label, must = false, defValue1, defValue2, defValue3, valueSet1, valueSet2, valueSet3, event1, event2, event3, disabled1 = false, disabled2 = false, disabled3 = false, sm1 = 3, sm2_1 = 3, sm2_2 = 3, sm2_3 = 3, lib = true }: Props) => {
  useEffect(() => moleculeLog('M_FieldSelectSeisan'))
  const newSelect: select = { value: '', label: '' }
  const selected1: select = valueSet1.find((data) => {
    return data.value === defValue1
  }) ?? { ...newSelect }
  const selected2: select = valueSet2.find((data) => {
    return data.value === defValue2
  }) ?? { ...newSelect }
  const selected3: select = valueSet3.find((data) => {
    return data.value === defValue3
  }) ?? { ...newSelect }

  return (
    <Grid container justify="flex-start">
      <Grid item xs={12} sm={sm1}>
        <HtmlToolTip
          title={
            <Typography variant="body2">
              {selected1.label}
              <br />
              {selected2.label}
            </Typography>
          }
          placement="bottom-start"
        >
          <Typography variant="body1" display="block" gutterBottom>
            {label}
            <A_IconMust must={must} />
          </Typography>
        </HtmlToolTip>
      </Grid>
      <Grid item xs={12} sm={sm2_1}>
        <A_SelectBoxNewNew valueSet={valueSet1} defValue={selected1} event={event1} disabled={disabled1} lib={lib} />
      </Grid>
      <Grid item xs={12} sm={sm2_2}>
        <A_SelectBoxNewNew valueSet={valueSet2} defValue={selected2} event={event2} disabled={disabled2} lib={lib} />
      </Grid>
      {valueSet3.length <= 0 ? (
        <></>
      ) : (
        <Grid item xs={12} sm={sm2_3}>
          <A_SelectBoxNewNew valueSet={valueSet3} defValue={selected3} event={event3} disabled={disabled3} lib={lib} />
        </Grid>
      )}
    </Grid>
  )
}

export default React.memo(M_FieldSelectSeisan)
