import { Reducer } from 'redux'
import produce from 'immer'
import { responseJokenRitsu } from 'types/ResponseTypes'
import { F_NUM, F_STR } from 'assets/FormatNumbers'
import { checkListRitsu } from 'types/SearchTypes'
import { isUniqueJokenRitsuDetail } from 'utils/booleanUtil'

export type TableJokenRitsuState = {
  jokenList: responseJokenRitsu[]
  SYORI_YM: number // 検索時点の年月を保持
  selectJoken: checkListRitsu[] // 選択している率管理Noを保持
  page: number
  perPage: number
  message: string
}

const initialState: TableJokenRitsuState = {
  jokenList: [],
  selectJoken: [],
  SYORI_YM: F_NUM, // 検索時点の年月を保持
  page: 0,
  perPage: 50,
  message: F_STR,
}

export type TableJokenRitsuType =
  | 'TableJokenRitsuCheck' //
  | 'TableJokenRitsuFormatPage'
  | 'TableJokenRitsuChangePage'
  | 'TableJokenRitsuChangePerPage'
  | 'TableJokenRitsuChangeMessage'
  | 'TableJokenRitsuFormat'
  | 'TableJokenRitsuAllCheck'
  | 'TableJokenRitsuChangeHininRiyu'
  | 'TableJokenRitsuChangeJochoHininRiyu'
  | 'TableJokenRitsuChangeCheck' // 率条件をチェックする処理
  | 'TableJokenRitsuClearCheck' // チェックリストを初期化する処理
  | 'dummy'

export type TableJokenRitsuAction = {
  type: TableJokenRitsuType
  joken?: responseJokenRitsu
  tekiyoDate?: number
  jokenList?: responseJokenRitsu[]
  isChecked?: boolean
  message?: string
  page?: number
  RITU_KANRI_NO?: string
  HININ_RIYU?: string
  checkData?: checkListRitsu
  checkDataList?: checkListRitsu[]
}

export const TableJokenRitsuReducer: Reducer<TableJokenRitsuState, TableJokenRitsuAction> = produce((draft: TableJokenRitsuState, action: TableJokenRitsuAction) => {
  switch (action.type) {
    // チェックリストを初期化する処理 //=========================//
    case 'TableJokenRitsuClearCheck': {
      draft.selectJoken = initialState.selectJoken
      return
    }
    case 'TableJokenRitsuChangeCheck': {
      draft.selectJoken = action.checkDataList!
      return
    }
    case 'TableJokenRitsuChangeJochoHininRiyu': {
      const checkData = action.checkData!
      draft.selectJoken = draft.selectJoken.filter((r) => !isUniqueJokenRitsuDetail(r, checkData))
      draft.selectJoken.push(checkData)
      return
    }
    case 'TableJokenRitsuChangeHininRiyu': {
      const checkData = action.checkData!
      draft.selectJoken = draft.selectJoken.filter((r) => !isUniqueJokenRitsuDetail(r, checkData))
      draft.selectJoken.push(checkData)
      return
    }
    case 'TableJokenRitsuAllCheck': {
      const isChecked = action.isChecked!
      const stateList = draft.selectJoken
      if (isChecked) {
        // チェック除去
        const selectList = []
        for (let i = 0; i < stateList.length; i++) {
          selectList.push(stateList[i].isChecked ? { ...stateList[i], isChecked: false } : stateList[i])
        }
        draft.selectJoken = selectList
      } else {
        // チェック付与
        const jokenList = action.jokenList!
        const originList: checkListRitsu[] = []
        for (let i = 0; i < jokenList.length; i++) {
          originList.push({
            isChecked: true,
            RITU_KANRI_NO: jokenList[i].RITU_KANRI_NO,
            JOTAI_KBN: jokenList[i].JOTAI_KBN,
            KAKUNIN_KBN: jokenList[i].KAKUNIN_KBN,
            ADD_YMD: jokenList[i].ADD_YMD,
            ADD_HNS: jokenList[i].ADD_HNS,
            TOKUI_CD: jokenList[i].TOKUI_CD,
            HININ_RIYU: jokenList[i].HININ_RIYU,
            JOCHO_HININ_RIYU: jokenList[i].JOCHO_HININ_RIYU,
          })
        }
        for (let i = 0; i < stateList.length; i++) {
          for (let j = 0; j < originList.length; j++) {
            if (isUniqueJokenRitsuDetail(stateList[i], originList[j])) {
              originList.splice(j, 1)
              originList.push({ ...stateList[i], isChecked: true })
              break
            }
          }
        }
        draft.selectJoken = originList
      }
      return
    }
    case 'TableJokenRitsuCheck': {
      const checkData = action.checkData!
      const isChecked = action.isChecked!
      const selectList = draft.selectJoken.filter((r) => isUniqueJokenRitsuDetail(r, checkData))
      if (selectList.length <= 0) {
        draft.selectJoken.push({ ...checkData, isChecked: !isChecked })
      } else {
        draft.selectJoken = draft.selectJoken.filter((r) => !isUniqueJokenRitsuDetail(r, checkData))
        draft.selectJoken.push({ ...selectList[0], isChecked: !isChecked })
      }
      return
    }
    case 'TableJokenRitsuFormatPage': {
      draft.perPage = initialState.perPage
      draft.page = initialState.page
      draft.message = initialState.message
      return
    }
    case 'TableJokenRitsuChangePage': {
      draft.page = action.page!
      return
    }
    case 'TableJokenRitsuChangePerPage': {
      draft.perPage = action.page!
      draft.page = 0
      return
    }
    case 'TableJokenRitsuChangeMessage': {
      draft.message = action.message!
      return
    }
    case 'TableJokenRitsuFormat': {
      for (const key in draft) {
        const tempKey: keyof TableJokenRitsuState = key as keyof TableJokenRitsuState
        ;(draft as any)[tempKey] = initialState[tempKey]
      }
      return
    }
  }
}, initialState)
