import { Reducer } from 'redux'
import produce from 'immer'
import { modalUser } from 'types/ModalTypes'
import { F_NUM, F_STR } from 'assets/FormatNumbers'
import { select } from 'types/SearchTypes'

export type ModalUserState = {
  modalUser: modalUser // ユーザー追加画面情報
  jigyoSet: select[] // モーダルを開いた時点の事業所リスト
  siireSet: select[] // モーダルを開いた時点の仕入先リスト
  loading: boolean // ローディング状態
  message: string // メッセージ
  messageColor: string // メッセージの色
}

const initialState: ModalUserState = {
  modalUser: {
    openUser: false, // ユーザー追加画面を開いているかどうか
    JIGYO_CD: F_NUM, // 事業所コード
    JIGYO_NM: F_STR, // 事業所名
    SIIRE_CD: F_NUM, // 仕入先コード
    SIIRE_NM: F_STR, // 仕入先名
    TOKUI_CD: F_NUM, // 仕入先コード
    TOKUI_NM: F_STR, // 仕入先名
    MAIL: F_STR, // メールアドレス
    NAME: F_STR, // 氏名
  },
  jigyoSet: [],
  siireSet: [],
  loading: false, // ローディング状態
  message: F_STR, // メッセージ
  messageColor: 'red', // メッセージの色
}

export type ModalUserType =
  | 'ModalUserOpen' // モーダルを開く処理
  | 'ModalUserClose' // モーダルを閉じる処理
  | 'ModalUserChangeLoading' // ローディング状態を変更する処理
  | 'ModalUserFormat' // ユーザー追加画面情報を初期化する処理
  | 'ModalUserChangeMessage' // メッセージを変更する処理
  | 'ModalUserChangeTokuiCode' // 得意先コードを変更する処理
  | 'ModalUserChangeTokuiName' // 得意先名を変更する処理
  | 'ModalUserChangeMail' // メールアドレスを変更する処理
  | 'ModalUserChangeName' // 氏名を変更する処理
  | 'ModalUserDeleteMessage' //メッセージを初期化する処理
  | 'dummy'

export type ModalUserAction = {
  type: ModalUserType
  jigyoSet?: select[] // モーダルを開いた時点の事業所リスト
  siireSet?: select[] // モーダルを開いた時点の仕入先リスト
  loading?: boolean // ローディング状態
  message?: string // メッセージ
  TOKUI_CD?: number // 得意先コード
  TOKUI_NM?: string // 得意先名
  MAIL?: string // メールアドレス
  NAME?: string // 氏名
  messageColor?: string // メッセージの色
  JIGYO_CD?: number // 事業所コード
  SIIRE_CD?: number // 仕入先コード
}

export const ModalUserReducer: Reducer<ModalUserState, ModalUserAction> = produce((draft: ModalUserState, action: ModalUserAction) => {
  switch (action.type) {
    // メッセージを初期化する処理 //===========================//
    case 'ModalUserDeleteMessage': {
      draft.message = initialState.message
      return
    }
    // 氏名を変更する処理 //===========================//
    case 'ModalUserChangeName': {
      draft.modalUser.NAME = action.NAME!
      return
    }
    // メールアドレスを変更する処理 //===========================//
    case 'ModalUserChangeMail': {
      draft.modalUser.MAIL = action.MAIL!
      return
    }
    // 得意先名を変更する処理 //===========================//
    case 'ModalUserChangeTokuiName': {
      draft.modalUser.TOKUI_NM = action.TOKUI_NM!
      return
    }
    //  得意先コードを変更する処理 //===========================//
    case 'ModalUserChangeTokuiCode': {
      draft.modalUser.TOKUI_CD = action.TOKUI_CD!
      return
    }
    case 'ModalUserOpen': {
      draft.modalUser.openUser = true
      draft.jigyoSet = action.jigyoSet!
      draft.siireSet = action.siireSet!
      draft.modalUser.JIGYO_CD = action.JIGYO_CD!
      const jigyo = action.jigyoSet!.find((data: select) => data.value === draft.modalUser.JIGYO_CD.toString())
      draft.modalUser.JIGYO_NM = jigyo ? jigyo.label : initialState.modalUser.JIGYO_NM
      draft.modalUser.SIIRE_CD = action.SIIRE_CD!
      const siire = action.siireSet!.find((data: select) => data.value === draft.modalUser.SIIRE_CD.toString())
      draft.modalUser.SIIRE_NM = siire ? siire.label : initialState.modalUser.SIIRE_NM
      return
    }
    case 'ModalUserClose': {
      for (const key in draft) {
        const tempKey: keyof ModalUserState = key as keyof ModalUserState
        ;(draft as any)[tempKey] = initialState[tempKey]
      }
      return
    }
    // 条件初期化 //===========================//
    case 'ModalUserFormat': {
      for (const key in draft) {
        const tempKey: keyof ModalUserState = key as keyof ModalUserState
        ;(draft as any)[tempKey] = initialState[tempKey]
      }
      return
    }
    // メッセージ変更 //===========================//
    case 'ModalUserChangeMessage': {
      draft.message = action.message!
      draft.messageColor = action.messageColor!
      return
    }
    // ローディング処理 //===========================//
    case 'ModalUserChangeLoading': {
      draft.loading = action.loading!
      return
    }
  }
}, initialState)
