import { SearchJokenRitsuAction } from 'reducers/SearchReducers/SearchJokenRitsuReducer'
import { tokuisaki, shiiresaki } from 'types/ReferTypes'
import { fixNullValue } from 'utils/numberUtil'

// 上長否認　確認済チェックボックスを変更する処理 //================================//
export const changeJochoHininkakuninzumiCheckbox = (): SearchJokenRitsuAction => {
  return {
    type: 'SearchJokenRitsuChangeJochoHininkakuninzumiCheckbox',
  }
}

// メーカー否認　確認済チェックボックスを変更する処理 //================================//
export const changeHininkakuninzumiCheckbox = (): SearchJokenRitsuAction => {
  return {
    type: 'SearchJokenRitsuChangeHininkakuninzumiCheckbox',
  }
}

// 上長否認　未確認チェックボックスを変更する処理 //================================//
export const changeJochoHininMikakuninCheckbox = (): SearchJokenRitsuAction => {
  return {
    type: 'SearchJokenRitsuChangeJochoHininMikakuninCheckbox',
  }
}

// メーカー否認　未確認チェックボックスを変更する処理 //================================//
export const changeHininMikakuninCheckbox = (): SearchJokenRitsuAction => {
  return {
    type: 'SearchJokenRitsuChangeHininMikakuninCheckbox',
  }
}

// 上長未承認チェックボックスを変更する処理 //================================//
export const changeJochoMishoninCheckbox = (): SearchJokenRitsuAction => {
  return {
    type: 'SearchJokenRitsuChangeJochoMishoninCheckbox',
  }
}

// 上長承認チェックボックスを変更する処理 //================================//
export const changeJochoShoninCheckbox = (): SearchJokenRitsuAction => {
  return {
    type: 'SearchJokenRitsuChangeJochoShoninCheckbox',
  }
}

// 検索後にメッセージを消さないフラグを変更する処理 //===========================//
export const changeIsDeleteMessage = (isNotDeleteMessage: boolean): SearchJokenRitsuAction => {
  return {
    type: 'SearchJokenRitsuChangeIsDeleteMessage',
    isNotDeleteMessage: isNotDeleteMessage,
  }
}

// 入力担当者フォーム処理 //================================//
export const changeAddTantoCode = (tantoCode: string): SearchJokenRitsuAction => {
  return {
    type: 'SearchJokenRitsuChangeAddTantoCode',
    tantoCode: fixNullValue(tantoCode),
  }
}

// 初期状態のチェックボックスを変更する処理 //===========================//
export const changeInitCheckbox = (): SearchJokenRitsuAction => {
  return {
    type: 'SearchJokenRitsuChangeInitCheckbox',
  }
}

// 確認対象表示状態にする処理 //===========================//
export const checkKakuinTaisyo = (initJotaiKbn: string): SearchJokenRitsuAction => {
  return {
    type: 'SearchJokenRitsuChangeKakunintaisyoCheckbox',
    initJotaiKbn: initJotaiKbn,
  }
}

// 確認済チェックボックスフォーム処理 //================================//
export const changeKakuninzumiCheckbox = (): SearchJokenRitsuAction => {
  return {
    type: 'SearchJokenRitsuChangeKakuninzumiCheckbox',
  }
}

// 否認チェックボックスフォーム処理 //================================//
export const changeHininCheckbox = (): SearchJokenRitsuAction => {
  return {
    type: 'SearchJokenRitsuChangeHininCheckbox',
  }
}

// 承認チェックボックスフォーム処理 //================================//
export const changeShoninCheckbox = (): SearchJokenRitsuAction => {
  return {
    type: 'SearchJokenRitsuChangeShoninCheckbox',
  }
}

// 未承認チェックボックスフォーム処理 //================================//
export const changeMishoninCheckbox = (): SearchJokenRitsuAction => {
  return {
    type: 'SearchJokenRitsuChangeMishoninCheckbox',
  }
}

// 処理年月終了フォーム処理 //================================//
export const changeSyoriEndDate = (syoriEndDate: string): SearchJokenRitsuAction => {
  return {
    type: 'SearchJokenRitsuChangeSyoriEndDate',
    syoriEndDate: syoriEndDate,
  }
}

// 処理年月開始フォーム処理 //================================//
export const changeSyoriStartDate = (syoriStartDate: string): SearchJokenRitsuAction => {
  return {
    type: 'SearchJokenRitsuChangeSyoriStartDate',
    syoriStartDate: syoriStartDate,
  }
}

// 得意先フォーム処理 //================================//
export const changeTokuisakiCode = (tokuisakiCode: string): SearchJokenRitsuAction => {
  return {
    type: 'SearchJokenRitsuChangeTokuisakiCode',
    tokuisakiCode: fixNullValue(tokuisakiCode),
  }
}
export const changeTokuisakiList = (tokuisakiList: tokuisaki[]): SearchJokenRitsuAction => {
  return {
    type: 'SearchJokenRitsuChangeTokuisakiList',
    tokuisakiList: tokuisakiList,
  }
}
export const changeTokuisakiError = (error: boolean): SearchJokenRitsuAction => {
  return {
    type: 'SearchJokenRitsuChangeTokuisakiError',
    error: error,
  }
}
export const deleteTokuisaki = (): SearchJokenRitsuAction => {
  return {
    type: 'SearchJokenRitsuDeleteTokuisaki',
  }
}

// 事業所フォーム処理 //================================//
export const changeJigyoshoCode = (jigyoshoCode: string): SearchJokenRitsuAction => {
  return {
    type: 'SearchJokenRitsuChangeJigyoshoCode',
    jigyoshoCode: fixNullValue(jigyoshoCode),
  }
}
// 初期フォームセット //================================//
export const setInitCode = (jigyoshoCode: number, tantoCode: number, isSuperUser: boolean, isJocho: boolean, isKoiki: boolean): SearchJokenRitsuAction => {
  return {
    type: 'SearchJokenRitsuChangeInitCode',
    jigyoshoCode: jigyoshoCode,
    tantoCode: tantoCode,
    isSuperUser: isSuperUser,
    isJocho: isJocho,
    isKoiki: isKoiki,
  }
}

// 仕入先フォーム処理 //================================//
export const changeShiiresakiCode = (shiiresakiCode: string): SearchJokenRitsuAction => {
  return {
    type: 'SearchJokenRitsuChangeShiiresakiCode',
    shiiresakiCode: fixNullValue(shiiresakiCode),
  }
}
export const changeShiiresakiList = (shiiresakiList: shiiresaki[]): SearchJokenRitsuAction => {
  return {
    type: 'SearchJokenRitsuChangeShiiresakiList',
    shiiresakiList: shiiresakiList,
  }
}
export const changeShiiresakiError = (error: boolean): SearchJokenRitsuAction => {
  return {
    type: 'SearchJokenRitsuChangeShiiresakiError',
    error: error,
  }
}
export const deleteShiiresakiName = (): SearchJokenRitsuAction => {
  return {
    type: 'SearchJokenRitsuDeleteShiiresakiName',
  }
}

// 条件初期化 //===========================//
export const format = (): SearchJokenRitsuAction => {
  return {
    type: 'SearchJokenRitsuFormat',
  }
}

// ローディング処理 //===========================//
export const changeLoading = (loading: boolean): SearchJokenRitsuAction => {
  return {
    type: 'SearchJokenRitsuChangeLoading',
    loading: loading,
  }
}

// 検索処理 //===========================//
export const changeTrail = (trail: number): SearchJokenRitsuAction => {
  return {
    type: 'SearchJokenRitsuChangeTrail',
    trail: trail,
  }
}

// 条件完全初期化 //===========================//
export const allFormat = (): SearchJokenRitsuAction => {
  return {
    type: 'SearchJokenRitsuAllFormat',
  }
}
