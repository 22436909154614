import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as SearchAction from 'actions/SearchActions/SearchLogAction' // 共通部分が多いActionはActionCreaterにまとめる
import * as TableCommonAction from 'actions/TableActions/TableAction'
import * as TableLogAction from 'actions/TableActions/TableLogAction'
import * as PageAction from 'actions/PageAction'
import { inputF, promiseBooleanF, voidF, voidNumberF, voidStringF } from 'types/FunctionTypes'
import { getRequestLog, postInfo, search2 } from 'utils/apiUtil'
import { getTimestampFromNumber } from 'utils/dateUtil'
import { convertNumber } from 'utils/numberUtil'
import { jigyosho, keyInfo, shiiresaki, tanto, tokuisaki, user } from 'types/ReferTypes'
import { responseLog } from 'types/ResponseTypes'
import { requestLog } from 'types/RequestTypes'
import { select } from 'types/SearchTypes'

// 年月日 //================================//
export const useDateTime = (): [string, string, inputF, inputF] => {
  const dispatch = useDispatch()
  const DT_FROM = useSelector((state) => state.SearchLogReducer.searchLog.DT_FROM)
  const DT_TO = useSelector((state) => state.SearchLogReducer.searchLog.DT_TO)
  const changeDateTimeFrom = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault()
      // 入力されていたら時間00:00:00を追加して登録
      dispatch(SearchAction.changeDateTimeFrom(e.target.value ? e.target.value + 'T00:00:00' : e.target.value))
    },
    [DT_FROM],
  )
  const changeDateTimeTo = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault()
      // 入力されていたら時間23:59:59を追加して登録
      dispatch(SearchAction.changeDateTimeTo(e.target.value ? e.target.value + 'T23:59:59' : e.target.value))
    },
    [DT_TO],
  )
  return [getTimestampFromNumber(DT_FROM === -1 ? 0 : convertNumber(DT_FROM, 12)), getTimestampFromNumber(convertNumber(DT_TO, 12)), changeDateTimeFrom, changeDateTimeTo]
}

// 区分 //================================//
export const useKbn = (): [boolean, boolean, voidF, voidF] => {
  const dispatch = useDispatch()
  const torokuCheckbox = useSelector((state) => state.SearchLogReducer.searchLog.torokuCheckbox)
  const sakujoCheckbox = useSelector((state) => state.SearchLogReducer.searchLog.sakujoCheckbox)
  const allCheckbox = [torokuCheckbox, sakujoCheckbox]

  const isUncheckAll = (index: number, allCheckbox: boolean[]) => {
    const otherCheckbox = allCheckbox.filter((_, i) => {
      return i !== index
    })
    return (
      allCheckbox[index] &&
      otherCheckbox.every((r) => {
        return !r
      })
    )
  }

  const changeToroku = useCallback(() => {
    const index = 0
    dispatch(SearchAction.changeTourokuCheckbox())
    if (isUncheckAll(index, allCheckbox)) {
      // すべてのチェックボックスが外れる場合、削除にチェックをつける
      dispatch(SearchAction.changeSakujoCheckbox())
    }
  }, [...allCheckbox])
  const changeSakujo = useCallback(() => {
    const index = 1
    dispatch(SearchAction.changeSakujoCheckbox())
    if (isUncheckAll(index, allCheckbox)) {
      // すべてのチェックボックスが外れる場合、登録にチェックをつける
      dispatch(SearchAction.changeTourokuCheckbox())
    }
  }, [...allCheckbox])
  return [torokuCheckbox, sakujoCheckbox, changeToroku, changeSakujo]
}

// 事業所コード //================================//
export const useJigyoshoCode = (): [number, inputF, voidStringF] => {
  const dispatch = useDispatch()
  const JIGYO_CD = useSelector((state) => state.SearchLogReducer.searchLog.JIGYO_CD)
  const changeJigyoCode = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(SearchAction.changeJigyoshoCode(e.target.value))
    },
    [JIGYO_CD],
  )
  const changeJigyoCodePulldown = useCallback(
    (value: string) => {
      dispatch(SearchAction.changeJigyoshoCode(value))
    },
    [JIGYO_CD],
  )
  return [JIGYO_CD, changeJigyoCode, changeJigyoCodePulldown]
}

// 事業所名 //================================//
export const useJigyoshoName = (): [string, promiseBooleanF] => {
  const dispatch = useDispatch()
  const JIGYO_CD = useSelector((state) => state.SearchLogReducer.searchLog.JIGYO_CD)
  const JIGYO_NM = useSelector((state) => state.SearchLogReducer.searchLog.JIGYO_NM)
  const loading = useSelector((state) => state.PageReducer.loading)
  const submitJigyosho = useCallback(async () => {
    if (loading) return false
    if (JIGYO_CD <= 0) {
      dispatch(SearchAction.changeJigyoshoName(''))
      dispatch(SearchAction.changeJigyoshoError(false))
      return true
    }
    if (JIGYO_CD > 0) {
      dispatch(PageAction.changeLoading(true))
      return await postInfo<jigyosho[]>(
        '/postJigyoshoInfo',
        { JIGYO_CD: JIGYO_CD },
        (r, err) => {
          dispatch(PageAction.changeLoading(false))
          dispatch(SearchAction.changeJigyoshoName(r[0].JIGYO_NM))
          dispatch(SearchAction.changeJigyoshoError(err))
        },
        () => dispatch(PageAction.changeErrorMessage()),
        () => dispatch(PageAction.changeLoading(false)),
      )
    }
    return true
  }, [JIGYO_CD, loading])
  return [JIGYO_NM, submitJigyosho]
}

// 仕入先コード //================================//
export const useShiiresakiCode = (): [number, inputF] => {
  const dispatch = useDispatch()
  const SIIRE_CD = useSelector((state) => state.SearchLogReducer.searchLog.SIIRE_CD)
  const changeShiiresakiCode = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(SearchAction.changeShiiresakiCode(e.target.value))
    },
    [SIIRE_CD],
  )
  return [SIIRE_CD, changeShiiresakiCode]
}

// 仕入先名 //================================//
export const useShiiresakiName = (): [string, promiseBooleanF] => {
  const dispatch = useDispatch()
  const SIIRE_CD = useSelector((state) => state.SearchLogReducer.searchLog.SIIRE_CD)
  const SIIRE_NM = useSelector((state) => state.SearchLogReducer.searchLog.SIIRE_NM)
  const loading = useSelector((state) => state.PageReducer.loading)
  const submitShiiresaki = useCallback(async () => {
    if (loading) return false
    if (SIIRE_CD <= 0) {
      dispatch(SearchAction.changeShiiresakiName(''))
      dispatch(SearchAction.changeShiiresakiError(false))
      return true
    }
    if (SIIRE_CD > 0) {
      dispatch(PageAction.changeLoading(true))
      return await postInfo<shiiresaki[]>(
        '/postShiiresakiInfo',
        { SIIRE_CD: SIIRE_CD },
        (r, err) => {
          dispatch(PageAction.changeLoading(false))
          dispatch(SearchAction.changeShiiresakiName(r[0].SIIRE_NM))
          dispatch(SearchAction.changeShiiresakiError(err))
        },
        () => dispatch(PageAction.changeErrorMessage()),
        () => dispatch(PageAction.changeLoading(false)),
      )
    }
    return true
  }, [SIIRE_CD, loading])
  return [SIIRE_NM, submitShiiresaki]
}

// 得意先コード //================================//
export const useTokuisakiCode = (): [number, inputF] => {
  const dispatch = useDispatch()
  const TOKUI_CD = useSelector((state) => state.SearchLogReducer.searchLog.TOKUI_CD)
  const changeTokuisakiCode = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(SearchAction.changeTokuisakiCode(e.target.value))
    },
    [TOKUI_CD],
  )
  return [TOKUI_CD, changeTokuisakiCode]
}

// 得意先名 //================================//
export const useTokuisakiName = (): [string, promiseBooleanF] => {
  const dispatch = useDispatch()
  const TOKUI_CD = useSelector((state) => state.SearchLogReducer.searchLog.TOKUI_CD)
  const TOKUI_NM = useSelector((state) => state.SearchLogReducer.searchLog.TOKUI_NM)
  const loading = useSelector((state) => state.PageReducer.loading)
  const submitTokuisaki = useCallback(async () => {
    if (loading) return false
    if (TOKUI_CD <= 0) {
      dispatch(SearchAction.changeTokuisakiName(''))
      dispatch(SearchAction.changeTokuisakiError(false))
      return true
    }
    if (TOKUI_CD > 0) {
      dispatch(PageAction.changeLoading(true))
      return await postInfo<tokuisaki>(
        '/postTokuisakiInfo',
        { TOKUI_CD: TOKUI_CD },
        (r, err) => {
          dispatch(PageAction.changeLoading(false))
          dispatch(SearchAction.changeTokuisakiName(r.TOKUI_NM))
          dispatch(SearchAction.changeTokuisakiError(err))
        },
        () => dispatch(PageAction.changeErrorMessage()),
        () => dispatch(PageAction.changeLoading(false)),
      )
    }
    return true
  }, [TOKUI_CD, loading])
  return [TOKUI_NM, submitTokuisaki]
}

// 担当者コード //================================//
export const useTantoCode = (): [number, inputF] => {
  const dispatch = useDispatch()
  const TANTO_CD = useSelector((state) => state.SearchLogReducer.searchLog.TANTO_CD)
  const changeTantoCode = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(SearchAction.changeTantoCode(e.target.value))
    },
    [TANTO_CD],
  )
  return [TANTO_CD, changeTantoCode]
}

// 担当者名 //================================//
export const useTantoName = (): [string, promiseBooleanF] => {
  const dispatch = useDispatch()
  const TANTO_CD = useSelector((state) => state.SearchLogReducer.searchLog.TANTO_CD)
  const TANTO_NM = useSelector((state) => state.SearchLogReducer.searchLog.TANTO_NM)
  const loading = useSelector((state) => state.PageReducer.loading)
  const submitTanto = useCallback(async () => {
    if (loading) return false
    if (TANTO_CD <= 0) {
      dispatch(SearchAction.changeTantoName(''))
      dispatch(SearchAction.changeTantoError(false))
      return true
    }
    if (TANTO_CD > 0) {
      dispatch(PageAction.changeLoading(true))
      return await postInfo<tanto[]>(
        '/postTantoInfo',
        { TANTO_CD: TANTO_CD },
        (r, err) => {
          dispatch(PageAction.changeLoading(false))
          dispatch(SearchAction.changeTantoName(r[0].TANTO_NM))
          dispatch(SearchAction.changeTantoError(err))
        },
        () => dispatch(PageAction.changeErrorMessage()),
        () => dispatch(PageAction.changeLoading(false)),
      )
    }
    return true
  }, [TANTO_CD, loading])
  return [TANTO_NM, submitTanto]
}

// メールアドレス //================================//
export const useMail = (): [string, inputF] => {
  const dispatch = useDispatch()
  const MAIL = useSelector((state) => state.SearchLogReducer.searchLog.MAIL)
  const changeMail = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(SearchAction.changeMail(e.target.value))
    },
    [MAIL],
  )
  return [MAIL, changeMail]
}

// 氏名 //================================//
export const useName = (): [string, promiseBooleanF] => {
  const dispatch = useDispatch()
  const MAIL = useSelector((state) => state.SearchLogReducer.searchLog.MAIL)
  const NAME = useSelector((state) => state.SearchLogReducer.searchLog.NAME)
  const loading = useSelector((state) => state.PageReducer.loading)
  const submitMail = useCallback(async () => {
    if (loading) return false
    if (MAIL.length <= 0) {
      dispatch(SearchAction.changeName(''))
      dispatch(SearchAction.changeMailError(false))
      return true
    }
    if (MAIL.length > 0) {
      dispatch(PageAction.changeLoading(true))
      return await postInfo<user[]>(
        '/postShiiresakiUserInfo',
        { MAIL: MAIL },
        (r, err) => {
          dispatch(PageAction.changeLoading(false))
          dispatch(SearchAction.changeName(r[0].NAME))
          dispatch(SearchAction.changeMailError(err))
        },
        () => dispatch(PageAction.changeErrorMessage()),
        () => dispatch(PageAction.changeLoading(false)),
      )
    }
    return true
  }, [MAIL, loading])
  return [NAME, submitMail]
}

// 事業所プルダウン //================================//
export const useJigyoList = (): [select[], promiseBooleanF] => {
  const dispatch = useDispatch()
  const keyInfo = useSelector((state) => state.LoginReducer.keyInfo)
  const isJocho = useSelector((state) => state.LoginReducer.isJocho)
  const jigyoList = useSelector((state) => state.SearchLogReducer.jigyoList)

  const postJigyList = useCallback(async () => {
    return await search2<{ keyInfo: keyInfo[]; isJochoUser: boolean }, { jigyoList: select[] }>(
      '/postLogPulldownInfo',
      { keyInfo: keyInfo, isJochoUser: isJocho },
      (r) => {
        dispatch(SearchAction.changeJigyoList(r.body.jigyoList))
      },
      () => dispatch(PageAction.changeErrorMessage()),
      () => dispatch(PageAction.changeLoading(false)),
    )
  }, [])
  return [jigyoList, postJigyList]
}

// 初期描画フラグ //================================//
export const useInitView = (): [number, voidNumberF] => {
  const dispatch = useDispatch()
  const initView = useSelector((state) => state.SearchLogReducer.initView)

  const changeInitView = useCallback((initView: number) => {
    dispatch(SearchAction.changeInitView(initView))
  }, [])
  return [initView, changeInitView]
}

// ボタン項目押下処理 //================================//
export const useSearch = (): [() => Promise<boolean>, () => Promise<boolean>] => {
  const dispatch = useDispatch()
  const searchLog = useSelector((state) => state.SearchLogReducer.searchLog)
  const initView = useSelector((state) => state.SearchLogReducer.initView)
  const isSuperUser = useSelector((state) => state.LoginReducer.isSuperUser)
  const isJocho = useSelector((state) => state.LoginReducer.isJocho)
  const keyInfo = useSelector((state) => state.LoginReducer.keyInfo)
  const page = useSelector((state) => state.TableReducer.page)
  const perPage = useSelector((state) => state.TableReducer.perPage)
  const loading = useSelector((state) => state.PageReducer.loading)

  // 検索ボタン押下処理 //================================//
  const search = useCallback(async () => {
    // if (loading) return false
    dispatch(PageAction.deleteMessage())
    dispatch(PageAction.changeLoading(true))
    dispatch(TableLogAction.changeSearchLogding(true))
    return await search2<{ searchLog: requestLog; keyInfo: keyInfo[]; isSuperUser: boolean; isJochoUser: boolean; page: number; perPage: number }, { table: responseLog[]; allCount: number }>(
      '/postLogInfo',
      {
        searchLog: getRequestLog(searchLog),
        keyInfo: keyInfo,
        isSuperUser: isSuperUser,
        isJochoUser: isJocho,
        page: page,
        perPage: perPage,
      },
      (r) => {
        dispatch(PageAction.changeLoading(false))
        if (initView === 0) dispatch(SearchAction.changeInitView(1))
        if (r.viewMsg) dispatch(PageAction.changeErrorMessage(r.viewMsg))
        if (!r.error) {
          dispatch(TableLogAction.changeLogList(r.body.table))
          dispatch(TableLogAction.changeLogAllCount(r.body.allCount))
        }
      },
      () => dispatch(PageAction.changeErrorMessage()),
      () => {
        dispatch(PageAction.changeLoading(false))
        dispatch(TableLogAction.changeSearchLogding(false))
        if (initView === 0) dispatch(SearchAction.changeInitView(1))
      },
    )
  }, [loading, searchLog, page, perPage, initView])

  const clickSearchButton = useCallback(async () => {
    if (page == 0) {
      // 1ページ目の場合は検索処理を実行
      return await search()
    } else {
      // 1ページ目でない場合はページを変えて検索
      dispatch(TableCommonAction.changePage(0))
      return (async () => {
        return true
      })()
    }
  }, [search, page])

  return [search, clickSearchButton]
}

// 初期描画調整 //================================//
export const useInitProcess = () => {
  const dispatch = useDispatch()
  const keyInfo = useSelector((state) => state.LoginReducer.keyInfo)
  const initProcess = useCallback(() => {
    dispatch(SearchAction.changeJigyoshoCode(String(keyInfo[0].JIGYO_CD)))
    dispatch(TableCommonAction.changePerPage(100))
  }, [])
  return initProcess
}
