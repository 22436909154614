import axiosBase, { AxiosRequestConfig } from 'axios'

const isDev = process.env.REACT_APP_ENVIRONMENT === 'dev'

const requestConfig: AxiosRequestConfig = {
  // headers: // ヘッダ類はCloudFrontにて設定する
  timeout: 59000, // タイムアウトは59秒(60秒だとCloudFrontの強制終了が優先されるため)
  auth: {
    // ベーシック認証
    username: 'name',
    password: `${process.env.REACT_APP_PASSWORD}`,
  },
  responseType: 'json',
}

export const axios = axiosBase.create(isDev ? { ...requestConfig, baseURL: `${process.env.REACT_APP_SERVER_URL}` } : requestConfig)

// GETではリクエストをボディに入れない(CLoudFrontで弾かれる)-
export const getFetcher =
  (url: string = '/') =>
  async () => {
    const { data } = await axios.get('/api' + url)
    return data
  }

export const postFetcher =
  (url: string = '/', req: any = {}) =>
  async () => {
    const { data } = await axios.post('/api' + url, { req: req })
    return data
  }
