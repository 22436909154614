import { F_NUM } from 'assets/FormatNumbers'
import { select } from 'types/SearchTypes'
import { fixPrice } from './stringUtil'

/**
 * 文字列変数にundefinedかNULLが入っていれば-1にして返す関数
 * @param value 入力値
 * @returns NULLは空文字にして返される入力値
 */
export const fixNullNumber = (value: number) => {
  if (value !== 0 && value == null) return -1
  return value
}

/**
 * 文字列変数にundefinedかNULLが入っていれば-1にして返す関数
 * @param value 入力値
 * @returns NULLは-1にして返される入力値
 */
export const fixNullValue = (value: string) => {
  const input = parseInt(value)
  if (isNaN(input)) return F_NUM
  return input ? input : input === 0 ? input : F_NUM
}

/**
 * 指定桁を超えた数値を除去した数値を返す関数(文字列にも対応)
 * @param input 入力値
 * @param digit 桁
 * @returns 指定桁を超えた数値を除去した数値
 */
export const convertNumber = (input: number | string, digit: number) => {
  // if(input === '')return input
  let isMinus = false
  if (parseInt(input.toString()) < 0) {
    isMinus = true
    input = parseInt(input.toString()) * -1
  }
  return parseInt(input.toString().substring(0, digit)) * (isMinus ? -1 : 1)
}

/**
 * はみ出した数値を0-100の間に丸めた数値を返す関数(文字列にも対応)
 * @param input 入力値
 * @returns 指定桁を超えた数値を除去した数値
 */
export const convertPercent = (input: number | string) => {
  const num = typeof input === 'number' ? input : parseInt(input.toString())
  return !num || num === F_NUM ? num : num > 100 ? 100 : num < 0 ? 0 : num
}

/**
 * 数値をXX.XX%と表記する文字列で返す関数(文字列にも対応, 小数点以下は可変)
 * ※100%を超えることもあるので注意
 * @param input 入力値
 * @param digit 桁(初期値=2)
 * @returns 指定桁を超えた数値を除去した数値
 */
export const displayPercent = (input: number | string, digit: number = 2) => {
  const num = typeof input === 'number' ? input : parseInt(input.toString())
  return num.toFixed(digit) + '%'
}

/**
 * 数値が初期値 -1 の場合は空文字を返す関数
 * @param input 表示元となる状態変数
 * @returns 入力フォーム上に表示される文字
 */
export const unDisplayNumber = (input: number) => {
  return input < 0 ? '' : input.toString()
}

/**
 * 粗利率を計算して返す関数
 */
export const getArariRitsu = (NET: number, NOUKA: number, SIIRE_TANKA: number) => {
  const num = SIIRE_TANKA < NET ? SIIRE_TANKA : NET
  return ((NOUKA - num) / NOUKA) * 100
}

/**
 * 任意の桁で四捨五入する関数
 * @param {number} value 四捨五入する数値
 * @param {number} base どの桁で四捨五入するか（10→10の位、0.1→小数第１位）
 * @return {number} 四捨五入した値
 */
export const FixNum = (value: number, base: number = 0) => {
  if (base === 0) return fixPrice(value)
  return fixPrice((Math.round(value * base) / base).toFixed(base))
}

/**
 * プルダウンで-1が選択されている場合は初期取得コードを返す関数
 */
export const getCodes = (CODE: number, List: select[]) => {
  if (CODE === F_NUM) {
    const arr: number[] = []
    List.forEach((r) => {
      if (r.value !== '-1') arr.push(parseInt(r.value))
    })
    return arr
  }
  return [CODE]
}

/**
 * 文字列のバイト数を取得する関数。半角1バイト 全角2バイト
 * @param value 入力値
 * @returns number
 */
export const getStrBytes = (value: string) => {
  let bytes = 0
  for (let i = 0; i < value.length; i++) {
    bytes = bytes + (value[i].match(/[ -~]/) ? 1 : 2)
  }

  return bytes
}
