import { withStyles, Theme } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'

export const HtmlToolTip = withStyles((theme: Theme) => ({
  tooltip: {
    // backgroundColor: '#f5f5f9',
    backgroundColor: '#ffff00',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fintSize: theme.typography.pxToRem(12),
    border: '1px solid # dadde9',
  },
}))(Tooltip)
