/* eslint-disable react/jsx-pascal-case */
import React, { useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { HtmlToolTip } from 'assets/ToolTips'
import A_IconMust from '../A_IconMust'
import { moleculeLog } from 'utils/logUtil'
import { inputQueryF, voidF } from 'types/FunctionTypes'
import '../textBox.css'

type Props = {
  key?: number
  tab?: number
  must?: boolean
  error?: boolean
  label: string
  valueCode: string
  valueName: string
  change: (e: React.ChangeEvent<HTMLInputElement>) => void
  focus?: boolean
  register?: {
    onKeyDown({ key }: { key: string }): void
    ref(element: HTMLInputElement | null): void
  }
  submit?: inputQueryF | voidF
  type?: string
  disabled?: boolean
}

const CODE_WIDTH = '25%' // コード入力欄の幅
const NAME_WIDTH = '70%' // 名称表示欄の幅

const M_FieldCodeBox = ({
  key = 0, //
  tab = -1,
  must = false,
  error = false,
  label,
  valueCode,
  valueName,
  change,
  focus = false,
  register,
  submit = () => {},
  type = 'number',
  disabled = false,
}: Props) => {
  useEffect(() => moleculeLog('M_FieldCodeBox'))
  const max = 100
  return (
    <Grid container justify="flex-start">
      <Grid item xs={12} sm={3}>
        <Typography variant="body1" display="block" gutterBottom>
          {label}
          <A_IconMust must={must} />
        </Typography>
      </Grid>
      <Grid item xs={12} sm={9}>
        <input
          key={key} //
          tabIndex={tab}
          type={type}
          style={{ width: CODE_WIDTH }}
          value={valueCode}
          onChange={change}
          onKeyDown={(e) => submit(e)}
          autoFocus={focus}
          {...register}
          maxLength={max}
          disabled={disabled}
        />
        {valueName?.length <= 0 ? (
          <input type="text" style={{ width: NAME_WIDTH }} value={valueName} disabled />
        ) : (
          <HtmlToolTip //
            title={<Typography variant="button">{valueName}</Typography>}
            placement="bottom-start"
          >
            {!error ? (
              <input
                type="text" //
                style={{ width: NAME_WIDTH }}
                value={valueName}
                disabled
              />
            ) : (
              <input
                type="text" //
                style={{ width: NAME_WIDTH, backgroundColor: 'red', color: 'yellow' }}
                value={valueName}
                disabled
              />
            )}
          </HtmlToolTip>
        )}
      </Grid>
    </Grid>
  )
}
export default React.memo(M_FieldCodeBox)
