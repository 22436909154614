import { Reducer } from 'redux'
import produce from 'immer'
import { searchUser } from 'types/SearchTypes'
import { convertNumber } from 'utils/numberUtil'
import { F_NUM, F_STR } from 'assets/FormatNumbers'
import { responseSummaryUser } from 'types/ResponseTypes'

/**
 * メイン参照テーブルはJS3M001, JS3M002
 */
export type SearchUserState = {
  searchUser: searchUser //ユーザー登録画面検索情報
  searchedUser: searchUser //ユーザー登録画面検索情報(明細表示中)
  message: string // メッセージ
  detailView: boolean // 明細画面表示中か
  isNotDeleteMessage: boolean // 検索後にメッセージを消さないフラグ
}

const initUser = {
  JIGYO_CD: F_NUM, // 事業所コード
  JIGYO_NM: F_STR, // 事業所名
  SIIRE_CD: F_NUM, // 仕入先コード
  SIIRE_NM: F_STR, // 仕入先名
  USER_NM: F_STR, // 氏名
  USER_MAIL: F_STR, // メールアドレス
}

/**
 * 初期状態
 * 入力区分は販売条件とする
 * コード値は-1とする
 */
const initialState: SearchUserState = {
  searchUser: initUser, //ユーザー登録画面検索情報
  searchedUser: initUser, //ユーザー登録画面検索情報(明細表示中)
  message: F_STR, // メッセージ
  detailView: false, // 明細画面表示中か
  isNotDeleteMessage: false, // 検索後にメッセージを消さないフラグ
}

export type SearchUserType =
  | 'SearchUserChangeJigyoCode' // 事業所コードを変更する処理
  | 'SearchUserChangeSiireCode' // 仕入先コードを変更する処理
  | 'SearchUserChangeName' // 氏名を変更する処理
  | 'SearchUserChangeMail' // メールアドレスを変更する処理
  | 'SearchUserFormat' // 条件検索情報を初期化する処理
  | 'SearchUserAllFormat' // 条件検索情報を完全に初期化する処理
  | 'SearchUserChangeMessage' // メッセージを変更する処理
  | 'SearchUserChangeDetailView' // 明細画面表示を変更する処理
  | 'SearchUserOpenDetail' //ユーザー登録画面検索情報(明細表示中)を変更する処理 ※仮処理
  | 'SearchUserCloseDetail' //ユーザー登録画面検索情報(明細表示中)を変更する処理 ※仮処理
  | 'SearchUserChangeIsDeleteMessage' // 検索後にメッセージを消さないフラグを変更する処理
  | 'dummy'

export type SearchUserAction = {
  type: SearchUserType
  user?: responseSummaryUser // ユーザー登録画面検索情報
  JIGYO_CD?: number
  SIIRE_CD?: number
  name?: string
  mail?: string
  error?: boolean
  message?: string
  detailView?: boolean // 明細画面表示中か
  isNotDeleteMessage?: boolean // 検索後にメッセージを消さないフラグ
}

export const SearchUserReducer: Reducer<SearchUserState, SearchUserAction> = produce((draft: SearchUserState, action: SearchUserAction) => {
  switch (action.type) {
    // 検索後にメッセージを消さないフラグを変更する処理 //===========================//
    case 'SearchUserChangeIsDeleteMessage': {
      draft.isNotDeleteMessage = action.isNotDeleteMessage!
      return
    }

    // 明細画面表示を変更する処理 //===========================//
    case 'SearchUserChangeDetailView': {
      draft.detailView = action.detailView!
      return
    }

    //ユーザー登録画面検索情報(明細表示中)を変更する処理 ※仮処理 //===========================//
    case 'SearchUserOpenDetail': {
      const user = action.user!
      draft.searchedUser = draft.searchUser
      draft.searchUser = {
        JIGYO_CD: user.JIGYO_CD, // 事業所コード
        JIGYO_NM: user.JIGYO_NM, // 事業所名
        SIIRE_CD: user.SIIRE_CD, // 仕入先コード
        SIIRE_NM: user.SIIRE_NM, // 仕入先名
        USER_NM: user.NAME, // 氏名
        USER_MAIL: user.MAIL, // メールアドレス
      }
      draft.detailView = true
      return
    }

    //ユーザー登録画面検索情報(明細表示中)を変更する処理 ※仮処理 //===========================//
    case 'SearchUserCloseDetail': {
      draft.searchUser = draft.searchedUser
      draft.detailView = false
      return
    }

    // 事業所処理 //===========================//
    case 'SearchUserChangeJigyoCode': {
      draft.searchUser.JIGYO_CD = convertNumber(action.JIGYO_CD!, 2)
      return
    }

    // 仕入先処理 //===========================//
    case 'SearchUserChangeSiireCode': {
      draft.searchUser.SIIRE_CD = convertNumber(action.SIIRE_CD!, 4)
      return
    }

    // 氏名処理 //===========================//
    case 'SearchUserChangeName': {
      draft.searchUser.USER_NM = action.name!
      return
    }

    // メール処理 //===========================//
    case 'SearchUserChangeMail': {
      draft.searchUser.USER_MAIL = action.mail!
      return
    }

    // 条件初期化 //===========================//
    case 'SearchUserFormat': {
      draft.searchUser = {
        ...initialState.searchUser,
      }
      draft.searchedUser = {
        ...initialState.searchedUser,
      }
      draft.detailView = initialState.detailView
      return
    }
    // 条件完全初期化 //===========================//
    case 'SearchUserAllFormat': {
      for (const key in draft) {
        const tempKey: keyof SearchUserState = key as keyof SearchUserState
        ;(draft as any)[tempKey] = initialState[tempKey]
      }
      return
    }
    // メッセージ変更 //===========================//
    case 'SearchUserChangeMessage': {
      draft.message = action.message!
      return
    }
  }
}, initialState)
