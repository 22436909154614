/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import M_ButtonNormal from 'components/1_Atoms/2_Molecules/M_ButtonNormal'
import * as ModalTokuiAction from 'actions/ModalTokuiAction'
import { organismLog } from 'utils/logUtil'

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
})

const O_FooterModalTokui = () => {
  useEffect(() => organismLog('O_FooterModalTokui'))
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleClose = useCallback(() => {
    dispatch(ModalTokuiAction.close())
  }, [dispatch])

  return (
    <div className={classes.root}>
      <Grid container justifyContent="flex-start" spacing={1}>
        <Grid item xs={12} sm={1}></Grid>
        <Grid item xs={12} sm={11}>
          <Grid container justify="flex-end">
            <M_ButtonNormal label={'閉じる'} event={handleClose} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
export default React.memo(O_FooterModalTokui)
