import { useEffect } from 'react'
import Select, { SingleValue } from 'react-select'
import { select } from 'types/SearchTypes'
import { atomLog } from 'utils/logUtil'

type Props = {
  valueSet: any
  defValue: select
  event: (targetValue: SingleValue<select>) => void
  lib?: boolean
  disabled?: boolean
}

const A_SelectBoxNewNew = ({ valueSet, defValue, event, lib = false, disabled = false }: Props) => {
  useEffect(() => atomLog('A_SelectBoxNewNew'))
  if (lib)
    return (
      <Select
        options={valueSet}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            // minHeight: 30, width: 250,
            fontSize: '10px',
            // borderColor: state.isFocused ? 'grey' : 'red',
            minHeight: '27px',
            height: '27px',
            // height: '90%',
            width: '100%',
            backgroundColor: disabled ? '#D8D8D8' : '#FFFFFF',
            borderColor: '#B2B2B2', // disabledだと線の色が薄くなるので固定
          }),
          dropdownIndicator: (baseStyles) => ({
            ...baseStyles,
            paddingTop: 0, // 高さ調整のため設定
            paddingBottom: 0, // 高さ調整のため設定
          }),
          clearIndicator: (baseStyles) => ({
            ...baseStyles,
            paddingTop: 0, // 高さ調整のため設定
            paddingBottom: 0, // 高さ調整のため設定
          }),
          menu: (baseStyles) => ({
            ...baseStyles,
            zIndex: 9999, // 選択肢がテーブルヘッダの下に入り込むため設定
          }),
        }}
        // isSearchable={false}
        // isRtl
        // isDisabled
        // isMulti
        value={defValue}
        onChange={event}
        placeholder=""
        isDisabled={disabled}
      />
    )

  return (
    <select style={{ height: 25, width: 140 }} onChange={(e) => event({ value: e.target.value, label: e.target.options[e.target.selectedIndex].label })} defaultValue={defValue.value}>
      {valueSet.map((op: select) => (
        <option value={op.value}>{op.label}</option>
      ))}
    </select>
  )
}

export default A_SelectBoxNewNew
