import React, { useEffect } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import TableRow from '@material-ui/core/TableRow'
import { jokenTableSummary } from 'assets/TableHeader'
import M_TableCell from 'components/1_Atoms/2_Molecules/M_TableCellHead'
import { organismLog } from 'utils/logUtil'
import { useUser } from 'hooks/useLogin'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
    },
    table: {
      // backgroundColor: '#66FF99',
    },
    container: {
      maxHeight: 600,
    },
  }),
)

const width = jokenTableSummary.width
const label = jokenTableSummary.label

type Props = {
  click: (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.MouseEvent<HTMLTableRowElement, MouseEvent>, isChecked: boolean) => void
  isChecked: boolean
}

const O_TableHeaderJoken = ({ click, isChecked }: Props) => {
  useEffect(() => organismLog('O_TableHeaderJoken'))
  const classes = useStyles()
  const userKind = useUser()

  return (
    <TableRow
      hover //
      onClick={(e) => click(e, isChecked)}
      className={classes.table}
    >
      {/* No */}
      <M_TableCell category="number" data={'NO'} width={width.NO} />
      {/* チェックボックス */}
      <M_TableCell category="checkbox" isChecked={isChecked} width={width.CHECK} />
      {/* 状態 */}
      <M_TableCell category="data" width={width.JOTAI} title={label.JOTAI} data={label.JOTAI} />
      {/* 上長承認状態 */ userKind === 0 ? <M_TableCell category="data" width={width.JOCHO_JOTAI} title={label.JOCHO_JOTAI} data={label.JOCHO_JOTAI} /> : <></>}
      {/* 管理No */}
      <M_TableCell category="data" width={width.KANRI_NO} title={label.KANRI_NO} data={label.KANRI_NO} />
      {/* 件名 */}
      <M_TableCell category="data" width={width.KEN} title={label.KEN} data={label.KEN} />
      {/* 適用期間(開始) */}
      <M_TableCell category="data" width={width.STRT_DT} title={label.STRT_DT} data={label.STRT_DT} />
      {/* 適用期間(終了) */}
      <M_TableCell category="data" width={width.END_DT} title={label.END_DT} data={label.END_DT} />
      {/* 事業所 */}
      <M_TableCell category="data" width={width.JIGYO} title={label.JIGYO} data={label.JIGYO} />
      {/* 全国 */}
      <M_TableCell category="data" width={width.ZENKOKU} title={label.ZENKOKU} data={label.ZENKOKU} />
      {/* 課 */}
      <M_TableCell category="data" width={width.BUSHO} title={label.BUSHO} data={label.BUSHO} />
      {/* 得意先 */}
      <M_TableCell category="data" width={width.TOKUI} title={label.TOKUI} data={label.TOKUI} />
      {/* 仕入先 */}
      <M_TableCell category="data" width={width.SIIRE} title={label.SIIRE} data={label.SIIRE} />
      {/* 担当者 */}
      <M_TableCell category="data" width={width.TANTO} title={label.TANTO} data={label.TANTO} />
      {/* 入力担当者 */ userKind === 0 ? <M_TableCell category="data" width={width.ADD_TANTO} title={label.ADD_TANTO} data={label.ADD_TANTO} /> : <></>}
    </TableRow>
  )
}

export default React.memo(O_TableHeaderJoken)
