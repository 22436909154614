import React, { useEffect } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import TableRow from '@material-ui/core/TableRow'
import { jokenTableDetail } from 'assets/TableHeader'
import M_TableCell from 'components/1_Atoms/2_Molecules/M_TableCellHead'
import { organismLog } from 'utils/logUtil'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
    },
    table: {
      // backgroundColor: '#66FF99',
    },
    container: {
      maxHeight: 600,
    },
  }),
)

const width = jokenTableDetail.width
const label = jokenTableDetail.label

type Props = {
  click: (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.MouseEvent<HTMLTableRowElement, MouseEvent>, isChecked: boolean) => void
  isChecked: boolean
}

const O_TableHeaderJoken = ({ click, isChecked }: Props) => {
  useEffect(() => organismLog('O_TableHeaderJoken'))
  const classes = useStyles()
  const userKind = useSelector((state) => state.LoginReducer.userKind)
  const isJocho = useSelector((state) => state.LoginReducer.isJocho)

  return (
    <TableRow
      hover //
      onClick={(e) => click(e, isChecked)}
      className={classes.table}
    >
      {/* No */}
      <M_TableCell category="number" data={'NO'} width={width.NO} />
      {/* チェックボックス */}
      <M_TableCell category="checkbox" isChecked={isChecked} width={width.CHECK} />
      {/* 状態 */}
      <M_TableCell category="data" width={width.JOTAI} title={label.JOTAI} data={label.JOTAI} />
      {/* 上長承認状態 */ userKind === 0 ? <M_TableCell category="data" width={width.JOCHO_JOTAI} title={label.JOCHO_JOTAI} data={label.JOCHO_JOTAI} /> : <></>}
      {/* JANコード */}
      <M_TableCell category="data" width={width.JAN_CD} title={label.JAN_CD} data={label.JAN_CD} />
      {/* JPコード */}
      <M_TableCell category="data" width={width.JP_CD} title={label.JP_CD} data={label.JP_CD} />
      {/* 品名／規格 */}
      <M_TableCell category="data" width={width.SHOHIN_KIKAKU} title={label.SHOHIN_KIKAKU} data={label.SHOHIN_KIKAKU} />
      {/* 仕入単価 */}
      <M_TableCell category="data" width={width.SIIRE_TAN} title={label.SIIRE_TAN} data={label.SIIRE_TAN} />
      {/* 個人Net */}
      <M_TableCell category="data" width={width.NET} title={label.NET} data={label.NET} />
      {/* 明細備考 */}
      <M_TableCell category="data" width={width.MEISAI_BIKO} title={label.MEISAI_BIKO} data={label.MEISAI_BIKO} />
      {/* 否認理由 */}
      {/* 否認理由・上長否認理由 */ isJocho ? <M_TableCell category="data" width={width.JOCHO_HININ_RIYU} title={label.JOCHO_HININ_RIYU} data={label.JOCHO_HININ_RIYU} /> : <M_TableCell category="data" width={width.HININ_RIYU} title={label.HININ_RIYU} data={label.HININ_RIYU} />}
    </TableRow>
  )
}

export default React.memo(O_TableHeaderJoken)
