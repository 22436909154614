import { Reducer } from 'redux'
import produce from 'immer'
import { F_STR } from 'assets/FormatNumbers'

export type PageState = {
  openAlert: boolean // アラートを表示しているか
  submitFunction: (file?: File) => Promise<void> // アラートで決定ボタン押下後に動く関数
  loading: boolean // ローディング状態
  messageAlert: string // アラートダイアログのメッセージ
  message: string // メッセージ
  messageColor: string // メッセージの色
  error: boolean // ダイアログを開く際にエラーが発生しているか
  initView: number // 年月フォームの初期描画フラグ
}

const initialState: PageState = {
  openAlert: false, // アラートを表示しているか
  submitFunction: async () => {}, // アラートで決定ボタン押下後に動く関数
  loading: false, // ローディング状態
  messageAlert: F_STR, // アラートダイアログのメッセージ
  message: F_STR, // メッセージ
  messageColor: 'red', // メッセージの色
  error: false, // ダイアログを開く際にエラーが発生しているか
  initView: 0, // 年月フォームの初期描画フラグ
}

export type PageType =
  | 'PageAlertOpen' // アラートを開く処理
  | 'PageAlertClose' // アラートを閉じる処理
  | 'PageChangeLoading' // ローディング状態を変更する処理
  | 'PageChangeMessageAlert' // アラートダイアログのメッセージを変更する処理
  | 'PageDeleteMessageAlert' // アラートダイアログのメッセージを変更する処理
  | 'PageChangeMessage' // メッセージを変更する処理
  | 'PageDeleteMessage' // メッセージを初期化する処理
  | 'PageChangeInitView' // 日付入力初期描画変更処理
  | 'PageFormat' // 初期化する処理
  | 'dummy'

export type PageAction = {
  type: PageType
  func?: () => Promise<void>
  loading?: boolean // ローディング状態
  message?: string
  color?: string
  err?: boolean
  initView?: number // 年月フォームの初期描画フラグ
}

export const PageReducer: Reducer<PageState, PageAction> = produce((draft: PageState, action: PageAction) => {
  switch (action.type) {
    // アラートを開く処理 //===========================//
    case 'PageAlertOpen': {
      draft.openAlert = true
      draft.messageAlert = action.message!
      draft.submitFunction = action.func!
      draft.error = action.err!
      return
    }
    // アラートを閉じる処理 //===========================//
    case 'PageAlertClose': {
      draft.openAlert = false
      draft.loading = false
      return
    }
    // ローディング処理 //===========================//
    case 'PageChangeLoading': {
      draft.loading = action.loading!
      return
    }
    // アラートダイアログのメッセージ変更 //===========================//
    case 'PageChangeMessageAlert': {
      draft.messageAlert = action.message!
      return
    }
    // アラートダイアログのメッセージ初期化 //===========================//
    case 'PageDeleteMessageAlert': {
      draft.messageAlert = initialState.messageAlert
      return
    }
    // メッセージ変更 //===========================//
    case 'PageChangeMessage': {
      draft.message = action.message!
      draft.messageColor = action.color!
      return
    }
    // メッセージ初期化 //===========================//
    case 'PageDeleteMessage': {
      draft.message = initialState.message
      return
    }
    // 日付入力変更処理 //===========================//
    case 'PageChangeInitView': {
      draft.initView = action.initView!
      return
    }
    // 初期化処理 //===========================//
    case 'PageFormat': {
      for (const key in draft) {
        const tempKey: keyof PageState = key as keyof PageState
        ;(draft as any)[tempKey] = initialState[tempKey]
      }
      return
    }
  }
}, initialState)
