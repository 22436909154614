import React, { useEffect } from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Collapse from '@material-ui/core/Collapse'
import M_FieldDateSet from 'components/1_Atoms/2_Molecules/M_FieldDateSetNew'
import M_FieldSelect from 'components/1_Atoms/2_Molecules/M_FieldSelectNew'
import M_ButtonSubmit from 'components/1_Atoms/2_Molecules/M_ButtonSubmit'
import M_FieldJotai from 'components/1_Atoms/2_Molecules/M_FieldJotai'
import { organismGroupLog } from 'utils/logUtil'
import { getTimestampFromNumber } from 'utils/dateUtil'
import { convertNumber } from 'utils/numberUtil'
import { baseInfo } from 'types/SearchTypes'
import { useDate, useJigyosho, useSearch, useShiiresaki, useShohin, useTokuisaki } from 'hooks/useRitsuJoken'
import { postFetcher } from 'SWRProvider'
import { commonMsg, msgCode } from 'assets/MessageCode'
import { useQuery } from 'react-query'

const manualMode = process.env.REACT_APP_MANUAL_MODE === '1'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    box: {
      textAlign: 'left',
    },
    impotant: {
      color: '#FF0000',
    },
    entered: {
      width: '100%',
    },
  }),
)

type Props = {
  info: baseInfo
}

const O_SearchBox = ({ info }: Props) => {
  useEffect(() => organismGroupLog('O_SearchBox'))
  const classes = useStyles()

  const [SYORI_YM_START, SYORI_YM_END, changeStartDt, changeEndDt] = useDate()
  const [SIIRE_CD, changeSiire] = useShiiresaki()
  const [TOKUI_CD, changeTokui] = useTokuisaki()
  const [JIGYO_CD, changeJigyo] = useJigyosho()
  const [mishonin, shonin, hinin, _JochoShonin, _jochoMishonin, _hininMikakunin, _jochoHininMikakunin, _hininkakuninzumi, _jochoHininKakuninzumi, changeMishonin, changeShonin, changeHinin, _changeJochoShonin, _changeJochoMishonin, _changeHininMikakunin, _changeJochoHininMikakunin, _changeHininkakuninzumi, _changeJochoHininkakuninzumi] = useShohin()
  const [clickReset, clickInputCSV] = useSearch()

  // 検索条件初期化ボタンの文言を取得
  const fetcher = postFetcher('/getMessage', msgCode.SHIIRESAKI_RESET_BUTTON)
  const { data, isFetching, isLoading, error } = useQuery(['postResetButtonWordShiiresaki', []], fetcher)
  const resetButton = error ? commonMsg.ERROR_MSG : isFetching || isLoading ? 'Loading...' : data.body

  return (
    <Collapse in={true} timeout="auto" className={classes.entered}>
      <Grid container spacing={1}>
        {/* //////////////////// 1 行目 //////////////////// */}
        <Grid item xs={12} sm={4} className={classes.root}>
          {/* ==== 年月 ==== */}
          <M_FieldDateSet
            key1={1} //
            value1={getTimestampFromNumber(convertNumber(SYORI_YM_START, 6))}
            blurFunc1={changeStartDt}
            focus={false}
            disabled1={false}
            key2={2}
            value2={getTimestampFromNumber(convertNumber(SYORI_YM_END, 6))}
            blurFunc2={changeEndDt}
            disabled2={false}
          />
        </Grid>
        <Grid item xs={12} sm={4} className={classes.root}>
          {/* ==== 仕入先 ==== */}
          <M_FieldSelect //
            label="仕入先"
            valueCode={SIIRE_CD}
            valueSet={info.siireList}
            event={changeSiire}
            blackout={manualMode}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          {/* -------- [ CSV登録ボタン ] -------- */}
          <Grid container justifyContent="flex-end">
            <M_ButtonSubmit label={'CSV登録'} event={clickInputCSV} />
          </Grid>
        </Grid>

        {/* //////////////////// 2 行目 //////////////////// */}
        <Grid item xs={12} sm={4} className={classes.root}>
          {/* ==== 得意先 ==== */}
          <M_FieldSelect //
            label="得意先"
            valueCode={TOKUI_CD}
            valueSet={info.tokuiList}
            event={changeTokui}
            blackout={manualMode}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          {/* ==== 事業所 ==== */}
          <M_FieldSelect //
            label="事業所"
            valueCode={JIGYO_CD}
            valueSet={info.jigyoList}
            event={changeJigyo}
            blackout={manualMode}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          {/* -------- [ 検索条件リセットボタン ] -------- */}
          <Grid container justifyContent="flex-end">
            <M_ButtonSubmit label={resetButton} event={clickReset} disabled={false} />
          </Grid>
        </Grid>

        {/* //////////////////// 3 行目 //////////////////// */}
        <Grid item xs={12} sm={4} className={classes.root}>
          {/* ==== 状態 ==== */}
          {<M_FieldJotai label={'状態'} check1={mishonin} check2={shonin} check3={hinin} event1={changeMishonin} event2={changeShonin} event3={changeHinin} disabled={false} />}
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="subtitle2" display="block" color="error">
            {'背景色グレーは当日作業分以外なので変更できません'}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}></Grid>
      </Grid>
    </Collapse>
  )
}
export default React.memo(O_SearchBox)
