import { useRef } from 'react'

const initSubmit = async () => {
  return true
}
let shift = false
let ctrl = false
export const useEnterKeyFocusControl = () => {
  const ref = useRef(new Map<number, HTMLInputElement>())

  return (
    index: number, //
    type: 'number' | 'text' | 'login',
    search: () => Promise<boolean> = initSubmit,
    submit: () => Promise<boolean> = initSubmit,
  ) => ({
    async onKeyDown(e: KeyboardEvent) {
      const key = e.key
      switch (key) {
        case 'Enter':
        case 'Tab':
          const aim = shift ? -1 : 1
          const sortedIndices = [...ref.current.keys()].sort()
          const nextIndex = sortedIndices[sortedIndices.indexOf(index) + aim]
          await submit()
          if (ctrl) {
            await search()
            return
          }
          // if (!(await submit(nextIndex))) return
          if (key === 'Tab') return
          if (typeof nextIndex === 'number') ref.current.get(nextIndex)?.focus()
          return
        case 'Shift':
          shift = true
          return
        case 'Control':
          ctrl = true
          return
        case 'CapsLock':
          if (type === 'login') {
            e.preventDefault()
          }
          return
        default:
          if (type === 'number') {
            if (key === 'e' || key === '+' || key === '-' || key === '.') e.preventDefault()
          }
          shift = false
          ctrl = false
          return
      }
    },
    async onKeyUp({ key }: { key: string }) {
      switch (key) {
        case 'Shift':
          shift = false
          return
        case 'Control':
          ctrl = false
          return
      }
    },
    async onBlur() {
      await submit()
    },
    ref(element: HTMLInputElement | null) {
      if (element) ref.current.set(index, element)
      else ref.current.delete(index)
    },
  })
}

export const useEnterKey = () => {
  return (submit: () => Promise<boolean> = initSubmit) => ({
    async onKeyDown(e: KeyboardEvent) {
      const key = e.key
      switch (key) {
        case 'Enter':
        case 'Tab':
          await submit()
          return
      }
    },
    async onBlur() {
      await submit()
    },
  })
}
