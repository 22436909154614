import { Reducer } from 'redux'
import produce from 'immer'
import { searchJokenRitsu } from 'types/SearchTypes'
import { shohin, jigyosho, shiiresaki, tokuisaki } from 'types/ReferTypes'
import { getNumberFromString } from 'utils/dateUtil'
import { convertNumber } from 'utils/numberUtil'
import { F_NUM, F_STR } from 'assets/FormatNumbers'
import { isJochoSpecialUser } from 'utils/booleanUtil'

/**
 * メイン参照テーブルはJTD368
 */
export type SearchJokenRitsuState = {
  searchJokenRitsu: searchJokenRitsu // 販売条件照会画面検索情報
  TOKUI_ERROR: boolean // 得意先コードが取得できているか
  SIIRE_ERROR: boolean // 仕入先コードが取得できているか
  shohinAllList: shohin[] // 選択してないのも含めたコード貼付時の全商品リスト
  loading: boolean // ローディング状態
  trail: number // 検索前後判定(0: 一度も検索したことがない, 1: 検索後) ※項目が増える可能性があるのでnumber
  message: string // メッセージ
  initMishoninCheckbox: boolean // 初期状態未承認チェックボックス
  initShoninCheckbox: boolean // 初期状態承認チェックボックス
  initHininCheckbox: boolean // 初期状態否認チェックボックス
  initJochoMishoninCheckbox: boolean // 初期状態上長未承認チェックボックス
  initJochoShoninCheckbox: boolean // 初期状態上長承認チェックボックス
  initHininMikakuninCheckbox: boolean // 初期状態メーカー否認　未確認チェックボックス
  initJochoHininMikakuninCheckbox: boolean // 初期状態上長否認　未確認チェックボックス
  initHininkakuninzumiCheckbox: boolean // 初期状態メーカー否認　確認済チェックボックス
  initJochoHininKakuninzumiCheckbox: boolean // 初期状態上長否認　確認済チェックボックス
  isNotDeleteMessage: boolean // 検索後にメッセージを消さないフラグ
}

/**
 * 初期状態
 * 入力区分は販売条件とする
 * コード値は-1とする
 */
const initialState: SearchJokenRitsuState = {
  searchJokenRitsu: {
    JIGYO_CD: F_NUM, // 事業所コード
    JIGYO_NM: F_STR, // 事業所名
    TOKUI_CD: F_NUM, // 得意先コード
    TOKUI_NM: F_STR, // 得意先名
    SIIRE_CD: F_NUM, // 仕入先コード
    SIIRE_NM: F_STR, // 仕入先名
    INPUT_RITU: F_NUM, // 入力率
    HASUU_SYORI: F_NUM, // 端数処理
    BATCH_YM: F_NUM, // バッチ開始年月
    BATCH_CYCLE: F_NUM, // バッチサイクル
    NAIYO_KBN2: F_STR, // 精算内容区分2
    NAIYO: F_STR, // 精算内容 ※JTM361未所持
    TYOKUSOU: F_NUM, // 直送除外
    HENPIN: F_NUM, // 返品含む
    shohinList: [], // 商品(除外アイテム)リスト
    tokuisakiList: [], // 得意先リスト
    jigyoshoList: [], // 事業所リスト
    shiiresakiList: [], // 仕入先リスト
    SYORI_YM_START: F_NUM, // 処理年月開始
    SYORI_YM_END: F_NUM, // 処理年月終了
    ADD_TANTO_CD: F_NUM, // 入力担当者コード
    ADD_TANTO_NM: F_STR, // 入力担当者名
    mishoninCheckbox: false, // 未承認チェックボックス
    shoninCheckbox: false, // 承認チェックボックス
    hininCheckbox: false, // 否認チェックボックス
    jochoMishoninCheckbox: false, // 上長未承認チェックボックス
    jochoShoninCheckbox: false, // 上長承認チェックボックス
    hininMikakuninCheckbox: false, // メーカー否認　未確認チェックボックス
    jochoHininMikakuninCheckbox: false, // 上長否認　未確認チェックボックス
    hininKakuninzumiCheckbox: false, // メーカー否認　確認済チェックボックス
    jochoHininKakuninzumiCheckbox: false, // 上長否認　確認済チェックボックス
  },
  TOKUI_ERROR: false, // 得意先コードが取得できているか
  SIIRE_ERROR: false, // 仕入先コードが取得できているか
  shohinAllList: [], // 選択してないのも含めたコード貼付時の全商品リスト
  loading: false, // ローディング状態
  trail: 0, // 検索前後判定(0: 一度も検索したことがない, 1: 検索後)
  message: F_STR, // メッセージ
  initMishoninCheckbox: false, // 初期状態未承認チェックボックス
  initShoninCheckbox: false, // 初期状態承認チェックボックス
  initHininCheckbox: false, // 初期状態否認チェックボックス
  initJochoShoninCheckbox: false, // 初期状態上長承認チェックボックス
  initJochoMishoninCheckbox: false, // 初期状態上長未承認チェックボックス
  initHininMikakuninCheckbox: false, // 初期状態メーカー否認　未確認チェックボックス
  initJochoHininMikakuninCheckbox: false, // 初期状態上長否認　未確認チェックボックス
  initHininkakuninzumiCheckbox: false, // 初期状態メーカー否認　確認済チェックボックス
  initJochoHininKakuninzumiCheckbox: false, // 初期状態上長否認　確認済チェックボックス
  isNotDeleteMessage: false, // 検索後にメッセージを消さないフラグ
}

export type SearchJokenRitsuType =
  | 'SearchJokenRitsuChangeJigyoshoCode' // 事業所コードを変更する処理
  | 'SearchJokenRitsuChangeInitCode' // ユーザー所属部署ごとの初期値入力処理
  | 'SearchJokenRitsuChangeTokuisakiCode' // 得意先コードを変更する処理
  | 'SearchJokenRitsuChangeTokuisakiList' // 得意先リストを変更する処理
  | 'SearchJokenRitsuChangeTokuisakiError' // 得意先取得判定を変更する処理
  | 'SearchJokenRitsuDeleteTokuisaki' // 得意先情報を初期化する処理
  | 'SearchJokenRitsuChangeShiiresakiCode' // 仕入先コードを変更する処理
  | 'SearchJokenRitsuDeleteShiiresakiName' // 仕入先名を初期化する処理
  | 'SearchJokenRitsuChangeShiiresakiList' // 仕入先リストを変更する処理
  | 'SearchJokenRitsuChangeShiiresakiError' // 仕入先取得判定を変更する処理
  | 'SearchJokenRitsuChangeLoading' // ローディング状態を変更する処理
  | 'SearchJokenRitsuChangeTrail' // 検索前後判定を変更する処理
  | 'SearchJokenRitsuFormat' // 条件検索情報を初期化する処理
  | 'SearchJokenRitsuAllFormat' // 条件検索情報を完全に初期化する処理
  | 'SearchJokenRitsuChangeMessage' // メッセージを変更する処理
  | 'SearchJokenRitsuChangeSyoriStartDate' // 処理年月開始を変更する処理
  | 'SearchJokenRitsuChangeSyoriEndDate' // 処理年月終了を変更する処理
  | 'SearchJokenRitsuChangeMishoninCheckbox' // 未承認チェックボックスを変更する処理
  | 'SearchJokenRitsuChangeShoninCheckbox' // 承認チェックボックスを変更する処理
  | 'SearchJokenRitsuChangeHininCheckbox' // 否認チェックボックスを変更する処理
  | 'SearchJokenRitsuChangeKakuninzumiCheckbox' // 確認済チェックボックスを変更する処理
  | 'SearchJokenRitsuChangeKakunintaisyoCheckbox' // 確認対象表示状態にする処理
  | 'SearchJokenRitsuChangeInitCheckbox' // 初期状態のチェックボックスを変更する処理
  | 'SearchJokenRitsuChangeAddTantoCode' // 入力担当者コードを変更する処理
  | 'SearchJokenRitsuChangeIsDeleteMessage' // 検索後にメッセージを消さないフラグを変更する処理
  | 'SearchJokenRitsuChangeJochoShoninCheckbox' // 上長承認チェックボックスを変更する処理
  | 'SearchJokenRitsuChangeJochoMishoninCheckbox' // 上長未承認チェックボックスを変更する処理
  | 'SearchJokenRitsuChangeHininMikakuninCheckbox' // メーカー否認　未確認チェックボックスを変更する処理
  | 'SearchJokenRitsuChangeJochoHininMikakuninCheckbox' // 上長否認　未確認チェックボックスを変更する処理
  | 'SearchJokenRitsuChangeHininkakuninzumiCheckbox' // メーカー否認　確認済チェックボックスを変更する処理
  | 'SearchJokenRitsuChangeJochoHininkakuninzumiCheckbox' // 上長否認　確認済チェックボックスを変更する処理
  | 'dummy'

export type SearchJokenRitsuAction = {
  type: SearchJokenRitsuType
  tokuisakiCode?: number
  tokuisakiList?: tokuisaki[]
  jigyoshoCode?: number
  jigyoshoList?: jigyosho[]
  shiiresakiCode?: number
  shiiresakiList?: shiiresaki[]
  error?: boolean
  loading?: boolean // ローディング状態
  trail?: number // 検索前後判定
  message?: string
  syoriStartDate?: string // 日付変更値(文字列型)
  syoriEndDate?: string // 日付変更値(文字列型)
  mishoninCheckbox?: boolean
  shoninCheckbox?: boolean
  hininCheckbox?: boolean
  kakuninzumiCheckbox?: boolean
  userKind?: number
  tantoCode?: number
  isNotDeleteMessage?: boolean // 検索後にメッセージを消さないフラグ
  isSuperUser?: boolean // スーパーユーザーかどうか
  isJocho?: boolean // 上長かどうか
  isKoiki?: boolean // 広域量販部かどうか
  initJotaiKbn?: string // ログイン時の状態チェックボックスを設定するための区分。1：ジャペル（一般・スーパーユーザー）、2：ジャペル（上長）、3：仕入先
}

export const SearchJokenRitsuReducer: Reducer<SearchJokenRitsuState, SearchJokenRitsuAction> = produce((draft: SearchJokenRitsuState, action: SearchJokenRitsuAction) => {
  switch (action.type) {
    // 上長否認　確認済チェックボックスを変更する処理 //===========================//
    case 'SearchJokenRitsuChangeJochoHininkakuninzumiCheckbox': {
      draft.searchJokenRitsu.jochoHininKakuninzumiCheckbox = !draft.searchJokenRitsu.jochoHininKakuninzumiCheckbox
      return
    }

    // メーカー否認　確認済チェックボックスを変更する処理 //===========================//
    case 'SearchJokenRitsuChangeHininkakuninzumiCheckbox': {
      draft.searchJokenRitsu.hininKakuninzumiCheckbox = !draft.searchJokenRitsu.hininKakuninzumiCheckbox
      return
    }

    // 上長否認　未確認チェックボックスを変更する処理 //===========================//
    case 'SearchJokenRitsuChangeJochoHininMikakuninCheckbox': {
      draft.searchJokenRitsu.jochoHininMikakuninCheckbox = !draft.searchJokenRitsu.jochoHininMikakuninCheckbox
      draft.searchJokenRitsu.jochoHininKakuninzumiCheckbox = !draft.searchJokenRitsu.jochoHininKakuninzumiCheckbox
      return
    }

    // メーカー否認　未確認チェックボックスを変更する処理 //===========================//
    case 'SearchJokenRitsuChangeHininMikakuninCheckbox': {
      draft.searchJokenRitsu.hininMikakuninCheckbox = !draft.searchJokenRitsu.hininMikakuninCheckbox
      return
    }

    // 上長未承認チェックボックスを変更する処理 //===========================//
    case 'SearchJokenRitsuChangeJochoMishoninCheckbox': {
      draft.searchJokenRitsu.jochoMishoninCheckbox = !draft.searchJokenRitsu.jochoMishoninCheckbox
      return
    }

    // 上長承認チェックボックスを変更する処理 //===========================//
    case 'SearchJokenRitsuChangeJochoShoninCheckbox': {
      draft.searchJokenRitsu.jochoShoninCheckbox = !draft.searchJokenRitsu.jochoShoninCheckbox
      return
    }
    // 検索後にメッセージを消さないフラグを変更する処理 //===========================//
    case 'SearchJokenRitsuChangeIsDeleteMessage': {
      draft.isNotDeleteMessage = action.isNotDeleteMessage!
      return
    }

    // 入力担当者処理 //===========================//
    case 'SearchJokenRitsuChangeAddTantoCode': {
      draft.searchJokenRitsu.ADD_TANTO_CD = convertNumber(action.tantoCode!, 6)
      return
    }

    // 初期状態のチェックボックスを変更する処理 //===========================//
    case 'SearchJokenRitsuChangeInitCheckbox': {
      draft.initMishoninCheckbox = draft.searchJokenRitsu.mishoninCheckbox
      draft.initShoninCheckbox = draft.searchJokenRitsu.shoninCheckbox
      draft.initHininCheckbox = draft.searchJokenRitsu.hininCheckbox
      draft.initJochoMishoninCheckbox = draft.searchJokenRitsu.jochoMishoninCheckbox
      draft.initHininMikakuninCheckbox = draft.searchJokenRitsu.hininMikakuninCheckbox
      draft.initJochoHininMikakuninCheckbox = draft.searchJokenRitsu.jochoHininMikakuninCheckbox
      draft.initHininkakuninzumiCheckbox = draft.searchJokenRitsu.hininKakuninzumiCheckbox
      draft.initJochoHininKakuninzumiCheckbox = draft.searchJokenRitsu.jochoHininKakuninzumiCheckbox
      return
    }

    // 確認対象表示状態にする処理 //===========================//
    case 'SearchJokenRitsuChangeKakunintaisyoCheckbox': {
      switch (action.initJotaiKbn) {
        case '1': // ジャペル（一般・スーパーユーザー）
          draft.searchJokenRitsu.mishoninCheckbox = true
          draft.searchJokenRitsu.hininMikakuninCheckbox = true
          break
        case '2': // ジャペル（上長）
          draft.searchJokenRitsu.jochoMishoninCheckbox = true
          break
        case '3': // 仕入先
          draft.searchJokenRitsu.mishoninCheckbox = true
          break
      }
      return
    }

    // 否認チェックボックスを変更する処理 //===========================//
    case 'SearchJokenRitsuChangeHininCheckbox': {
      draft.searchJokenRitsu.hininCheckbox = !draft.searchJokenRitsu.hininCheckbox
      return
    }

    // 承認チェックボックスを変更する処理 //===========================//
    case 'SearchJokenRitsuChangeShoninCheckbox': {
      draft.searchJokenRitsu.shoninCheckbox = !draft.searchJokenRitsu.shoninCheckbox
      return
    }

    // 未承認チェックボックスを変更する処理 //===========================//
    case 'SearchJokenRitsuChangeMishoninCheckbox': {
      draft.searchJokenRitsu.mishoninCheckbox = !draft.searchJokenRitsu.mishoninCheckbox
      return
    }
    // 処理年月終了を変更する処理 //===========================//
    case 'SearchJokenRitsuChangeSyoriEndDate': {
      draft.searchJokenRitsu.SYORI_YM_END = getNumberFromString(action.syoriEndDate!)
      return
    }

    // 処理年月開始を変更する処理 //===========================//
    case 'SearchJokenRitsuChangeSyoriStartDate': {
      draft.searchJokenRitsu.SYORI_YM_START = getNumberFromString(action.syoriStartDate!)
      return
    }

    // 得意先処理 //===========================//
    case 'SearchJokenRitsuChangeTokuisakiCode': {
      draft.searchJokenRitsu.TOKUI_CD = convertNumber(action.tokuisakiCode!, 6)
      return
    }
    case 'SearchJokenRitsuChangeTokuisakiList': {
      draft.searchJokenRitsu.tokuisakiList = action.tokuisakiList!
      const tokuisakiList = draft.searchJokenRitsu.tokuisakiList
      if (tokuisakiList.length >= 1) {
        // 1つ選択される場合
        draft.searchJokenRitsu.TOKUI_CD = tokuisakiList[0].TOKUI_CD
        draft.searchJokenRitsu.TOKUI_NM = tokuisakiList[0].TOKUI_NM
      } else {
        // 1つも選択されていない場合
        draft.searchJokenRitsu.TOKUI_CD = initialState.searchJokenRitsu.TOKUI_CD
        draft.searchJokenRitsu.TOKUI_NM = initialState.searchJokenRitsu.TOKUI_NM
      }
      return
    }
    case 'SearchJokenRitsuChangeTokuisakiError': {
      draft.TOKUI_ERROR = action.error!
      return
    }
    case 'SearchJokenRitsuDeleteTokuisaki': {
      draft.searchJokenRitsu.tokuisakiList = initialState.searchJokenRitsu.tokuisakiList
      draft.searchJokenRitsu.TOKUI_CD = initialState.searchJokenRitsu.TOKUI_CD
      draft.searchJokenRitsu.TOKUI_NM = initialState.searchJokenRitsu.TOKUI_NM
      draft.TOKUI_ERROR = initialState.TOKUI_ERROR
      return
    }

    // 事業所処理 //===========================//
    case 'SearchJokenRitsuChangeJigyoshoCode': {
      draft.searchJokenRitsu.JIGYO_CD = convertNumber(action.jigyoshoCode!, 2)
      return
    }
    // ユーザー所属部署ごとの初期値入力処理 //===========================//
    case 'SearchJokenRitsuChangeInitCode': {
      const JIGYO_CD = convertNumber(action.jigyoshoCode!, 2)
      const TANTO_CD = convertNumber(action.tantoCode!, 6)
      const isSuperUser = action.isSuperUser!
      const isJocho = action.isJocho!
      const isKoiki = action.isKoiki!
      if (isSuperUser) {
        draft.searchJokenRitsu.JIGYO_CD = JIGYO_CD // 特定の部署の人の事業所の初期値は自分の事業所を選択
      } else {
        if (!isJocho) draft.searchJokenRitsu.ADD_TANTO_CD = TANTO_CD // 一般ユーザーの入力担当者の初期値は自分
      }
      if (isJocho && !isKoiki) {
        if (!isJochoSpecialUser(JIGYO_CD)) draft.searchJokenRitsu.JIGYO_CD = JIGYO_CD // 上長かつ関西か名古屋以外かつ広域量販部ではない事業所の初期値は自分の事業所
      }
      return
    }

    // 仕入先処理 //===========================//
    case 'SearchJokenRitsuChangeShiiresakiCode': {
      draft.searchJokenRitsu.SIIRE_CD = convertNumber(action.shiiresakiCode!, 4)
      return
    }
    case 'SearchJokenRitsuDeleteShiiresakiName': {
      draft.searchJokenRitsu.SIIRE_NM = ''
      return
    }
    case 'SearchJokenRitsuChangeShiiresakiList': {
      const shiiresakiList = action.shiiresakiList!
      draft.searchJokenRitsu.shiiresakiList = shiiresakiList
      if (shiiresakiList.length >= 1) {
        draft.searchJokenRitsu.SIIRE_CD = shiiresakiList[0].SIIRE_CD
        draft.searchJokenRitsu.SIIRE_NM = shiiresakiList[0].SIIRE_NM
      }
      return
    }
    case 'SearchJokenRitsuChangeShiiresakiError': {
      draft.SIIRE_ERROR = action.error!
      return
    }

    // 条件初期化 //===========================//
    case 'SearchJokenRitsuFormat': {
      draft.searchJokenRitsu = {
        ...initialState.searchJokenRitsu,
        mishoninCheckbox: draft.initMishoninCheckbox,
        shoninCheckbox: draft.initShoninCheckbox,
        hininCheckbox: draft.initHininCheckbox,
        jochoMishoninCheckbox: draft.initJochoMishoninCheckbox,
        hininMikakuninCheckbox: draft.initHininMikakuninCheckbox,
        jochoHininMikakuninCheckbox: draft.initJochoHininMikakuninCheckbox,
        hininKakuninzumiCheckbox: draft.initHininkakuninzumiCheckbox,
        jochoHininKakuninzumiCheckbox: draft.initJochoHininKakuninzumiCheckbox,
      }
      return
    }
    // 条件完全初期化 //===========================//
    case 'SearchJokenRitsuAllFormat': {
      for (const key in draft) {
        const tempKey: keyof SearchJokenRitsuState = key as keyof SearchJokenRitsuState
        ;(draft as any)[tempKey] = initialState[tempKey]
      }
      return
    }
    // メッセージ変更 //===========================//
    case 'SearchJokenRitsuChangeMessage': {
      draft.message = action.message!
      return
    }
    // ローディング処理 //===========================//
    case 'SearchJokenRitsuChangeLoading': {
      draft.loading = action.loading!
      return
    }
    // 検索前後判定 //===========================//
    case 'SearchJokenRitsuChangeTrail': {
      draft.trail = action.trail!
      return
    }
  }
}, initialState)
