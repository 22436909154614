import { commonMsg } from 'assets/MessageCode'
import { LoginAction } from 'reducers/LoginReducer'
import { keyInfo } from 'types/ReferTypes'

// マニュアルのPDFのページ数 //===========================//
export const loginChangeManualNumPages = (manualNumPages: number): LoginAction => {
  return {
    type: 'LoginChangeManualNumPages',
    manualNumPages: manualNumPages,
  }
}

// ログインユーザーの種類を変更処理 //===========================//
export const loginChangeUserKind = (userKind: number): LoginAction => {
  return {
    type: 'LoginChangeUserKind',
    userKind: userKind,
  }
}

// ログイン前後判定変更処理 //================================//
export const changeLoginKbn = (loginKbn: 0 | 1 | 2): LoginAction => {
  return {
    type: 'LoginChangeLoginKbn',
    loginKbn: loginKbn,
  }
}

// ログインユーザーコード入力処理 //================================//
export const changeUserCode = (user_code: string): LoginAction => {
  return {
    type: 'LoginChangeUserCode',
    user_code: user_code,
  }
}

// ログインユーザー名入力処理 //================================//
export const changeInfo = (info: {
  user_name: string
  mail: string
  keyInfo: keyInfo[]
  TANTO_CD: number
  isJocho: boolean
  isKoiki: boolean
  isSuperUser: boolean
  resetButton: string
  userTorokuMessage: string //
}): LoginAction => {
  return {
    type: 'LoginChangeInfo',
    user_name: info.user_name,
    mail: info.mail,
    keyInfo: info.keyInfo,
    TANTO_CD: info.TANTO_CD,
    isJocho: info.isJocho,
    isKoiki: info.isKoiki,
    isSuperUser: info.isSuperUser,
    // resetButton: info.resetButton,
    // userTorokuMessage: info.userTorokuMessage,
  }
}

// メッセージ変更処理 //================================//
export const changeMessage = (message: string): LoginAction => {
  return {
    type: 'LoginChangeMessage',
    message: message,
  }
}

// 検索失敗メッセージ取得処理 //================================//
export const changeErrorMessage = (): LoginAction => {
  return {
    type: 'LoginChangeMessage',
    message: commonMsg.ERROR_LOGIN_MSG,
  }
}

// パスワード再発行時に入力するメールアドレスを変更する処理 //================================//
export const changeInputMail = (inputMail: string): LoginAction => {
  return {
    type: 'LoginChangeInputMail',
    inputMail: inputMail,
  }
}

// パスワード変更時に入力するパスワードを変更する処理 //================================//
export const changeInputPassword = (inputPassword: string): LoginAction => {
  return {
    type: 'LoginChangeInputPassword',
    inputPassword: inputPassword,
  }
}

// パスワード変更時に入力するユーザー名を変更する処理 //================================//
export const changeInputUserName = (inputUserName: string): LoginAction => {
  return {
    type: 'LoginChangeInputUserName',
    inputUserName: inputUserName,
  }
}

// パスワード変更時に入力する再入力パスワードを変更する処理 //================================//
export const changeReInputPassword = (reInputPassword: string): LoginAction => {
  return {
    type: 'LoginChangeReInputPassword',
    reInputPassword: reInputPassword,
  }
}

// 初期化時メッセージを初期化するかのフラグを変更する処理 //================================//
export const changeInitMessageFlg = (initMessageFlg: boolean): LoginAction => {
  return {
    type: 'LoginChangeInitMessageFlg',
    initMessageFlg: initMessageFlg,
  }
}

// パスワード再発行の前後判定を変更する処理 //================================//
export const changeIsCreatedPassword = (isCreatedPassword: boolean): LoginAction => {
  return {
    type: 'LoginChangeIsCreatedPassword',
    isPassword: isCreatedPassword,
  }
}

// パスワード変更の前後判定を変更する処理 //================================//
export const changeIsChangedPassword = (isChangedPassword: boolean): LoginAction => {
  return {
    type: 'LoginChangeIsChangedPassword',
    isPassword: isChangedPassword,
  }
}

// ログアウト処理 //================================//
export const format = (): LoginAction => {
  return {
    type: 'LoginFormat',
  }
}
