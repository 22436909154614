import { TableAction } from 'reducers/TableReducers/TableReducer'

// 詳細画面のページネーション初期化処理 //================================//
export const formatPageDetail = (): TableAction => {
  return {
    type: 'TableFormatPageDetail',
  }
}

// 詳細画面のページ送り処理 //================================//
export const changePageDetail = (newPage: number): TableAction => {
  return {
    type: 'TableChangePageDetail',
    page: newPage,
  }
}

// 詳細画面のページ表示数変更処理 //================================//
export const changePerPageDetail = (page: number): TableAction => {
  return {
    type: 'TableChangePerPageDetail',
    page: page,
  }
}

// 全チェック処理 //================================//
export const allCheck = (isChecked: boolean): TableAction => {
  return {
    type: 'TableAllCheck',
    isChecked: isChecked,
  }
}

// ページ送り処理 //================================//
export const changePage = (newPage: number): TableAction => {
  return {
    type: 'TableChangePage',
    page: newPage,
  }
}

// ページ表示数変更処理 //================================//
export const changePerPage = (page: number): TableAction => {
  return {
    type: 'TableChangePerPage',
    page: page,
  }
}

// 明細ページ表示件数以外初期化処理 //================================//
export const format = (): TableAction => {
  return {
    type: 'TableFormat',
  }
}
// 完全初期化処理 //================================//
export const allFormat = (): TableAction => {
  return {
    type: 'TableAllFormat',
  }
}
