import Select from 'react-select'
import { select } from 'types/SearchTypes'

type Props = {
  valueSet: any
  defValue: string
  event: (targetValue: string) => void
  lib?: boolean
  disabled?: boolean
}

const A_SelectBox = ({ valueSet, defValue, event, lib = false, disabled = false }: Props) => {
  if (lib)
    return (
      <Select
        options={valueSet}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            // minHeight: 30, width: 250,
            // fontSize: 5,
            // borderColor: state.isFocused ? 'grey' : 'red',
            height: '90%',
            width: '90%',
          }),
        }}
        // isSearchable={false}
        // isRtl
        // isDisabled
        // isMulti
        defaultValue={defValue}
        onChange={(value) => {
          console.log(value)
        }}
      />
    )

  return (
    <select style={{ height: 25, width: 140 }} onChange={(e) => event(e.target.value)} defaultValue={defValue} disabled={disabled}>
      {valueSet.map((op: select) => (
        <option value={op.value} selected={defValue === op.value ? true : false}>
          {op.label}
        </option>
      ))}
    </select>
  )
}

export default A_SelectBox
