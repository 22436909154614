import React, { useEffect } from 'react'
import A_Button from '../A_Button'
import { moleculeLog } from 'utils/logUtil'

type Props = {
  label: string
  disabled?: boolean
  event: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const M_ButtonAlert = ({ label, event, disabled = false }: Props) => {
  useEffect(() => moleculeLog('M_ButtonAlert'))
  return <A_Button variant="contained" color="secondary" label={label} event={event} disabled={disabled} />
}
export default React.memo(M_ButtonAlert)
