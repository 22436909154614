import { Reducer } from 'redux'
import produce from 'immer'
import { F_STR } from 'assets/FormatNumbers'

export type TableState = {
  isChecked: boolean
  message: string
  page: number
  pageDetail: number
  perPage: number
  perPageDetail: number
}

const initialState: TableState = {
  isChecked: false,
  message: F_STR,
  page: 0,
  perPage: 100,
  pageDetail: 0,
  perPageDetail: 100,
}

export type TableType =
  | 'TableAllCheck' //
  | 'TableChangePage'
  | 'TableChangePerPage'
  | 'TableChangeMessage'
  | 'TableFormatPage'
  | 'TableFormat'
  | 'TableChangePageDetail'
  | 'TableChangePerPageDetail'
  | 'TableFormatPageDetail'
  | 'TableAllFormat'
  | 'dummy'

export type TableAction = {
  type: TableType
  list_id?: string
  item_id?: string
  isChecked?: boolean
  message?: string
  page?: number
}

export const TableReducer: Reducer<TableState, TableAction> = produce((draft: TableState, action: TableAction) => {
  switch (action.type) {
    case 'TableFormatPageDetail': {
      draft.pageDetail = initialState.pageDetail
      return
    }
    case 'TableChangePageDetail': {
      draft.pageDetail = action.page!
      return
    }
    case 'TableChangePerPageDetail': {
      draft.perPageDetail = action.page!
      draft.pageDetail = initialState.pageDetail
      return
    }
    case 'TableAllCheck': {
      draft.isChecked = action.isChecked!
      return
    }
    case 'TableFormatPage': {
      draft.perPage = initialState.perPage
      draft.page = initialState.page
      return
    }
    case 'TableChangePage': {
      draft.page = action.page!
      return
    }
    case 'TableChangePerPage': {
      draft.perPage = action.page!
      draft.page = initialState.page
      return
    }
    case 'TableChangeMessage': {
      draft.message = action.message!
      return
    }
    case 'TableFormat': {
      for (const key in draft) {
        const tempKey: keyof TableState = key as keyof TableState
        if (tempKey !== 'perPageDetail') (draft as any)[tempKey] = initialState[tempKey]
      }
      return
    }
    case 'TableAllFormat': {
      for (const key in draft) {
        const tempKey: keyof TableState = key as keyof TableState
        ;(draft as any)[tempKey] = initialState[tempKey]
      }
      return
    }
  }
}, initialState)
