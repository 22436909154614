import { TableJokenAction } from 'reducers/TableReducers/TableJokenReducer'
import { responseDetailJoken, responseSummaryJoken } from 'types/ResponseTypes'
import { checkListDetail, checkListSummary } from 'types/SearchTypes'

// 上長否認理由を変更する処理 //================================//
export const changeJochoHininRiyu = (checkShohin: checkListDetail): TableJokenAction => {
  return {
    type: 'TableJokenChangeJochoHininRiyu',
    checkShohin: checkShohin,
  }
}

// 条件を選択する処理 //===========================//
export const clickJoken = (kanriNo: string): TableJokenAction => {
  return {
    type: 'TableJokenClickJoken',
    kanriNo: kanriNo,
  }
}

// 条件管理番号を全チェックする処理 //================================//
export const allCheckJoken = (isChecked: boolean, jokenList: responseSummaryJoken[]): TableJokenAction => {
  return {
    type: 'TableJokenAllCheckJoken',
    isChecked: isChecked,
    jokenList: jokenList,
  }
}

// 条件管理番号をチェックする処理 //================================//
export const checkJoken = (isChecked: boolean, checkData: checkListSummary): TableJokenAction => {
  return {
    type: 'TableJokenCheckJoken',
    isChecked: isChecked,
    checkData: checkData,
  }
}

// 条件管理番号チェックリストを変更する処理 //================================//
export const changeCheckJoken = (checkDataList: checkListSummary[]): TableJokenAction => {
  return {
    type: 'TableJokenChangeCheckJoken',
    checkDataList: checkDataList,
  }
}

// 明細を全チェックする処理 //================================//
export const allCheckShohin = (isChecked: boolean, detailList: responseDetailJoken[]): TableJokenAction => {
  return {
    type: 'TableJokenAllCheckShohin',
    isChecked: isChecked,
    detailList: detailList,
  }
}

// 明細をチェックする処理 //================================//
export const checkDetail = (isChecked: boolean, checkDetail: checkListDetail): TableJokenAction => {
  return {
    type: 'TableJokenCheckShohin',
    isChecked: isChecked,
    checkShohin: checkDetail,
  }
}

// 否認理由を変更する処理 //================================//
export const changeHininRiyu = (checkShohin: checkListDetail): TableJokenAction => {
  return {
    type: 'TableJokenChangeHininRiyu',
    checkShohin: checkShohin,
  }
}

// 明細チェックリストを変更する処理 //================================//
export const changeCheckJokenDetail = (checkListDetail: checkListDetail[]): TableJokenAction => {
  return {
    type: 'TableJokenChangeCheckJokenDetail',
    checkListDetail: checkListDetail,
  }
}

// サマリチェックリストを初期化する処理 //================================//
export const formatCheckSummary = (): TableJokenAction => {
  return {
    type: 'TableJokenFormatCheckSummary',
  }
}

// 明細チェックリストを初期化する処理 //================================//
export const formatCheckDetail = (): TableJokenAction => {
  return {
    type: 'TableJokenFormatCheckDetail',
  }
}

// 初期化処理 //================================//
export const format = (): TableJokenAction => {
  return {
    type: 'TableJokenFormat',
  }
}
