/* eslint-disable react/jsx-pascal-case */
import React, { useEffect } from 'react'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import Radio from '@material-ui/core/Radio'
import Checkbox from '@material-ui/core/Checkbox'
import { HtmlToolTip } from 'assets/ToolTips'
import { getStyle } from 'assets/TableHeader'
import { moleculeLog } from 'utils/logUtil'

type Props = {
  category: 'number' | 'radio' | 'checkbox' | 'data'
  caption?: boolean
  size?: 'caption' | 'button'
  title?: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal
  data?: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal
  isChecked?: boolean
  width?: number | string
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify' | undefined
  click?: React.MouseEventHandler<HTMLSpanElement> | undefined
}

const M_TableCellHead = ({
  category, //
  caption = true,
  size = 'caption',
  title,
  data,
  isChecked,
  width = 30,
  align = 'center',
  click = () => {},
}: Props) => {
  useEffect(() => moleculeLog('M_TableCellHead'))

  if (category === 'number') {
    return (
      <TableCell style={getStyle(width, true)} align={align}>
        <Typography variant={size}>{data}</Typography>
      </TableCell>
    )
  }
  if (category === 'radio') {
    return (
      <TableCell style={getStyle(width, true)} padding="checkbox" align={align}>
        <Radio checked={isChecked} />
      </TableCell>
    )
  }
  if (category === 'checkbox') {
    return (
      <TableCell style={getStyle(width, true)} padding="checkbox" align={align}>
        <Checkbox checked={isChecked} />
      </TableCell>
    )
  }
  return (
    <TableCell style={getStyle(width, true)} align={align}>
      {caption ? (
        <HtmlToolTip
          title={
            <Typography variant={size} color="inherit">
              {title}
            </Typography>
          }
        >
          <Typography variant={size} onClick={click}>
            {data}
          </Typography>
        </HtmlToolTip>
      ) : (
        <Typography variant={size} onClick={click}>
          {data}
        </Typography>
      )}
    </TableCell>
  )
}

export default React.memo(M_TableCellHead)
