/* eslint-disable react/jsx-pascal-case */
import React, { ReactNode, useEffect } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { templateLog } from 'utils/logUtil'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
    },
  }),
)

type Props = {
  children: ReactNode
}

const T_FormOnly = ({ children }: Props) => {
  useEffect(() => templateLog('T_FormOnly'))
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <CssBaseline />
      {children}
    </div>
  )
}
export default React.memo(T_FormOnly)
