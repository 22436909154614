import { F_NUM } from 'assets/FormatNumbers'

/**
 * DatePicker用フォーマットを数値時刻して返す関数
 * @param dateStr 時刻文字列
 * @param split 区切り文字(デフォルトは'-')
 * @returns 数値時刻
 */
export const getNumberFromString = (dateStr: string) => {
  if (!dateStr) return F_NUM
  return parseInt(dateStr.replace(/-/g, ''), 10)
}

/**
 * DatePicker用日時フォーマットを数値時刻して返す関数
 * @param dateStr 時刻文字列
 * @param split 区切り文字(デフォルトは'-')
 * @returns 数値時刻
 */
export const getNumberFromDateTimeString = (dateStr: string) => {
  return parseInt(dateStr.replace(/[-T:]/g, ''), 10)
}

/**
 * 数値時刻をDatePicker用フォーマットに合わせて返す関数
 * @param date 時刻
 * @param digit 桁数(デフォルトは 8 )
 * @returns フォーマットに合わせた時刻
 */
export const getNumberFromDate = (date: Date, digit: number = 8) => {
  if (digit === 8) {
    return date.getFullYear() * 10000 + (date.getMonth() + 1) * 100 + date.getDate()
  }
  return date.getFullYear() * 100 + (date.getMonth() + 1)
}

/**
 * 数値時刻をDatePicker用フォーマットに合わせて返す関数
 * @param number 数値時刻
 * @param split 区切り文字(デフォルトは'-')
 * @returns フォーマットに合わせた時刻
 */
export const getTimestampFromNumber = (number: number, split: string = '-') => {
  const digit = number.toString().length
  if (digit === 8) {
    return ((number / 10000) | 0) + split + zeroPadding(((number % 10000 | 0) / 100) | 0) + split + zeroPadding((number % 1000 | 0) % 100 | 0)
  }
  if (digit === 12) {
    return ((number / 100000000) | 0) + split + zeroPadding(((number % 100000000 | 0) / 1000000) | 0) + split + zeroPadding(((number % 1000000 | 0) / 10000) | 0) + 'T' + zeroPadding(((number % 10000 | 0) / 100) | 0) + ':' + zeroPadding(((number % 100 | 0) / 1) | 0)
  }
  return (
    // 2021 10
    ((number / 100) | 0) + split + zeroPadding(number % 100 | 0)
  )
}

/**
 * うるう年かどうか判定する処理
 * @param year 年
 * @returns うるう年か否か
 */
const getUru = (year: number) => {
  if (year % 4 === 0) {
    if (year % 100 === 0) {
      if (year % 400 === 0) {
        return true // うるう年
      }
    } else {
      return true // うるう年
    }
  }
  return false
}

/**
 * 数値時刻をフォーマットに合わせた時刻に変換して返す関数
 * @param number 数値時刻
 * @param format フォーマット
 * @returns フォーマットに合わせた時刻
 */
export const getTimestampFromNumberFormat = (number: number, format: string, edge: 'start' | 'end', zeroPadding: boolean = false) => {
  const digit = number.toString().length
  let year = -1
  let month = -1
  let day = -1
  if (digit === 8) {
    year = (number / 10000) | 0
    month = ((number % 10000 | 0) / 100) | 0
    day = (number % 1000 | 0) % 100 | 0
  } else {
    year = (number / 100) | 0
    month = number % 100 | 0
    if (edge === 'start') {
      day = 1
    } else if (edge === 'end') {
      switch (month) {
        case 2:
          day = getUru(year) ? 29 : 28
          break
        case 4:
        case 6:
        case 9:
        case 11:
          day = 30
          break
        default:
          day = 31
          break
      }
    }
  }

  format = format.replace(/YYYY/g, zeroPadding ? ('0000' + year).slice(-4) : year.toString())
  format = format.replace(/MM/g, zeroPadding ? ('00' + month).slice(-2) : month.toString())
  format = format.replace(/DD/g, zeroPadding ? ('00' + day).slice(-2) : day.toString())
  return format
}

/**
 * 数値日付をYYYYMMDDの形式にして返す関数
 * @param number 変換元数値日付
 * @return number YYYYMMDDの形式にした数値日付
 */
export const convertYMD = (number: number, edge: 'start' | 'end' = 'end') => {
  return parseInt(getTimestampFromNumberFormat(number, 'YYYYMMDD', edge, true))
}

/**
 * UTC時刻をフォーマットに合わせた時刻に変換して返す関数
 * @param number UTC時刻
 * @param format フォーマット
 * @returns フォーマットに合わせた時刻
 */
export const getTimestampFromUTCNumber = (number: number, format: string) => {
  const date = new Date(number)
  format = format.replace(/YYYY/g, date.getFullYear().toString())
  format = format.replace(/MM/g, zeroPadding(date.getMonth() + 1))
  format = format.replace(/DD/g, zeroPadding(date.getDate()))
  format = format.replace(/hh/g, zeroPadding(date.getHours()))
  format = format.replace(/mm/g, zeroPadding(date.getMinutes()))
  format = format.replace(/ss/g, zeroPadding(date.getSeconds()))
  return format
}

/**
 * 日付インスタンスをフォーマットに合わせた時刻に変換して返す関数
 * @param date 日付インスタンス
 * @param format フォーマット
 * @returns フォーマットに合わせた時刻
 */
export const getTimestampFromDate = (date: Date, format: string) => {
  format = format.replace(/YYYY/g, date.getFullYear().toString())
  format = format.replace(/MM/g, zeroPadding(date.getMonth() + 1))
  format = format.replace(/DD/g, zeroPadding(date.getDate()))
  format = format.replace(/hh/g, zeroPadding(date.getHours()))
  format = format.replace(/mm/g, zeroPadding(date.getMinutes()))
  format = format.replace(/ss/g, zeroPadding(date.getSeconds()))
  return format
}

/**
 * DateをUTC時刻にして返す関数
 * @param date 日付インスタンス
 * @returns UTC時刻
 */
export const getTime = (date: Date) => {
  return date.getTime()
}

/**
 * 日付をゼロ埋めして返す関数
 * @param dateStr 日付(文字列)
 * @returns 左からゼロ埋めされた日付文字列
 */
export const zeroPadding = (dateStr: number) => {
  // return dateStr.toString()
  return ('0' + dateStr).slice(-2)
}

/**
 * 確定月を取得する関数
 * @param togetsu 入力値
 * @returns string
 */
export const getZengetsu = (tougetsu: string | number) => {
  const ym = typeof tougetsu === 'number' ? tougetsu : parseInt(tougetsu)
  const year = (ym / 100) | 0
  const month = ym - year * 100
  return month === 1 ? ((year - 1) * 100 + 12).toString() : (year * 100 + month - 1).toString()
}

/**
 * 翌月を取得する関数
 * @param togetsu 入力値
 * @returns string
 */
export const getYokugetsu = (tougetsu: string | number) => {
  const ym = typeof tougetsu === 'number' ? tougetsu : parseInt(tougetsu)
  const year = (ym / 100) | 0
  const month = ym - year * 100
  return month === 12 ? ((year + 1) * 100 + 1).toString() : (year * 100 + month + 1).toString()
}
