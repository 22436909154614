import React, { useEffect } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import TableRow from '@material-ui/core/TableRow'
import { tenyuryokuTableSummary } from 'assets/TableHeader'
import M_TableCell from 'components/1_Atoms/2_Molecules/M_TableCellHead'
import { organismLog } from 'utils/logUtil'
import { useUser } from 'hooks/useLogin'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
    },
    table: {
      // backgroundColor: '#66FF99',
    },
    container: {
      maxHeight: 600,
    },
  }),
)

const width = tenyuryokuTableSummary.width
const label = tenyuryokuTableSummary.label

type Props = {
  click: (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.MouseEvent<HTMLTableRowElement, MouseEvent>, isChecked: boolean) => void
  isChecked: boolean
}

const O_TableHeaderTenyuryokuSummary = ({ click, isChecked }: Props) => {
  useEffect(() => organismLog('O_TableHeaderTenyuryokuSummary'))
  const classes = useStyles()
  const userKind = useUser()

  return (
    <TableRow
      hover //
      onClick={(e) => click(e, isChecked)}
      className={classes.table}
    >
      {/* No */}
      <M_TableCell category="number" data={'NO'} width={width.NO} />
      {/* チェックボックス */}
      <M_TableCell category="checkbox" isChecked={isChecked} width={width.CHECK} />
      {/* 状態 */}
      <M_TableCell category="data" width={width.JOTAI} title={label.JOTAI} data={label.JOTAI} />
      {/* 上長承認状態 */ userKind === 0 ? <M_TableCell category="data" width={width.JOCHO_JOTAI} title={label.JOCHO_JOTAI} data={label.JOCHO_JOTAI} /> : <></>}
      {/* 処理No */}
      <M_TableCell category="data" width={width.DENPYO_NO} title={label.DENPYO_NO} data={label.DENPYO_NO} />
      {/* 処理票日付 */}
      <M_TableCell category="data" width={width.SYORI_YMD} title={label.SYORI_YMD} data={label.SYORI_YMD} />
      {/* 件名 */}
      <M_TableCell category="data" width={width.SEISAN_NAIYO} title={label.SEISAN_NAIYO} data={label.SEISAN_NAIYO} />
      {/* 事業所名 */}
      <M_TableCell category="data" width={width.JIGYO_NM} title={label.JIGYO_NM} data={label.JIGYO_NM} />
      {/* 仕入先名 */}
      <M_TableCell category="data" width={width.SIIRE_NM} title={label.SIIRE_NM} data={label.SIIRE_NM} />
      {/* 担当者名 */}
      <M_TableCell category="data" width={width.TANTO_NM} title={label.TANTO_NM} data={label.TANTO_NM} />
      {/* 精算金額 */}
      <M_TableCell category="data" width={width.SEISAN_TOTAL_GK} title={label.SEISAN_TOTAL_GK} data={label.SEISAN_TOTAL_GK} />
      {/* ファイル */}
      <M_TableCell category="data" width={width.existFile} title={label.existFile} data={label.existFile} />
      {/* 備考 */}
      <M_TableCell category="data" width={width.BIKOU} title={label.BIKOU} data={label.BIKOU} />
    </TableRow>
  )
}

export default React.memo(O_TableHeaderTenyuryokuSummary)
