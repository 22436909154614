import { Reducer } from 'redux'
import produce from 'immer'
import { searchLog, select } from 'types/SearchTypes'
import { convertNumber, fixNullValue } from 'utils/numberUtil'
import { F_NUM, F_STR } from 'assets/FormatNumbers'
import { getNumberFromDateTimeString } from 'utils/dateUtil'

/**
 * メイン参照テーブルはJTD368
 */
export type SearchLogState = {
  searchLog: searchLog // 販売条件照会画面検索情報
  JIGYO_ERROR: boolean // 事業所コードが取得できているか
  SIIRE_ERROR: boolean // 仕入先コードが取得できているか
  TOKUI_ERROR: boolean // 得意先コードが取得できているか
  TANTO_ERROR: boolean // 担当者コードが取得できているか
  MAIL_ERROR: boolean // メールアドレスが取得できているか
  initView: number // 初期描画フラグ
  jigyoList: select[] // 事業所リスト
}

const initJoken = {
  JIGYO_CD: F_NUM, // 事業所コード
  JIGYO_NM: F_STR, // 事業所名
  SIIRE_CD: F_NUM, // 仕入先コード
  SIIRE_NM: F_STR, // 仕入先名
  TOKUI_CD: F_NUM, // 得意先コード
  TOKUI_NM: F_STR, // 得意先名
  TANTO_CD: F_NUM, // 担当者コード
  TANTO_NM: F_STR, // 担当者名
  DT_FROM: F_NUM, // 日時FROM
  DT_TO: F_NUM, // 日時FROM
  MAIL: F_STR, // メールアドレス
  NAME: F_STR, // 氏名
  torokuCheckbox: true, // 登録チェックボックス
  sakujoCheckbox: true, // 削除チェックボックス
}

/**
 * 初期状態
 * 入力区分は販売条件とする
 * コード値は-1とする
 */
const initialState: SearchLogState = {
  searchLog: initJoken, // 販売条件照会画面検索情報
  JIGYO_ERROR: false, // 事業所コードが取得できているか
  SIIRE_ERROR: false, // 仕入先コードが取得できているか
  TOKUI_ERROR: false, // 得意先コードが取得できているか
  TANTO_ERROR: false, // 担当者コードが取得できているか
  MAIL_ERROR: false, // メールアドレスが取得できているか
  initView: 0, // 初期描画フラグ
  jigyoList: [], // 事業所リスト
}

export type SearchLogType =
  | 'SearchLogChangeDateTimeFrom' // 日時FROMを変更する処理
  | 'SearchLogChangeDateTimeTo' // 日時TOを変更する処理
  | 'SearchLogChangeTourokuCheckbox' // 登録チェックボックスを変更する処理
  | 'SearchLogChangeSakujoCheckbox' // 削除チェックボックスを変更する処理
  | 'SearchLogChangeTantoCode' // 担当者コードを変更する処理
  | 'SearchLogChangeTantoName' // 担当者名を変更する処理
  | 'SearchLogChangeJigyoshoCode' // 事業所コードを変更する処理
  | 'SearchLogChangeJigyoshoName' // 事業所名を変更する処理
  | 'SearchLogChangeShiiresakiCode' // 仕入先コードを変更する処理
  | 'SearchLogChangeShiiresakiName' // 仕入先名を変更する処理
  | 'SearchLogChangeTokuisakiCode' // 得意先コードを変更する処理
  | 'SearchLogChangeTokuisakiName' // 得意先名を変更する処理
  | 'SearchLogChangeMail' // メールアドレスを変更する処理
  | 'SearchLogChangeName' // 氏名を変更する処理
  | 'SearchLogJigyoshoError' // 事業所コードを取得できているかを変更する処理
  | 'SearchLogShiiresakiError' // 仕入先コードを取得できているかを変更する処理
  | 'SearchLogTokuisakiError' // 得意先コードを取得できているかを変更する処理
  | 'SearchLogTantoError' // 担当者コードを取得できているかを変更する処理
  | 'SearchLogMailError' // メールアドレスを取得できているかを変更する処理
  | 'SearchLogChangeInitView' // 初期描画フラグを変更する処理
  | 'SearchLogChangeJigyoList' // 事業所リストを変更する処理
  | 'SearchLogFormat' // 初期化する処理
  | 'dummy'

export type SearchLogAction = {
  type: SearchLogType
  dateTimeFrom?: string
  dateTimeTo?: string
  tantoCode?: number
  tantoName?: string
  jigyoshoCode?: number
  jigyoshoName?: string
  shiiresakiCode?: number
  shiiresakiName?: string
  tokuisakiCode?: number
  tokuisakiName?: string
  mail?: string
  name?: string
  jigyoshoError?: boolean
  shiiresakiError?: boolean
  tokuisakiError?: boolean
  tantoError?: boolean
  mailError?: boolean
  initView?: number
  jigyoList?: select[]
}

export const SearchLogReducer: Reducer<SearchLogState, SearchLogAction> = produce((draft: SearchLogState, action: SearchLogAction) => {
  switch (action.type) {
    // 事業所リストを変更する処理 //===========================//
    case 'SearchLogChangeJigyoList': {
      draft.jigyoList = action.jigyoList!
      return
    }

    // 初期描画フラグを変更する処理 //===========================//
    case 'SearchLogChangeInitView': {
      draft.initView = action.initView!
      return
    }

    // メールアドレスを取得できているかを変更する処理 //===========================//
    case 'SearchLogMailError': {
      draft.MAIL_ERROR = action.mailError!
      return
    }

    // 担当者コードを取得できているかを変更する処理 //===========================//
    case 'SearchLogTantoError': {
      draft.TANTO_ERROR = action.tantoError!
      return
    }

    // 得意先コードを取得できているかを変更する処理 //===========================//
    case 'SearchLogTokuisakiError': {
      draft.TOKUI_ERROR = action.tokuisakiError!
      return
    }

    // 仕入先コードを取得できているかを変更する処理 //===========================//
    case 'SearchLogShiiresakiError': {
      draft.SIIRE_ERROR = action.shiiresakiError!
      return
    }

    // 事業所コードを取得できているかを変更する処理 //===========================//
    case 'SearchLogJigyoshoError': {
      draft.JIGYO_ERROR = action.jigyoshoError!
      return
    }

    // 氏名を変更する処理 //===========================//
    case 'SearchLogChangeName': {
      draft.searchLog.NAME = action.name!
      return
    }

    // メールアドレスを変更する処理 //===========================//
    case 'SearchLogChangeMail': {
      draft.searchLog.MAIL = action.mail!
      return
    }

    // 得意先名を変更する処理 //===========================//
    case 'SearchLogChangeTokuisakiName': {
      draft.searchLog.TOKUI_NM = action.tokuisakiName!
      return
    }

    // 得意先コードを変更する処理 //===========================//
    case 'SearchLogChangeTokuisakiCode': {
      draft.searchLog.TOKUI_CD = convertNumber(action.tokuisakiCode!, 5)
      return
    }

    // 仕入先名を変更する処理 //===========================//
    case 'SearchLogChangeShiiresakiName': {
      draft.searchLog.SIIRE_NM = action.shiiresakiName!
      return
    }

    // 仕入先コードを変更する処理 //===========================//
    case 'SearchLogChangeShiiresakiCode': {
      draft.searchLog.SIIRE_CD = convertNumber(action.shiiresakiCode!, 4)
      return
    }

    // 事業所名を変更する処理 //===========================//
    case 'SearchLogChangeJigyoshoName': {
      draft.searchLog.JIGYO_NM = action.jigyoshoName!
      return
    }

    // 事業所コードを変更する処理 //===========================//
    case 'SearchLogChangeJigyoshoCode': {
      draft.searchLog.JIGYO_CD = convertNumber(action.jigyoshoCode!, 2)
      return
    }

    // 担当者名を変更する処理 //===========================//
    case 'SearchLogChangeTantoName': {
      draft.searchLog.TANTO_NM = action.tantoName!
      return
    }

    // 担当者コードを変更する処理 //===========================//
    case 'SearchLogChangeTantoCode': {
      draft.searchLog.TANTO_CD = convertNumber(action.tantoCode!, 6)
      return
    }

    // 削除チェックボックスを変更する処理 //===========================//
    case 'SearchLogChangeSakujoCheckbox': {
      draft.searchLog.sakujoCheckbox = !draft.searchLog.sakujoCheckbox
      return
    }

    // 登録チェックボックスを変更する処理 //===========================//
    case 'SearchLogChangeTourokuCheckbox': {
      draft.searchLog.torokuCheckbox = !draft.searchLog.torokuCheckbox
      return
    }

    // 日時Toを変更する処理 //===========================//
    case 'SearchLogChangeDateTimeTo': {
      draft.searchLog.DT_TO = fixNullValue(getNumberFromDateTimeString(action.dateTimeTo!).toString())
      return
    }

    // 日時FROMを変更する処理 //===========================//
    case 'SearchLogChangeDateTimeFrom': {
      draft.searchLog.DT_FROM = fixNullValue(getNumberFromDateTimeString(action.dateTimeFrom!).toString())
      return
    }

    // 初期化する処理 //===========================//
    case 'SearchLogFormat': {
      for (const key in draft) {
        const tempKey: keyof SearchLogState = key as keyof SearchLogState
        ;(draft as any)[tempKey] = initialState[tempKey]
      }
      return
    }
  }
}, initialState)
