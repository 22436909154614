/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useEffect, useState } from 'react'
import { TableVirtuoso } from 'react-virtuoso'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import O_TableHeaderUserSummary from 'components/3_Organisms/O_TableHeaderUserSummary'
import O_TableRowUserSummary from 'components/3_Organisms/O_TableRowUserSummary'
import O_TableBarUserDetail from 'components/3_Organisms/O_TableBarUserDetail'
import { useButtonsSummary, useCheckListSummary, useSearchedFormatSummary } from 'hooks/useUserTorokuTable'
import { useLoading } from 'hooks/usePage'
import { useChangeTableHeight, useChangeInnerHeight, useFormatDetail } from 'hooks/useCommon'
import { organismGroupLog } from 'utils/logUtil'
import { isUniqueUserSummary } from 'utils/booleanUtil'
import { responseSummaryUser } from 'types/ResponseTypes'
import { voidF } from 'types/FunctionTypes'
import { useLocation } from 'react-router-dom'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
    },
    container: {
      maxHeight: 600,
    },
    isWithinAYear: {
      height: 30,
      backgroundColor: '#00FF00',
    },
    isSelectedWithinAYear: {
      height: 30,
      backgroundColor: '#00BE1E',
    },
  }),
)

type Props = {
  userList: responseSummaryUser[]
  isFetching: boolean
  refetch: voidF
}

const O_TableUserSummary = ({ userList, isFetching, refetch }: Props) => {
  const location = useLocation()
  const [formatDetail] = useFormatDetail(location.pathname)
  useEffect(() => {
    organismGroupLog('O_TableUserSummary')
    formatDetail()
  }, [])
  const [tableHeight, setTableHeight] = useState(window.innerHeight)
  const [currentInnerHeight, setCurrentInnerHeight] = useState(window.innerHeight)
  const [previousInnerHeight, setPreviousInnerHeight] = useState(window.innerHeight)
  const [changeTableHeight] = useChangeTableHeight(tableHeight, currentInnerHeight, previousInnerHeight, setTableHeight)
  const [changeInnerHeight] = useChangeInnerHeight(currentInnerHeight, setCurrentInnerHeight, setPreviousInnerHeight)
  useEffect(() => {
    // ウィンドウサイズ変更時にchangeInnerHeightを呼び出すようにリスナーに登録
    window.removeEventListener('resize', changeInnerHeight)
    window.addEventListener('resize', changeInnerHeight)
    return () => {
      window.removeEventListener('resize', changeInnerHeight)
    }
  }, [window.innerHeight, currentInnerHeight])
  useEffect(() => {
    // useEffectが呼ばれるのはウィンドウサイズを変えたとき、スクロール含めた高さが変わったとき（初期表示時にも調整するため）
    // ウィンドウサイズを変えると上記2パターンに引っ掛かり2回動くので
    // changeTableHeightを呼ぶのはcurrentInnerHeightを更新した後のみにする
    if (currentInnerHeight === window.innerHeight) changeTableHeight()
  }, [document.documentElement.scrollHeight, currentInnerHeight])
  const classes = useStyles()
  const [loading] = useLoading()
  const [checkList, allClick] = useCheckListSummary(userList)
  const [clickSendMail, clickUpdate] = useButtonsSummary()
  const searchedFormat = useSearchedFormatSummary()
  const isChangeMail = checkList.some((data) => !(data.MAIL && data.MAIL === data.INP_MAIL))
  const SIIRE_CD = checkList.length === 0 ? -1 : checkList[checkList.length - 1].SIIRE_CD
  const getIsAllChecked = () => {
    if (userList.length === 0) return false
    return userList.length === checkList.length
  }
  const getChecked = (joken: responseSummaryUser) => {
    if (getIsAllChecked()) return true
    if (checkList.length <= 0) return false
    return checkList.some((r) => isUniqueUserSummary(r, joken))
  }
  const table = (props: any) => <Table {...props} style={{ borderCollapse: 'separate' }} size="small" aria-label="purchases" />
  const tableRow = (props: any) => {
    let isChecked = getChecked(props.item)
    let setClass = undefined
    if (props.item.isWithinAYear === 1) {
      if (isChecked) {
        setClass = classes.isSelectedWithinAYear
        isChecked = false
      } else {
        setClass = classes.isWithinAYear
      }
    }
    return <TableRow hover role="checkbox" {...props} className={setClass} selected={isChecked} />
  }
  const getComponent = useCallback(() => {
    return {
      Scroller: TableContainer,
      // Scroller: React.forwardRef((props, ref) => <TableContainer component={Paper} {...props} className={classes.container} ref={ref} />),
      Table: table,
      TableHead: TableHead,
      TableRow: tableRow,
      // TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
      TableBody: TableBody,
    }
  }, [checkList.length])

  if (isFetching) {
    searchedFormat()
    return <div>検索中...</div>
  }

  const convert = (data: responseSummaryUser) => {
    const checkData = checkList.filter((r) => isUniqueUserSummary(r, data))
    const isChecked = checkData.length > 0
    let retData: responseSummaryUser = data
    retData.INP_MAIL = data.MAIL
    if (isChecked) {
      retData = {
        JIGYO_CD: checkData[0].JIGYO_CD,
        JIGYO_NM: checkData[0].JIGYO_NM,
        SIIRE_CD: checkData[0].SIIRE_CD,
        SIIRE_NM: checkData[0].SIIRE_NM,
        INP_MAIL: checkData[0].INP_MAIL,
        MAIL: checkData[0].MAIL,
        NAME: checkData[0].NAME,
        isExist: checkData[0].isExist,
        isWithinAYear: checkData[0].isWithinAYear,
      }
    }
    return {
      isChecked: isChecked,
      data: retData,
    }
  }

  const getTableBar = () => {
    const isChecked = checkList.length > 0
    return (
      <O_TableBarUserDetail
        count={userList.length} //
        checkedList={[isChecked && !isChangeMail, false, isChecked && isChangeMail, false, false]}
        clickSendMail={clickSendMail}
        clickAdd={() => {}}
        clickUpdate={clickUpdate}
        clickDelete={() => {}}
        clickBack={() => {}}
        refetch={refetch}
      />
    )
  }
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item style={{ width: '100%' }}>
          {getTableBar()}
        </Grid>
        <Grid item style={{ width: '100%' }}>
          <TableVirtuoso
            style={{ height: tableHeight }} // 仮想リスト(表示範囲400以降はスクロール毎に描画する)
            data={userList}
            components={getComponent()}
            fixedHeaderContent={() => <O_TableHeaderUserSummary click={allClick} isChecked={getIsAllChecked()} />}
            itemContent={(_, user) => {
              const info = convert(user)
              const link = loading ? `userToroku` : `userToroku/${user.JIGYO_CD}_${user.SIIRE_CD}`
              return <O_TableRowUserSummary user={info.data} linkTo={link} isChecked={info.isChecked} focus={SIIRE_CD === info.data.SIIRE_CD} />
            }}
          />
        </Grid>
        <Grid item style={{ width: '100%' }}>
          {getTableBar()}
        </Grid>
      </Grid>
    </div>
  )
}

export default React.memo(O_TableUserSummary)
