import { axios } from 'SWRProvider'
import { checkListDetail, checkListRitsu, checkListSummaryUser, searchJoken, searchJokenRitsu, searchUser, searchLog, searchTenyuryoku, searchJokenKura } from 'types/SearchTypes'
import { requestDetailCSV, requestJokenSummary, requestUser, requestRitsu, requestRitsuCSV, requestUserUpdate, requestLog, requestTenyuryokuSummary, requestJokenDetail, requestTenyuryokuDetail, requestRitsuSearch, requestRitsuAllCSV } from 'types/RequestTypes'
import { tanto, tokuisaki, keyInfo, jotai } from 'types/ReferTypes'
import { commonMsg } from 'assets/MessageCode'
import { apiLog } from './logUtil'
import { zeroPadding, zeroPaddingRight } from './stringUtil'
import { F_NUM, HAN_KBN, KURA_KBN } from 'assets/FormatNumbers'

export class ApiInstance {
  private timerId?: any
  private r?: any

  /**
   * バックエンドから参照情報を取得する共通関数
   * @param T ジェネリクス: レスポンスの型
   * @param url URL
   * @param list リクエスト
   * @param func コールバック処理
   * @returns
   */
  post<T>(
    url: string, //
    req: Object,
    func: (list: T, err: boolean) => void,
  ) {
    this.abort()
    return new Promise<void>((resolve) => {
      this.r = resolve
      // this.timerId = setTimeout(async () => {
      this.timerId = null
      // await axios.post('/api' + url, { req: req }).then((res) => {
      axios.post('/api' + url, { req: req }).then((res) => {
        func(res.data.body, res.data.error)
        resolve()
      })
      // apiLog(url)
      resolve()
      // }, parseInt(`${process.env.REACT_APP_TIMEOUT}`)*1000)
    })
  }

  /**
   * 主に検索を行うAPI処理をする共通関数
   * @param T ジェネリクス: リクエストの型(オブジェクト型)
   * @param U ジェネリクス: レスポンスの型(オブジェクト型、配列になって帰ってくるので注意)
   * @param url URL
   * @param req リクエスト(型を指定する)
   * @param func コールバック関数(レスポンスの型は配列になるので注意)
   * @returns
   */
  search<T, U>(
    url: string, //
    req: T,
    func: (list: U[], err: boolean, msg?: string) => void,
  ) {
    this.abort()
    return new Promise<void>((resolve) => {
      this.r = resolve
      this.timerId = setTimeout(async () => {
        this.timerId = null
        await axios.post('/api' + url, { req: req }).then((res) => {
          func(res.data.body, res.data.error, res.data.viewMsg)
        })
        apiLog(url)
        resolve()
      }, 500)
    })
  }

  // 中断
  abort = () => {
    if (this.timerId) {
      clearTimeout(this.timerId)
      this.timerId = null
      this.r()
    }
  }
}
export const api = new ApiInstance()

// export const post = <T>(
//   url: string, //
//   req: Object,
//   func: (list: T, err: boolean) => void
// ) => {
//   let timerId: any
//   let r: any

//   const exec = () => new Promise<void>((resolve) => {
//     r = resolve
//     timerId = setTimeout(async () => {
//       timerId = null
//       await axios.post('/api' + url, { req: req }).then((res) => {
//         func(res.data.body, res.data.error)
//       })
//       // apiLog(url)
//       resolve()
//     }, parseInt(`${process.env.REACT_APP_TIMEOUT}`))
//   })
//   return {
//     exec,
//     cancel: () => {
//       if (timerId) {
//         clearTimeout(timerId)
//         timerId = null
//         r()
//       }
//     }
//   }
// }

/**
 * バックエンドから参照情報を取得する共通関数
 * @param T ジェネリクス: レスポンスの型
 * @param url URL
 * @param list リクエスト
 * @param func コールバック処理
 * @returns エラー有無
 */
export const postInfo = async <T>(
  url: string, //
  req: Object,
  func: (list: T, err: boolean) => void,
  errFunc: () => void = () => {},
  finalFunc: () => void = () => {},
) => {
  let err = false
  try {
    await axios.post('/api' + url, { req: req }).then((res) => {
      func(res.data.body, res.data.error)
      err = res.data.viewMsg.length === 0
    })
  } catch (error) {
    errFunc()
    console.error(error)
  } finally {
    finalFunc()
    apiLog(url)
  }
  return err
}

/**
 * バックエンドから参照情報を取得する共通関数
 * @param url URL
 * @param func コールバック処理
 * @returns エラー有無
 */
export const getInfo = async <T extends Object>(
  url: string, //
  func: (res: T, err: boolean) => void,
  errFunc: (err: boolean) => void = () => {},
  finalFunc: () => void = () => {},
) => {
  let err = false
  try {
    await axios.get('/api' + url).then((res) => {
      func(res.data.body, res.data.error)
      err = res.data.viewMsg.length === 0
    })
  } catch (error) {
    errFunc(true)
    console.error(error)
  } finally {
    finalFunc()
    apiLog(url)
  }
  return err
}

/**
 * 主に検索を行うAPI処理をする共通関数
 * @param T ジェネリクス: リクエストの型(オブジェクト型)
 * @param U ジェネリクス: レスポンスの型(オブジェクト型、配列になって帰ってくるので注意)
 * @param url URL
 * @param req リクエスト(型を指定する)
 * @param func コールバック関数(レスポンスの型は配列になるので注意)
 * @param errFunc エラー発生時に走る処理
 * @param finalFunc finallyで行う処理
 * @returns
 */
export const search = async <T extends Object, U extends Object>(
  url: string, //
  req: T,
  func: (res: { body: U[]; error: boolean; viewMsg: string; fname: string; alert?: boolean }) => void,
  errFunc: () => void = () => {},
  finalFunc: () => void = () => {},
) => {
  let err = false
  try {
    await axios.post('/api' + url, { req: req }).then((res) => {
      func(res.data)
      err = res.data.viewMsg.length === 0
    })
  } catch (error) {
    errFunc()
    console.error(error)
  } finally {
    finalFunc()
    apiLog(url)
  }
  return err
}

export const search2 = async <T extends Object, U extends Object>(
  url: string, //
  req: T,
  func: (res: { body: U; error: boolean; viewMsg: string; fname: string }) => void,
  errFunc: () => void = () => {},
  finalFunc: () => void = () => {},
) => {
  let err = false
  try {
    await axios.post('/api' + url, { req: req }).then((res) => {
      func(res.data)
      err = res.data.viewMsg.length === 0
    })
  } catch (error) {
    errFunc()
    console.error(error)
  } finally {
    finalFunc()
    apiLog(url)
  }
  return err
}
// export const search = async <T extends Object, U extends Object>(
//   url: string, //
//   req: T,
//   func: (res: U[], e?: boolean, msg?: string) => void,
//   errFunc: () => void = () => {},
//   finalFunc: () => void = () => {},
// ) => {
//   let err = false
//   try {
//     await axios.post('/api' + url, { req: req }).then((res) => {
//       func(res.data.body, res.data.viewMsg, res.data.error)
//       err = res.data.viewMsg.length === 0
//     })
//   } catch (error) {
//     errFunc()
//     console.error(error)
//   } finally {
//     finalFunc()
//     apiLog(url)
//   }
//   return err
// }

/**
 * バックエンドから参照情報を取得する共通関数
 * @param T ジェネリクス: レスポンスの型
 * @param url URL
 * @param list リクエスト
 * @param func コールバック関数(レスポンスの型は配列になるので注意)
 * @param errFunc エラー発生時に走る処理
 * @param finalFunc finallyで行う処理
 * @returns エラー有無
 */
export const putFile = async (
  url: string, //
  req: FormData,
  func: (res: { body: []; error: boolean; viewMsg: string; fname: string }) => void,
  errFunc: () => void = () => {},
  finalFunc: () => void = () => {},
) => {
  try {
    await axios.post('/api' + url, req).then((res) => {
      func(res.data)
    })
  } catch (error) {
    errFunc()
    console.error(error)
  } finally {
    finalFunc()
    apiLog(url)
  }
}

/**
 * バックエンドから参照情報を取得する共通関数
 * @param T ジェネリクス: レスポンスの型
 * @param url URL
 * @param list リクエスト
 * @param func コールバック関数(レスポンスの型は配列になるので注意)
 * @param errFunc エラー発生時に走る処理
 * @param finalFunc finallyで行う処理
 * @returns エラー有無
 */
export const putFile2 = async <T extends Object>(
  url: string, //
  req: FormData,
  func: (res: { body: T; error: boolean; viewMsg: string; fname: string }) => void,
  errFunc: () => void = () => {},
  finalFunc: () => void = () => {},
) => {
  try {
    await axios.post('/api' + url, req).then((res) => {
      func(res.data)
    })
  } catch (error) {
    errFunc()
    console.error(error)
  } finally {
    finalFunc()
    apiLog(url)
  }
}

/**
 * 主に検索を行うAPI処理をする共通関数
 * @param T ジェネリクス: リクエストの型(オブジェクト型)
 * @param U ジェネリクス: レスポンスの型(オブジェクト型)
 * @param url URL
 * @param req リクエスト(型を指定する)
 * @param func コールバック関数(レスポンスの型は配列になるので注意)
 * @param errFunc エラー発生時に走る処理
 * @param finalFunc finallyで行う処理
 * @returns
 */
export const searchObj = async <T extends Object, U extends Object>(
  url: string, //
  req: T,
  func: (res: U) => void,
  errFunc: () => void = () => {},
  finalFunc: () => void = () => {},
) => {
  let err = false
  try {
    await axios.post('/api' + url, { req: req }).then((res) => {
      func(res.data.body)
      err = res.data.viewMsg.length === 0
    })
  } catch (error) {
    errFunc()
    console.error(error)
  } finally {
    finalFunc()
    apiLog(url)
  }
  return err
}

/**
 * メッセージ取得処理をする共通API関数
 * @param req リクエスト(型を指定する)
 * @param errFunc エラー発生時に走る処理
 * @param finalFunc finallyで行う処理
 * @returns
 */
export const searchMessage = async (req: number, errFunc: () => void = () => {}, finalFunc: () => void = () => {}) => {
  let msg = ''
  try {
    await axios.post('/api' + '/getMessage', { req: req }).then((res) => {
      msg = res.data.body
    })
  } catch (error) {
    msg = commonMsg.ERROR_MSG
    errFunc()
    console.error(error)
  } finally {
    finalFunc()
    apiLog('getMessage')
  }
  return msg
}

/**
 * ファイル名取得処理をする共通API関数
 * @param req リクエスト(型を指定する)
 * @param errFunc エラー発生時に走る処理
 * @param finalFunc finallyで行う処理
 * @returns
 */
export const searchFileName = async (req: number, errFunc: () => void = () => {}, finalFunc: () => void = () => {}) => {
  let msg = ''
  try {
    await axios.post('/api' + '/getFileName', { req: req }).then((res) => {
      msg = res.data.body
    })
  } catch (error) {
    msg = commonMsg.ERROR_CSV_MSG
    errFunc()
    console.error(error)
  } finally {
    finalFunc()
    apiLog('getFileName')
  }
  return msg
}

/**
 * メッセージ取得処理をする共通API関数
 * @param req リクエスト(型を指定する)
 * @param errFunc エラー発生時に走る処理
 * @param finalFunc finallyで行う処理
 * @returns
 */
export const getOutputMessage = async (req: { fileCode: number; msgCode: number }, errFunc: () => void = () => {}, finalFunc: () => void = () => {}) => {
  let msg = ''
  try {
    await axios.post('/api' + '/getOutputMessage', { req: req }).then((res) => {
      msg = res.data.body
    })
  } catch (error) {
    msg = commonMsg.ERROR_CSV_MSG
    errFunc()
    console.error(error)
  } finally {
    finalFunc()
    apiLog('getOutputMessage')
  }
  return msg
}

/**
 * バックエンドから得意先情報を取得する関数
 * @param url URL
 * @param list リクエスト
 * @param func コールバック処理
 * @param errFunc エラー発生時に走る処理
 * @param finalFunc finallyで行う処理
 * @returns エラー有無
 */
export const getTokuisakiInfo = async (
  url: string, //
  req: { TEKIYO_DT: number; TOKUI_CD: number },
  func: (tokuisakiList: tokuisaki[], tantoList: tanto[], err: boolean) => void,
  errFunc: () => void,
  finalFunc: () => void,
) => {
  let err = false
  try {
    await axios.post('/api' + url, { req: req }).then((res) => {
      const body = res.data.body
      func(body.tokuisaki, body.tanto, res.data.error)
      err = res.data.viewMsg.length === 0
    })
  } catch (error) {
    errFunc()
    console.error(error)
  } finally {
    finalFunc()
    apiLog(url)
  }
  return err
}

/**
 * 検索項目からリクエストに必要な情報だけに絞り込む処理
 * @param searchJoken 販売/倉出条件
 * @returns
 */
export const getRequestJoken = (MAIL: string, keyInfo: keyInfo[], TANTO_CD: number, isSuperUser: boolean, isJocho: boolean, isKoiki: boolean, page: number, perPage: number, isHiddenPagination: boolean, INP_KBN: number, searchJoken: searchJoken | searchJokenKura): requestJokenSummary => {
  const base = {
    MAIL: MAIL,
    keyInfo: keyInfo,
    TANTO_CD: TANTO_CD,
    isSuperUser: isSuperUser,
    isJocho: isJocho,
    isKoiki: isKoiki,
    page: isHiddenPagination ? F_NUM : page,
    perPage: isHiddenPagination ? F_NUM : perPage,
    INP_KBN: INP_KBN,
    searchJoken: {
      JOKEN_KANRI_NO: zeroPadding(parseInt(searchJoken.JOKEN_KANRI_NO), 10),
      KEN_NM: searchJoken.KEN_NM,
      SIIRE_CD: searchJoken.SIIRE_CD,
      JIGYO_CD: searchJoken.JIGYO_CD,
      BUSHO_CD: searchJoken.BUSHO_CD,
      TOKUI_CD: searchJoken.TOKUI_CD,
      // TANTO_CD: F_NUM,
      TEKIYO_START_DT: searchJoken.TEKIYO_START_DT,
      TEKIYO_END_DT: searchJoken.TEKIYO_END_DT,
      ADD_TANTO_CD: searchJoken.ADD_TANTO_CD,
      // CYOKUSO_JOGAI: searchJoken.cyokusoCheckbox,
      JOTAI: {
        mishonin: searchJoken.mishoninCheckbox,
        jochoMishonin: searchJoken.jochoMishoninCheckbox,
        shonin: searchJoken.shoninCheckbox,
        jochoShonin: searchJoken.jochoShoninCheckbox,
        hinin: searchJoken.hininCheckbox,
        jochoHininMikakunin: searchJoken.jochoHininMikakuninCheckbox,
        hininMikakunin: searchJoken.hininMikakuninCheckbox,
        jochoHininKakuninzumi: searchJoken.jochoHininKakuninzumiCheckbox,
        hininkakuninzumi: searchJoken.hininKakuninzumiCheckbox,
      },
    },
  }
  if (INP_KBN === HAN_KBN) {
    // FIXME: searchJokenの型を販売条件、倉出条件で同じにする
    const joken = searchJoken as searchJoken
    return {
      ...base,
      searchJoken: {
        ...base.searchJoken,
        TANTO_CD: joken.TANTO_CD,
        // CYOKUSO_JOGAI: false
      },
    }
  }
  if (INP_KBN === KURA_KBN) {
    // FIXME: searchJokenの型を販売条件、倉出条件で同じにする
    // const joken = searchJoken as searchJokenKura
    return {
      ...base,
      searchJoken: {
        ...base.searchJoken,
        TANTO_CD: F_NUM,
        // CYOKUSO_JOGAI: joken.cyokusoCheckbox
      },
    }
  }

  // ここには来ない想定
  return {
    ...base,
    searchJoken: {
      ...base.searchJoken,
      TANTO_CD: F_NUM,
      // CYOKUSO_JOGAI: false
    },
  }
}
// /**
//  * 検索項目からリクエストに必要な情報だけに絞り込む処理
//  * @param searchJoken 販売/倉出条件
//  * @returns
//  */
// export const getRequestJokenKuraDetail = (page: number, perPage: number, isHiddenPagination: boolean, INP_KBN: number, MAIL: string, JOKEN_KANRI_NO: string, searchJoken: searchJokenKura): requestJokenKuraDetail => {
//   return {
//     page: isHiddenPagination ? F_NUM : page,
//     perPage: isHiddenPagination ? F_NUM : perPage,
//     INP_KBN: INP_KBN,
//     MAIL: MAIL,
//     TOKUI_CD: searchJoken.TOKUI_CD,
//     JOKEN_KANRI_NO: JOKEN_KANRI_NO,
//     JOTAI: {
//       mishonin: searchJoken.mishoninCheckbox,
//       jochoMishonin: searchJoken.jochoMishoninCheckbox,
//       shonin: searchJoken.shoninCheckbox,
//       jochoShonin: searchJoken.jochoShoninCheckbox,
//       hinin: searchJoken.hininCheckbox,
//       jochoHininMikakunin: searchJoken.jochoHininMikakuninCheckbox,
//       hininMikakunin: searchJoken.hininMikakuninCheckbox,
//       jochoHininKakuninzumi: searchJoken.jochoHininKakuninzumiCheckbox,
//       hininkakuninzumi: searchJoken.hininKakuninzumiCheckbox,
//     },
//   }
// }
/**
 * 検索項目からリクエストに必要な情報だけに絞り込む処理
 * @param searchJoken 販売/倉出条件
 * @returns
 */
export const getRequestJokenDetail = (page: number, perPage: number, isHiddenPagination: boolean, INP_KBN: number, MAIL: string, JOKEN_KANRI_NO: string, searchJoken: searchJoken | searchJokenKura, TANTO_CD: number): requestJokenDetail => {
  const base = {
    page: isHiddenPagination ? F_NUM : page,
    perPage: isHiddenPagination ? F_NUM : perPage,
    INP_KBN: INP_KBN,
    MAIL: MAIL,
    TOKUI_CD: searchJoken.TOKUI_CD,
    JOKEN_KANRI_NO: JOKEN_KANRI_NO,
    // CYOKUSO_JOGAI: searchJoken.cyokusoCheckbox
    JOTAI: {
      mishonin: searchJoken.mishoninCheckbox,
      jochoMishonin: searchJoken.jochoMishoninCheckbox,
      shonin: searchJoken.shoninCheckbox,
      jochoShonin: searchJoken.jochoShoninCheckbox,
      hinin: searchJoken.hininCheckbox,
      jochoHininMikakunin: searchJoken.jochoHininMikakuninCheckbox,
      hininMikakunin: searchJoken.hininMikakuninCheckbox,
      jochoHininKakuninzumi: searchJoken.jochoHininKakuninzumiCheckbox,
      hininkakuninzumi: searchJoken.hininKakuninzumiCheckbox,
    },
    TANTO_CD: TANTO_CD, // 検索では不要だが、全件確認で使用
  }
  if (INP_KBN === HAN_KBN) {
    // FIXME: searchJokenの型を販売条件、倉出条件で同じにする
    return {
      ...base,
      // CYOKUSO_JOGAI: false
    }
  }
  if (INP_KBN === KURA_KBN) {
    // FIXME: searchJokenの型を販売条件、倉出条件で同じにする
    // const joken = searchJoken as searchJokenKura
    return {
      ...base,
      // CYOKUSO_JOGAI: joken.cyokusoCheckbox,
    }
  }

  // ここには来ない想定
  return {
    ...base,
    // CYOKUSO_JOGAI: false
  }
}

// /**
//  * 検索項目からリクエストに必要な情報だけに絞り込む処理
//  * @param searchJoken 販売/倉出条件
//  * @returns
//  */
// export const getRequestJokenKura = (searchJoken: searchJokenKura): requestJokenKura => {
//   return {
//     JOKEN_KANRI_NO: zeroPadding(parseInt(searchJoken.JOKEN_KANRI_NO), 10),
//     KEN_NM: searchJoken.KEN_NM,
//     SIIRE_CD: searchJoken.SIIRE_CD,
//     JIGYO_CD: searchJoken.JIGYO_CD,
//     BUSHO_CD: searchJoken.BUSHO_CD,
//     // TOKUI_CD: searchJoken.TOKUI_CD,
//     // TANTO_CD: searchJoken.TANTO_CD,
//     TEKIYO_START_DT: searchJoken.TEKIYO_START_DT,
//     TEKIYO_END_DT: searchJoken.TEKIYO_END_DT,
//     ADD_TANTO_CD: searchJoken.ADD_TANTO_CD,
//     CYOKUSO_JOGAI: searchJoken.cyokusoCheckbox,
//     JOTAI: {
//       mishonin: searchJoken.mishoninCheckbox,
//       jochoMishonin: searchJoken.jochoMishoninCheckbox,
//       shonin: searchJoken.shoninCheckbox,
//       jochoShonin: searchJoken.jochoShoninCheckbox,
//       hinin: searchJoken.hininCheckbox,
//       jochoHininMikakunin: searchJoken.jochoHininMikakuninCheckbox,
//       hininMikakunin: searchJoken.hininMikakuninCheckbox,
//       jochoHininKakuninzumi: searchJoken.jochoHininKakuninzumiCheckbox,
//       hininkakuninzumi: searchJoken.hininKakuninzumiCheckbox,
//     },
//   }
// }
// export const getRequestJokenKuraDetail = (selectList: string[], jotai: jotai) => {
//   return {
//     KANRI_NOS: selectList,
//     jotai: jotai,
//   }
// }

/**
 * 検索項目からリクエストに必要な情報だけに絞り込む処理
 * @param searchJoken 率条件
 * @returns
 */
export const getRequestJokenRitsu = (MAIL: string, keyInfo: keyInfo[], TANTO_CD: number, isSuperUser: boolean, isJocho: boolean, isKoiki: boolean, page: number, perPage: number, isHiddenPagination: boolean, searchJoken: searchJokenRitsu): requestRitsuSearch => {
  return {
    MAIL: MAIL,
    keyInfo: keyInfo,
    TANTO_CD: TANTO_CD,
    isSuperUser: isSuperUser,
    isJocho: isJocho,
    isKoiki: isKoiki,
    page: isHiddenPagination ? F_NUM : page,
    perPage: isHiddenPagination ? F_NUM : perPage,
    searchJoken: {
      JIGYO_CD: searchJoken.JIGYO_CD, // 事業所コード
      TOKUI_CD: searchJoken.TOKUI_CD, // 得意先コード
      SIIRE_CD: searchJoken.SIIRE_CD, // 仕入先コード
      SYORI_YM_START: searchJoken.SYORI_YM_START,
      SYORI_YM_END: searchJoken.SYORI_YM_END,
      ADD_TANTO_CD: searchJoken.ADD_TANTO_CD,
      JOTAI: {
        mishonin: searchJoken.mishoninCheckbox,
        jochoMishonin: searchJoken.jochoMishoninCheckbox,
        shonin: searchJoken.shoninCheckbox,
        jochoShonin: searchJoken.jochoShoninCheckbox,
        hinin: searchJoken.hininCheckbox,
        jochoHininMikakunin: searchJoken.jochoHininMikakuninCheckbox,
        hininMikakunin: searchJoken.hininMikakuninCheckbox,
        jochoHininKakuninzumi: searchJoken.jochoHininKakuninzumiCheckbox,
        hininkakuninzumi: searchJoken.hininKakuninzumiCheckbox,
      },
    },
  }
}

/**
 * 検索項目からリクエストに必要な情報だけに絞り込む処理
 * @param searchUser ユーザー登録情報
 * @returns
 */
export const getRequestUser = (searchUser: searchUser, initJigyoCd: number): requestUser => {
  return {
    JIGYO_CD: searchUser.JIGYO_CD === F_NUM ? initJigyoCd : searchUser.JIGYO_CD,
    SIIRE_CD: searchUser.SIIRE_CD,
    USER_NM: searchUser.USER_NM,
    USER_MAIL: searchUser.USER_MAIL,
  }
}
/**
 * 販売条件詳細のCSV出力のリクエストに必要な情報だけに絞り込む処理
 * @param searchJoken 販売条件
 * @returns
 */
export const getRequestDetailCSV = (formatNo: number, JOKEN_KANRI_NO: string, TOKUI_CD: number, INP_KBN: number, list: checkListDetail[]): requestDetailCSV => {
  return {
    isSummary: false,
    formatNo: formatNo,
    JOKEN_KANRI_NO: JOKEN_KANRI_NO,
    TOKUI_CD: TOKUI_CD,
    INP_KBN: INP_KBN,
    checkListDetail: list,
  }
}
/**
 * 率条件のチェックデータのリクエストを取得する処理
 * @param searchJoken 率条件
 * @returns
 */
export const getRequestRitsu = (list: checkListRitsu[]): requestRitsu => {
  return {
    checkListRitsu: list,
  }
}
/**
 * 率条件のCSV出力のCSVを取得する処理
 * @param searchJoken 率条件
 * @returns
 */
export const getRequestRitsuCSV = (formatNo: number, list: checkListRitsu[]): requestRitsuCSV => {
  return {
    formatNo: formatNo,
    checkListRitsu: list,
  }
}
/**
 * 率条件のCSV出力のCSVを取得する処理
 * @param searchJoken 率条件
 * @returns
 */
export const getRequestRitsuCSVAll = (formatNo: number, requestRitsuSearch: requestRitsuSearch): requestRitsuAllCSV => {
  return {
    formatNo: formatNo,
    requestRitsuSearch: requestRitsuSearch,
  }
}
/**
 * ユーザー登録に必要なリクエストを取得する処理
 * @param searchJoken 率条件
 * @returns
 */
export const getRequestUserUpdate = (JIGYO_CD: number, TOKUI_CD: number, TANTO_CD: number, checkListSummaryUser: checkListSummaryUser[], isNeededCheck: boolean): requestUserUpdate => {
  return {
    JIGYO_CD: JIGYO_CD,
    TOKUI_CD: TOKUI_CD,
    TANTO_CD: TANTO_CD,
    checkListSummaryUser: checkListSummaryUser,
    isNeededCheck: isNeededCheck,
  }
}

/**
 * 検索項目からリクエストに必要な情報だけに絞り込む処理
 * @param searchLog ログ
 * @returns
 */
export const getRequestLog = (searchLog: searchLog): requestLog => {
  return {
    JIGYO_CD: searchLog.JIGYO_CD,
    SIIRE_CD: searchLog.SIIRE_CD,
    TOKUI_CD: searchLog.TOKUI_CD,
    TANTO_CD: searchLog.TANTO_CD,
    DT_FROM: searchLog.DT_FROM > 0 ? Number(zeroPaddingRight(searchLog.DT_FROM, 14)) : searchLog.DT_FROM, // 入力は分までなので秒までにして渡す
    DT_TO: searchLog.DT_TO > 0 ? Number(zeroPaddingRight(searchLog.DT_TO, 14)) : searchLog.DT_TO, // 入力は分までなので秒までにして渡す
    MAIL: searchLog.MAIL,
    torokuCheckbox: searchLog.torokuCheckbox,
    sakujoCheckbox: searchLog.sakujoCheckbox,
  }
}

/**
 * 検索項目からリクエストに必要な情報だけに絞り込む処理
 * @param searchTenyuryoku 手入力照会
 * @returns
 */
export const getRequestTenyuryoku = (MAIL: string, keyInfo: keyInfo[], TANTO_CD: number, isSuperUser: boolean, isJocho: boolean, isKoiki: boolean, page: number, perPage: number, isHiddenPagination: boolean, searchTenyuryoku: searchTenyuryoku): requestTenyuryokuSummary => {
  return {
    MAIL: MAIL,
    keyInfo: keyInfo,
    TANTO_CD: TANTO_CD,
    isSuperUser: isSuperUser,
    isJocho: isJocho,
    isKoiki: isKoiki,
    page: isHiddenPagination ? F_NUM : page,
    perPage: isHiddenPagination ? F_NUM : perPage,
    searchTenyuryoku: {
      SYORI_YMD_FROM: searchTenyuryoku.SYORI_YMD_FROM,
      SYORI_YMD_TO: searchTenyuryoku.SYORI_YMD_TO,
      ADD_YMD_FROM: searchTenyuryoku.ADD_YMD_FROM,
      ADD_YMD_TO: searchTenyuryoku.ADD_YMD_TO,
      NYUKIN_YMD_FROM: searchTenyuryoku.NYUKIN_YMD_FROM,
      NYUKIN_YMD_TO: searchTenyuryoku.NYUKIN_YMD_TO,
      SIIRE_CD: searchTenyuryoku.SIIRE_CD,
      JIGYO_CD: searchTenyuryoku.JIGYO_CD,
      TANTO_CD: searchTenyuryoku.TANTO_CD,
      NAIYO_KBN1: searchTenyuryoku.NAIYO_KBN1,
      NAIYO_KBN2: searchTenyuryoku.NAIYO_KBN2,
      SEISAN_KOUMOKU: searchTenyuryoku.SEISAN_KOUMOKU,
      DENPYO_NO: searchTenyuryoku.DENPYO_NO,
      SEISAN_NAIYO: searchTenyuryoku.SEISAN_NAIYO,
      JOTAI: {
        mishonin: searchTenyuryoku.mishoninCheckbox,
        jochoMishonin: searchTenyuryoku.jochoMishoninCheckbox,
        shonin: searchTenyuryoku.shoninCheckbox,
        jochoShonin: searchTenyuryoku.jochoShoninCheckbox,
        hinin: searchTenyuryoku.hininCheckbox,
        jochoHininMikakunin: searchTenyuryoku.jochoHininMikakuninCheckbox,
        hininMikakunin: searchTenyuryoku.hininMikakuninCheckbox,
        jochoHininKakuninzumi: searchTenyuryoku.jochoHininKakuninzumiCheckbox,
        hininkakuninzumi: searchTenyuryoku.hininKakuninzumiCheckbox,
      },
    },
  }
}
/**
 * 検索項目からリクエストに必要な情報だけに絞り込む処理
 * @param searchTenyuryoku 手入力照会
 * @returns
 */
export const getRequestTenyuryokuDetail = (userKind: number, MAIL: string, TANTO_CD: number, DENPYO_NO: string, JOTAI: jotai, TOKUI_CDS: number[], page: number, perPage: number, isHiddenPagination: boolean): requestTenyuryokuDetail => {
  return {
    MAIL: MAIL,
    TANTO_CD: TANTO_CD,
    userKind: userKind,
    page: isHiddenPagination ? F_NUM : page,
    perPage: isHiddenPagination ? F_NUM : perPage,
    DENPYO_NO: DENPYO_NO,
    JOTAI: JOTAI,
    TOKUI_CDS: TOKUI_CDS,
  }
}
