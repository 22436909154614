/* eslint-disable react/jsx-pascal-case */
import React, { useEffect } from 'react'
import A_Button from '../A_Button'
import { moleculeLog } from 'utils/logUtil'

type Props = {
  label: string
}

const M_ButtonDisable = ({ label }: Props) => {
  useEffect(() => moleculeLog('M_ButtonDisable'))
  return <A_Button variant="contained" color="" label={label} event={() => {}} disabled={true} />
}
export default React.memo(M_ButtonDisable)
