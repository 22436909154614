import { menu, shohin, tokuisaki } from 'types/ReferTypes'
import { notExist } from './booleanUtil'

/**
 * 商品名と規格をNULLチェックして連結した文字列を返す関数
 * @param shohin 商品
 * @returns 商品規格
 */
export const getShohinKikaku = (shohin: shohin) => {
  const SHOHIN_NM = shohin.SHOHIN_NM ? shohin.SHOHIN_NM : ''
  const KIKAKU = shohin.KIKAKU ? shohin.KIKAKU : ''
  return SHOHIN_NM + ' ' + KIKAKU
}

/**
 * 数値を与えられた桁数に対してゼロ埋めした文字列を返す関数
 * @param num 変換対象数値
 * @param len 文字列桁数
 * @returns ゼロ埋め後の文字列
 */
export const zeroPadding = (num: number, len: number) => {
  return notExist(num) ? '' : (Array(len).join('0') + num).slice(-len)
}

/**
 * 数値を与えられた桁数に対してゼロ埋めした文字列を返す関数
 * @param num 変換対象数値
 * @param len 文字列桁数
 * @returns ゼロ埋め後の文字列
 */
export const zeroPaddingRight = (num: number, len: number) => {
  return notExist(num) ? '' : (num + Array(len).join('0')).slice(0, len)
}

/**
 * 得意先をハイフン連結した文字列を返す関数
 * @param tokuisaki 得意先リスト
 * @returns 複数得意先
 */
export const getHukuTokuisaki = (tokuisakiList: tokuisaki[]) => {
  let HUKU_TOKUI = ''
  if (tokuisakiList.length === 1) return HUKU_TOKUI
  for (let i = 0; i < tokuisakiList.length; i++) {
    HUKU_TOKUI += tokuisakiList[i].TOKUI_CD.toString()
    if (i < tokuisakiList.length - 1) {
      HUKU_TOKUI += '-'
    }
  }
  return HUKU_TOKUI
}

/**
 * 文字列変数にundefinedかNULLが入っていれば空文字にして返す関数
 * @param str 入力値
 * @returns NULLは空文字にして返される入力値
 */
export const fixNullString = (str: string | undefined | null) => {
  return str ?? ''
}

/**
 * 文字列変数が空文字かundefinedかNULLかどうかを判定する関数
 * @param value 入力値
 * @returns bookean
 */
export const isNullValue = (value: string) => {
  return value ? true : false
}

/**
 * 数値フォームの入力値に「+」「-」「.」があれば除去する関数
 * @param value 入力値
 * @returns string
 */
export const getNumStr = (value: string) => {
  return value.replace('+', '').replace('-', '').replace('.', '')
}

/**
 * 数値変数が0以下なら空文字を返す関数
 * @param value 入力値
 */
export const numToStr = (value: number) => {
  return value > 0 ? value.toString() : ''
}

/**
 * マスタ取得中なら「Loading...」を返す関数
 * @param value 入力値
 */
export const getTitle = (value: string) => {
  if (value == null) return '…'
  if (process.env.REACT_APP_ENVIRONMENT === 'stg') return value + '[検証環境]'
  return value
}

/**
 * マスタ取得中なら「Loading...」を返す関数
 * @param value 入力値
 */
export const getMenuLabel = (value: menu) => {
  return value == null ? '…' : value.label
}

/**
 * 3桁おきに「,」を付けた文字列を返す関数
 * @param {number} value 数値
 * @return {string} 数値価格
 */
export const fixPrice = (value: number | string): string => {
  if (value == null) return ''
  if (typeof value === 'string') return parseInt(value).toLocaleString()
  return value.toLocaleString()
}
