import { Reducer } from 'redux'
import produce from 'immer'
import { modalTokui } from 'types/ModalTypes'
import { F_STR } from 'assets/FormatNumbers'
import { tokuisaki } from 'types/ReferTypes'

export type ModalTokuiState = {
  modalTokui: modalTokui // 得意先コード貼付画面情報
  loading: boolean // ローディング状態
  message: string // 得意先コード貼付画面のメッセージ
}

const initialState: ModalTokuiState = {
  modalTokui: {
    openTokui: false, // 得意先コード貼付画面を開いているかどうか(得意先)
    tokuiInfo: [], // マスタ取得情報(得意先)
  },
  loading: false, // ローディング状態
  message: F_STR, // 得意先コード貼付画面のメッセージ
}

export type ModalTokuiType =
  | 'ModalTokuiOpen' // モーダルを開く処理
  | 'ModalTokuiClose' // モーダルを閉じる処理
  | 'ModalTokuiChangeLoading' // ローディング状態を変更する処理
  | 'ModalTokuiFormat' // 得意先コード貼付画面情報を初期化する処理
  | 'ModalTokuiChangeMessage' // 得意先コード貼付画面のメッセージを変更する処理
  | 'dummy'

export type ModalTokuiAction = {
  type: ModalTokuiType
  tokuiInfo?: tokuisaki[]
  loading?: boolean // ローディング状態
  message?: string // 得意先コード貼付画面のメッセージ
}

export const ModalTokuiReducer: Reducer<ModalTokuiState, ModalTokuiAction> = produce((draft: ModalTokuiState, action: ModalTokuiAction) => {
  switch (action.type) {
    case 'ModalTokuiOpen': {
      draft.modalTokui.openTokui = true
      draft.modalTokui.tokuiInfo = action.tokuiInfo!
      return
    }
    case 'ModalTokuiClose': {
      draft.modalTokui.openTokui = false
      draft.modalTokui.tokuiInfo = []
      draft.message = F_STR
      return
    }
    // 条件初期化 //===========================//
    case 'ModalTokuiFormat': {
      for (const key in draft) {
        const tempKey: keyof ModalTokuiState = key as keyof ModalTokuiState
        ;(draft as any)[tempKey] = initialState[tempKey]
      }
      return
    }
    // メッセージ変更 //===========================//
    case 'ModalTokuiChangeMessage': {
      draft.message = action.message!
      return
    }
    // ローディング処理 //===========================//
    case 'ModalTokuiChangeLoading': {
      draft.loading = action.loading!
      return
    }
  }
}, initialState)
