import { Reducer } from 'redux'
import produce from 'immer'
import { responseDetailJoken, responseSummaryJoken } from 'types/ResponseTypes'
import { checkListDetail, checkListSummary } from 'types/SearchTypes'
import { isUniqueJoken, isUniqueJokenDetail } from 'utils/booleanUtil'

export type TableJokenState = {
  jokenList: responseSummaryJoken[]
  checkListJoken: checkListSummary[] // 選択している条件管理番号を保持
  checkListDetail: checkListDetail[] // 選択している明細を保持
  clickNo: string // 選択した条件管理NOを保持
}

const initialState: TableJokenState = {
  jokenList: [],
  checkListJoken: [], // 選択している条件管理番号を保持
  checkListDetail: [], // 選択している明細を保持
  clickNo: '', // 選択した条件管理NOを保持
}

export type TableJokenType =
  | 'TableJokenAllCheckJoken' // 条件管理番号を全チェックする処理
  | 'TableJokenCheckJoken' // 条件管理番号をチェックする処理
  | 'TableJokenChangeCheckJoken' // 条件管理番号チェックリストを変更する処理
  | 'TableJokenAllCheckShohin' // 明細を全チェックする処理
  | 'TableJokenCheckShohin' // 明細をチェックする処理
  | 'TableJokenFormatCheckSummary' // サマリチェックリストを初期化する処理
  | 'TableJokenFormatCheckDetail' // 明細チェックリストを初期化する処理
  | 'TableJokenFormat'
  | 'TableJokenClickJoken' // 条件を選択する処理
  | 'TableJokenChangeHininRiyu' // 否認理由を変更する処理
  | 'TableJokenChangeCheckJokenDetail' // 明細チェックリストを変更する処理
  | 'TableJokenChangeJochoHininRiyu' // 上長否認理由を変更する処理
  | 'dummy'

export type TableJokenAction = {
  type: TableJokenType
  jokenList?: responseSummaryJoken[]
  detailList?: responseDetailJoken[]
  isChecked?: boolean
  kanriNo?: string
  checkShohin?: checkListDetail
  checkData?: checkListSummary
  checkDataList?: checkListSummary[]
  checkListDetail?: checkListDetail[]
}

export const TableJokenReducer: Reducer<TableJokenState, TableJokenAction> = produce((draft: TableJokenState, action: TableJokenAction) => {
  switch (action.type) {
    // 上長否認理由を変更する処理 //===========================//
    case 'TableJokenChangeJochoHininRiyu': {
      const checkShohin = action.checkShohin!
      draft.checkListDetail = draft.checkListDetail.filter((r) => !isUniqueJokenDetail(r, checkShohin))
      draft.checkListDetail.push(checkShohin)
      return
    }
    // 条件管理番号を全チェックする処理 //=========================//
    case 'TableJokenAllCheckJoken': {
      const isChecked = action.isChecked!
      if (isChecked) {
        // チェック除去
        draft.checkListJoken = []
      } else {
        // チェック付与
        const selectList: checkListSummary[] = []
        action.jokenList!.forEach((r) =>
          selectList.push({
            JOKEN_KANRI_NO: r.JOKEN_KANRI_NO,
            TOKUI_CD: r.TOKUI_CD,
            JOTAI_KBN_0: r.JOTAI_KBN_0,
            JOTAI_KBN_1: r.JOTAI_KBN_1,
            JOTAI_KBN_2_1: r.JOTAI_KBN_2_1,
          }),
        )
        draft.checkListJoken = selectList
      }
      return
    }
    // 条件管理番号をチェックする処理 //=========================//
    case 'TableJokenCheckJoken': {
      const checkData = action.checkData!
      const isChecked = action.isChecked!
      if (isChecked) {
        // チェック除去
        draft.checkListJoken = draft.checkListJoken.filter((r) => !isUniqueJoken(r, checkData))
      } else {
        // チェック付与
        draft.checkListJoken.push(checkData)
      }
      return
    }
    // 条件管理番号チェックリストを変更する処理 //=========================//
    case 'TableJokenChangeCheckJoken': {
      draft.checkListJoken = action.checkDataList!
      return
    }
    // 明細を全チェックする処理 //================================//
    case 'TableJokenAllCheckShohin': {
      const isChecked = action.isChecked!
      const stateList = draft.checkListDetail
      if (isChecked) {
        // チェック除去
        const selectList: checkListDetail[] = []
        for (let i = 0; i < stateList.length; i++) {
          selectList.push(stateList[i].isChecked ? { ...stateList[i], isChecked: false } : stateList[i])
        }
        draft.checkListDetail = selectList
      } else {
        // チェック付与
        const detailList = action.detailList!
        const originList: checkListDetail[] = []
        for (let i = 0; i < detailList.length; i++) {
          originList.push({
            isChecked: true,
            JOTAI_KBN: detailList[i].JOTAI_KBN,
            KAKUNIN_KBN: detailList[i].KAKUNIN_KBN,
            ADD_YMD: detailList[i].ADD_YMD,
            ADD_HNS: detailList[i].ADD_HNS,
            JP_CD: detailList[i].JP_CD,
            NET: detailList[i].NET,
            HININ_RIYU: detailList[i].HININ_RIYU,
            JOCHO_HININ_RIYU: detailList[i].JOCHO_HININ_RIYU,
          })
        }
        for (let i = 0; i < stateList.length; i++) {
          for (let j = 0; j < originList.length; j++) {
            if (isUniqueJokenDetail(stateList[i], originList[j])) {
              originList.splice(j, 1)
              originList.push({ ...stateList[i], isChecked: true })
              break
            }
          }
        }
        draft.checkListDetail = originList
      }
      return
    }
    // 明細をチェックする処理 //================================//
    case 'TableJokenCheckShohin': {
      const checkShohin = action.checkShohin!
      const isChecked = action.isChecked!
      const selectList = draft.checkListDetail.filter((r) => isUniqueJokenDetail(r, checkShohin))
      if (selectList.length <= 0) {
        draft.checkListDetail.push({ ...checkShohin, isChecked: !isChecked })
      } else {
        draft.checkListDetail = draft.checkListDetail.filter((r) => !isUniqueJokenDetail(r, checkShohin))
        draft.checkListDetail.push({ ...selectList[0], isChecked: !isChecked })
      }
      return
    }
    // サマリチェックリストを初期化する処理 //================================//
    case 'TableJokenFormatCheckSummary': {
      draft.checkListJoken = initialState.checkListJoken
      return
    }
    // 明細チェックリストを初期化する処理 //================================//
    case 'TableJokenFormatCheckDetail': {
      draft.checkListDetail = initialState.checkListDetail
      return
    }
    // 否認理由を変更する処理 //===========================//
    case 'TableJokenChangeHininRiyu': {
      const checkShohin = action.checkShohin!
      draft.checkListDetail = draft.checkListDetail.filter((r) => !isUniqueJokenDetail(r, checkShohin))
      draft.checkListDetail.push(checkShohin)
      return
    }
    // 明細チェックリストを変更する処理 //=========================//
    case 'TableJokenChangeCheckJokenDetail': {
      draft.checkListDetail = action.checkListDetail!
      return
    }
    case 'TableJokenFormat': {
      for (const key in draft) {
        const tempKey: keyof TableJokenState = key as keyof TableJokenState
        ;(draft as any)[tempKey] = initialState[tempKey]
      }
      return
    }
    // 条件を選択する処理 //===========================//
    case 'TableJokenClickJoken': {
      draft.clickNo = action.kanriNo!
      draft.checkListDetail = initialState.checkListDetail
      return
    }
  }
}, initialState)
