/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useEffect, useState } from 'react'
import { TableVirtuoso } from 'react-virtuoso'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import O_TableHeaderLog from 'components/3_Organisms/O_TableHeaderLog'
import O_TableRowLog from 'components/3_Organisms/O_TableRowLog'
import O_TableBarLog from 'components/3_Organisms/O_TableBarLog'
import { useChangeTableHeight, useChangeInnerHeight, useFormatDetail } from 'hooks/useCommon'
import { organismGroupLog } from 'utils/logUtil'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
    },
    container: {
      maxHeight: 600,
    },
    isWithinAYear: {
      height: 30,
      backgroundColor: '#00FF00',
    },
    isSelectedWithinAYear: {
      height: 30,
      backgroundColor: '#00BE1E',
    },
  }),
)

type Props = {
  logList: any[]
}

const O_TableLog = ({ logList }: Props) => {
  const location = useLocation()
  const [formatDetail] = useFormatDetail(location.pathname)
  useEffect(() => {
    organismGroupLog('O_TableLog')
    formatDetail()
  }, [])
  const [tableHeight, setTableHeight] = useState(window.innerHeight)
  const [currentInnerHeight, setCurrentInnerHeight] = useState(window.innerHeight)
  const [previousInnerHeight, setPreviousInnerHeight] = useState(window.innerHeight)
  const [changeTableHeight] = useChangeTableHeight(tableHeight, currentInnerHeight, previousInnerHeight, setTableHeight)
  const [changeInnerHeight] = useChangeInnerHeight(currentInnerHeight, setCurrentInnerHeight, setPreviousInnerHeight)
  const allCount = useSelector((state) => state.TableLogReducer.allCount)
  const searchLoading = useSelector((state) => state.TableLogReducer.searchLoading)
  useEffect(() => {
    // ウィンドウサイズ変更時にchangeInnerHeightを呼び出すようにリスナーに登録
    window.removeEventListener('resize', changeInnerHeight)
    window.addEventListener('resize', changeInnerHeight)
    return () => {
      window.removeEventListener('resize', changeInnerHeight)
    }
  }, [window.innerHeight, currentInnerHeight])
  useEffect(() => {
    // useEffectが呼ばれるのはウィンドウサイズを変えたとき、スクロール含めた高さが変わったとき（初期表示時にも調整するため）
    // ウィンドウサイズを変えると上記2パターンに引っ掛かり2回動くので
    // changeTableHeightを呼ぶのはcurrentInnerHeightを更新した後のみにする
    if (currentInnerHeight === window.innerHeight) changeTableHeight()
  }, [document.documentElement.scrollHeight, currentInnerHeight, searchLoading])

  const classes = useStyles()

  const table = (props: any) => <Table {...props} style={{ borderCollapse: 'separate' }} size="small" aria-label="purchases" />
  const tableRow = (props: any) => <TableRow hover role="checkbox" {...props} />
  const getComponent = useCallback(() => {
    return {
      Scroller: TableContainer,
      // Scroller: React.forwardRef((props, ref) => <TableContainer component={Paper} {...props} className={classes.container} ref={ref} />),
      Table: table,
      TableHead: TableHead,
      TableRow: tableRow,
      // TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
      TableBody: TableBody,
    }
  }, [])

  if (searchLoading) {
    return <div>検索中...</div>
  }

  const getTableBar = () => {
    return <O_TableBarLog count={allCount} />
  }
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item style={{ width: '100%' }}>
          {getTableBar()}
        </Grid>
        <Grid item style={{ width: '100%' }}>
          <TableVirtuoso
            style={{ height: tableHeight }} // 仮想リスト(表示範囲400以降はスクロール毎に描画する)
            data={logList}
            components={getComponent()}
            fixedHeaderContent={() => <O_TableHeaderLog />}
            itemContent={(_, log) => {
              return <O_TableRowLog log={log} />
            }}
          />
        </Grid>
        <Grid item style={{ width: '100%' }}>
          {getTableBar()}
        </Grid>
      </Grid>
    </div>
  )
}

export default React.memo(O_TableLog)
