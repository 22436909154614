import React, { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import { Card, CardHeader, CardContent, CardActions } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import M_ButtonSearch from 'components/1_Atoms/2_Molecules/M_ButtonSearch'
import * as LoginAction from 'actions/LoginAction' // 共通部分が多いActionはActionCreaterにまとめる
import * as PageAction from 'actions/PageAction'
import A_Message from 'components/1_Atoms/A_Message'
import { commonMsg } from 'assets/MessageCode'
import { organismGroupLog } from 'utils/logUtil'
import { search2 } from 'utils/apiUtil'
import { getStrBytes } from 'utils/numberUtil'
import { useHistory } from 'react-router-dom'
import M_FieldTextLong from 'components/1_Atoms/2_Molecules/M_FieldTextLong'

const WIDTH = 500
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      alignContent: 'center',
      alignItems: 'center',
    },
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      width: WIDTH,
      margin: `${theme.spacing(0)} auto`,
    },
    header: {
      width: WIDTH,
      textAlign: 'center',
      background: '#212121',
      color: '#fff',
    },
    card: {
      width: WIDTH,
      marginTop: theme.spacing(10),
    },
  }),
)

const O_SearchBoxChangePassword = ({ title }: { title: string }) => {
  useEffect(() => organismGroupLog('O_SearchBoxChangePassword'), [])
  const classes = useStyles()
  const dispatch = useDispatch()
  const message = useSelector((state) => state.LoginReducer.message)
  const inputPassword = useSelector((state) => state.LoginReducer.inputPassword)
  const reInputPassword = useSelector((state) => state.LoginReducer.reInputPassword)
  const inputUserName = useSelector((state) => state.LoginReducer.inputUserName)
  const isChangedPassword = useSelector((state) => state.LoginReducer.isChangedPassword)
  const MAIL = useSelector((state) => state.LoginReducer.MAIL)
  const loading = useSelector((state) => state.PageReducer.loading)
  const history = useHistory()

  const onChangeInputUserName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault()

      // 文字バイトチェック。40バイトまで許可 ※アルファベットのとき25文字までになっているので修正必要
      const bytes = getStrBytes(e.target.value)
      if (bytes > 40) return
      dispatch(LoginAction.changeInputUserName(e.target.value))
    },
    [inputUserName],
  )

  const onChangeInputPassword = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault()
      dispatch(LoginAction.changeInputPassword(e.target.value))
    },
    [inputPassword],
  )

  const onChangeReInputPassword = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault()
      dispatch(LoginAction.changeReInputPassword(e.target.value))
    },
    [reInputPassword],
  )

  const onClickLogin = useCallback(async () => {
    if (loading) return false
    dispatch(PageAction.changeLoading(true))
    await search2<{ inputUserName: string; inputPassword: string; reInputPassword: string; MAIL: string }, any>(
      '/postChangePassword',
      { inputUserName: inputUserName, inputPassword: inputPassword, reInputPassword: reInputPassword, MAIL: MAIL },
      (res) => {
        dispatch(LoginAction.changeMessage(res.viewMsg))
        if (!res.error) {
          dispatch(LoginAction.changeIsChangedPassword(true))
          dispatch(LoginAction.changeInitMessageFlg(false))
          dispatch(LoginAction.changeLoginKbn(0))
        }
      },
      () => dispatch(LoginAction.changeMessage(commonMsg.ERROR_LOGIN_MSG)),
      () => dispatch(PageAction.changeLoading(false)),
    )
    return true
  }, [inputUserName, inputPassword, reInputPassword, loading])

  const onClickBack = useCallback(() => {
    dispatch(LoginAction.changeMessage(''))
    history.push('/')
    return true
  }, [])

  return (
    <form className={classes.container} autoComplete="new-off">
      <Card className={classes.card} onSubmit={onClickLogin}>
        <CardHeader className={classes.header} title={title} autoComplete="new-off" />
        <CardContent>
          {isChangedPassword ? null : (
            <div>
              <M_FieldTextLong
                key={1} //
                tab={1}
                label="氏名"
                max={25}
                valueCode={inputUserName}
                change={onChangeInputUserName}
                autoComplete={'new-password'}
              />
              <M_FieldTextLong
                key={2} //
                tab={2}
                type={'password'}
                label="新パスワード"
                max={25}
                valueCode={inputPassword}
                change={onChangeInputPassword}
                autoComplete={'new-password'}
              />
              <M_FieldTextLong
                key={3} //
                tab={3}
                type={'password'}
                label="再入力新パスワード"
                max={25}
                valueCode={reInputPassword}
                change={onChangeReInputPassword}
              />
            </div>
          )}
        </CardContent>
        <CardActions>
          <Grid container className={classes.root} justify={'center'}>
            <Grid item xs={8}>
              <A_Message color="red" message={message ? message : ''} align={'center'} />
            </Grid>
            <Grid item xs={8}>
              {isChangedPassword ? <M_ButtonSearch label="戻る" onClick={onClickBack} /> : <M_ButtonSearch label="登録" onClick={onClickLogin} />}
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </form>
  )
}
export default React.memo(O_SearchBoxChangePassword)
