import { axios } from 'SWRProvider'
import { useDispatch, useSelector } from 'react-redux'
import * as PageAction from 'actions/PageAction' // 共通部分が多いActionはActionCreaterにまとめる
import { useCallback } from 'react'
import { apiLog } from 'utils/logUtil'
import { voidF, voidStringF } from 'types/FunctionTypes'

// メッセージ //================================//
export const usePageMessage = (): [voidStringF, voidF] => {
  const dispatch = useDispatch()
  const changePageErrorMessage = useCallback((text: string) => {
    dispatch(PageAction.changeErrorMessage(text))
  }, [])
  const deletePageMessage = useCallback(() => {
    dispatch(PageAction.deleteMessage())
  }, [])
  return [changePageErrorMessage, deletePageMessage]
}

/**
 * ローディング //================================//
 */
export const useLoading = (): [boolean, (load: boolean) => void] => {
  const dispatch = useDispatch()
  // ローディング
  const loading = useSelector((state) => state.PageReducer.loading)
  const changeLoading = useCallback(
    (load: boolean) => {
      dispatch(PageAction.changeLoading(load))
    },
    [loading],
  )

  return [loading, changeLoading]
}

// 検索API ※没 //================================//
export const useSearch = <T extends Object, U extends Object>(
  url: string, //
  req: T,
  func: (res: { body: U; error: boolean; viewMsg: string; fname: string }) => void,
) => {
  const dispatch = useDispatch()
  // ローディング
  const loading = useSelector((state) => state.PageReducer.loading)
  const search = async () => {
    if (!loading) {
      // メッセージ消去
      dispatch(PageAction.deleteMessage())
      // ローディング開始
      dispatch(PageAction.changeLoading(true))
      try {
        await axios.post('/api' + url, { req: req }).then((res) => {
          if (res.data.error) dispatch(PageAction.changeMessage(res.data.viewMsg))
          else {
            func(res.data)
            dispatch(PageAction.changeMessage(res.data.viewMsg, 'blue'))
          }
        })
      } catch (error) {
        dispatch(PageAction.changeErrorMessage())
        console.error(error)
      } finally {
        dispatch(PageAction.changeLoading(false))
        apiLog(url)
      }
    }
  }
  return search
}
