import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import { Card, CardHeader, CardContent, CardActions, LinearProgress } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import M_ButtonSearch from 'components/1_Atoms/2_Molecules/M_ButtonSearch'
import A_Message from 'components/1_Atoms/A_Message'
import M_FieldTextLong from 'components/1_Atoms/2_Molecules/M_FieldTextLong'
import { useLogin } from 'hooks/useLogin'
import { organismGroupLog } from 'utils/logUtil'

const WIDTH = 500
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      alignContent: 'center',
      alignItems: 'center',
    },
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      width: WIDTH,
      margin: `${theme.spacing(0)} auto`,
    },
    header: {
      width: WIDTH,
      textAlign: 'center',
      background: '#212121',
      color: '#fff',
    },
    card: {
      width: WIDTH,
      marginTop: theme.spacing(10),
    },
  }),
)

const O_SearchBoxLogin = ({ title }: { title: string }) => {
  useEffect(() => organismGroupLog('O_SearchBoxLogin'), [])
  const classes = useStyles()
  const [USER_CD, changeUserCode, PASSWORD, changePassword, login, loading, message] = useLogin(useHistory())

  return (
    <div className={classes.root}>
      <form className={classes.container} noValidate autoComplete="none">
        <Card className={classes.card} onSubmit={login}>
          <CardHeader className={classes.header} title={title} />
          {loading ? <LinearProgress /> : ''}
          <CardContent>
            <div>
              <M_FieldTextLong
                key={1} //
                tab={1}
                label="ID"
                max={90}
                valueCode={USER_CD}
                change={changeUserCode}
                focus={true}
              />
              <M_FieldTextLong
                key={2} //
                tab={2}
                label="パスワード"
                type={'password'}
                max={30}
                valueCode={PASSWORD}
                change={changePassword}
              />
            </div>
          </CardContent>
          <CardActions>
            <Grid container className={classes.root} justify={'center'}>
              <Grid item xs={8}>
                {loading ? <A_Message color="green" message={'ログイン中...'} align={'center'} /> : <A_Message color="red" message={message ? message : '　'} align={'center'} />}
              </Grid>
              <Grid item xs={8}>
                <M_ButtonSearch label="ログイン" onClick={login} />
                <p style={{ textAlign: 'center' }}>
                  <Link to={loading ? '' : '/createPassword'}>パスワード再発行はこちら</Link>
                </p>
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </form>
    </div>
  )
}
export default React.memo(O_SearchBoxLogin)
