import { ModalUserAction } from 'reducers/ModalUserReducer'
import { select } from 'types/SearchTypes'

// メッセージを初期化する処理 //================================//
export const deleteMessage = (): ModalUserAction => {
  return {
    type: 'ModalUserDeleteMessage',
  }
}

// 氏名を変更する処理 //================================//
export const changeName = (NAME: string): ModalUserAction => {
  return {
    type: 'ModalUserChangeName',
    NAME: NAME,
  }
}

// メールアドレスを変更する処理 //================================//
export const changeMail = (MAIL: string): ModalUserAction => {
  return {
    type: 'ModalUserChangeMail',
    MAIL: MAIL,
  }
}

// 得意先名を変更する処理 //================================//
export const changeTokuiName = (TOKUI_NM: string): ModalUserAction => {
  return {
    type: 'ModalUserChangeTokuiName',
    TOKUI_NM: TOKUI_NM,
  }
}

// 得意先コードを変更する処理 //================================//
export const changeTokuiCode = (TOKUI_CD: number): ModalUserAction => {
  return {
    type: 'ModalUserChangeTokuiCode',
    TOKUI_CD: TOKUI_CD,
  }
}

// モーダルを開く処理 //================================//
export const open = (jigyoSet: select[], siireSet: select[], JIGYO_CD: number, SIIRE_CD: number): ModalUserAction => {
  return {
    type: 'ModalUserOpen',
    jigyoSet: jigyoSet,
    siireSet: siireSet,
    JIGYO_CD: JIGYO_CD,
    SIIRE_CD: SIIRE_CD,
  }
}

// モーダルを閉じる処理 //================================//
export const close = (): ModalUserAction => {
  return {
    type: 'ModalUserClose',
  }
}

//  //================================//
export const changeMessage = (message: string, messageColor: string = 'red'): ModalUserAction => {
  return {
    type: 'ModalUserChangeMessage',
    message: message,
    messageColor: messageColor,
  }
}

// ローディング処理を変更する処理 //================================//
export const changeLoading = (loading: boolean): ModalUserAction => {
  return {
    type: 'ModalUserChangeLoading',
    loading: loading,
  }
}

// 条件初期化 //================================//
export const format = (): ModalUserAction => {
  return {
    type: 'ModalUserFormat',
  }
}
