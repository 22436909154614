import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import M_ButtonNormal from 'components/1_Atoms/2_Molecules/M_ButtonNormal'
import Grid from '@material-ui/core/Grid'
import { organismLog } from 'utils/logUtil'

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
})

type Props = {
  disabled: boolean
  clickInsert: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  clickClose: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const O_FooterModalUser = ({ disabled, clickInsert, clickClose }: Props) => {
  useEffect(() => organismLog('O_FooterModalUser'))
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Grid container justify="flex-start" spacing={1}>
        <Grid item xs={12} sm={1}>
          <M_ButtonNormal label={'追加'} event={clickInsert} disabled={disabled} />
        </Grid>
        <Grid item xs={12} sm={2}></Grid>
        <Grid item xs={12} sm={9}>
          <Grid container justify="flex-end">
            <M_ButtonNormal label={'閉じる'} event={clickClose} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
export default React.memo(O_FooterModalUser)
