/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import M_ButtonNormal from 'components/1_Atoms/2_Molecules/M_ButtonNormal'
import * as ModalShohinAction from 'actions/ModalShohinAction'
import { organismLog } from 'utils/logUtil'
import { fileCode } from 'assets/FileName'
import { createCSV } from 'utils/fileUtil'
import { CSVHeaderPreRitsuJokenJogai } from 'assets/CSVHeaders'
import { searchFileName, searchMessage } from 'utils/apiUtil'

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
})

const O_FooterModalShohin = () => {
  useEffect(() => organismLog('O_FooterModalShohin'))
  const classes = useStyles()
  const dispatch = useDispatch()
  const modalShohin = useSelector((state) => state.ModalShohinReducer.modalShohin)
  const loading = useSelector((state) => state.PageReducer.loading)

  const onClickCSV = useCallback(async () => {
    if (loading) return false
    dispatch(ModalShohinAction.changeLoading(true))
    const fname = await searchFileName(fileCode.RITSU_JOGAI_CSV)
    const message = await searchMessage(52)
    await createCSV(CSVHeaderPreRitsuJokenJogai, modalShohin.shohinInfo, fname)
    dispatch(ModalShohinAction.changeMessage(fname + message))
    dispatch(ModalShohinAction.changeLoading(false))
    return false
  }, [dispatch, loading])
  const handleClose = useCallback(() => {
    dispatch(ModalShohinAction.close())
  }, [dispatch])

  return (
    <div className={classes.root}>
      <Grid container justify="flex-start" spacing={1}>
        <Grid item xs={12} sm={1}>
          <M_ButtonNormal label={'CSV出力'} event={onClickCSV} />
        </Grid>
        <Grid item xs={12} sm={11}>
          <Grid container justify="flex-end">
            <M_ButtonNormal label={'閉じる'} event={handleClose} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
export default React.memo(O_FooterModalShohin)
