/* eslint-disable react/jsx-pascal-case */
import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import O_SearchBoxRitsuShiiresaki from 'components/3_Organisms/Groups/O_SearchBoxRitsuShiiresaki'
import O_SearchBoxRitsuJapell from 'components/3_Organisms/Groups/O_SearchBoxRitsuJapell'
import O_TableJoken from 'components/3_Organisms/Groups/O_TableJokenRitsu'
import O_DialogAlert from 'components/3_Organisms/O_DialogAlert'
import O_CodeHaritsukeShohin from 'components/3_Organisms/Groups/O_CodeHaritsukeShohin'
import O_FileUpload from 'components/3_Organisms/Groups/O_FileUpload'
import { templateLog } from 'utils/logUtil'
import { useClearCheck, useFetcherSummary, useIsNotDeleteMessage } from 'hooks/useRitsuJoken'
import { useErrorLogout, useUser } from 'hooks/useLogin'
import { usePageMessage, useLoading } from 'hooks/usePage'
import { baseInfo } from 'types/SearchTypes'
import { responseJokenRitsu } from 'types/ResponseTypes'
import { usePage } from 'hooks/useTable'
import { useHistory } from 'react-router-dom'

const C_RitsuJoken = (): JSX.Element => {
  useEffect(() => templateLog('C_RitsuJoken'))
  const userKind = useUser()
  const [searchJoken, fetcher] = useFetcherSummary()
  const clearCheck = useClearCheck()
  const [changePageErrorMessage, deletePageMessage] = usePageMessage()
  const [isNotDeleteMessage, changeIsNotDeleteMessage] = useIsNotDeleteMessage()
  const [page, perPage] = usePage()
  const [backLogin] = useErrorLogout(useHistory())

  // SWR情報の取得
  const { data, refetch, isFetching, isLoading, error } = useQuery(['postJokenRitsuInfo', searchJoken, page, perPage], fetcher)
  const [loding, changeLoading] = useLoading()
  useEffect(() => {
    if (isLoading || isFetching) {
      changeLoading(true)
    }
    if (!(isLoading || isFetching) && loding) {
      changeLoading(false)
    }
  }, [isLoading, isFetching])
  useEffect(() => {
    if ((isLoading || isFetching) && !isNotDeleteMessage) {
      deletePageMessage()
    }
    if (!(isLoading || isFetching) && isNotDeleteMessage) {
      changeIsNotDeleteMessage(false)
    }
  }, [isLoading, isFetching])
  if (isLoading) {
    clearCheck()
    return <div>Loading...</div>
  }
  if (isFetching) {
    clearCheck()
  }
  if (error) {
    backLogin()
    return <></>
  }
  const info: { pullDown: baseInfo; table: responseJokenRitsu[]; allCount: number } = data.body
  if (data.viewMsg) {
    changePageErrorMessage(data.viewMsg)
  }
  return (
    <>
      {userKind === 0 ? (
        <O_SearchBoxRitsuJapell info={info.pullDown} /> //
      ) : (
        <O_SearchBoxRitsuShiiresaki info={info.pullDown} /> //
      )}
      <hr />
      <O_TableJoken jokenList={info.table} allCount={info.allCount} isFetching={isFetching} refetch={refetch} />
      {/* 商品コード貼付画面のモーダル */}
      <O_CodeHaritsukeShohin />
      {/* ファイル取込画面のモーダル */}
      <O_FileUpload INP_KBN={2} refetch={refetch} />
      {/* アラートダイアログ */}
      <O_DialogAlert />
    </>
  )
}
export default React.memo(C_RitsuJoken)
