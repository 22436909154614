/* eslint-disable react/jsx-pascal-case */
import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import O_SearchBoxJokenJapell from 'components/3_Organisms/Groups/O_SearchBoxJokenJapell'
import O_SearchBoxJokenShiiresaki from 'components/3_Organisms/Groups/O_SearchBoxJokenShiiresaki'
import O_TableJokenSummary from 'components/3_Organisms/Groups/O_TableJokenSummary'
import O_TableJokenDetail from 'components/3_Organisms/Groups/O_TableJokenDetail'
import O_DialogAlert from 'components/3_Organisms/O_DialogAlert'
import { templateLog } from 'utils/logUtil'
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom'
import { baseInfo } from 'types/SearchTypes'
import { responseSummaryJoken } from 'types/ResponseTypes'
import { useFetcherSummary, useIsNotDeleteMessage, useSearch } from 'hooks/useHanJoken'
import { usePageMessage, useLoading } from 'hooks/usePage'
import { useSearchedFormatBoth } from 'hooks/useHanJokenTable'
import { useErrorLogout, useUser } from 'hooks/useLogin'
import { usePage } from 'hooks/useTable'

const C_HanJoken = (): JSX.Element => {
  useEffect(() => templateLog('C_HanJoken'))
  const userKind = useUser()
  const [searchJoken, fetcher] = useFetcherSummary()
  const searchedFormat = useSearchedFormatBoth()
  const [changePageErrorMessage, deletePageMessage] = usePageMessage()
  const [isNotDeleteMessage, changeIsNotDeleteMessage] = useIsNotDeleteMessage()
  const [backLogin] = useErrorLogout(useHistory())
  const [page, perPage] = usePage()

  // SWR情報の取得
  const [detailView] = useSearch()
  const { data, refetch, isFetching, isLoading, error } = useQuery(['postJokenSummary', searchJoken, page, perPage], fetcher)
  const [loding, changeLoading] = useLoading()
  useEffect(() => {
    if (!detailView && (isLoading || isFetching)) {
      changeLoading(true)
    }
    if (!detailView && !(isLoading || isFetching) && loding) {
      changeLoading(false)
    }
  }, [isLoading, isFetching, detailView])
  useEffect(() => {
    if (!detailView && (isLoading || isFetching) && !isNotDeleteMessage) {
      deletePageMessage()
    }
    if (!detailView && !(isLoading || isFetching) && isNotDeleteMessage) {
      changeIsNotDeleteMessage(false)
    }
  }, [isLoading, isFetching, detailView])

  if (isLoading) {
    searchedFormat()
    return <div>Loading...</div>
  }
  if (isFetching) {
    searchedFormat()
  }
  if (error) {
    backLogin()
    return <></>
  }
  const info: { pullDown: baseInfo; table: responseSummaryJoken[]; allCount: number } = data.body
  if (data.viewMsg) {
    changePageErrorMessage(data.viewMsg)
  }
  return (
    <>
      {userKind === 0 ? (
        <O_SearchBoxJokenJapell info={info.pullDown} /> //
      ) : (
        <O_SearchBoxJokenShiiresaki info={info.pullDown} /> //
      )}
      <hr />
      <Router>
        <Switch>
          <Route path="/hanJoken" exact>
            <O_TableJokenSummary jokenList={info.table} allCount={info.allCount} isFetching={isFetching} refetch={refetch} />
          </Route>
          <Route path="/hanJoken/:postId" exact component={O_TableJokenDetail} />
        </Switch>
      </Router>
      {/* アラートダイアログ */}
      <O_DialogAlert />
    </>
  )
}

export default React.memo(C_HanJoken)
