/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useRef, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import A_IconMust from '../A_IconMust'
import { moleculeLog } from 'utils/logUtil'
import TextField from '@material-ui/core/TextField'

type Props = {
  label?: string
  key1?: number
  type?: 'date' | 'month'
  must?: boolean
  value1?: number | string
  min1?: string
  max1?: string
  disabled1?: boolean
  change1?: (e: React.ChangeEvent<HTMLInputElement>) => void
  blurFunc1?: (e: React.FocusEvent<HTMLInputElement>) => void
  focus?: boolean
  key2?: number
  value2?: number | string
  min2?: string
  max2?: string
  disabled2?: boolean
  change2?: (e: React.ChangeEvent<HTMLInputElement>) => void
  blurFunc2?: (e: React.FocusEvent<HTMLInputElement>) => void
}

// const WIDTH = 140
const WIDTH = '100%'
const HEIGHT = '27px' // 入力フォームの高さ

const M_FieldDateSetNew = ({
  label = '年月', //
  key1 = 0,
  type = 'month',
  must = false,
  value1,
  min1 = type === 'month' ? '1000-01' : '1000-01-01',
  max1 = type === 'month' ? '9999-12' : '9999-12-31',
  disabled1 = false,
  change1 = undefined,
  blurFunc1 = undefined,
  key2 = 1,
  value2,
  min2 = type === 'month' ? '1000-01' : '1000-01-01',
  max2 = type === 'month' ? '9999-12' : '9999-12-31',
  disabled2 = false,
  change2 = undefined,
  blurFunc2 = undefined,
}: Props) => {
  // blurでreducerを変更する場合について
  // chageValueで入力中の値を保持、フォーカスアウトでreducerを更新
  // この入力フォーム以外でreducerが更新されてもchangeValueを変えないと入力フォームの値が変わらないので
  // reducerの値とprevValueRefに持たせた前の値と比較して、変わっていたらchageValueを更新
  const [changeValue1, setValue1] = useState(value1)
  const [changeValue2, setValue2] = useState(value2)
  const inputRef1 = useRef<HTMLInputElement | null>()
  const inputRef2 = useRef<HTMLInputElement | null>()
  const prevValueRef1 = useRef<string | number | undefined>(value1)
  const prevValueRef2 = useRef<string | number | undefined>(value2)
  useEffect(() => {
    moleculeLog('M_FieldDateSetNew')
    if (prevValueRef1.current !== value1) {
      setValue1(value1)
      prevValueRef1.current = value1
    }
    if (prevValueRef2.current !== value2) {
      setValue2(value2)
      prevValueRef2.current = value2
    }
  })
  const isBlur1 = blurFunc1 !== undefined
  const isBlur2 = blurFunc2 !== undefined
  return (
    <Grid container justify="flex-start">
      <Grid item xs={12} sm={3}>
        <Typography variant="body1" display="block" gutterBottom>
          {label}
          <A_IconMust must={must} />
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Grid container justify="flex-start">
          <TextField
            inputRef={inputRef1}
            style={{ width: WIDTH }} //
            key={key1}
            type={type}
            value={isBlur1 ? changeValue1 : value1}
            inputProps={{
              min: min1,
              max: max1,
              style: {
                // width: WIDTH,
                height: HEIGHT,
                paddingTop: 0,
                paddingBottom: 0,
                backgroundColor: disabled1 ? '#D8D8D8' : '#FFFFFF',
                fontSize: '13px',
              },
            }}
            onChange={
              isBlur1
                ? (e) => {
                    setValue1(e.target.value)
                  }
                : change1
            }
            onBlur={blurFunc1}
            onKeyDown={(e) => {
              if (e.key === 'Enter') inputRef1.current?.blur()
            }}
            variant="outlined"
            disabled={disabled1}
          />
        </Grid>
      </Grid>
      <Typography variant="body1" display="block" gutterBottom>
        <b style={{ padding: 5 }}>{'～'}</b>
      </Typography>
      <Grid item xs={12} sm={4}>
        <Grid container justify="flex-start">
          <TextField
            inputRef={inputRef2}
            style={{ width: WIDTH }} //
            key={key2}
            type={type}
            value={isBlur2 ? changeValue2 : value2}
            inputProps={{
              min: min2,
              max: max2,
              style: {
                // width: WIDTH,
                height: HEIGHT,
                paddingTop: 0,
                paddingBottom: 0,
                backgroundColor: disabled2 ? '#D8D8D8' : '#FFFFFF',
                fontSize: '13px',
              },
            }}
            onChange={
              isBlur2
                ? (e) => {
                    setValue2(e.target.value)
                  }
                : change2
            }
            onBlur={blurFunc2}
            onKeyDown={(e) => {
              if (e.key === 'Enter') inputRef2.current?.blur()
            }}
            variant="outlined"
            disabled={disabled2}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
export default React.memo(M_FieldDateSetNew)
