import { Reducer } from 'redux'
import produce from 'immer'
import { s3FileInfo, searchTenyuryoku } from 'types/SearchTypes'
import { getNumberFromString } from 'utils/dateUtil'
import { convertNumber } from 'utils/numberUtil'
import { F_NUM, F_STR } from 'assets/FormatNumbers'
import { responseSummaryTenyuryoku } from 'types/ResponseTypes'
import { isJochoSpecialUser } from 'utils/booleanUtil'
import { zeroPadding } from 'utils/stringUtil'

/**
 * メイン参照テーブルはJTD368
 */
export type SearchTenyuryokuState = {
  SearchTenyuryoku: searchTenyuryoku // 手入力分照会画面検索情報
  searchedTenyuryoku: searchTenyuryoku // 手入力分照会画面検索情報(明細表示中)
  message: string // メッセージ
  detailView: boolean // 明細画面表示中か
  initMishoninCheckbox: boolean // 初期状態未承認チェックボックス
  initShoninCheckbox: boolean // 初期状態承認チェックボックス
  initHininCheckbox: boolean // 初期状態否認チェックボックス
  initJochoMishoninCheckbox: boolean // 初期状態上長未承認チェックボックス
  initJochoShoninCheckbox: boolean // 初期状態上長承認チェックボックス
  initHininMikakuninCheckbox: boolean // 初期状態メーカー否認　未確認チェックボックス
  initJochoHininMikakuninCheckbox: boolean // 初期状態上長否認　未確認チェックボックス
  initHininkakuninzumiCheckbox: boolean // 初期状態メーカー否認　確認済チェックボックス
  initJochoHininKakuninzumiCheckbox: boolean // 初期状態上長否認　確認済チェックボックス
  isNotDeleteMessage: boolean // 検索後にメッセージを消さないフラグ
  expand: boolean // 検索ボックスを開いているか否か
}

const initJoken = {
  SYORI_YMD_FROM: F_NUM, // 処理票日付FROM
  SYORI_YMD_TO: F_NUM, // 処理票日付TO
  ADD_YMD_FROM: F_NUM, // 登録日FROM
  ADD_YMD_TO: F_NUM, // 登録日TO
  NYUKIN_YMD_FROM: F_NUM, // 相殺/入金日FROM
  NYUKIN_YMD_TO: F_NUM, // 相殺/入金日TO
  SIIRE_CD: F_NUM, // 仕入先コード
  JIGYO_CD: F_NUM, // 事業所コード
  TANTO_CD: F_NUM, // 担当者コード
  NAIYO_KBN1: F_NUM, // 精算内容区分1
  NAIYO_KBN2: F_NUM, // 精算内容区分2
  SEISAN_KOUMOKU: F_NUM, // 精算項目
  DENPYO_NO: F_STR, //	処理No
  SEISAN_NAIYO: F_STR, // 精算内容
  mishoninCheckbox: false, // 未承認チェックボックス
  shoninCheckbox: false, // 承認チェックボックス
  hininCheckbox: false, // 否認チェックボックス
  jochoShoninCheckbox: false, // 上長承認チェックボックス
  jochoMishoninCheckbox: false, // 上長未承認チェックボックス
  hininMikakuninCheckbox: false, // メーカー否認　未確認チェックボックス
  jochoHininMikakuninCheckbox: false, // 上長否認　未確認チェックボックス
  hininKakuninzumiCheckbox: false, // メーカー否認　確認済チェックボックス
  jochoHininKakuninzumiCheckbox: false, // 上長否認　確認済チェックボックス
  file: [], // ファイル
}

/**
 * 初期状態
 * 入力区分は販売条件とする
 * コード値は-1とする
 */
const initialState: SearchTenyuryokuState = {
  SearchTenyuryoku: initJoken, // 手入力処理分照会画面検索情報
  searchedTenyuryoku: initJoken, // 手入力処理分照会画面検索情報(明細表示中)
  message: F_STR, // メッセージ
  detailView: false, // 明細画面表示中か
  initMishoninCheckbox: false, // 初期状態未承認チェックボックス
  initShoninCheckbox: false, // 初期状態承認チェックボックス
  initHininCheckbox: false, // 初期状態否認チェックボックス
  initJochoShoninCheckbox: false, // 初期状態上長承認チェックボックス
  initJochoMishoninCheckbox: false, // 初期状態上長未承認チェックボックス
  initHininMikakuninCheckbox: false, // 初期状態メーカー否認　未確認チェックボックス
  initJochoHininMikakuninCheckbox: false, // 初期状態上長否認　未確認チェックボックス
  initHininkakuninzumiCheckbox: false, // 初期状態メーカー否認　確認済チェックボックス
  initJochoHininKakuninzumiCheckbox: false, // 初期状態上長否認　確認済チェックボックス
  isNotDeleteMessage: false, // 検索後にメッセージを消さないフラグ
  expand: true, // 検索ボックスを開いているか否か
}

export type SearchTenyuryokuType =
  | 'SearchTenyuryokuChangeSyoriYmdFrom' // 処理票日付FROMを変更する処理
  | 'SearchTenyuryokuChangeSyoriYmdTo' // 処理票日付TOを変更する処理
  | 'SearchTenyuryokuChangeAddYmdFrom' // 登録日FROMを変更する処理
  | 'SearchTenyuryokuChangeAddYmdTo' // 登録日TOを変更する処理
  | 'SearchTenyuryokuChangeNyukinYmdFrom' // 相殺/入金FROMを変更する処理
  | 'SearchTenyuryokuChangeNyukinYmdTo' // 相殺/入金TOを変更する処理
  | 'SearchTenyuryokuChangeShiiresakiCode' // 仕入先コードを変更する処理
  | 'SearchTenyuryokuChangeJigyoshoCode' // 事業所コードを変更する処理
  | 'SearchTenyuryokuChangeTantoCode' // 担当者コードを変更する処理
  | 'SearchTenyuryokuChangeNaiyoKbn1' // 精算内容区分1を変更する処理
  | 'SearchTenyuryokuChangeNaiyoKbn2' // 精算内容区分2を変更する処理
  | 'SearchTenyuryokuChangeSeisanKoumoku' // 精算項目を変更する処理
  | 'SearchTenyuryokuChangeDenpyoNo' // 処理Noを変更する処理
  | 'SearchTenyuryokuChangeSeisanNaiyo' // 件名を変更する処理
  | 'SearchTenyuryokuChangeDetailView' // 明細画面表示を変更する処理
  | 'SearchTenyuryokuOpenDetail' // 手入力処理分照会画面検索情報(明細表示中)を変更する処理
  | 'SearchTenyuryokuCloseDetail' // 手入力処理分照会画面検索情報(明細表示中)を変更する処理
  | 'SearchTenyuryokuChangeMishoninCheckbox' // 未承認チェックボックスを変更する処理
  | 'SearchTenyuryokuChangeShoninCheckbox' // 承認チェックボックスを変更する処理
  | 'SearchTenyuryokuChangeHininCheckbox' // 否認チェックボックスを変更する処理
  | 'SearchTenyuryokuChangeKakunintaisyoCheckbox' // 確認対象表示状態にする処理
  | 'SearchTenyuryokuChangeInitCheckbox' // 初期状態のチェックボックスを変更する処理
  | 'SearchTenyuryokuChangeIsDeleteMessage' // 検索後にメッセージを消さないフラグを変更する処理
  | 'SearchTenyuryokuChangeJochoShoninCheckbox' // 上長承認チェックボックスを変更する処理
  | 'SearchTenyuryokuChangeJochoMishoninCheckbox' // 上長未承認チェックボックスを変更する処理
  | 'SearchTenyuryokuChangeHininMikakuninCheckbox' // メーカー否認　未確認チェックボックスを変更する処理
  | 'SearchTenyuryokuChangeJochoHininMikakuninCheckbox' // 上長否認　未確認チェックボックスを変更する処理
  | 'SearchTenyuryokuChangeHininkakuninzumiCheckbox' // メーカー否認　確認済チェックボックスを変更する処理
  | 'SearchTenyuryokuChangeJochoHininkakuninzumiCheckbox' // 上長否認　確認済チェックボックスを変更する処理
  | 'SearchTenyuryokuChangeFile' // ファイルを変更する処理
  | 'SearchTenyuryokuChangeExpand' // 検索ボックスを開いているか否かを変更する処理
  | 'SearchTenyuryokuChangeMessage' // メッセージを変更する処理
  | 'SearchTenyuryokuChangeInitCode' // ユーザー所属部署ごとの初期値入力処理
  | 'SearchTenyuryokuFormatExceptJotai' // 状態以外の条件検索情報を初期化する処理
  | 'SearchTenyuryokuFormat' // 条件検索情報を初期化する処理
  | 'SearchTenyuryokuAllFormat' // 条件検索情報を完全に初期化する処理
  | 'dummy'

export type SearchTenyuryokuAction = {
  type: SearchTenyuryokuType
  syoriYmdFrom?: string
  syoriYmdTo?: string
  addYmdFrom?: string
  addYmdTo?: string
  nyukinYmdFrom?: string
  nyukinYmdTo?: string
  shiiresakiCode?: number
  jigyoshoCode?: number
  tantoCode?: number
  naiyoKbn1?: number
  naiyoKbn2?: number
  seisanKoumoku?: number
  denpyoNo?: string
  seisanNaiyo?: string
  message?: string
  detailView?: boolean // 明細画面表示中か
  joken?: responseSummaryTenyuryoku // 手入力処理分照会画面検索情報
  isNotDeleteMessage?: boolean // 検索後にメッセージを消さないフラグ
  isSuperUser?: boolean // スーパーユーザーかどうか
  isJocho?: boolean // 上長かどうか
  isKoiki?: boolean // 広域量販部かどうか
  initJotaiKbn?: string // ログイン時の状態チェックボックスを設定するための区分。1：ジャペル（一般・スーパーユーザー）、2：ジャペル（上長）、3：仕入先
  file?: s3FileInfo[] // ファイル
  expand?: boolean
}

export const SearchTenyuryokuReducer: Reducer<SearchTenyuryokuState, SearchTenyuryokuAction> = produce((draft: SearchTenyuryokuState, action: SearchTenyuryokuAction) => {
  switch (action.type) {
    // 検索ボックスを開いているか否かを変更する処理 //===========================//
    case 'SearchTenyuryokuChangeExpand': {
      draft.expand = action.expand!
      return
    }

    // ファイルを変更する処理 //===========================//
    case 'SearchTenyuryokuChangeFile': {
      draft.SearchTenyuryoku.file = action.file!
      return
    }

    // 上長否認　確認済チェックボックスを変更する処理 //===========================//
    case 'SearchTenyuryokuChangeJochoHininkakuninzumiCheckbox': {
      draft.SearchTenyuryoku.jochoHininKakuninzumiCheckbox = !draft.SearchTenyuryoku.jochoHininKakuninzumiCheckbox
      return
    }

    // メーカー否認　確認済チェックボックスを変更する処理 //===========================//
    case 'SearchTenyuryokuChangeHininkakuninzumiCheckbox': {
      draft.SearchTenyuryoku.hininKakuninzumiCheckbox = !draft.SearchTenyuryoku.hininKakuninzumiCheckbox
      return
    }

    // 上長否認　未確認チェックボックスを変更する処理 //===========================//
    case 'SearchTenyuryokuChangeJochoHininMikakuninCheckbox': {
      draft.SearchTenyuryoku.jochoHininMikakuninCheckbox = !draft.SearchTenyuryoku.jochoHininMikakuninCheckbox
      return
    }

    // メーカー否認　未確認チェックボックスを変更する処理 //===========================//
    case 'SearchTenyuryokuChangeHininMikakuninCheckbox': {
      draft.SearchTenyuryoku.hininMikakuninCheckbox = !draft.SearchTenyuryoku.hininMikakuninCheckbox
      return
    }

    // 上長未承認チェックボックスを変更する処理 //===========================//
    case 'SearchTenyuryokuChangeJochoMishoninCheckbox': {
      draft.SearchTenyuryoku.jochoMishoninCheckbox = !draft.SearchTenyuryoku.jochoMishoninCheckbox
      return
    }

    // 上長承認チェックボックスを変更する処理 //===========================//
    case 'SearchTenyuryokuChangeJochoShoninCheckbox': {
      draft.SearchTenyuryoku.jochoShoninCheckbox = !draft.SearchTenyuryoku.jochoShoninCheckbox
      return
    }

    // 検索後にメッセージを消さないフラグを変更する処理 //===========================//
    case 'SearchTenyuryokuChangeIsDeleteMessage': {
      draft.isNotDeleteMessage = action.isNotDeleteMessage!
      return
    }

    // 初期状態のチェックボックスを変更する処理 //===========================//
    case 'SearchTenyuryokuChangeInitCheckbox': {
      draft.initMishoninCheckbox = draft.SearchTenyuryoku.mishoninCheckbox
      draft.initShoninCheckbox = draft.SearchTenyuryoku.shoninCheckbox
      draft.initHininCheckbox = draft.SearchTenyuryoku.hininCheckbox
      draft.initJochoMishoninCheckbox = draft.SearchTenyuryoku.jochoMishoninCheckbox
      draft.initHininMikakuninCheckbox = draft.SearchTenyuryoku.hininMikakuninCheckbox
      draft.initJochoHininMikakuninCheckbox = draft.SearchTenyuryoku.jochoHininMikakuninCheckbox
      draft.initHininkakuninzumiCheckbox = draft.SearchTenyuryoku.hininKakuninzumiCheckbox
      draft.initJochoHininKakuninzumiCheckbox = draft.SearchTenyuryoku.jochoHininKakuninzumiCheckbox
      return
    }

    // 確認対象表示状態にする処理 //===========================//
    case 'SearchTenyuryokuChangeKakunintaisyoCheckbox': {
      switch (action.initJotaiKbn) {
        case '1': // ジャペル（一般・スーパーユーザー）
          draft.SearchTenyuryoku.mishoninCheckbox = true
          draft.SearchTenyuryoku.hininMikakuninCheckbox = true
          break
        case '2': // ジャペル（上長）
          draft.SearchTenyuryoku.jochoMishoninCheckbox = true
          break
        case '3': // 仕入先
          draft.SearchTenyuryoku.mishoninCheckbox = true
          break
      }
      return
    }

    // 否認チェックボックスを変更する処理 //===========================//
    case 'SearchTenyuryokuChangeHininCheckbox': {
      draft.SearchTenyuryoku.hininCheckbox = !draft.SearchTenyuryoku.hininCheckbox
      return
    }

    // 承認チェックボックスを変更する処理 //===========================//
    case 'SearchTenyuryokuChangeShoninCheckbox': {
      draft.SearchTenyuryoku.shoninCheckbox = !draft.SearchTenyuryoku.shoninCheckbox
      return
    }

    // 未承認チェックボックスを変更する処理 //===========================//
    case 'SearchTenyuryokuChangeMishoninCheckbox': {
      draft.SearchTenyuryoku.mishoninCheckbox = !draft.SearchTenyuryoku.mishoninCheckbox
      return
    }

    // 明細画面表示を変更する処理 //===========================//
    case 'SearchTenyuryokuChangeDetailView': {
      draft.detailView = action.detailView!
      return
    }

    // 手入力処理分照会画面検索情報(明細表示中)を変更する処理 ※仮処理 //===========================//
    case 'SearchTenyuryokuOpenDetail': {
      const joken = action.joken!
      draft.searchedTenyuryoku = draft.SearchTenyuryoku
      draft.SearchTenyuryoku = {
        SYORI_YMD_FROM: joken.SYORI_YMD, // 処理票日付FROM
        SYORI_YMD_TO: joken.SYORI_YMD, // 処理票日付TO
        ADD_YMD_FROM: joken.ADD_YMD, // 登録日FROM
        ADD_YMD_TO: joken.ADD_YMD, // 登録日TO
        NYUKIN_YMD_FROM: joken.NYUKIN_YMD, // 相殺/入金日FROM
        NYUKIN_YMD_TO: joken.NYUKIN_YMD, // 相殺/入金日TO
        SIIRE_CD: joken.SIIRE_CD, // 仕入先コード
        JIGYO_CD: joken.JIGYO_CD, // 事業所コード
        TANTO_CD: joken.TANTO_CD, // 担当者コード
        NAIYO_KBN1: joken.NAIYO_KBN1, // 精算内容区分1
        NAIYO_KBN2: joken.NAIYO_KBN2, // 精算内容区分2
        SEISAN_KOUMOKU: joken.SEISAN_KOUMOKU, // 精算項目
        DENPYO_NO: joken.DENPYO_NO, // 処理No
        SEISAN_NAIYO: joken.SEISAN_NAIYO, // 件名
        mishoninCheckbox: joken.JOTAI_KBN_0 === 1, // 未承認チェックボックス
        shoninCheckbox: joken.JOTAI_KBN_1 === 1, // 承認チェックボックス
        hininCheckbox: joken.JOTAI_KBN_2 === 1, // 否認チェックボックス
        jochoMishoninCheckbox: joken.JOCHO_JOTAI_KBN_0 === 1, // 上長未承認チェックボックス
        jochoShoninCheckbox: joken.JOCHO_JOTAI_KBN_1 === 1, // 上長未承認チェックボックス
        hininMikakuninCheckbox: joken.JOTAI_KBN_2_1 === 1, // メーカー否認　未確認チェックボックス
        jochoHininMikakuninCheckbox: joken.JOCHO_JOTAI_KBN_2_1 === 1, // 上長否認　未確認チェックボックス
        hininKakuninzumiCheckbox: joken.JOTAI_KBN_2_2 === 1, // メーカー否認　確認済チェックボックス
        jochoHininKakuninzumiCheckbox: joken.JOCHO_JOTAI_KBN_2_2 === 1, // 上長否認　確認済チェックボックス
        file: [], // ファイル。明細取得時にセットするのでここでは空配列にする
      }
      draft.detailView = true
      draft.expand = false
      return
    }

    // 手入力処理分照会画面検索情報(明細表示中)を変更する処理 ※仮処理 //===========================//
    case 'SearchTenyuryokuCloseDetail': {
      draft.SearchTenyuryoku = draft.searchedTenyuryoku
      draft.detailView = false
      draft.expand = true
      return
    }

    // 件名処理 //===========================//
    case 'SearchTenyuryokuChangeSeisanNaiyo': {
      draft.SearchTenyuryoku.SEISAN_NAIYO = action.seisanNaiyo!
      return
    }

    // 処理No処理 //===========================//
    case 'SearchTenyuryokuChangeDenpyoNo': {
      if (!action.denpyoNo) {
        draft.SearchTenyuryoku.DENPYO_NO = initialState.SearchTenyuryoku.DENPYO_NO
        return
      }
      draft.SearchTenyuryoku.DENPYO_NO = convertNumber(action.denpyoNo!, 10).toString()
      return
    }

    // 精算項目処理 //===========================//
    case 'SearchTenyuryokuChangeSeisanKoumoku': {
      draft.SearchTenyuryoku.SEISAN_KOUMOKU = convertNumber(action.seisanKoumoku!, 2)
      return
    }

    // 精算内容区分2処理 //===========================//
    case 'SearchTenyuryokuChangeNaiyoKbn2': {
      draft.SearchTenyuryoku.NAIYO_KBN2 = convertNumber(action.naiyoKbn2!, 4)
      if (action.naiyoKbn2! > 0) {
        draft.SearchTenyuryoku.NAIYO_KBN1 = Number(zeroPadding(action.naiyoKbn2!, 4).slice(0, 2))
      }
      return
    }

    // 精算内容区分1処理 //===========================//
    case 'SearchTenyuryokuChangeNaiyoKbn1': {
      draft.SearchTenyuryoku.NAIYO_KBN1 = convertNumber(action.naiyoKbn1!, 2)
      return
    }

    // 事業所処理 //===========================//
    case 'SearchTenyuryokuChangeJigyoshoCode': {
      draft.SearchTenyuryoku.JIGYO_CD = convertNumber(action.jigyoshoCode!, 2)
      return
    }

    // 担当者処理 //===========================//
    case 'SearchTenyuryokuChangeTantoCode': {
      draft.SearchTenyuryoku.TANTO_CD = convertNumber(action.tantoCode!, 6)
      return
    }

    // 仕入先処理 //===========================//
    case 'SearchTenyuryokuChangeShiiresakiCode': {
      draft.SearchTenyuryoku.SIIRE_CD = convertNumber(action.shiiresakiCode!, 4)
      return
    }
    // 相殺/入金日TOを変更する処理 //===========================//
    case 'SearchTenyuryokuChangeNyukinYmdTo': {
      draft.SearchTenyuryoku.NYUKIN_YMD_TO = getNumberFromString(action.nyukinYmdTo!)
      return
    }

    // 相殺/入金日FROMを変更する処理 //===========================//
    case 'SearchTenyuryokuChangeNyukinYmdFrom': {
      draft.SearchTenyuryoku.NYUKIN_YMD_FROM = getNumberFromString(action.nyukinYmdFrom!)
      return
    }

    // 登録日TOを変更する処理 //===========================//
    case 'SearchTenyuryokuChangeAddYmdTo': {
      draft.SearchTenyuryoku.ADD_YMD_TO = getNumberFromString(action.addYmdTo!)
      return
    }

    // 登録日FROMを変更する処理 //===========================//
    case 'SearchTenyuryokuChangeAddYmdFrom': {
      draft.SearchTenyuryoku.ADD_YMD_FROM = getNumberFromString(action.addYmdFrom!)
      return
    }

    // 処理票日付TOを変更する処理 //===========================//
    case 'SearchTenyuryokuChangeSyoriYmdTo': {
      draft.SearchTenyuryoku.SYORI_YMD_TO = getNumberFromString(action.syoriYmdTo!)
      return
    }

    // 処理票日付FROMを変更する処理 //===========================//
    case 'SearchTenyuryokuChangeSyoriYmdFrom': {
      draft.SearchTenyuryoku.SYORI_YMD_FROM = getNumberFromString(action.syoriYmdFrom!)
      return
    }

    // ユーザー所属部署ごとの初期値入力処理 //===========================//
    case 'SearchTenyuryokuChangeInitCode': {
      const JIGYO_CD = convertNumber(action.jigyoshoCode!, 2)
      const TANTO_CD = convertNumber(action.tantoCode!, 6)
      const isSuperUser = action.isSuperUser!
      const isJocho = action.isJocho!
      const isKoiki = action.isKoiki!
      if (isSuperUser) {
        draft.SearchTenyuryoku.JIGYO_CD = JIGYO_CD // 特定の部署の人の事業所の初期値は自分の事業所を選択
      } else {
        if (!isJocho) draft.SearchTenyuryoku.TANTO_CD = TANTO_CD // 一般ユーザーの入力担当者の初期値は自分
      }
      if (isJocho && !isKoiki) {
        if (!isJochoSpecialUser(JIGYO_CD)) draft.SearchTenyuryoku.JIGYO_CD = JIGYO_CD // 上長かつ関西か名古屋以外かつ広域量販部ではない事業所の初期値は自分の事業所
      }
      return
    }
    // 検索条件以外の条件初期化 //===========================//
    case 'SearchTenyuryokuFormatExceptJotai': {
      draft.SearchTenyuryoku = {
        ...initialState.SearchTenyuryoku,
        mishoninCheckbox: draft.SearchTenyuryoku.mishoninCheckbox,
        shoninCheckbox: draft.SearchTenyuryoku.shoninCheckbox,
        hininCheckbox: draft.SearchTenyuryoku.hininCheckbox,
        jochoMishoninCheckbox: draft.SearchTenyuryoku.jochoMishoninCheckbox,
        hininMikakuninCheckbox: draft.SearchTenyuryoku.hininMikakuninCheckbox,
        jochoHininMikakuninCheckbox: draft.SearchTenyuryoku.jochoHininMikakuninCheckbox,
        hininKakuninzumiCheckbox: draft.SearchTenyuryoku.hininKakuninzumiCheckbox,
        jochoHininKakuninzumiCheckbox: draft.SearchTenyuryoku.jochoHininKakuninzumiCheckbox,
      }
      return
    }

    // 条件初期化 //===========================//
    case 'SearchTenyuryokuFormat': {
      draft.SearchTenyuryoku = {
        ...initialState.SearchTenyuryoku,
        mishoninCheckbox: draft.initMishoninCheckbox,
        shoninCheckbox: draft.initShoninCheckbox,
        hininCheckbox: draft.initHininCheckbox,
        jochoMishoninCheckbox: draft.initJochoMishoninCheckbox,
        hininMikakuninCheckbox: draft.initHininMikakuninCheckbox,
        jochoHininMikakuninCheckbox: draft.initJochoHininMikakuninCheckbox,
        hininKakuninzumiCheckbox: draft.initHininkakuninzumiCheckbox,
        jochoHininKakuninzumiCheckbox: draft.initJochoHininKakuninzumiCheckbox,
      }
      draft.searchedTenyuryoku = {
        ...initialState.searchedTenyuryoku,
        mishoninCheckbox: draft.initMishoninCheckbox,
        shoninCheckbox: draft.initShoninCheckbox,
        hininCheckbox: draft.initHininCheckbox,
        jochoMishoninCheckbox: draft.initJochoMishoninCheckbox,
        hininMikakuninCheckbox: draft.initHininMikakuninCheckbox,
        jochoHininMikakuninCheckbox: draft.initJochoHininMikakuninCheckbox,
        hininKakuninzumiCheckbox: draft.initHininkakuninzumiCheckbox,
        jochoHininKakuninzumiCheckbox: draft.initJochoHininKakuninzumiCheckbox,
      }
      draft.detailView = initialState.detailView
      draft.expand = initialState.expand
      return
    }
    // 条件完全初期化 //===========================//
    case 'SearchTenyuryokuAllFormat': {
      for (const key in draft) {
        const tempKey: keyof SearchTenyuryokuState = key as keyof SearchTenyuryokuState
        ;(draft as any)[tempKey] = initialState[tempKey]
      }
      return
    }
  }
}, initialState)
