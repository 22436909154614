import { memo, useEffect } from 'react'
import { logTable } from 'assets/TableHeader'
import M_TableCell from 'components/1_Atoms/2_Molecules/M_TableCell'
import { organismLog } from 'utils/logUtil'

const manualMode = process.env.REACT_APP_MANUAL_MODE === '1'

type Props = {
  log: any
}
const width = logTable.width

const O_TableRowLog = ({ log }: Props) => {
  useEffect(() => organismLog('O_TableRowLog'), [])

  return (
    <>
      {/* 日付 */}
      <M_TableCell
        category="data" //
        width={width.ADD_YMD}
        align={'left'}
        title={log.ADD_YMD}
        data={log.ADD_YMD}
        blackout={manualMode}
      />
      {/* 時間 */}
      <M_TableCell
        category="data" //
        width={width.ADD_HNS}
        align={'left'}
        title={log.ADD_HNS}
        data={log.ADD_HNS}
        blackout={manualMode}
      />
      {/* 登録：削除 */}
      <M_TableCell
        category="data" //
        width={width.TOROKU_KBN}
        align={'left'}
        title={log.TOROKU_KBN}
        data={log.TOROKU_KBN}
      />
      {/* 事業所CD */}
      <M_TableCell
        category="data" //
        width={width.JIGYO_CD}
        align={'left'}
        title={log.JIGYO_CD}
        data={log.JIGYO_CD}
        blackout={manualMode}
      />
      {/* 事業所名 */}
      <M_TableCell
        category="data" //
        width={width.JIGYO_NM}
        align={'left'}
        title={log.JIGYO_NM}
        data={log.JIGYO_NM}
        blackout={manualMode}
      />
      {/* 仕入先コード */}
      <M_TableCell
        category="data" //
        width={width.SIIRE_CD}
        align={'left'}
        title={log.SIIRE_CD}
        data={log.SIIRE_CD}
        blackout={manualMode}
      />
      {/* 仕入先名 */}
      <M_TableCell
        category="data" //
        width={width.SIIRE_NM}
        align={'left'}
        title={log.SIIRE_NM}
        data={log.SIIRE_NM}
        blackout={manualMode}
      />
      {/* メールアドレス */}
      <M_TableCell
        category="data" //
        width={width.MAIL}
        align={'left'}
        title={log.MAIL}
        data={log.MAIL}
        blackout={manualMode}
      />
      {/* 氏名 */}
      <M_TableCell
        category="data" //
        width={width.NAME}
        align={'left'}
        title={log.NAME}
        data={log.NAME}
        blackout={manualMode}
      />
      {/* 担当者コード */}
      <M_TableCell
        category="data" //
        width={width.TANTO_CD}
        align={'left'}
        title={log.TANTO_CD}
        data={log.TANTO_CD}
        blackout={manualMode}
      />
      {/* 担当者名 */}
      <M_TableCell
        category="data" //
        width={width.TANTO_NM}
        align={'left'}
        title={log.TANTO_NM}
        data={log.TANTO_NM}
        blackout={manualMode}
      />
      {/* 得意先CD */}
      <M_TableCell
        category="data" //
        width={width.TOKUI_CD}
        align={'left'}
        title={log.TOKUI_CD}
        data={log.TOKUI_CD}
        blackout={manualMode}
      />
      {/* 得意先名 */}
      <M_TableCell
        category="data" //
        width={width.TOKUI_NM}
        align={'left'}
        title={log.TOKUI_NM}
        data={log.TOKUI_NM}
        blackout={manualMode}
      />
    </>
  )
}

export default memo(O_TableRowLog)
