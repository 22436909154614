import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SingleValue } from 'react-select'
import * as SearchAction from 'actions/SearchActions/SearchJokenAction' // 共通部分が多いActionはActionCreaterにまとめる
import * as TableCommonAction from 'actions/TableActions/TableAction'
import * as TableAction from 'actions/TableActions/TableJokenAction'
import * as ModalFileAction from 'actions/ModalFileAction'
import * as PageAction from 'actions/PageAction'
import { selectF, voidF, inputFocusF, voidBooleanF } from 'types/FunctionTypes'
import { getRequestJoken, getRequestJokenDetail } from 'utils/apiUtil'
import { postFetcher } from 'SWRProvider'
// import { commonMsg } from 'assets/MessageCode'
import { getTimestampFromNumber } from 'utils/dateUtil'
import { convertNumber } from 'utils/numberUtil'
import { useGetIsHiddenPagination, useGetIsHiddenPaginationDetail } from './useTable'
import { HAN_KBN } from 'assets/FormatNumbers'
import { requestJokenSummary } from 'types/RequestTypes'

// 検索後にメッセージを消さないフラグ //================================//
export const useIsNotDeleteMessage = (): [boolean, voidBooleanF] => {
  const dispatch = useDispatch()
  const isNotDeleteMessage = useSelector((state) => state.SearchJokenReducer.isNotDeleteMessage)
  const changeIsNotDeleteMessage = useCallback(
    (isNotDeleteMessage: boolean) => {
      dispatch(SearchAction.changeIsDeleteMessage(isNotDeleteMessage))
    },
    [isNotDeleteMessage],
  )
  return [isNotDeleteMessage, changeIsNotDeleteMessage]
}

// 年月日 //================================//
export const useDate = (): [string, string, inputFocusF, inputFocusF] => {
  const dispatch = useDispatch()
  const TEKIYO_START_DT = useSelector((state) => state.SearchJokenReducer.searchJoken.TEKIYO_START_DT)
  const TEKIYO_END_DT = useSelector((state) => state.SearchJokenReducer.searchJoken.TEKIYO_END_DT)
  const changeStartDate = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      e.preventDefault()
      // if(e.target.valueAsNumber > TEKIYO_END_DT && TEKIYO_END_DT !== F_NUM) {
      //   dispatch(PageAction.changeMessage('開始年月日は終了年月日以前に設定してください'))
      // } else {
      dispatch(SearchAction.changeTekiyoStartDate(e.target.value))
      dispatch(TableCommonAction.changePage(0))
      // }
    },
    [TEKIYO_START_DT],
  )
  const changeEndDate = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      e.preventDefault()
      // if(e.target.valueAsNumber < TEKIYO_START_DT) {
      //   dispatch(PageAction.changeMessage('終了年月日は開始年月日以後に設定してください'))
      // } else {
      dispatch(SearchAction.changeTekiyoEndDate(e.target.value))
      dispatch(TableCommonAction.changePage(0))
      // }
    },
    [TEKIYO_END_DT],
  )
  return [getTimestampFromNumber(convertNumber(TEKIYO_START_DT, 8)), getTimestampFromNumber(convertNumber(TEKIYO_END_DT, 8)), changeStartDate, changeEndDate]
}

// 仕入先 //================================//
export const useShiiresaki = (): [string, selectF] => {
  const dispatch = useDispatch()
  const SIIRE_CD = useSelector((state) => state.SearchJokenReducer.searchJoken.SIIRE_CD)
  const changeSiire = useCallback(
    (targetValue: SingleValue<any>) => {
      dispatch(SearchAction.changeShiiresakiCode(targetValue!.value))
      dispatch(TableCommonAction.changePage(0))
    },
    [SIIRE_CD],
  )
  return [String(SIIRE_CD), changeSiire]
}

// 得意先 //================================//
export const useTokuisaki = (): [string, selectF] => {
  const dispatch = useDispatch()
  const TOKUI_CD = useSelector((state) => state.SearchJokenReducer.searchJoken.TOKUI_CD)
  const changeTokui = useCallback(
    (targetValue: SingleValue<any>) => {
      dispatch(SearchAction.changeTokuisakiCode(targetValue!.value))
      dispatch(TableCommonAction.changePage(0))
    },
    [TOKUI_CD],
  )
  return [String(TOKUI_CD), changeTokui]
}

// 事業所 //================================//
export const useJigyosho = (): [string, selectF] => {
  const dispatch = useDispatch()
  const JIGYO_CD = useSelector((state) => state.SearchJokenReducer.searchJoken.JIGYO_CD)
  const changeJigyo = useCallback(
    (targetValue: SingleValue<any>) => {
      dispatch(SearchAction.changeJigyoshoCode(targetValue!.value))
      dispatch(TableCommonAction.changePage(0))
    },
    [JIGYO_CD],
  )
  return [String(JIGYO_CD), changeJigyo]
}

// 課 //================================//
export const useBusho = (): [string, selectF] => {
  const dispatch = useDispatch()
  const BUSHO_CD = useSelector((state) => state.SearchJokenReducer.searchJoken.BUSHO_CD)
  const changeBusho = useCallback(
    (targetValue: SingleValue<any>) => {
      dispatch(SearchAction.changeBushoCode(targetValue!.value))
      dispatch(TableCommonAction.changePage(0))
    },
    [BUSHO_CD],
  )
  return [String(BUSHO_CD), changeBusho]
}

// 担当者 //================================//
export const useTanto = (): [string, selectF] => {
  const dispatch = useDispatch()
  const TANTO_CD = useSelector((state) => state.SearchJokenReducer.searchJoken.TANTO_CD)
  const changeTanto = useCallback(
    (targetValue: SingleValue<any>) => {
      dispatch(SearchAction.changeTantoCode(targetValue!.value))
      dispatch(TableCommonAction.changePage(0))
    },
    [TANTO_CD],
  )
  return [String(TANTO_CD), changeTanto]
}

// 入力担当者 //================================//
export const useAddTanto = (): [string, selectF] => {
  const dispatch = useDispatch()
  const ADD_TANTO_CD = useSelector((state) => state.SearchJokenReducer.searchJoken.ADD_TANTO_CD)
  const changeAddTanto = useCallback(
    (targetValue: SingleValue<any>) => {
      dispatch(SearchAction.changeAddTantoCode(targetValue!.value))
      dispatch(TableCommonAction.changePage(0))
    },
    [ADD_TANTO_CD],
  )
  return [String(ADD_TANTO_CD), changeAddTanto]
}

// 件名 //================================//
export const useKen = (): [string, inputFocusF] => {
  const dispatch = useDispatch()
  const KEN_NM = useSelector((state) => state.SearchJokenReducer.searchJoken.KEN_NM)
  const changeKen = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      e.preventDefault()
      dispatch(SearchAction.changeKenName(e.target.value))
      dispatch(TableCommonAction.changePage(0))
    },
    [KEN_NM],
  )
  return [KEN_NM, changeKen]
}

// 管理No //================================//
export const useKanriNo = (): [string, inputFocusF] => {
  const dispatch = useDispatch()
  const JOKEN_KANRI_NO = useSelector((state) => state.SearchJokenReducer.searchJoken.JOKEN_KANRI_NO)
  const changeKanriNo = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      e.preventDefault()
      dispatch(SearchAction.changeJokenKanriNo(e.target.value))
      dispatch(TableCommonAction.changePage(0))
    },
    [JOKEN_KANRI_NO],
  )
  return [JOKEN_KANRI_NO, changeKanriNo]
}

// 状態 //================================//
export const useShohin = (): [boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, voidF, voidF, voidF, voidF, voidF, voidF, voidF, voidF, voidF] => {
  const dispatch = useDispatch()
  const mishonin = useSelector((state) => state.SearchJokenReducer.searchJoken.mishoninCheckbox)
  const shonin = useSelector((state) => state.SearchJokenReducer.searchJoken.shoninCheckbox)
  const hinin = useSelector((state) => state.SearchJokenReducer.searchJoken.hininCheckbox)
  const jochoShonin = useSelector((state) => state.SearchJokenReducer.searchJoken.jochoShoninCheckbox)
  const jochoMishonin = useSelector((state) => state.SearchJokenReducer.searchJoken.jochoMishoninCheckbox)
  const hininMikakunin = useSelector((state) => state.SearchJokenReducer.searchJoken.hininMikakuninCheckbox)
  const jochoHininMikakunin = useSelector((state) => state.SearchJokenReducer.searchJoken.jochoHininMikakuninCheckbox)
  const hininkakuninzumi = useSelector((state) => state.SearchJokenReducer.searchJoken.hininKakuninzumiCheckbox)
  const jochoHininKakuninzumi = useSelector((state) => state.SearchJokenReducer.searchJoken.jochoHininKakuninzumiCheckbox)

  const changeMishonin = useCallback(() => {
    dispatch(SearchAction.changeMishoninCheckbox())
    dispatch(TableCommonAction.changePage(0))
  }, [])
  const changeShonin = useCallback(() => {
    dispatch(SearchAction.changeShoninCheckbox())
    dispatch(TableCommonAction.changePage(0))
  }, [])
  const changeHinin = useCallback(() => {
    dispatch(SearchAction.changeHininCheckbox())
    dispatch(TableCommonAction.changePage(0))
  }, [])
  const changeJochoMishonin = useCallback(() => {
    dispatch(SearchAction.changeJochoMishoninCheckbox())
    dispatch(TableCommonAction.changePage(0))
  }, [])
  const changeJochoShonin = useCallback(() => {
    dispatch(SearchAction.changeJochoShoninCheckbox())
    dispatch(TableCommonAction.changePage(0))
  }, [])
  const changeHininMikakunin = useCallback(() => {
    dispatch(SearchAction.changeHininMikakuninCheckbox())
    dispatch(TableCommonAction.changePage(0))
  }, [])
  const changeJochoHininMikakunin = useCallback(() => {
    dispatch(SearchAction.changeJochoHininMikakuninCheckbox())
    dispatch(TableCommonAction.changePage(0))
  }, [])
  const changeHininkakuninzumi = useCallback(() => {
    dispatch(SearchAction.changeHininkakuninzumiCheckbox())
    dispatch(TableCommonAction.changePage(0))
  }, [])
  const changeJochoHininkakuninzumi = useCallback(() => {
    dispatch(SearchAction.changeJochoHininkakuninzumiCheckbox())
    dispatch(TableCommonAction.changePage(0))
  }, [])
  return [mishonin, shonin, hinin, jochoShonin, jochoMishonin, hininMikakunin, jochoHininMikakunin, hininkakuninzumi, jochoHininKakuninzumi, changeMishonin, changeShonin, changeHinin, changeJochoShonin, changeJochoMishonin, changeHininMikakunin, changeJochoHininMikakunin, changeHininkakuninzumi, changeJochoHininkakuninzumi]
}

// ボタン項目押下処理 //================================//
export const useSearch = (): [boolean, voidF, voidF] => {
  const dispatch = useDispatch()
  const detailView = useSelector((state) => state.SearchJokenReducer.detailView)
  const loading = useSelector((state) => state.PageReducer.loading)
  const TANTO_CD = useSelector((state) => state.LoginReducer.TANTO_CD)
  const keyInfo = useSelector((state) => state.LoginReducer.keyInfo)
  const userKind = useSelector((state) => state.LoginReducer.userKind)
  const isSuperUser = useSelector((state) => state.LoginReducer.isSuperUser)
  const isJocho = useSelector((state) => state.LoginReducer.isJocho)
  const isKoiki = useSelector((state) => state.LoginReducer.isKoiki)

  // CSV登録ボタン押下処理 //================================//
  const clickInputCSV = useCallback(() => {
    if (!loading) dispatch(ModalFileAction.open())
  }, [loading])

  // 検索条件リセットボタン押下処理 //================================//
  const clickReset = useCallback(() => {
    dispatch(PageAction.format())
    dispatch(SearchAction.format())
    if (userKind === 0) {
      // ジャペルユーザーの場合
      dispatch(SearchAction.setInitCode(keyInfo[0].JIGYO_CD, TANTO_CD, isSuperUser, isJocho, isKoiki)) // 所属部署に合わせた初期値をセット
    }
    dispatch(TableAction.format())
    dispatch(TableCommonAction.format())
    dispatch({ type: 'ModalFileFormat' })
  }, [userKind])

  return [detailView, clickReset, clickInputCSV]
}

// サマリフェッチャー //================================//
export const useFetcherSummary = (): [any, () => Promise<any>] => {
  const MAIL = useSelector((state) => state.LoginReducer.MAIL)
  const keyInfo = useSelector((state) => state.LoginReducer.keyInfo)
  const TANTO_CD = useSelector((state) => state.LoginReducer.TANTO_CD)
  const searchJoken = useSelector((state) => state.SearchJokenReducer.searchJoken)
  const detailView = useSelector((state) => state.SearchJokenReducer.detailView)
  const isSuperUser = useSelector((state) => state.LoginReducer.isSuperUser)
  const isJocho = useSelector((state) => state.LoginReducer.isJocho)
  const isKoiki = useSelector((state) => state.LoginReducer.isKoiki)
  const page = useSelector((state) => state.TableReducer.page)
  const perPage = useSelector((state) => state.TableReducer.perPage)

  const isHiddenPagination = useGetIsHiddenPagination()
  // 明細時はserachJokenにすべて値が入るため検索結果は1件に絞られる。
  // そのときページ内データ（例えば50-100）のみ取得するとその範囲のデータは0件になりプルダウンが表示できなくなる可能性があるので、明細時は全ページ分取得する
  const request: requestJokenSummary = getRequestJoken(MAIL, keyInfo, TANTO_CD, isSuperUser, isJocho, isKoiki, page, perPage, detailView ? true : isHiddenPagination, HAN_KBN, searchJoken)
  return [
    searchJoken,
    postFetcher('/postJokenSummary', request),
    //   MAIL: MAIL,
    //   keyInfo: keyInfo,
    //   TANTO_CD: TANTO_CD,
    //   searchJoken: getRequestJoken(searchJoken, userKind),
    //   isSuperUser: isSuperUser,
    //   isJocho: isJocho,
    //   isKoiki: isKoiki,
    // }),
  ]
}

// 明細フェッチャー
export const useFetcherDetail = (): (() => Promise<any>) => {
  const TANTO_CD = useSelector((state) => state.LoginReducer.TANTO_CD)
  const MAIL = useSelector((state) => state.LoginReducer.MAIL)
  const JOKEN_KANRI_NO = useSelector((state) => state.TableJokenReducer.clickNo)
  const searchJoken = useSelector((state) => state.SearchJokenReducer.searchJoken)
  const page = useSelector((state) => state.TableReducer.pageDetail)
  const perPage = useSelector((state) => state.TableReducer.perPageDetail)

  const isHiddenPagination = useGetIsHiddenPaginationDetail()

  return postFetcher('/postJokenDetail', getRequestJokenDetail(page, perPage, isHiddenPagination, HAN_KBN, MAIL, JOKEN_KANRI_NO, searchJoken, TANTO_CD))
  //   ...getRequestJoken(searchJoken, userKind),
  //   JOKEN_KANRI_NO: JOKEN_KANRI_NO,
  //   MAIL: MAIL,
  // })
}

// サマリ初期描画調整 //================================//
export const useInitProcessSummary = () => {
  const dispatch = useDispatch()
  const detailView = useSelector((state) => state.SearchJokenReducer.detailView)
  const initProcess = useCallback(() => {
    dispatch(TableCommonAction.formatPageDetail())
    if (detailView) dispatch(SearchAction.closeDetail())
  }, [detailView])
  return initProcess
}

// 明細初期描画調整 //================================//
export const useInitProcessDetail = (path: string, history: any) => {
  const clickNo = useSelector((state) => state.TableJokenReducer.clickNo)
  const initProcess = useCallback(() => {
    const JOKEN_KANRI_NO = path.split('/')[path.split('/').length - 1]
    if (clickNo !== JOKEN_KANRI_NO) {
      // 管理NOリンク以外からの遷移なのでサマリに戻す
      history.push('/hanJoken')
    }
  }, [clickNo])
  return initProcess
}
