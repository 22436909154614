import React, { useEffect } from 'react'
import Button from '@material-ui/core/Button'
import SearchIcon from '@material-ui/icons/Search'
import Typography from '@material-ui/core/Typography'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import { moleculeLog } from 'utils/logUtil'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
      width: '100%',
    },
  }),
)

type Props = {
  // onClick: React.MouseEvent<HTMLDivElement, MouseEvent>
  label?: string
  icon?: string
  onClick: any
}

const M_ButtonSearch = ({ label, icon, onClick }: Props) => {
  useEffect(() => moleculeLog('M_ButtonSearch'))
  const classes = useStyles()
  return (
    <Button className={classes.button} variant="contained" color="primary" onClick={onClick} size="medium">
      <Box m={0} px={1}>
        <Typography variant="body1" display="block">
          {label}
        </Typography>
      </Box>
      {icon ? <SearchIcon /> : <></>}
    </Button>
  )
}
export default React.memo(M_ButtonSearch)
