import React, { useEffect } from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { organismLog } from 'utils/logUtil'
import O_TablePagination from './O_TablePagination'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    hit: {
      marginRight: theme.spacing(1),
      // width: 250
    },
    paddingRight0: {
      paddingRight: '0px',
    },
  }),
)

type Props = {
  count?: number
}

const O_TableBarLog = ({ count = 0 }: Props) => {
  useEffect(() => organismLog('O_TableBarLog'))
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Toolbar variant="dense" className={classes.paddingRight0}>
        <Typography className={classes.hit} color="inherit" variant="subtitle1" component="div">
          {count}件 見つかりました。
        </Typography>
        <Box className={classes.root}></Box>
        <Box>
          <O_TablePagination count={count} isDetail={false} perPageOptions={[100]} />
        </Box>
      </Toolbar>
    </div>
  )
}

export default React.memo(O_TableBarLog)
