import { getTimestampFromDate } from './dateUtil'
import ExcelJS from 'exceljs'
import { ritsuCSV, ritsuJogaiCSV } from 'types/CSVTypes'
import { getShohinKikaku } from 'utils/stringUtil'
import { responseJokenRitsu } from 'types/ResponseTypes'

/**
 * CSVファイル名を返す関数
 * @param title CSVの種類
 * @returns CSVの種類CSV_タイムスタンプ
 */
export const getCSVName = (title: string) => {
  return '【JS3】' + title + 'CSV_' + getTimestampFromDate(new Date(), 'YYYYMMDDhhmmss')
}

/**
 * CSVファイル名を返す関数
 * @param title CSVの種類
 * @returns CSVの種類CSV_タイムスタンプ
 */
export const getFileName = (title: string) => {
  return title + '_' + getTimestampFromDate(new Date(), 'YYYYMMDDhhmmss')
}

/**
 * CSV作成関数
 * @param header ヘッダー
 * @param data データ
 * @param fname ファイル名
 */
export const createCSV = async (header: any, data: any, fname: string, fixName: boolean = true) => {
  const workbook = new ExcelJS.Workbook()
  workbook.addWorksheet('sheet1')
  const worksheet = workbook.getWorksheet('sheet1')

  worksheet.columns = header
  worksheet.addRows(data)
  const uint8Array = await workbook.csv.writeBuffer() //csvの場合
  const bom = new Uint8Array([0xef, 0xbb, 0xbf]) // BOMを埋め込む
  const blob = new Blob([bom, uint8Array], { type: 'application/octet-binary' })
  const url = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = fixName ? getFileName(fname) + '.csv' : fname
  a.click()
  a.remove()
}

/**
 * 率条件CSVデータを返す関数
 * @param list 条件リスト
 * @returns CSV用データ
 */
export const convertRitsuCSV = (list: responseJokenRitsu[]): ritsuCSV[] => {
  let csvList: ritsuCSV[] = []
  list.forEach((r) => {
    csvList.push({
      delete: '', // 削除
      SYORI_YM_START: r.SYORI_YM_START, // 処理年月開始
      SYORI_YM_END: r.SYORI_YM_END, // 処理年月終了
      JIGYO_CD: r.JIGYO_CD, // 事業所コード
      JIGYO_NM: r.JIGYO_NM, // 事業所名
      TOKUI_CD: r.TOKUI_CD, // 得意先コード
      TOKUI_NM: r.TOKUI_NM, // 得意先名
      SIIRE_CD: r.SIIRE_CD, // 仕入先コード
      SIIRE_NM: r.SIIRE_NM, // 仕入先名
      INPUT_RITU: r.INPUT_RITU, // 入力率
      HASUU_SYORI: r.HASUU_SYORI, // 端数処理
      BATCH_CYCLE: r.BATCH_CYCLE, // 精算サイクル
      NAIYO_KBN1: r.NAIYO_KBN1, // 精算内容区分1
      NAIYO_KBN2: r.NAIYO_KBN2, // 精算内容区分2
      TYOKUSOU: r.TYOKUSOU, // 直送除外
      HENPIN: r.HENPIN, // 返品含む
      ADD_TANTO_CD: r.ADD_TANTO_CD, // 担当者コード
      HUKU_TOKUI: r.HUKU_TOKUI, // 複数得意先 ※JTM362から取得したものを連結
      JOGAI_ITEM: r.JOGAI_ITEM, // 除外アイテム ※JTM364から取得したものを連結
      ERROR: '', // 取込エラー内容
    })
  })
  return csvList
}

/**
 * 除外アイテムCSVデータを返す関数
 * @param list 条件リスト
 * @returns CSV用データ
 */
export const convertJogaiCSV = (list: responseJokenRitsu[]): ritsuJogaiCSV[] => {
  const csvList: ritsuJogaiCSV[] = []
  list.forEach((r) => {
    r.shohinList.forEach((s) => {
      csvList.push({
        delete: '', // 削除
        SYORI_YM_START: r.SYORI_YM_START, // 処理年月開始
        SYORI_YM_END: r.SYORI_YM_END, // 処理年月終了
        JIGYO_CD: r.JIGYO_CD, // 事業所コード
        JIGYO_NM: r.JIGYO_NM, // 事業所名
        TOKUI_CD: r.TOKUI_CD, // 得意先コード
        TOKUI_NM: r.TOKUI_NM, // 得意先名
        SIIRE_CD: r.SIIRE_CD, // 仕入先コード
        SIIRE_NM: r.SIIRE_NM, // 仕入先名
        NAIYO_KBN1: r.NAIYO_KBN1, // 精算内容区分1
        NAIYO_KBN2: r.NAIYO_KBN2, // 精算内容区分2
        ADD_TANTO_CD: r.ADD_TANTO_CD, // 担当者コード
        JOGAI_ITEM: s.SHOHIN_CD, // 除外アイテム ※JTM364から取得したものを連結
        SHOHIN_KIKAKU: getShohinKikaku(s), // 品名規格
        ERROR: '', // 取込エラー内容
      })
    })
  })
  return csvList
}
