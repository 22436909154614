import React, { useEffect } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import { getStyle, jokenRitsuTable } from 'assets/TableHeader'
import { HtmlToolTip } from 'assets/ToolTips'
import { organismLog } from 'utils/logUtil'
import M_TableCell from 'components/1_Atoms/2_Molecules/M_TableCellHead'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(() =>
  createStyles({
    table: {
      // backgroundColor: '#66FF99',
    },
  }),
)

const width = jokenRitsuTable.width
const label = jokenRitsuTable.label

type Props = {
  click: (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.MouseEvent<HTMLTableRowElement, MouseEvent>, isChecked: boolean) => void
  isChecked: boolean
}

const typoVariant = 'caption'

const O_TableHeaderJokenRitsu = ({ click, isChecked }: Props) => {
  useEffect(() => organismLog('O_TableHeaderJokeRitsu'))
  const classes = useStyles()
  const userKind = useSelector((state) => state.LoginReducer.userKind)
  const isJocho = useSelector((state) => state.LoginReducer.isJocho)
  return (
    <TableRow className={classes.table} onClick={(e) => click(e, isChecked)} hover>
      {/* No */}
      <M_TableCell category="number" data={'NO'} width={width.NO} />
      {/* チェックボックス */}
      <M_TableCell category="checkbox" isChecked={isChecked} width={width.CHECK} />
      {/* 状態 */}
      <M_TableCell category="data" width={width.JOTAI} title={label.JOTAI} data={label.JOTAI} />
      {/* 上長承認状態 */ userKind === 0 ? <M_TableCell category="data" width={width.JOCHO_JOTAI} title={label.JOCHO_JOTAI} data={label.JOCHO_JOTAI} /> : <></>}
      {/* 年月(開始) */}
      <M_TableCell category="data" width={width.STRT_DT} title={label.STRT_DT} data={label.STRT_DT} />
      {/* 年月(終了) */}
      <M_TableCell category="data" width={width.END_DT} title={label.END_DT} data={label.END_DT} />
      {/* 得意先名 */}
      <M_TableCell category="data" width={width.TOKUI_NM} title={label.TOKUI_NM} data={label.TOKUI_NM} />
      {/* 仕入先名 */}
      <M_TableCell category="data" width={width.SIIRE_NM} title={label.SIIRE_NM} data={label.SIIRE_NM} />
      <TableCell style={getStyle(width.JOGAI_ITEM, true)} align="center">
        <HtmlToolTip
          title={
            <Typography variant={typoVariant} color="inherit">
              除外アイテム
            </Typography>
          }
        >
          <Typography variant={typoVariant}>除外</Typography>
        </HtmlToolTip>
      </TableCell>
      {/* 入力率 */}
      <M_TableCell category="data" width={width.NYURYOKU} title={label.NYURYOKU} data={label.NYURYOKU} />
      {/* 端数 */}
      <M_TableCell category="data" width={width.HASU} title={label.HASU} data={label.HASU} />
      <TableCell style={getStyle(width.SEISAN_START, true)} align="center">
        <HtmlToolTip
          title={
            <Typography variant={typoVariant} color="inherit">
              精算開始年月
            </Typography>
          }
        >
          <Typography variant={typoVariant}>精算開始</Typography>
        </HtmlToolTip>
      </TableCell>
      {/* サイクル */}
      <M_TableCell category="data" width={width.CYCLE} title={label.CYCLE} data={label.CYCLE} />
      {/* 初回精算 */}
      <M_TableCell category="data" width={width.FIRST_SEISAN} title={label.FIRST_SEISAN} data={label.FIRST_SEISAN} />
      {/* 精算内容 */}
      <M_TableCell category="data" width={width.SEISAN_NAIYO} title={label.SEISAN_NAIYO} data={label.SEISAN_NAIYO} />
      <TableCell style={getStyle(width.CYOKU, true)} align="center">
        <HtmlToolTip
          title={
            <Typography variant={typoVariant} color="inherit">
              直送除外
            </Typography>
          }
        >
          <Typography variant={typoVariant}>直</Typography>
        </HtmlToolTip>
      </TableCell>
      <TableCell style={getStyle(width.HENPIN, true)} align="center">
        <HtmlToolTip
          title={
            <Typography variant={typoVariant} color="inherit">
              返品含む
            </Typography>
          }
        >
          <Typography variant={typoVariant}>返</Typography>
        </HtmlToolTip>
      </TableCell>
      {/* 入力担当者 */ userKind === 0 ? <M_TableCell category="data" width={width.ADD_TANTO_NM} title={label.ADD_TANTO_NM} data={label.ADD_TANTO_NM} /> : <></>}
      {/* 否認理由 */}
      {/* 否認理由・上長否認理由 */ isJocho ? <M_TableCell category="data" width={width.JOCHO_HININ_RIYU} title={label.JOCHO_HININ_RIYU} data={label.JOCHO_HININ_RIYU} /> : <M_TableCell category="data" width={width.HININ_RIYU} title={label.HININ_RIYU} data={label.HININ_RIYU} />}
    </TableRow>
  )
}

export default React.memo(O_TableHeaderJokenRitsu)
