import { Reducer } from 'redux'
import produce from 'immer'

export type ModalFileState = {
  openFile: boolean // ファイル取込画面を開いているかどうか
  file: any // ファイル
  fileName: string // ファイル名
  csvData: any // 出力CSV用データ(any)
  loading: boolean // ファイル取り込み中状態
  status: -1 | 1 | 0 // ステータス(-1: 処理前, 1: 成功, 0: 失敗)
  message: string // メッセージ
  messageColor: string // メッセージの色
}

const initialState: ModalFileState = {
  openFile: false, // ファイル取込画面を開いているかどうか
  file: new File([new Blob()], 'name'), // ファイル型の初期値
  fileName: '', // ファイル名
  csvData: [],
  loading: false, // ファイル取り込み中状態
  status: -1,
  message: '', // メッセージ
  messageColor: 'red', // メッセージの色
}

export type ModalFileType =
  | 'ModalFileOpen' // モーダルを開く処理
  | 'ModalFileClose' // モーダルを閉じる処理
  | 'ModalFileChangeFile' // ファイルを変数に入れる処理
  | 'ModalFileDeleteFile' // ファイルを初期化する処理
  | 'ModalFileChangeCSV' // 出力CSV用データ(any)を変更する処理
  | 'ModalFileChangeLoading' // ファイル取り込み中状態を変更する処理
  | 'ModalFileChangeMessage' // メッセージを変える処理
  | 'ModalFileChangeStatus' // ステータスを変更する処理
  | 'ModalFileFormat' // CSV取込実行画面情報を初期化する処理
  | 'AllFormat' // 全情報を初期化する処理
  | 'dummy'

export type ModalFileAction = {
  type: ModalFileType
  file?: any
  loading?: boolean
  message?: string
  status?: -1 | 1 | 0
  fileName?: string
  messageColor?: string // メッセージの色
}

export const ModalFileReducer: Reducer<ModalFileState, ModalFileAction> = produce((draft: ModalFileState, action: ModalFileAction) => {
  switch (action.type) {
    case 'ModalFileOpen': {
      draft.openFile = true
      return
    }
    case 'ModalFileClose': {
      draft.openFile = false
      draft.file = new File([new Blob()], 'name') // ファイルは初期化する
      return
    }
    case 'ModalFileChangeLoading': {
      draft.loading = action.loading!
      return
    }
    case 'ModalFileChangeStatus': {
      draft.status = action.status!
      return
    }
    case 'ModalFileChangeFile': {
      draft.file = action.file!
      draft.fileName = action.file.name!
      draft.message = initialState.message
      return
    }
    case 'ModalFileChangeCSV': {
      draft.csvData = action.file!
      return
    }
    case 'ModalFileDeleteFile': {
      draft.file = initialState.file
      // draft.fileName = initialState.fileName
      return
    }
    case 'ModalFileChangeMessage': {
      draft.message = action.message!
      draft.messageColor = action.messageColor!
      return
    }
    case 'ModalFileFormat': {
      for (const key in draft) {
        const tempKey: keyof ModalFileState = key as keyof ModalFileState
        ;(draft as any)[tempKey] = initialState[tempKey]
      }
      return
    }
  }
}, initialState)
