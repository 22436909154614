import { Reducer } from 'redux'
import produce from 'immer'
import { responseDetailTenyuryoku, responseSummaryTenyuryoku } from 'types/ResponseTypes'
import { checkListSummaryTenyuryoku, checkListDetailTenyuryoku } from 'types/SearchTypes'
import { isUniqueTenyuryokuDetail, isUniqueTenyuryokuSummary } from 'utils/booleanUtil'

export type TableTenyuryokuState = {
  checkListTenyuryoku: checkListSummaryTenyuryoku[] // 選択している処理Noを保持
  checkListDetail: checkListDetailTenyuryoku[] // 選択している明細を保持
  clickNo: string // 選択した処理Noを保持
  clickTokuiCds: number[] // 選択した処理Noの検索対象の得意先コードを保持
}

const initialState: TableTenyuryokuState = {
  checkListTenyuryoku: [], // 選択している処理Noを保持
  checkListDetail: [], // 選択している明細を保持
  clickNo: '', // 選択した処理Noを保持
  clickTokuiCds: [], // 選択した処理Noの検索対象の得意先コードを保持
}

export type TableTenyuryokuType =
  | 'TableTenyuryokuChangeJochoHininRiyu' // 上長否認理由を変更する処理
  | 'TableTenyuryokuChangeHininRiyu' // 否認理由を変更する処理
  | 'TableTenyuryokuClickDenpyo' // 処理Noを選択する処理
  | 'TableTenyuryokuAllCheckDetail' // 明細を全チェックする処理
  | 'TableTenyuryokuCheckDetail' // 明細をチェックする処理
  | 'TableTenyuryokuChangeCheckDetail' // 明細チェックリストを変更する処理
  | 'TableTenyuryokuAllCheck' // 処理Noを全チェックする処理
  | 'TableTenyuryokuCheck' // 処理Noをチェックする処理
  | 'TableTenyuryokuChangeCheck' // 処理Noチェックリストを変更する処理
  | 'TableTenyuryokuFormatCheckSummary' // サマリチェックリストを初期化する処理
  | 'TableTenyuryokuFormatCheckDetail' // 明細チェックリストを初期化する処理
  | 'TableTenyuryokuFormat'
  | 'dummy'

export type TableTenyuryokuAction = {
  type: TableTenyuryokuType
  tenyuryokuList?: responseSummaryTenyuryoku[]
  isChecked?: boolean
  denpyoNo?: string
  clickTokuiCds?: number[]
  checkData?: checkListSummaryTenyuryoku
  checkDataList?: checkListSummaryTenyuryoku[]
  checkDetail?: checkListDetailTenyuryoku
  checkListDetail?: checkListDetailTenyuryoku[]
  detailList?: responseDetailTenyuryoku[]
}

export const TableTenyuryokuReducer: Reducer<TableTenyuryokuState, TableTenyuryokuAction> = produce((draft: TableTenyuryokuState, action: TableTenyuryokuAction) => {
  switch (action.type) {
    // 上長否認理由を変更する処理 //===========================//
    case 'TableTenyuryokuChangeJochoHininRiyu': {
      const checkDetail = action.checkDetail!
      draft.checkListDetail = draft.checkListDetail.filter((r) => !isUniqueTenyuryokuDetail(r, checkDetail))
      draft.checkListDetail.push(checkDetail)
      return
    }
    // 否認理由を変更する処理 //===========================//
    case 'TableTenyuryokuChangeHininRiyu': {
      const checkDetail = action.checkDetail!
      draft.checkListDetail = draft.checkListDetail.filter((r) => !isUniqueTenyuryokuDetail(r, checkDetail))
      draft.checkListDetail.push(checkDetail)
      return
    }
    // 処理Noを選択する処理 //===========================//
    case 'TableTenyuryokuClickDenpyo': {
      draft.clickNo = action.denpyoNo!
      draft.clickTokuiCds = action.clickTokuiCds!
      draft.checkListDetail = initialState.checkListDetail
      return
    }
    // 明細を全チェックする処理 //================================//
    case 'TableTenyuryokuAllCheckDetail': {
      const isChecked = action.isChecked!
      const stateList = draft.checkListDetail
      if (isChecked) {
        // チェック除去
        const selectList: checkListDetailTenyuryoku[] = []
        for (let i = 0; i < stateList.length; i++) {
          selectList.push(stateList[i].isChecked ? { ...stateList[i], isChecked: false } : stateList[i])
        }
        draft.checkListDetail = selectList
      } else {
        // チェック付与
        const detailList = action.detailList!
        const originList: checkListDetailTenyuryoku[] = []
        for (let i = 0; i < detailList.length; i++) {
          originList.push({
            isChecked: true,
            JOTAI_KBN: detailList[i].JOTAI_KBN,
            KAKUNIN_KBN: detailList[i].KAKUNIN_KBN,
            GYO_NO: detailList[i].GYO_NO,
            HININ_RIYU: detailList[i].HININ_RIYU,
            JOCHO_HININ_RIYU: detailList[i].JOCHO_HININ_RIYU,
          })
        }
        for (let i = 0; i < stateList.length; i++) {
          for (let j = 0; j < originList.length; j++) {
            if (isUniqueTenyuryokuDetail(stateList[i], originList[j])) {
              originList.splice(j, 1)
              originList.push({ ...stateList[i], isChecked: true })
              break
            }
          }
        }
        draft.checkListDetail = originList
      }
      return
    }
    // 明細をチェックする処理 //================================//
    case 'TableTenyuryokuCheckDetail': {
      const checkDetailData = action.checkDetail!
      const isChecked = action.isChecked!
      const selectList = draft.checkListDetail.filter((r) => isUniqueTenyuryokuDetail(r, checkDetailData))
      if (selectList.length <= 0) {
        draft.checkListDetail.push({ ...checkDetailData, isChecked: !isChecked })
      } else {
        draft.checkListDetail = draft.checkListDetail.filter((r) => !isUniqueTenyuryokuDetail(r, checkDetailData))
        draft.checkListDetail.push({ ...selectList[0], isChecked: !isChecked })
      }
      return
    }
    // 明細チェックリストを変更する処理 //=========================//
    case 'TableTenyuryokuChangeCheckDetail': {
      draft.checkListDetail = action.checkListDetail!
      return
    }
    // 処理Noを全チェックする処理 //=========================//
    case 'TableTenyuryokuAllCheck': {
      const isChecked = action.isChecked!
      if (isChecked) {
        // チェック除去
        draft.checkListTenyuryoku = []
      } else {
        // チェック付与
        const selectList: checkListSummaryTenyuryoku[] = []
        action.tenyuryokuList!.forEach((r) =>
          selectList.push({
            DENPYO_NO: r.DENPYO_NO,
            JOTAI_KBN_0: r.JOTAI_KBN_0,
            JOTAI_KBN_1: r.JOTAI_KBN_1,
            JOTAI_KBN_2_1: r.JOTAI_KBN_2_1,
            TOKUI_CDS: r.TOKUI_CDS,
          }),
        )
        draft.checkListTenyuryoku = selectList
      }
      return
    }
    // 処理Noをチェックする処理 //=========================//
    case 'TableTenyuryokuCheck': {
      const checkData = action.checkData!
      const isChecked = action.isChecked!
      if (isChecked) {
        // チェック除去
        draft.checkListTenyuryoku = draft.checkListTenyuryoku.filter((r) => !isUniqueTenyuryokuSummary(r, checkData))
      } else {
        // チェック付与
        draft.checkListTenyuryoku.push(checkData)
      }
      return
    }
    // 処理Noチェックリストを変更する処理 //=========================//
    case 'TableTenyuryokuChangeCheck': {
      draft.checkListTenyuryoku = action.checkDataList!
      return
    }
    // 明細チェックリストを初期化する処理 //================================//
    case 'TableTenyuryokuFormatCheckDetail': {
      draft.checkListDetail = initialState.checkListDetail
      return
    }
    // サマリチェックリストを初期化する処理 //================================//
    case 'TableTenyuryokuFormatCheckSummary': {
      draft.checkListTenyuryoku = initialState.checkListTenyuryoku
      return
    }
    case 'TableTenyuryokuFormat': {
      for (const key in draft) {
        const tempKey: keyof TableTenyuryokuState = key as keyof TableTenyuryokuState
        ;(draft as any)[tempKey] = initialState[tempKey]
      }
      return
    }
  }
}, initialState)
