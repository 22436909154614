/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import O_TableTokuiCode from 'components/3_Organisms/O_TableTokuiCode'
import O_FooterModalTokui from 'components/3_Organisms/O_FooterModalTokui'
import { DialogActions, DialogContent } from 'assets/DialogSet'
import { organismGroupLog } from 'utils/logUtil'
import * as ModalTokuiAction from 'actions/ModalTokuiAction'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      // width: '100ch',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  })

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string
  children: React.ReactNode
  onClose: () => void
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props
  const message = useSelector((state) => state.ModalTokuiReducer.message)

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Grid container direction="row" alignItems="center">
        <Typography variant="h6">{children}</Typography>
        <Typography variant="h6">{'　　 '}</Typography>
        <Typography variant="body1">
          <b style={{ color: 'blue' }}>{message}</b>
        </Typography>
      </Grid>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const O_CodeHaritsukeTokui = () => {
  useEffect(() => organismGroupLog('O_CodeHaritsukeTokui'))
  const dispatch = useDispatch()
  // 得意先コード貼り付け画面の表示切替え
  const modalTokui = useSelector((state) => state.ModalTokuiReducer.modalTokui)
  const openTokui = modalTokui.openTokui

  const handleCodeTokui = useCallback(() => {
    dispatch(ModalTokuiAction.close())
  }, [dispatch])

  return (
    <div>
      <Dialog fullWidth={true} maxWidth="md" onClose={handleCodeTokui} aria-labelledby="customized-dialog-title" open={openTokui}>
        <DialogTitle id="customized-dialog-title" onClose={handleCodeTokui}>
          除外(得意先)照会
        </DialogTitle>
        <DialogContent dividers>
          <O_TableTokuiCode />
        </DialogContent>
        <DialogActions>
          <O_FooterModalTokui />
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default React.memo(O_CodeHaritsukeTokui)
