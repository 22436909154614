import { ModalTokuiAction } from 'reducers/ModalTokuiReducer'
import { tokuisaki } from 'types/ReferTypes'

// モーダルを開く処理 //================================//
export const open = (tokuiInfo: tokuisaki[]): ModalTokuiAction => {
  return {
    type: 'ModalTokuiOpen',
    tokuiInfo: tokuiInfo,
  }
}

// モーダルを閉じる処理 //================================//
export const close = (): ModalTokuiAction => {
  return {
    type: 'ModalTokuiClose',
  }
}

//  //================================//
export const changeMessage = (message: string): ModalTokuiAction => {
  return {
    type: 'ModalTokuiChangeMessage',
    message: message,
  }
}

// ローディング処理を変更する処理 //================================//
export const changeLoading = (loading: boolean): ModalTokuiAction => {
  return {
    type: 'ModalTokuiChangeLoading',
    loading: loading,
  }
}
// 条件初期化 //================================//
export const format = (): ModalTokuiAction => {
  return {
    type: 'ModalTokuiFormat',
  }
}
