import { TableTenyuryokuAction } from 'reducers/TableReducers/TableTenyuryokuReducer'
import { responseDetailTenyuryoku, responseSummaryTenyuryoku } from 'types/ResponseTypes'
import { checkListDetailTenyuryoku, checkListSummaryTenyuryoku } from 'types/SearchTypes'

// 上長否認理由を変更する処理 //================================//
export const changeJochoHininRiyu = (checkDetail: checkListDetailTenyuryoku): TableTenyuryokuAction => {
  return {
    type: 'TableTenyuryokuChangeJochoHininRiyu',
    checkDetail: checkDetail,
  }
}

// 否認理由を変更する処理 //================================//
export const changeHininRiyu = (checkDetail: checkListDetailTenyuryoku): TableTenyuryokuAction => {
  return {
    type: 'TableTenyuryokuChangeHininRiyu',
    checkDetail: checkDetail,
  }
}

// 明細を全チェックする処理 //================================//
export const clickDenpyo = (denpyoNo: string, clickTokuiCds: number[]): TableTenyuryokuAction => {
  return {
    type: 'TableTenyuryokuClickDenpyo',
    denpyoNo: denpyoNo,
    clickTokuiCds: clickTokuiCds,
  }
}

// 明細を全チェックする処理 //================================//
export const allCheckDetail = (isChecked: boolean, detailList: responseDetailTenyuryoku[]): TableTenyuryokuAction => {
  return {
    type: 'TableTenyuryokuAllCheckDetail',
    isChecked: isChecked,
    detailList: detailList,
  }
}

// 明細をチェックする処理 //================================//
export const checkDetail = (isChecked: boolean, checkDetail: checkListDetailTenyuryoku): TableTenyuryokuAction => {
  return {
    type: 'TableTenyuryokuCheckDetail',
    isChecked: isChecked,
    checkDetail: checkDetail,
  }
}

// 明細チェックリストを変更する処理 //================================//
export const changeCheckDetail = (checkListDetail: checkListDetailTenyuryoku[]): TableTenyuryokuAction => {
  return {
    type: 'TableTenyuryokuChangeCheckDetail',
    checkListDetail: checkListDetail,
  }
}

// 処理Noを全チェックする処理 //================================//
export const allCheck = (isChecked: boolean, jokenList: responseSummaryTenyuryoku[]): TableTenyuryokuAction => {
  return {
    type: 'TableTenyuryokuAllCheck',
    isChecked: isChecked,
    tenyuryokuList: jokenList,
  }
}

// 処理Noをチェックする処理 //================================//
export const check = (isChecked: boolean, checkData: checkListSummaryTenyuryoku): TableTenyuryokuAction => {
  return {
    type: 'TableTenyuryokuCheck',
    isChecked: isChecked,
    checkData: checkData,
  }
}

// 処理Noチェックリストを変更する処理 //================================//
export const changeCheck = (checkDataList: checkListSummaryTenyuryoku[]): TableTenyuryokuAction => {
  return {
    type: 'TableTenyuryokuChangeCheck',
    checkDataList: checkDataList,
  }
}

// 明細チェックリストを初期化する処理 //================================//
export const formatCheckDetail = (): TableTenyuryokuAction => {
  return {
    type: 'TableTenyuryokuFormatCheckDetail',
  }
}

// サマリチェックリストを初期化する処理 //================================//
export const formatCheckSummary = (): TableTenyuryokuAction => {
  return {
    type: 'TableTenyuryokuFormatCheckSummary',
  }
}

// 初期化処理 //================================//
export const format = (): TableTenyuryokuAction => {
  return {
    type: 'TableTenyuryokuFormat',
  }
}
