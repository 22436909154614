/* eslint-disable react/jsx-pascal-case */
import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import O_SearchBoxLog from 'components/3_Organisms/Groups/O_SearchBoxLog'
import O_TableLog from 'components/3_Organisms/Groups/O_TableLog'
import { templateLog } from 'utils/logUtil'
import { useInitProcess, useSearch } from 'hooks/useLog'
import { useSelector } from 'react-redux'

const C_Log = (): JSX.Element => {
  const initProcess = useInitProcess()
  useEffect(() => {
    templateLog('C_Log')
    initProcess()
  }, [])

  const initView = useSelector((state) => state.SearchLogReducer.initView)
  const page = useSelector((state) => state.TableReducer.page)
  const perPage = useSelector((state) => state.TableReducer.perPage)
  const [search] = useSearch()
  useEffect(() => {
    // ページ変更時の検索
    if (perPage === 100) search()
  }, [perPage, page])
  const data = useSelector((state) => state.TableLogReducer.logList)

  if (initView === 0) return <>Loading...</>
  return (
    <>
      <O_SearchBoxLog />
      <hr />
      <Router>
        <Switch>
          <Route path="/log" exact>
            <O_TableLog logList={data} />
          </Route>
        </Switch>
      </Router>
    </>
  )
}

export default React.memo(C_Log)
