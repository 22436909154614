import React, { useEffect } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import TableRow from '@material-ui/core/TableRow'
import { userTableDetail } from 'assets/TableHeader'
import M_TableCell from 'components/1_Atoms/2_Molecules/M_TableCellHead'
import { organismLog } from 'utils/logUtil'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
    },
    table: {
      // backgroundColor: '#66FF99',
    },
    container: {
      maxHeight: 600,
    },
  }),
)

const width = userTableDetail.width
const label = userTableDetail.label

type Props = {
  click: (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.MouseEvent<HTMLTableRowElement, MouseEvent>, isChecked: boolean) => void
  isChecked: boolean
}

const O_TableHeaderUserDetail = ({ click, isChecked }: Props) => {
  useEffect(() => organismLog('O_TableHeaderUserDetail'))
  const classes = useStyles()

  return (
    <TableRow
      hover //
      onClick={(e) => click(e, isChecked)}
      className={classes.table}
    >
      {/* No */}
      {/* <M_TableCell category="number" data={'NO'} width={width.NO} /> */}
      {/* チェックボックス */}
      <M_TableCell category="checkbox" isChecked={isChecked} width={width.CHECK} />
      {/* 得意先コード */}
      <M_TableCell category="data" width={width.TOKUI_CD} title={label.TOKUI_CD} data={label.TOKUI_CD} />
      {/* 得意先名 */}
      <M_TableCell category="data" width={width.TOKUI_NM} title={label.TOKUI_NM} data={label.TOKUI_NM} />
      {/* メールアドレス */}
      <M_TableCell category="data" width={width.MAIL} title={label.MAIL} data={label.MAIL} />
      {/* 氏名 */}
      <M_TableCell category="data" width={width.NAME} title={label.NAME} data={label.NAME} />
    </TableRow>
  )
}

export default React.memo(O_TableHeaderUserDetail)
