import { ModalFileAction } from 'reducers/ModalFileReducer'

// モーダルを開く処理 //================================//
export const open = (): ModalFileAction => {
  return {
    type: 'ModalFileOpen',
  }
}

// モーダルを閉じる処理 //================================//
export const close = (): ModalFileAction => {
  return {
    type: 'ModalFileClose',
  }
}

// ファイル取り込み中状態を変更する処理 //================================//
export const changeLoading = (loading: boolean): ModalFileAction => {
  return {
    type: 'ModalFileChangeLoading',
    loading: loading,
  }
}

// ファイルを選択する処理 //================================//
export const changeFile = (file: any): ModalFileAction => {
  return {
    type: 'ModalFileChangeFile',
    file: file,
  }
}

// ステータスを変更する処理 //================================//
export const changeStatus = (status: -1 | 0 | 1): ModalFileAction => {
  return {
    type: 'ModalFileChangeStatus',
    status: status,
  }
}

// 出力用CSVデータを変更する処理 //================================//
export const changeCSV = (file: any): ModalFileAction => {
  return {
    type: 'ModalFileChangeCSV',
    file: file,
  }
}

// ファイルを初期化する処理 //================================//
export const deleteFile = (): ModalFileAction => {
  return {
    type: 'ModalFileDeleteFile',
  }
}

//  //================================//
export const changeMessage = (message: string, messageColor: string = 'red'): ModalFileAction => {
  return {
    type: 'ModalFileChangeMessage',
    message: message,
    messageColor: messageColor,
  }
}

// CSV取込実行画面情報を初期化する処理 //================================//
export const format = (): ModalFileAction => {
  return {
    type: 'ModalFileFormat',
  }
}
