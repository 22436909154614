/* eslint-disable react/jsx-pascal-case */
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import * as LoginAction from 'actions/LoginAction' // 共通部分が多いActionはActionCreaterにまとめる
import * as PageAction from 'actions/PageAction'
import T_FormOnly from 'components/4_Templates/T_FormOnly'
import O_SearchBoxChangePassword from 'components/3_Organisms/Groups/O_SearchBoxChangePassword'

const P_ChangePassword = ({ title }: { title: string }) => {
  // ログインユーザーの種類(0: ジャペル, 1: 仕入先)
  const userKind = useSelector((state) => state.LoginReducer.userKind)
  const loginKbn = useSelector((state) => state.LoginReducer.loginKbn)
  const dispatch = useDispatch()
  const history = useHistory()
  useEffect(() => {
    // 仮ログイン状態は許可
    if (loginKbn === 0 || userKind === 0) {
      dispatch(LoginAction.format())
      history.push('/') // ログイン画面に遷移
    } else {
      dispatch(PageAction.deleteMessage())
    }
  }, [])

  return (
    <T_FormOnly>
      <O_SearchBoxChangePassword title={title} />
    </T_FormOnly>
  )
}
export default P_ChangePassword
