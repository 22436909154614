import React, { useEffect } from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import M_FieldText from 'components/1_Atoms/2_Molecules/M_FieldTextNew'
import M_FieldSelect from 'components/1_Atoms/2_Molecules/M_FieldSelectNew'
import M_ButtonSubmit from 'components/1_Atoms/2_Molecules/M_ButtonSubmit'
import { organismGroupLog } from 'utils/logUtil'
import { baseInfo } from 'types/SearchTypes'
import { useShiiresaki, useName, useMail, useDetail, useJigyosho, useJigyoList } from 'hooks/useUserTorokuForm'
import { postFetcher } from 'SWRProvider'
import { commonMsg, msgCode } from 'assets/MessageCode'
import { useQuery } from 'react-query'

const manualMode = process.env.REACT_APP_MANUAL_MODE === '1'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    box: {
      textAlign: 'left',
    },
    impotant: {
      color: '#FF0000',
    },
  }),
)

type Props = {
  info: baseInfo
}

const O_SearchBoxUser = ({ info }: Props) => {
  useEffect(() => organismGroupLog('O_SearchBoxUser'))
  const classes = useStyles()

  const jigyoList = useJigyoList(info.jigyoList)
  const [JIGYO_CD, changeJigyo] = useJigyosho()
  const [SIIRE_CD, changeSiire] = useShiiresaki()
  const [NAME, changeName] = useName()
  const [MAIL, changeMail] = useMail()
  const [detailView, clickReset] = useDetail()

  // 検索条件に表示する補足情報を取得
  const fetcher = postFetcher('/getMessage', msgCode.USER_TOROKU_MESSAGE)
  const { data, isFetching, isLoading, error } = useQuery(['postUserTorokuMessage', []], fetcher)
  const userTorokuMessage = error ? commonMsg.ERROR_MSG : isFetching || isLoading ? 'Loading...' : data.body
  // ログインユーサーの事業所コード=19の場合は事業所コード=15のデータも選択できるようにする

  const getTextOrText = (label: string, valueCode: string, change?: (e: React.ChangeEvent<HTMLInputElement>) => void, blur?: (e: React.FocusEvent<HTMLInputElement>) => void, type: string = 'text') => {
    if (detailView) return <M_FieldText label={label} valueCode={valueCode} disabled={true} type={type} />
    return <M_FieldText label={label} valueCode={valueCode} max={40} change={change} blurFunc={blur} type={type} />
  }

  return (
    <div>
      <Grid container spacing={1}>
        {/* //////////////////// 1 行目 //////////////////// */}
        <Grid item xs={12} sm={4} className={classes.root}>
          {/* ==== 事業所 ==== */}
          <M_FieldSelect //
            label="事業所"
            valueCode={JIGYO_CD}
            valueSet={jigyoList}
            event={changeJigyo}
            disabled={detailView}
            blackout={manualMode}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          {/* ==== 仕入先 ==== */}
          <M_FieldSelect //
            label="仕入先"
            valueCode={SIIRE_CD}
            valueSet={info.siireList}
            event={changeSiire}
            disabled={detailView}
            blackout={manualMode}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          {/* -------- [ 検索条件リセットボタン ] -------- */}
          <Grid container justifyContent="flex-end">
            <M_ButtonSubmit label={'検索条件リセット'} event={clickReset} disabled={detailView} />
          </Grid>
        </Grid>

        {/* //////////////////// 2 行目 //////////////////// */}
        <Grid item xs={12} sm={4} className={classes.root}>
          {/* ==== 件名 ==== */}
          {getTextOrText('氏名', NAME, undefined, changeName)}
        </Grid>
        <Grid item xs={12} sm={4}>
          {/* ==== メール ==== */}
          {getTextOrText('メール', MAIL, undefined, changeMail)}
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="subtitle2" display="block" color="error">
            {detailView ? '' : userTorokuMessage}
          </Typography>
        </Grid>
      </Grid>
    </div>
  )
}
export default React.memo(O_SearchBoxUser)
