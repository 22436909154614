import { SearchJokenAction } from 'reducers/SearchReducers/SearchJokenReducer'
import { responseSummaryJoken } from 'types/ResponseTypes'
import { fixNullValue } from 'utils/numberUtil'

// 上長否認　確認済チェックボックスを変更する処理 //================================//
export const changeJochoHininkakuninzumiCheckbox = (): SearchJokenAction => {
  return {
    type: 'SearchJokenChangeJochoHininkakuninzumiCheckbox',
  }
}

// メーカー否認　確認済チェックボックスを変更する処理 //================================//
export const changeHininkakuninzumiCheckbox = (): SearchJokenAction => {
  return {
    type: 'SearchJokenChangeHininkakuninzumiCheckbox',
  }
}

// 上長否認　未確認チェックボックスを変更する処理 //================================//
export const changeJochoHininMikakuninCheckbox = (): SearchJokenAction => {
  return {
    type: 'SearchJokenChangeJochoHininMikakuninCheckbox',
  }
}

// メーカー否認　未確認チェックボックスを変更する処理 //================================//
export const changeHininMikakuninCheckbox = (): SearchJokenAction => {
  return {
    type: 'SearchJokenChangeHininMikakuninCheckbox',
  }
}

// 上長未承認チェックボックスを変更する処理 //================================//
export const changeJochoMishoninCheckbox = (): SearchJokenAction => {
  return {
    type: 'SearchJokenChangeJochoMishoninCheckbox',
  }
}

// 上長承認チェックボックスを変更する処理 //================================//
export const changeJochoShoninCheckbox = (): SearchJokenAction => {
  return {
    type: 'SearchJokenChangeJochoShoninCheckbox',
  }
}

// 検索後にメッセージを消さないフラグを変更する処理 //===========================//
export const changeIsDeleteMessage = (isNotDeleteMessage: boolean): SearchJokenAction => {
  return {
    type: 'SearchJokenChangeIsDeleteMessage',
    isNotDeleteMessage: isNotDeleteMessage,
  }
}

// 初期状態のチェックボックスを変更する処理 //===========================//
export const changeInitCheckbox = (): SearchJokenAction => {
  return {
    type: 'SearchJokenChangeInitCheckbox',
  }
}

// 確認対象表示状態にする処理 //===========================//
export const checkKakuinTaisyo = (initJotaiKbn: string): SearchJokenAction => {
  return {
    type: 'SearchJokenChangeKakunintaisyoCheckbox',
    initJotaiKbn: initJotaiKbn,
  }
}

// 否認チェックボックスフォーム処理 //================================//
export const changeHininCheckbox = (): SearchJokenAction => {
  return {
    type: 'SearchJokenChangeHininCheckbox',
  }
}

// 承認チェックボックスフォーム処理 //================================//
export const changeShoninCheckbox = (): SearchJokenAction => {
  return {
    type: 'SearchJokenChangeShoninCheckbox',
  }
}

// 未承認チェックボックスフォーム処理 //================================//
export const changeMishoninCheckbox = (): SearchJokenAction => {
  return {
    type: 'SearchJokenChangeMishoninCheckbox',
  }
}

// 適用期間終了フォーム処理 //================================//
export const changeTekiyoEndDate = (tekiyoEndDate: string): SearchJokenAction => {
  return {
    type: 'SearchJokenChangeTekiyoEndDate',
    tekiyoEndDate: tekiyoEndDate,
  }
}

// 適用期間開始フォーム処理 //================================//
export const changeTekiyoStartDate = (tekiyoStartDate: string): SearchJokenAction => {
  return {
    type: 'SearchJokenChangeTekiyoStartDate',
    tekiyoStartDate: tekiyoStartDate,
  }
}

// 明細画面表示を変更する処理 //================================//
export const changeDetailView = (detailView: boolean): SearchJokenAction => {
  return {
    type: 'SearchJokenChangeDetailView',
    detailView: detailView,
  }
}

// 販売条件照会画面検索情報(明細表示中)を変更する処理 ※仮処理 //================================//
export const openDetail = (joken: responseSummaryJoken): SearchJokenAction => {
  return {
    type: 'SearchJokenOpenDetail',
    joken: joken,
  }
}

// 販売条件照会画面検索情報(明細表示中)を変更する処理 ※仮処理 //================================//
export const closeDetail = (): SearchJokenAction => {
  return {
    type: 'SearchJokenCloseDetail',
  }
}

// 管理Noフォーム処理 //================================//
export const changeJokenKanriNo = (jokenKanriNo: string): SearchJokenAction => {
  return {
    type: 'SearchJokenChangeJokenKanriNo',
    jokenKanriNo: jokenKanriNo,
  }
}

// 得意先フォーム処理 //================================//
export const changeTokuisakiCode = (tokuisakiCode: string): SearchJokenAction => {
  return {
    type: 'SearchJokenChangeTokuisakiCode',
    tokuisakiCode: fixNullValue(tokuisakiCode),
  }
}
export const changeTokuisakiError = (error: boolean): SearchJokenAction => {
  return {
    type: 'SearchJokenChangeTokuisakiError',
    error: error,
  }
}
export const deleteTokuisaki = (): SearchJokenAction => {
  return {
    type: 'SearchJokenDeleteTokuisaki',
  }
}

// 件名フォーム処理 //================================//
export const changeKenName = (kenName: string): SearchJokenAction => {
  return {
    type: 'SearchJokenChangeKenName',
    kenName: kenName,
  }
}

// 事業所フォーム処理 //================================//
export const changeJigyoshoCode = (jigyoshoCode: string): SearchJokenAction => {
  return {
    type: 'SearchJokenChangeJigyoshoCode',
    jigyoshoCode: fixNullValue(jigyoshoCode),
  }
}
// 初期フォームセット //================================//
export const setInitCode = (jigyoshoCode: number, tantoCode: number, isSuperUser: boolean, isJocho: boolean, isKoiki: boolean): SearchJokenAction => {
  return {
    type: 'SearchJokenChangeInitCode',
    jigyoshoCode: jigyoshoCode,
    tantoCode: tantoCode,
    isSuperUser: isSuperUser,
    isJocho: isJocho,
    isKoiki: isKoiki,
  }
}

// 課フォーム処理 //================================//
export const changeBushoCode = (bushoCode: string): SearchJokenAction => {
  return {
    type: 'SearchJokenChangeBushoCode',
    bushoCode: fixNullValue(bushoCode),
  }
}
export const changeBushoError = (error: boolean): SearchJokenAction => {
  return {
    type: 'SearchJokenChangeBushoError',
    error: error,
  }
}

// 仕入先フォーム処理 //================================//
export const changeShiiresakiCode = (shiiresakiCode: string): SearchJokenAction => {
  return {
    type: 'SearchJokenChangeShiiresakiCode',
    shiiresakiCode: fixNullValue(shiiresakiCode),
  }
}

// 担当者フォーム処理 //================================//
export const changeTantoCode = (tantoCode: string): SearchJokenAction => {
  return {
    type: 'SearchJokenChangeTantoCode',
    tantoCode: fixNullValue(tantoCode),
  }
}
export const changeTantoError = (error: boolean): SearchJokenAction => {
  return {
    type: 'SearchJokenChangeTantoError',
    error: error,
  }
}
export const deleteTanto = (): SearchJokenAction => {
  return {
    type: 'SearchJokenDeleteTantoInfo',
  }
}

// 入力担当者フォーム処理 //================================//
export const changeAddTantoCode = (tantoCode: string): SearchJokenAction => {
  return {
    type: 'SearchJokenChangeAddTantoCode',
    tantoCode: fixNullValue(tantoCode),
  }
}
export const changeAddTantoError = (error: boolean): SearchJokenAction => {
  return {
    type: 'SearchJokenChangeAddTantoError',
    error: error,
  }
}

// 状態以外の条件初期化 //===========================//
export const formatExceptJotai = (): SearchJokenAction => {
  return {
    type: 'SearchJokenFormatExceptJotai',
  }
}

// 条件初期化 //===========================//
export const format = (): SearchJokenAction => {
  return {
    type: 'SearchJokenFormat',
  }
}

// 条件完全初期化 //===========================//
export const allFormat = (): SearchJokenAction => {
  return {
    type: 'SearchJokenAllFormat',
  }
}
