/**
 * 販売条件照会CSVヘッダー
 */
export const CSVHeaderPreHanJoken = [
  { header: '承認フラグ', key: 'SYONIN_FLG' },
  { header: '否認理由', key: 'HININ_RIYU' },
  { header: '上長承認フラグ', key: 'JOCHO_SYONIN_FLG' },
  { header: '上長否認理由', key: 'JOCHO_HININ_RIYU' },
  { header: '管理NO', key: 'JOKEN_KANRI_NO' },
  { header: '事業所コード', key: 'JIGYO_CD' },
  { header: '事業所名', key: 'JIGYO_NM' },
  { header: '課コード', key: 'BUSHO_CD' },
  { header: '課名', key: 'BUSHO_NM' },
  { header: '得意先コード', key: 'TOKUI_CD' },
  { header: '得意先名', key: 'TOKUI_NM' },
  { header: '仕入先コード', key: 'SIIRE_CD' },
  { header: '仕入先名', key: 'SIIRE_NM' },
  { header: '担当者名', key: 'TANTO_NM' },
  { header: '件名', key: 'KEN_NM' },
  { header: '期間開始', key: 'TEKIYO_START_DT' },
  { header: '期間終了', key: 'TEKIYO_END_DT' },
  { header: '備考', key: 'JYOKEN_BIKO' },
  { header: 'JANコード', key: 'JAN_CD' },
  { header: 'JPコード', key: 'JP_CD' },
  { header: '品名規格', key: 'TAN_SHOHIN_KIKAKU_NM' },
  { header: '仕入単価', key: 'SIIRE_TAN' },
  { header: '個人NET', key: 'NET' },
  { header: '明細備考', key: 'MEISAI_BIKO' },
  { header: '登録日付', key: 'ADD_YMD' },
  { header: '登録時間', key: 'ADD_HNS' },
  { header: '入力担当者名', key: 'ADD_TANTO_NM' },
  { header: 'ジャペル確認済', key: 'KAKUNINZUMI' },
  { header: '取込エラー内容', key: 'TORIKOMI_ERROR_NAIYO' },
]

/**
 * 仕入先用販売条件照会CSVヘッダー
 */
export const CSVHeaderPreHanJokenSiire = [
  { header: '承認フラグ', key: 'SYONIN_FLG' },
  { header: '否認理由', key: 'HININ_RIYU' },
  { header: '管理NO', key: 'JOKEN_KANRI_NO' },
  { header: '事業所コード', key: 'JIGYO_CD' },
  { header: '事業所名', key: 'JIGYO_NM' },
  { header: '課コード', key: 'BUSHO_CD' },
  { header: '課名', key: 'BUSHO_NM' },
  { header: '得意先コード', key: 'TOKUI_CD' },
  { header: '得意先名', key: 'TOKUI_NM' },
  { header: '仕入先コード', key: 'SIIRE_CD' },
  { header: '仕入先名', key: 'SIIRE_NM' },
  { header: '担当者名', key: 'TANTO_NM' },
  { header: '件名', key: 'KEN_NM' },
  { header: '期間開始', key: 'TEKIYO_START_DT' },
  { header: '期間終了', key: 'TEKIYO_END_DT' },
  { header: '備考', key: 'JYOKEN_BIKO' },
  { header: 'JANコード', key: 'JAN_CD' },
  { header: 'JPコード', key: 'JP_CD' },
  { header: '品名規格', key: 'TAN_SHOHIN_KIKAKU_NM' },
  { header: '仕入単価', key: 'SIIRE_TAN' },
  { header: '個人NET', key: 'NET' },
  { header: '明細備考', key: 'MEISAI_BIKO' },
  { header: '登録日付', key: 'ADD_YMD' },
  { header: '登録時間', key: 'ADD_HNS' },
  { header: 'ジャペル確認済', key: 'KAKUNINZUMI' },
  { header: '取込エラー内容', key: 'TORIKOMI_ERROR_NAIYO' },
]

/**
 * 倉出条件照会CSVヘッダー
 */
export const CSVHeaderPreKuraJoken = [
  { header: '承認フラグ', key: 'SYONIN_FLG' },
  { header: '否認理由', key: 'HININ_RIYU' },
  { header: '上長承認フラグ', key: 'JOCHO_SYONIN_FLG' },
  { header: '上長否認理由', key: 'JOCHO_HININ_RIYU' },
  { header: '管理NO', key: 'JOKEN_KANRI_NO' },
  { header: '事業所コード', key: 'JIGYO_CD' },
  { header: '事業所名', key: 'JIGYO_NM' },
  { header: '課コード', key: 'BUSHO_CD' },
  { header: '課名', key: 'BUSHO_NM' },
  { header: '得意先コード', key: 'TOKUI_CD' },
  { header: '得意先名', key: 'TOKUI_NM' },
  { header: '仕入先コード', key: 'SIIRE_CD' },
  { header: '仕入先名', key: 'SIIRE_NM' },
  { header: '担当者名', key: 'TANTO_NM' },
  { header: '件名', key: 'KEN_NM' },
  { header: '期間開始', key: 'TEKIYO_START_DT' },
  { header: '期間終了', key: 'TEKIYO_END_DT' },
  { header: '備考', key: 'JYOKEN_BIKO' },
  { header: '除外(得意先)', key: 'JOGAI_TOKUI_CD' },
  { header: '除外(直送)', key: 'CYOKUSO_JOGAI' },
  { header: 'JANコード', key: 'JAN_CD' },
  { header: 'JPコード', key: 'JP_CD' },
  { header: '品名規格', key: 'TAN_SHOHIN_KIKAKU_NM' },
  { header: '仕入単価', key: 'SIIRE_TAN' },
  { header: '倉出NET', key: 'NET' },
  { header: '明細備考', key: 'MEISAI_BIKO' },
  { header: '登録日付', key: 'ADD_YMD' },
  { header: '登録時間', key: 'ADD_HNS' },
  { header: '入力担当者名', key: 'ADD_TANTO_NM' },
  { header: 'ジャペル確認済', key: 'KAKUNINZUMI' },
  { header: '取込エラー内容', key: 'TORIKOMI_ERROR_NAIYO' },
]

/**
 * 仕入先用販売条件照会CSVヘッダー
 */
export const CSVHeaderPreKuraJokenSiire = [
  { header: '承認フラグ', key: 'SYONIN_FLG' },
  { header: '否認理由', key: 'HININ_RIYU' },
  { header: '管理NO', key: 'JOKEN_KANRI_NO' },
  { header: '事業所コード', key: 'JIGYO_CD' },
  { header: '事業所名', key: 'JIGYO_NM' },
  { header: '課コード', key: 'BUSHO_CD' },
  { header: '課名', key: 'BUSHO_NM' },
  { header: '得意先コード', key: 'TOKUI_CD' },
  { header: '得意先名', key: 'TOKUI_NM' },
  { header: '仕入先コード', key: 'SIIRE_CD' },
  { header: '仕入先名', key: 'SIIRE_NM' },
  { header: '担当者名', key: 'TANTO_NM' },
  { header: '件名', key: 'KEN_NM' },
  { header: '期間開始', key: 'TEKIYO_START_DT' },
  { header: '期間終了', key: 'TEKIYO_END_DT' },
  { header: '備考', key: 'JYOKEN_BIKO' },
  { header: '除外(得意先)', key: 'JOGAI_TOKUI_CD' },
  { header: '除外(直送)', key: 'CYOKUSO_JOGAI' },
  { header: 'JANコード', key: 'JAN_CD' },
  { header: 'JPコード', key: 'JP_CD' },
  { header: '品名規格', key: 'TAN_SHOHIN_KIKAKU_NM' },
  { header: '仕入単価', key: 'SIIRE_TAN' },
  { header: '倉出NET', key: 'NET' },
  { header: '明細備考', key: 'MEISAI_BIKO' },
  { header: '登録日付', key: 'ADD_YMD' },
  { header: '登録時間', key: 'ADD_HNS' },
  { header: 'ジャペル確認済', key: 'KAKUNINZUMI' },
  { header: '取込エラー内容', key: 'TORIKOMI_ERROR_NAIYO' },
]

/**
 * 率条件照会CSVヘッダー
 */
export const CSVHeaderPreRitsuJoken = [
  { header: '承認フラグ', key: 'SYONIN_FLG' },
  { header: '否認理由', key: 'HININ_RIYU' },
  { header: '上長承認フラグ', key: 'JOCHO_SYONIN_FLG' },
  { header: '上長否認理由', key: 'JOCHO_HININ_RIYU' },
  { header: '管理NO', key: 'RITU_KANRI_NO' },
  { header: '処理年月開始', key: 'SYORI_YM_START' },
  { header: '処理年月終了', key: 'SYORI_YM_END' },
  { header: '事業所コード', key: 'JIGYO_CD' },
  { header: '事業所名', key: 'JIGYO_NM' },
  { header: '得意先コード', key: 'TOKUI_CD' },
  { header: '得意先名', key: 'TOKUI_NM' },
  { header: '仕入先コード', key: 'SIIRE_CD' },
  { header: '仕入先名', key: 'SIIRE_NM' },
  { header: '件名', key: 'KEN_NM' },
  { header: '入力率', key: 'INPUT_RITU' },
  { header: '端数処理', key: 'HASUU_SYORI_STR' },
  { header: '精算サイクル', key: 'BATCH_CYCLE' },
  { header: '直送除外', key: 'TYOKUSOU' },
  { header: '返品含む', key: 'HENPIN' },
  { header: '担当者名', key: 'TANTO_NM' },
  { header: '除外アイテム', key: 'JOGAI_ITEM' },
  { header: '備考', key: 'JYOKEN_BIKO' },
  { header: '登録日付', key: 'ADD_YMD' },
  { header: '登録時間', key: 'ADD_HNS' },
  { header: '入力担当者名', key: 'ADD_TANTO_NM' },
  { header: 'ジャペル確認済', key: 'KAKUNINZUMI' },
  { header: '取込エラー内容', key: 'TORIKOMI_ERROR_NAIYO' },
]

/**
 * 仕入先用率条件照会CSVヘッダー
 */
export const CSVHeaderPreRitsuJokenSiire = [
  { header: '承認フラグ', key: 'SYONIN_FLG' },
  { header: '否認理由', key: 'HININ_RIYU' },
  { header: '管理NO', key: 'RITU_KANRI_NO' },
  { header: '処理年月開始', key: 'SYORI_YM_START' },
  { header: '処理年月終了', key: 'SYORI_YM_END' },
  { header: '事業所コード', key: 'JIGYO_CD' },
  { header: '事業所名', key: 'JIGYO_NM' },
  { header: '得意先コード', key: 'TOKUI_CD' },
  { header: '得意先名', key: 'TOKUI_NM' },
  { header: '仕入先コード', key: 'SIIRE_CD' },
  { header: '仕入先名', key: 'SIIRE_NM' },
  { header: '件名', key: 'KEN_NM' },
  { header: '入力率', key: 'INPUT_RITU' },
  { header: '端数処理', key: 'HASUU_SYORI_STR' },
  { header: '精算サイクル', key: 'BATCH_CYCLE' },
  { header: '直送除外', key: 'TYOKUSOU' },
  { header: '返品含む', key: 'HENPIN' },
  { header: '担当者コード', key: 'ADD_TANTO_CD' },
  { header: '除外アイテム', key: 'JOGAI_ITEM' },
  { header: '備考', key: 'JYOKEN_BIKO' },
  { header: '登録日付', key: 'ADD_YMD' },
  { header: '登録時間', key: 'ADD_HNS' },
  { header: 'ジャペル確認済', key: 'KAKUNINZUMI' },
  { header: '取込エラー内容', key: 'TORIKOMI_ERROR_NAIYO' },
]

/**
 * 率条件照会除外アイテムCSVヘッダー
 */
export const CSVHeaderPreRitsuJokenJogai = [
  { header: '管理NO', key: 'KANRI_NO' },
  { header: 'JANコード', key: 'JAN_CD' },
  { header: 'JPコード', key: 'JP_CD' },
  { header: '品名規格', key: 'SHOHIN_KIKAKU' },
]

/**
 * 手入力分照会CSVヘッダー
 */
export const CSVHeaderTenyuryoku = [
  { header: '承認フラグ', key: 'SYONIN_FLG' },
  { header: '否認理由', key: 'HININ_RIYU' },
  { header: '上長承認フラグ', key: 'JOCHO_SYONIN_FLG' },
  { header: '上長否認理由', key: 'JOCHO_HININ_RIYU' },
  { header: '処理No', key: 'DENPYO_NO' },
  { header: '件名', key: 'SEISAN_NAIYO' },
  { header: '事業所コード', key: 'JIGYO_CD' },
  { header: '事業所名', key: 'JIGYO_NM' },
  { header: '仕入先コード', key: 'SIIRE_CD' },
  { header: '仕入先名', key: 'SIIRE_NM' },
  { header: '入力担当者コード', key: 'TANTO_CD' },
  { header: '入力担当者名', key: 'TANTO_NM' },
  { header: 'ファイル', key: 'existFile' },
  { header: '備考', key: 'BIKOU' },
  { header: '行No', key: 'GYO_NO' },
  { header: '得意先コード', key: 'TOKUI_CD' },
  { header: '得意先名', key: 'TOKUI_NM' },
  { header: 'JANコード', key: 'JAN_CD' },
  { header: 'JPコード', key: 'SHOHIN_CD' },
  { header: '品名規格', key: 'TAN_SHOHIN_KIKAKU_NM' },
  { header: '数量', key: 'SEISAN_SU' },
  { header: '仕入単価', key: 'SIIRE_TANKA' },
  { header: '倉入NET', key: 'KURAIRI_NET' },
  { header: '個人NET', key: 'KOJIN_NET' },
  { header: '単価', key: 'SEISAN_TANKA' },
  { header: '合計金額', key: 'SEISAN_GK' },
  { header: 'ジャペル確認済', key: 'KAKUNINZUMI' },
  { header: '取込エラー内容', key: 'TORIKOMI_ERROR_NAIYO' },
]

/**
 * 仕入先用手入力分照会CSVヘッダー
 */
export const CSVHeaderTenyuryokuSiire = [
  { header: '承認フラグ', key: 'SYONIN_FLG' },
  { header: '否認理由', key: 'HININ_RIYU' },
  { header: '処理No', key: 'DENPYO_NO' },
  { header: '件名', key: 'SEISAN_NAIYO' },
  { header: '事業所コード', key: 'JIGYO_CD' },
  { header: '事業所名', key: 'JIGYO_NM' },
  { header: '仕入先コード', key: 'SIIRE_CD' },
  { header: '仕入先名', key: 'SIIRE_NM' },
  { header: '入力担当者コード', key: 'TANTO_CD' },
  { header: '入力担当者名', key: 'TANTO_NM' },
  { header: 'ファイル', key: 'existFile' },
  { header: '備考', key: 'BIKOU' },
  { header: '行No', key: 'GYO_NO' },
  { header: '得意先コード', key: 'TOKUI_CD' },
  { header: '得意先名', key: 'TOKUI_NM' },
  { header: 'JANコード', key: 'JAN_CD' },
  { header: 'JPコード', key: 'SHOHIN_CD' },
  { header: '品名規格', key: 'TAN_SHOHIN_KIKAKU_NM' },
  { header: '数量', key: 'SEISAN_SU' },
  { header: '仕入単価', key: 'SIIRE_TANKA' },
  { header: '倉入NET', key: 'KURAIRI_NET' },
  { header: '個人NET', key: 'KOJIN_NET' },
  { header: '単価', key: 'SEISAN_TANKA' },
  { header: '合計金額', key: 'SEISAN_GK' },
  { header: 'ジャペル確認済', key: 'KAKUNINZUMI' },
  { header: '取込エラー内容', key: 'TORIKOMI_ERROR_NAIYO' },
]
