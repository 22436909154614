/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import O_TableShohinCode from 'components/3_Organisms/O_TableShohinCode'
import O_FooterModalShohin from 'components/3_Organisms/O_FooterModalShohin'
import { DialogActions, DialogContent } from 'assets/DialogSet'
import { organismGroupLog } from 'utils/logUtil'
import * as ModalShohinAction from 'actions/ModalShohinAction'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      // width: '100ch',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  })

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string
  children: React.ReactNode
  onClose: () => void
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props
  const message = useSelector((state) => state.ModalShohinReducer.message)

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Grid container direction="row" alignItems="center">
        <Typography variant="h6">{children}</Typography>
        <Typography variant="h6">{'　　 '}</Typography>
        <Typography variant="body1">
          <b style={{ color: 'blue' }}>{message}</b>
        </Typography>
      </Grid>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const O_CodeHaritsukeShohin = () => {
  useEffect(() => organismGroupLog('O_CodeHaritsukeShohin'))
  const dispatch = useDispatch()
  // 得意先コード貼り付け画面の表示切替え
  const modalShohin = useSelector((state) => state.ModalShohinReducer.modalShohin)
  const openShohin = modalShohin.openShohin

  const handleCodeShohin = useCallback(() => {
    dispatch(ModalShohinAction.close())
  }, [dispatch])

  return (
    <div>
      <Dialog fullWidth={true} maxWidth="md" onClose={handleCodeShohin} aria-labelledby="customized-dialog-title" open={openShohin}>
        <DialogTitle id="customized-dialog-title" onClose={handleCodeShohin}>
          除外アイテム照会
        </DialogTitle>
        <DialogContent dividers>
          <O_TableShohinCode />
        </DialogContent>
        <DialogActions>
          <O_FooterModalShohin />
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default React.memo(O_CodeHaritsukeShohin)
