import React, { useEffect } from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import M_FieldText from 'components/1_Atoms/2_Molecules/M_FieldTextNew'
import M_FieldDateSet from 'components/1_Atoms/2_Molecules/M_FieldDateSetNew'
import M_FieldSelect from 'components/1_Atoms/2_Molecules/M_FieldSelectNew'
import M_ButtonSubmit from 'components/1_Atoms/2_Molecules/M_ButtonSubmit'
import { organismGroupLog } from 'utils/logUtil'
import { baseInfo, s3FileInfo } from 'types/SearchTypes'
import { useSyoriYmd, useAddYmd, useNyukinYmd, useShiiresaki, useJigyosho, useTanto, useNaiyoKbn1, useNaiyoKbn2, useSeisanKoumoku, useDenpyoNo, useSeisanNaiyo, useShohin, useFile, useSearch, useExpand } from 'hooks/useTenyuryoku'
import M_FieldSelectSeisan from 'components/1_Atoms/2_Molecules/M_FieldSelectSeisan'
import M_FieldJotaiJapell from 'components/1_Atoms/2_Molecules/M_FieldJotaiJapell'
import M_TextFile from 'components/1_Atoms/2_Molecules/M_TextFile'
import Typography from '@material-ui/core/Typography'
import { postFetcher } from 'SWRProvider'
import { useQuery } from 'react-query'
import { commonMsg, msgCode } from 'assets/MessageCode'
import { Collapse } from '@material-ui/core'

const manualMode = process.env.REACT_APP_MANUAL_MODE === '1'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      // height: window.innerHeight * 0.3,
    },
    grid: {
      flexGrow: 1,
    },
    box: {
      textAlign: 'left',
    },
    impotant: {
      color: '#FF0000',
    },
  }),
)

type Props = {
  info: baseInfo
}

const O_SearchBoxTenyuryokuJapell = ({ info: info }: Props) => {
  useEffect(() => organismGroupLog('O_SearchBoxTenyuryokuJapell'))
  const classes = useStyles()
  // const dispatch = useDispatch()

  const [SYORI_YMD_FROM, SYORI_YMD_TO, changeSyoriYmdFrom, changeSyoriYmdTo] = useSyoriYmd()
  const [ADD_YMD_FROM, ADD_YMD_TO, changeAddYmdFrom, changeAddYmdTo] = useAddYmd()
  const [NYUKIN_YMD_FROM, NYUKIN_YMD_TO, changeNyukinYmdFrom, changeNyukinYmdTo] = useNyukinYmd()
  const [SIIRE_CD, changeSiire] = useShiiresaki()
  const [JIGYO_CD, changeJigyo] = useJigyosho()
  const [TANTO_CD, changeTanto] = useTanto()
  const [NAIYO_KBN1, changeNaiyoKbn1] = useNaiyoKbn1()
  const [NAIYO_KBN2, changeNaiyoKbn2] = useNaiyoKbn2()
  const [SEISAN_KOUMOKU, changeSeisanKoumoku] = useSeisanKoumoku()
  const [DENPYO_NO, changeDenpyoNo] = useDenpyoNo()
  const [SEISAN_NAIYO, changeSeisanNaiyo] = useSeisanNaiyo()
  const [mishonin, shonin, _hinin, jochoShonin, jochoMishonin, hininMikakunin, jochoHininMikakunin, hininkakuninzumi, _, changeMishonin, changeShonin, _changeHinin, changeJochoShonin, changeJochoMishonin, changeHininMikakunin, changeJochoHininMikakunin, changeHininkakuninzumi, __] = useShohin()
  const [file, _changeFile] = useFile()
  const [detailView, clickReset] = useSearch()

  // 検索条件初期化ボタンの文言を取得
  const fetcher = postFetcher('/getMessage', msgCode.JAPELL_RESET_BUTTON)
  const { data, isFetching, isLoading, error } = useQuery(['postResetButtonWordJapell', []], fetcher)
  const resetButton = error ? commonMsg.ERROR_MSG : isFetching || isLoading ? 'Loading...' : data.body

  const getTextOrText = (label: string, valueCode: string, change?: (e: React.ChangeEvent<HTMLInputElement>) => void, blur?: (e: React.FocusEvent<HTMLInputElement>) => void, type: string = 'text', sm1?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12, sm2?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12) => {
    if (detailView) return <M_FieldText label={label} valueCode={valueCode} disabled={true} type={type} sm1={sm1} sm2={sm2} />
    return <M_FieldText label={label} valueCode={valueCode} max={40} change={change} blurFunc={blur} type={type} sm1={sm1} sm2={sm2} />
  }
  const [expand] = useExpand()

  return (
    <div className={classes.root}>
      <Collapse in={expand} timeout="auto">
        <Grid container spacing={1}>
          {/* //////////////////// 1 行目 //////////////////// */}
          <Grid item xs={12} sm={4} className={classes.grid}>
            {/* ==== 処理票日付 ==== */}
            <M_FieldDateSet
              key1={1} //
              label="処理票日付"
              value1={SYORI_YMD_FROM}
              blurFunc1={changeSyoriYmdFrom}
              focus={false}
              disabled1={detailView}
              key2={2}
              value2={SYORI_YMD_TO}
              blurFunc2={changeSyoriYmdTo}
              disabled2={detailView}
              type="date"
            />
          </Grid>
          <Grid item xs={12} sm={4} className={classes.grid}>
            {/* ==== 登録日 ==== */}
            <M_FieldDateSet
              key1={1} //
              label="登録日"
              value1={ADD_YMD_FROM}
              blurFunc1={changeAddYmdFrom}
              focus={false}
              disabled1={detailView}
              key2={2}
              value2={ADD_YMD_TO}
              blurFunc2={changeAddYmdTo}
              disabled2={detailView}
              type="date"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            {/* ==== 相殺/入金日 ==== */}
            <M_FieldDateSet
              key1={1} //
              label="相殺/入金日"
              value1={NYUKIN_YMD_FROM}
              blurFunc1={changeNyukinYmdFrom}
              focus={false}
              disabled1={detailView}
              key2={2}
              value2={NYUKIN_YMD_TO}
              blurFunc2={changeNyukinYmdTo}
              disabled2={detailView}
              type="date"
            />
          </Grid>

          {/* //////////////////// 2 行目 //////////////////// */}
          <Grid item xs={12} sm={4} className={classes.grid}>
            {/* ==== 仕入先 ==== */}
            <M_FieldSelect //
              label="仕入先"
              valueCode={SIIRE_CD}
              valueSet={info.siireList}
              event={changeSiire}
              disabled={detailView}
              blackout={manualMode}
            />
          </Grid>
          <Grid item xs={12} sm={4} className={classes.grid}>
            {/* ==== 事業所 ==== */}
            <M_FieldSelect //
              label="事業所"
              valueCode={JIGYO_CD}
              valueSet={info.jigyoList}
              event={changeJigyo}
              disabled={detailView}
              blackout={manualMode}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            {/* ==== 担当者 ==== */}
            <M_FieldSelect //
              label="担当者"
              valueCode={TANTO_CD}
              valueSet={info.tantoList}
              event={changeTanto}
              disabled={detailView}
              blackout={manualMode}
            />
          </Grid>

          {/* //////////////////// 3 行目 //////////////////// */}
          <Grid item xs={12} sm={4}>
            {/* ==== 精算項目 ==== */}
            <M_FieldSelect //
              label="精算項目"
              valueCode={SEISAN_KOUMOKU}
              valueSet={info.seisanKoumokuList}
              event={changeSeisanKoumoku}
              disabled={detailView}
              blackout={manualMode}
            />
          </Grid>
          <Grid item xs={12} sm={6} className={classes.grid}>
            {/* ==== 精算内容 ==== */}
            <M_FieldSelectSeisan label={'精算内容'} valueSet1={info.naiyoKbn1List} event1={changeNaiyoKbn1} defValue1={NAIYO_KBN1} disabled1={detailView} valueSet2={info.naiyoKbn2List} defValue2={NAIYO_KBN2} disabled2={detailView} event2={changeNaiyoKbn2} valueSet3={[]} defValue3={''} event3={() => {}} sm1={2} sm2_1={4} sm2_2={6} />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Grid container justifyContent="flex-end">
              {detailView ? null : <M_ButtonSubmit label={resetButton} event={clickReset} disabled={detailView} />}
            </Grid>
          </Grid>

          {/* //////////////////// 4 行目 //////////////////// */}
          <Grid item xs={12} sm={4} className={classes.grid}>
            {/* ==== 処理No ==== */}
            {getTextOrText('処理No', DENPYO_NO, undefined, changeDenpyoNo, 'number')}
          </Grid>
          <Grid item xs={12} sm={6}>
            {/* ==== 件名 ==== */}
            {getTextOrText('件名', SEISAN_NAIYO, undefined, changeSeisanNaiyo, 'text', 2, 10)}
          </Grid>
          <Grid item xs={12} sm={2}></Grid>

          {/* //////////////////// 5 行目 //////////////////// */}
          <Grid item xs={12} sm={8} className={classes.grid}>
            {/* ==== 状態 ==== */}
            <M_FieldJotaiJapell
              label1={'仕入先承認状態'} //
              label2={'上長承認状態'} //
              check1={mishonin}
              check2={shonin}
              check3={hininMikakunin}
              check4={hininkakuninzumi}
              check5={jochoMishonin}
              check6={jochoHininMikakunin}
              // check7={jochoHininKakuninzumi}
              check8={jochoShonin}
              event1={changeMishonin}
              event2={changeShonin}
              event3={changeHininMikakunin}
              event4={changeHininkakuninzumi}
              event5={changeJochoMishonin}
              event6={changeJochoHininMikakunin}
              // event7={changeJochoHininkakuninzumi}
              event8={changeJochoShonin}
              disabled={detailView}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            {detailView ? (
              <>
                <Typography variant="body1" display="block" gutterBottom>
                  {'添付ファイル'}
                </Typography>
                <Grid container justifyContent="flex-start">
                  {file.map((r: s3FileInfo) => {
                    return <M_TextFile file={r} DENPYO_NO={DENPYO_NO} delDisabled={true} />
                  })}
                </Grid>
              </>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Collapse>
    </div>
  )
}
export default React.memo(O_SearchBoxTenyuryokuJapell)
