/**
 * 数値が0以上かどうか判定する関数
 * @param num
 * @returns
 */
export const exist = (num: number) => {
  if (typeof num !== 'number') return false
  return num && num >= 0
}

export const notExist = (num: number) => {
  return !num || num == null || num < 0
}

/**
 * オブジェクト同士の比較を行う関数
 * @param a オブジェクトA
 * @param b オブジェクトB
 * @returns 比較結果
 */
export const compareObj = (a: object, b: object) => {
  return JSON.stringify(a) === JSON.stringify(b)
}

/**
 * オブジェクト配列同士の比較を行う関数
 * @param a オブジェクト配列A
 * @param b オブジェクト配列B
 * @returns 比較結果
 */
export const compareObjArray = (a: object[], b: object[]) => {
  if (a.length !== b.length) return false
  for (let i = 0; i < a.length; i++) {
    if (!compareObj(a[i], b[i])) return false
  }
  return true
}

/**
 * 販売条件画面チェック判定関数
 */
// type jokenKey = { [K in 'JOKEN_KANRI_NO' | 'ADD_YMD' | 'ADD_HNS']: number | string }
type jokenKey = { [K in 'JOKEN_KANRI_NO' | 'TOKUI_CD']: number | string }
export const isUniqueJoken = (obj1: jokenKey, obj2: jokenKey) => {
  // return obj1.JOKEN_KANRI_NO === obj2.JOKEN_KANRI_NO && obj1.ADD_YMD === obj2.ADD_YMD && obj1.ADD_HNS === obj2.ADD_HNS
  return obj1.JOKEN_KANRI_NO === obj2.JOKEN_KANRI_NO && obj1.TOKUI_CD === obj2.TOKUI_CD
}

/**
 * 販売条件明細画面チェック判定関数
 */
type jokenDetailKey = { [K in 'ADD_YMD' | 'ADD_HNS' | 'JP_CD' | 'NET']: number | string }
export const isUniqueJokenDetail = (obj1: jokenDetailKey, obj2: jokenDetailKey) => {
  return obj1.ADD_YMD === obj2.ADD_YMD && obj1.ADD_HNS === obj2.ADD_HNS && obj1.JP_CD === obj2.JP_CD && obj1.NET === obj2.NET
}

/**
 * 倉出条件画面チェック判定関数
 */
// type jokenKey = { [K in 'JOKEN_KANRI_NO' | 'ADD_YMD' | 'ADD_HNS']: number | string }
type jokenKuraKey = { [K in 'JOKEN_KANRI_NO' | 'TOKUI_CD']: number | string }
export const isUniqueJokenKura = (obj1: jokenKuraKey, obj2: jokenKuraKey) => {
  // return obj1.JOKEN_KANRI_NO === obj2.JOKEN_KANRI_NO && obj1.ADD_YMD === obj2.ADD_YMD && obj1.ADD_HNS === obj2.ADD_HNS
  return obj1.JOKEN_KANRI_NO === obj2.JOKEN_KANRI_NO && obj1.TOKUI_CD === obj2.TOKUI_CD
}

/**
 * 販売条件明細画面チェック判定関数
 */
type jokenKuraDetailKey = { [K in 'ADD_YMD' | 'ADD_HNS' | 'JP_CD' | 'NET']: number | string }
export const isUniqueJokenKuraDetail = (obj1: jokenKuraDetailKey, obj2: jokenKuraDetailKey) => {
  return obj1.ADD_YMD === obj2.ADD_YMD && obj1.ADD_HNS === obj2.ADD_HNS && obj1.JP_CD === obj2.JP_CD && obj1.NET === obj2.NET
}

/**
 * 率条件画面チェック判定関数
 */
type jokenRitsuDetailKey = { [K in 'RITU_KANRI_NO' | 'TOKUI_CD' | 'ADD_YMD' | 'ADD_HNS']: number | string }
export const isUniqueJokenRitsuDetail = (obj1: jokenRitsuDetailKey, obj2: jokenRitsuDetailKey) => {
  return obj1.RITU_KANRI_NO === obj2.RITU_KANRI_NO && obj1.TOKUI_CD === obj2.TOKUI_CD && obj1.ADD_YMD === obj2.ADD_YMD && obj1.ADD_HNS === obj2.ADD_HNS
}

/**
 * ユーザー登録サマリ画面チェック判定関数
 */
type userSummaryKey = { [K in 'JIGYO_CD' | 'SIIRE_CD']: number }
export const isUniqueUserSummary = (obj1: userSummaryKey, obj2: userSummaryKey) => {
  return obj1.JIGYO_CD === obj2.JIGYO_CD && obj1.SIIRE_CD === obj2.SIIRE_CD
}

/**
 * ユーザー登録画面チェック判定関数
 */
type userKey = { [K in 'JIGYO_CD' | 'SIIRE_CD' | 'TOKUI_CD']: number }
export const isUniqueUser = (obj1: userKey, obj2: userKey) => {
  return obj1.JIGYO_CD === obj2.JIGYO_CD && obj1.SIIRE_CD === obj2.SIIRE_CD && obj1.TOKUI_CD === obj2.TOKUI_CD
}

/**
 * 手入力処理分サマリ画面チェック判定関数
 */
type tenyuryokuSummaryKey = { [K in 'DENPYO_NO']: number | string }
export const isUniqueTenyuryokuSummary = (obj1: tenyuryokuSummaryKey, obj2: tenyuryokuSummaryKey) => {
  return obj1.DENPYO_NO === obj2.DENPYO_NO
}

/**
 * 手入力処理分明細画面チェック判定関数
 */
type tenyuryokuDetailKey = { [K in 'GYO_NO']: number | string }
export const isUniqueTenyuryokuDetail = (obj1: tenyuryokuDetailKey, obj2: tenyuryokuDetailKey) => {
  return obj1.GYO_NO === obj2.GYO_NO
}

/**
 * 関西のユーザーかどうか
 * @param JIGYO_CD
 * @returns
 */
export const isKansaiJigyoUser = (JIGYO_CD: number) => {
  return JIGYO_CD === 19
}

/**
 * 名古屋のユーザーかどうか
 * @param JIGYO_CD
 * @returns
 */
export const isNagoyaJigyoUser = (JIGYO_CD: number) => {
  return JIGYO_CD === 1
}

/**
 * 上長かつ特殊なユーザー（関西、名古屋）かどうか
 * @param JIGYO_CD
 * @returns
 */
export const isJochoSpecialUser = (JIGYO_CD: number) => {
  const JIGYO_CDS = [1, 19]
  return JIGYO_CDS.some((r) => {
    return r === JIGYO_CD
  })
}
