import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import M_ButtonNormal from 'components/1_Atoms/2_Molecules/M_ButtonNormal'
import M_ButtonDisable from 'components/1_Atoms/2_Molecules/M_ButtonDisable'
import Grid from '@material-ui/core/Grid'
import * as PageAction from 'actions/PageAction'
import { organismLog } from 'utils/logUtil'
import { searchMessage } from 'utils/apiUtil'
import { msgCode } from 'assets/MessageCode'
import { voidF } from 'types/FunctionTypes'

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
})

type Props = {
  status: 0 | 1 | -1
  func: (file: File, param: number) => Promise<void>
  func2: voidF
  close: voidF
}

const O_FooterModalFile = ({ status, func = async () => {}, func2, close }: Props) => {
  useEffect(() => organismLog('O_FooterModalFile'))
  const classes = useStyles()
  const dispatch = useDispatch()
  const file = useSelector((state) => state.ModalFileReducer.file)

  const execute = async () => {
    if (!file || file.size === 0) return
    let err = false
    const msg = await searchMessage(msgCode.CONFIRM_UPLOAD, () => {
      err = true
    })
    dispatch(PageAction.openAlert(msg, (file?: File) => func(file!, 0), err))
  }

  return (
    <div className={classes.root}>
      <Grid container justify="flex-start" spacing={1}>
        <Grid item xs={12} sm={1}>
          {!file || file.size === 0 ? <M_ButtonDisable label={'実行'} /> : <M_ButtonNormal label={'実行'} event={execute} />}
        </Grid>
        <Grid item xs={12} sm={2}>
          {status === 1 ? <M_ButtonNormal label={'取込CSV出力'} event={func2} /> : <M_ButtonDisable label={'取込CSV出力'} />}
        </Grid>
        <Grid item xs={12} sm={9}>
          <Grid container justify="flex-end">
            <M_ButtonNormal label={'閉じる'} event={close} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
export default React.memo(O_FooterModalFile)
