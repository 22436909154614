// !!!!!!! バックエンドにも同様のファイルがあるので併せて更新すること !!!!!!!

/**
 * マスタから取得するメッセージコード一覧
 * ※コメントのメッセージは変わる可能性があります。
 */
export const msgCode = {
  ERROR_CD: 0, // エラーが発生しました。もう一度検索してください
  LOGIN_ERROR_CD: 1, // ログインに失敗しました
  JIGYO_ERROR_CD: 2, // 事業所が見つかりませんでした
  SIIRE_ERROR_CD: 3, // 仕入先が見つかりませんでした
  SHOHIN_ERROR_CD: 4, // 商品が見つかりませんでした
  TANTO_ERROR_CD: 5, // 担当者が見つかりませんでした
  TOKUI_ERROR_CD: 6, // 得意先が見つかりませんでした
  BUSHO_ERROR_CD: 7, // 課が見つかりませんでした
  MUST_ERROR_CD: 8, // 検索条件を入力してください
  ZERO_DATA_ERROR_CD: 9, // 対象データが見つかりませんでした
  MUST_CHECK_CD: 10, // 必須条件を入力してください
  NENGETU_CHECK_CD: 11, // 年月を入力してください
  FROMTO_CHECK_CD: 12, // 開始日付を終了日付以前に設定してください
  JIGYO_CHECK_CD: 13, // 事業所を入力してください
  TOKUI_CHECK_CD: 14, // 得意先を入力してください
  SHOHIN_CHECK_CD: 15, // 商品を入力してください
  OUTUNIT_CHECK_CD: 16, // 出力単位を選択してください
  KOSHINDT_CHECK_CD: 17, // 更新日付を入力してください
  CHECKBOX_CHECK_CD: 18, // チェックボックスを入力してください
  REQUEST_OVER_CD: 19, // 1000件以内にしてください。
  BUSHO_CHECK_CD: 20, // 課を入力してください。
  FILE_UPLOAD_CD: 50, // ファイルが取り込まれました
  FILE_UPLOAD_ERROR_CD: 51, // ファイルのアップロードに失敗しました
  OUTPUT_CD: 52, // を出力しました
  OUTPUT_SIIRE_TAN_CD: 53, // を出力しました。 ※仕入単価は画面年月から取得しています。
  CONFIRM_UPLOAD: 54, // CSVを取り込みます。よろしいですか？
  CONFIRM_KOSHIN: 55, // 更新処理を行います。よろしいですか？
  COMPLETE_KOSHIN: 56, // 販売更新が完了しました。
  LOCKED_ERROR_CD: 59, // 他の方が処理を行っています。しばらくして再度実行してください。
  FILE_GET_ERROR_CD: 60, // ファイルが取得できませんでした。再度ファイルを選択してください。
  FILE_SIZE_ERROR_CD: 61, // ファイルに大きすぎるデータが含まれています。
  FILE_FORMAT_ERROR_CD: 62, // ファイルのフォーマットが異なります。
  JAPELL_RESET_BUTTON: 126, // 確認対象表示　※
  SHIIRESAKI_RESET_BUTTON: 127, // 検索条件リセット　※
  USER_TOROKU_MESSAGE: 168, //緑色は精算金が発生したことのある仕入先です。 ※
}

/**
 * フロントに持たせる共通メッセージ(サーバーが落ちてる場合に出すメッセージ)
 */
export const commonMsg = {
  ERROR_MSG: 'エラーが発生しました。再度処理を行ってください。',
  ERROR_CSV_MSG: 'エラーが発生しました。再度処理を行ってください。',
  ERROR_LOGIN_MSG: 'エラーが発生しました。再ログインしてください。',
  ERROR_KOSHIN_MSG: 'エラーが発生しました。再度処理を行ってください。',
  ERROR_JOTAI: '状態はどれか選択してください',
  ERROR_CSV_UPLOAD: 'CSVの取り込みに失敗しました。再度CSVをアップロードしてください。',
}
