/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useEffect, useState } from 'react'
import { TableVirtuoso } from 'react-virtuoso'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import O_TableBarTenyuryokuSummary from 'components/3_Organisms/O_TableBarTenyuryokuSummary'
import O_TableRowTenyuryokuSummary from 'components/3_Organisms/O_TableRowTenyuryokuSummary'
import O_TableHeaderTenyuryokuSummary from 'components/3_Organisms/O_TableHeaderTenyuryokuSummary'
import O_FileUpload from 'components/3_Organisms/Groups/O_FileUpload'
import { useButtonsSummary, useSearchedFormatSummary, useCheckListSummary } from 'hooks/useTenyuryokuTable'
import { useExpand } from 'hooks/useTenyuryoku'
import { usePage } from 'hooks/useTable'
import { useGetIsHiddenPagination } from 'hooks/useTenyuryokuTable'
import { useLoading } from 'hooks/usePage'
import { organismGroupLog } from 'utils/logUtil'
import { isUniqueTenyuryokuSummary } from 'utils/booleanUtil'
import { responseSummaryTenyuryoku } from 'types/ResponseTypes'
import { voidF } from 'types/FunctionTypes'
import { useChangeTableHeightNew, useFormatDetail } from 'hooks/useCommon'
import { useLocation } from 'react-router-dom'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
    },
    container: {
      // maxHeight: 600,
    },
  }),
)

type Props = {
  tenyuryokuList: responseSummaryTenyuryoku[]
  allCount: number
  refetch: voidF
  isFetching: boolean
}

const O_TableTenyuryokuSummary = ({ tenyuryokuList, allCount, refetch, isFetching }: Props) => {
  const [loding, changeLoading] = useLoading()
  useEffect(() => {
    organismGroupLog('O_TableTenyuryokuSummary')
  })
  useEffect(() => {
    if (isFetching && !loding) {
      changeLoading(true)
    }
    if (!isFetching && loding) {
      changeLoading(false)
    }
  }, [isFetching])
  const location = useLocation()
  const [formatDetail] = useFormatDetail(location.pathname)
  //検索ボックス開閉状態の取得
  const [expand, changeExpand] = useExpand()
  useEffect(() => {
    formatDetail()
    // 初期表示は検索条件表示。リロードのためここで設定
    changeExpand(true)
  }, [])
  const classes = useStyles()
  const [checkListTenyuryoku, allCheck] = useCheckListSummary(tenyuryokuList)
  const [page, perPage] = usePage()
  const isHiddenPagination = useGetIsHiddenPagination()
  const [checkedList, clickCSV, clickShonin, clickKakunin, clickJochoShonin] = useButtonsSummary(refetch, tenyuryokuList.length, allCount)
  const searchedFormat = useSearchedFormatSummary()
  const list = tenyuryokuList

  const isAllChecked = tenyuryokuList.length === 0 ? false : tenyuryokuList.length === checkListTenyuryoku.length
  const [tableHeight, setTableHeight] = useState(268)
  const [changeTableHeight, getNewTableHeight] = useChangeTableHeightNew(tableHeight, setTableHeight)

  useEffect(() => {
    // ウィンドウサイズ変更時にchangeTableHeightを呼び出すようにリスナーに登録
    window.removeEventListener('resize', changeTableHeight)
    window.addEventListener('resize', changeTableHeight)
    return () => {
      window.removeEventListener('resize', changeTableHeight)
    }
  }, [tableHeight])

  useEffect(() => {
    // 描画時にテーブルの高さを調整
    // 条件開閉したときアニメーションでレイアウトが変わるので、tableHeightの値が変わらなくなるまで0.2秒間隔で処理を実行
    let oldTableHeight = -1
    if (!isFetching) {
      // フェッチ中はテーブルを表示しないので、フェッチが終わったときにテーブルの高さを設定
      const timerId = setInterval(() => {
        if (oldTableHeight !== tableHeight) {
          const nowTableHeight = getNewTableHeight()
          if (oldTableHeight === nowTableHeight) {
            changeTableHeight()
            clearInterval(timerId)
          } else {
            oldTableHeight = getNewTableHeight()
          }
        } else {
          oldTableHeight = getNewTableHeight()
        }
      }, 200)
    }
  }, [expand, isFetching])

  const getChecked = (tenyuryoku: responseSummaryTenyuryoku) => (isAllChecked ? true : checkListTenyuryoku.length <= 0 ? false : checkListTenyuryoku.some((r) => isUniqueTenyuryokuSummary(r, tenyuryoku)))
  const table = (props: any) => <Table {...props} style={{ borderCollapse: 'separate' }} size="small" aria-label="purchases" />
  const tableHead = (props: any) => <TableHead {...props} id={'headerElement'} />
  const tableRow = (props: any) => <TableRow hover role="checkbox" {...props} selected={getChecked(props.item)} />
  const getComponent = useCallback(() => {
    return {
      Scroller: TableContainer,
      // Scroller: React.forwardRef((props, ref) => <TableContainer component={Paper} {...props} className={classes.container} ref={ref} />),
      Table: table,
      TableHead: tableHead,
      TableRow: tableRow,
      // TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
      TableBody: TableBody,
    }
  }, [checkListTenyuryoku.length])

  if (isFetching) {
    searchedFormat()
    return <div>検索中...</div>
  }
  const getTableBar = () => {
    return (
      <O_TableBarTenyuryokuSummary
        count={allCount} //
        checkedList={checkedList}
        clickShohin={clickShonin}
        clickKakuninzumi={clickKakunin}
        clickCSV={clickCSV}
        clickJochoShonin={clickJochoShonin}
      />
    )
  }
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item style={{ width: '100%' }}>
          {getTableBar()}
        </Grid>
        <Grid item style={{ width: '100%' }}>
          <TableVirtuoso
            style={{ height: tableHeight }} // 仮想リスト(表示範囲250以降はスクロール毎に描画する)
            data={list}
            components={getComponent()}
            fixedHeaderContent={() => <O_TableHeaderTenyuryokuSummary click={allCheck} isChecked={isAllChecked} />}
            itemContent={(index, tenyuryoku) => {
              const no = isHiddenPagination ? index + 1 : index + page * perPage + 1
              const isChecked = getChecked(tenyuryoku)
              return <O_TableRowTenyuryokuSummary tenyuryoku={tenyuryoku} isChecked={isChecked} no={no} />
            }}
          />
        </Grid>
        <Grid item style={{ width: '100%' }} id={'toolbarElement'}>
          {getTableBar()}
        </Grid>
      </Grid>
      {/* ファイル取込画面のモーダル */}
      <O_FileUpload INP_KBN={3} refetch={refetch} />
      <div style={{ visibility: 'hidden', position: 'fixed', bottom: 0, height: '1.5em' }} id={'bottomElement'}></div>
    </div>
  )
}

export default React.memo(O_TableTenyuryokuSummary)
