import React, { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { responseJokenRitsu, responseJokenRitsuJogaiItem } from 'types/ResponseTypes'
import { getTimestampFromNumber } from 'utils/dateUtil'
import { jokenRitsuTable } from 'assets/TableHeader'
import * as ModalShohinAction from 'actions/ModalShohinAction'
import * as PageAction from 'actions/PageAction'
import M_TableCell from 'components/1_Atoms/2_Molecules/M_TableCell'
import { displayPercent } from 'utils/numberUtil'
import { organismLog } from 'utils/logUtil'
import * as TableAction from 'actions/TableActions/TableJokenRitsuAction'
import { checkListRitsu } from 'types/SearchTypes'
import { requestRitsuJogaiItem } from 'types/RequestTypes'
import { search } from 'utils/apiUtil'

const width = jokenRitsuTable.width
const manualMode = process.env.REACT_APP_MANUAL_MODE === '1'

type Props = {
  joken: responseJokenRitsu
  isChecked: boolean
  HININ_RIYU: string
  no: number
}

const getFirstSeisan = (ym: number, cycle: number) => {
  const year = Math.floor(ym / 100)
  let month = (ym % 100) + cycle
  month = month > 12 ? month - Math.floor(month / 12) * 12 : month
  return getTimestampFromNumber(year * 100 + month, '/')
}

const O_TableRowJokenRitsu = ({ joken, isChecked, HININ_RIYU, no }: Props) => {
  useEffect(() => organismLog('O_TableRowJokenRitsu'))
  const dispatch = useDispatch()
  const userKind = useSelector((state) => state.LoginReducer.userKind)
  const isJocho = useSelector((state) => state.LoginReducer.isJocho)

  //除外アイテム押下処理 //================================//
  const onClickJogaiItem = useCallback(async (RITU_KANRI_NO: string) => {
    return await search<requestRitsuJogaiItem, responseJokenRitsuJogaiItem>(
      '/postJokenRitsuJogaiItem',
      { RITU_KANRI_NO: RITU_KANRI_NO },
      (res) => {
        if (res.error) dispatch(PageAction.changeMessage(res.viewMsg))
        else {
          dispatch(ModalShohinAction.open(res.body))
        }
      },
      () => dispatch(PageAction.changeErrorMessage()),
      () => dispatch(PageAction.changeLoading(false)),
    )
  }, [])

  // チェック処理 //================================//
  const handleRowClick = useCallback((isChecked: boolean, joken: responseJokenRitsu) => {
    const data: checkListRitsu = {
      isChecked: !isChecked,
      RITU_KANRI_NO: joken.RITU_KANRI_NO,
      JOTAI_KBN: joken.JOTAI_KBN,
      KAKUNIN_KBN: joken.KAKUNIN_KBN,
      ADD_YMD: joken.ADD_YMD,
      ADD_HNS: joken.ADD_HNS,
      TOKUI_CD: joken.TOKUI_CD,
      HININ_RIYU: joken.HININ_RIYU,
      JOCHO_HININ_RIYU: joken.JOCHO_HININ_RIYU,
    }
    dispatch(TableAction.check(isChecked, data))
  }, [])

  // 否認理由フォーム処理 //================================//
  const onChangeHininRiyu = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault()
      dispatch(
        TableAction.changeHininRiyu({
          isChecked: true,
          RITU_KANRI_NO: joken.RITU_KANRI_NO,
          JOTAI_KBN: joken.JOTAI_KBN,
          KAKUNIN_KBN: joken.KAKUNIN_KBN,
          ADD_YMD: joken.ADD_YMD,
          ADD_HNS: joken.ADD_HNS,
          TOKUI_CD: joken.TOKUI_CD,
          HININ_RIYU: e.target.value,
          JOCHO_HININ_RIYU: joken.JOCHO_HININ_RIYU,
        }),
      )
    },
    [HININ_RIYU],
  )

  // 上長否認理由フォーム処理 //================================//
  const onChangeJochoHininRiyu = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault()
      dispatch(
        TableAction.changeJochoHininRiyu({
          isChecked: true,
          RITU_KANRI_NO: joken.RITU_KANRI_NO,
          JOTAI_KBN: joken.JOTAI_KBN,
          KAKUNIN_KBN: joken.KAKUNIN_KBN,
          ADD_YMD: joken.ADD_YMD,
          ADD_HNS: joken.ADD_HNS,
          TOKUI_CD: joken.TOKUI_CD,
          HININ_RIYU: joken.HININ_RIYU,
          JOCHO_HININ_RIYU: e.target.value,
        }),
      )
    },
    [HININ_RIYU],
  )

  return (
    <>
      {/* No */}
      <M_TableCell category="number" data={no} width={width.NO} />
      {/* <M_TableCell category="data" data={no} width={width.NO} title={joken.RITU_KANRI_NO} /> */}
      {/* チェックボックス */}
      <M_TableCell category="checkbox" isChecked={isChecked} width={width.CHECK} click={() => handleRowClick(isChecked, joken)} />
      {/* 状態 */}
      <M_TableCell
        category="data" //
        width={width.JOTAI}
        align={'left'}
        title={joken.JOTAI}
        data={joken.JOTAI}
      />
      {
        /* 上長承認状態 */
        userKind === 0 ? (
          <M_TableCell
            category="data" //
            width={width.JOCHO_JOTAI}
            align={'left'}
            title={!isJocho && joken.JOCHO_KBN === 2 ? joken.JOCHO_HININ_RIYU : joken.JOCHO_JOTAI}
            data={joken.JOCHO_JOTAI}
          />
        ) : (
          <></>
        )
      }
      {/* 適用期間(開始) */}
      <M_TableCell
        category="data" //
        width={width.STRT_DT}
        align={'left'}
        title={getTimestampFromNumber(joken.SYORI_YM_START, '/')}
        data={getTimestampFromNumber(joken.SYORI_YM_START, '/')}
        blackout={manualMode}
      />
      {/* 適用期間(終了) */}
      <M_TableCell
        category="data" //
        width={width.END_DT}
        align={'left'}
        title={getTimestampFromNumber(joken.SYORI_YM_END, '/')}
        data={getTimestampFromNumber(joken.SYORI_YM_END, '/')}
        blackout={manualMode}
      />
      {/* 得意先名 */}
      <M_TableCell
        category="data" //
        width={width.TOKUI_NM}
        align={'left'}
        title={joken.TOKUI_NM}
        data={joken.TOKUI_NM}
        blackout={manualMode}
      />
      {/* 仕入先名 */}
      <M_TableCell
        category="data" //
        width={width.SIIRE_NM}
        align={'left'}
        title={joken.SIIRE_NM}
        data={joken.SIIRE_NM}
        blackout={manualMode}
      />
      {/* 除外アイテム */}
      <M_TableCell
        category="data" //
        width={width.JOGAI_ITEM}
        align={'right'}
        title={joken.JOGAI_ITEM}
        data={
          <b>
            <u>{joken.JOGAI_ITEM}</u>
          </b>
        }
        click={() => onClickJogaiItem(joken.RITU_KANRI_NO)}
        blackout={manualMode}
      />
      {/* 入力率 */}
      <M_TableCell
        category="data" //
        width={width.NYURYOKU}
        align={'right'}
        title={displayPercent(joken.INPUT_RITU)}
        data={displayPercent(joken.INPUT_RITU)}
        blackout={manualMode}
      />
      {/* 端数 */}
      <M_TableCell
        category="data" //
        width={width.HASU}
        align={'left'}
        title={joken.HASUU_SYORI_STR}
        data={joken.HASUU_SYORI_STR}
        blackout={manualMode}
      />
      {/* 精算開始 */}
      <M_TableCell
        category="data" //
        width={width.SEISAN_START}
        align={'left'}
        title={getTimestampFromNumber(joken.BATCH_YM, '/')}
        data={getTimestampFromNumber(joken.BATCH_YM, '/')}
        blackout={manualMode}
      />
      {/* サイクル */}
      <M_TableCell
        category="data" //
        align={'left'}
        width={width.CYCLE}
        title={joken.BATCH_CYCLE_STR}
        data={joken.BATCH_CYCLE_STR}
        blackout={manualMode}
      />
      {/* 初回精算年月 */}
      <M_TableCell
        category="data" //
        width={width.FIRST_SEISAN}
        align={'left'}
        title={getFirstSeisan(joken.BATCH_YM, joken.BATCH_CYCLE)}
        data={getFirstSeisan(joken.BATCH_YM, joken.BATCH_CYCLE)}
        blackout={manualMode}
      />
      {/* 精算内容 */}
      <M_TableCell
        category="data" //
        width={width.SEISAN_NAIYO}
        align={'left'}
        title={joken.NAIYO}
        data={joken.NAIYO}
        blackout={manualMode}
      />
      {/* 直送除外 */}
      <M_TableCell
        category="checkbox" //
        width={width.CYOKU}
        isChecked={joken.TYOKUSOU === 1}
        disabled={true}
      />
      {/* 返品含む */}
      <M_TableCell
        category="checkbox" //
        width={width.HENPIN}
        isChecked={joken.HENPIN === 1}
        disabled={true}
      />
      {
        /* 入力担当者 */
        userKind === 0 ? (
          <M_TableCell
            category="data" //
            width={width.ADD_TANTO_NM}
            align={'left'}
            title={joken.ADD_TANTO_NM}
            data={joken.ADD_TANTO_NM}
            blackout={manualMode}
          />
        ) : (
          <></>
        )
      }
      {
        /* 否認理由・上長否認理由 */
        isJocho ? (
          <M_TableCell
            category="form" //
            width={width.JOCHO_HININ_RIYU}
            align={'left'}
            title={HININ_RIYU}
            data={HININ_RIYU}
            change={onChangeJochoHininRiyu}
            disabled={!isChecked}
            blackout={manualMode}
          />
        ) : (
          <M_TableCell
            category="form" //
            width={width.HININ_RIYU}
            align={'left'}
            title={HININ_RIYU}
            data={HININ_RIYU}
            change={onChangeHininRiyu}
            disabled={userKind === 0 ? true : !isChecked}
            blackout={manualMode}
          />
        )
      }
    </>
  )
}

export default React.memo(O_TableRowJokenRitsu)
