import { TableJokenKuraAction } from 'reducers/TableReducers/TableJokenKuraReducer'
import { responseDetailJoken, responseSummaryJokenKura } from 'types/ResponseTypes'
import { checkListDetail, checkListSummary } from 'types/SearchTypes'

// 上長否認理由を変更する処理 //================================//
export const changeJochoHininRiyu = (checkShohin: checkListDetail): TableJokenKuraAction => {
  return {
    type: 'TableJokenKuraChangeJochoHininRiyu',
    checkShohin: checkShohin,
  }
}

// 条件を選択する処理 //===========================//
export const clickJoken = (kanriNo: string): TableJokenKuraAction => {
  return {
    type: 'TableJokenKuraClickJoken',
    kanriNo: kanriNo,
  }
}

// 条件管理番号を全チェックする処理 //================================//
export const allCheckJoken = (isChecked: boolean, jokenList: responseSummaryJokenKura[]): TableJokenKuraAction => {
  return {
    type: 'TableJokenKuraAllCheckJoken',
    isChecked: isChecked,
    jokenList: jokenList,
  }
}

// 条件管理番号をチェックする処理 //================================//
export const checkJoken = (isChecked: boolean, checkData: checkListSummary): TableJokenKuraAction => {
  return {
    type: 'TableJokenKuraCheckJoken',
    isChecked: isChecked,
    checkData: checkData,
  }
}

// 条件管理番号チェックリストを変更する処理 //================================//
export const changeCheckJoken = (checkDataList: checkListSummary[]): TableJokenKuraAction => {
  return {
    type: 'TableJokenKuraChangeCheckJoken',
    checkDataList: checkDataList,
  }
}

// 明細を全チェックする処理 //================================//
export const allCheckShohin = (isChecked: boolean, detailList: responseDetailJoken[]): TableJokenKuraAction => {
  return {
    type: 'TableJokenKuraAllCheckShohin',
    isChecked: isChecked,
    detailList: detailList,
  }
}

// 明細をチェックする処理 //================================//
export const checkDetail = (isChecked: boolean, checkDetail: checkListDetail): TableJokenKuraAction => {
  return {
    type: 'TableJokenKuraCheckShohin',
    isChecked: isChecked,
    checkShohin: checkDetail,
  }
}

// 否認理由を変更する処理 //================================//
export const changeHininRiyu = (checkShohin: checkListDetail): TableJokenKuraAction => {
  return {
    type: 'TableJokenKuraChangeHininRiyu',
    checkShohin: checkShohin,
  }
}

// 明細チェックリストを変更する処理 //================================//
export const changeCheckJokenDetail = (checkListDetail: checkListDetail[]): TableJokenKuraAction => {
  return {
    type: 'TableJokenKuraChangeCheckJokenDetail',
    checkListDetail: checkListDetail,
  }
}

// サマリチェックリストを初期化する処理 //================================//
export const formatCheckSummary = (): TableJokenKuraAction => {
  return {
    type: 'TableJokenKuraFormatCheckSummary',
  }
}

// 明細チェックリストを初期化する処理 //================================//
export const formatCheckDetail = (): TableJokenKuraAction => {
  return {
    type: 'TableJokenKuraFormatCheckDetail',
  }
}

// 初期化処理 //================================//
export const format = (): TableJokenKuraAction => {
  return {
    type: 'TableJokenKuraFormat',
  }
}
