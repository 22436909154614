/* eslint-disable react/jsx-pascal-case */
import { useEffect } from 'react'
import T_FormOnly from 'components/4_Templates/T_FormOnly'
import O_SearchBoxLogin from 'components/3_Organisms/Groups/O_SearchBoxLogin'
import { useInitAllReducer } from 'hooks/useCommon'

const P_Login = ({ title }: { title: string }) => {
  const [initAllReducer] = useInitAllReducer()
  useEffect(() => {
    initAllReducer()
  }, [])

  return (
    <T_FormOnly>
      <O_SearchBoxLogin title={title} />
    </T_FormOnly>
  )
}
export default P_Login
