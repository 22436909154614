import { Reducer } from 'redux'
import produce from 'immer'
import { responseLog } from 'types/ResponseTypes'

export type TableLogState = {
  logList: responseLog[]
  allCount: number
  searchLoading: boolean
}

const initialState: TableLogState = {
  logList: [],
  allCount: 0,
  searchLoading: false,
}

export type TableLogType =
  | 'TableLogChangeLog' // ログリストを変更する処理
  | 'TableLogChangeAllCount' // 表示ページ外のデータも含めた件数を変更する処理
  | 'TableLogChangeSearchLogding' // 検索中フラグを変更する処理
  | 'TableLogFormat' // すべて初期化する処理
  | 'dummy'

export type TableLogAction = {
  type: TableLogType
  logList?: responseLog[]
  allCount?: number
  searchLoading?: boolean
}

export const TableLogReducer: Reducer<TableLogState, TableLogAction> = produce((draft: TableLogState, action: TableLogAction) => {
  switch (action.type) {
    // 検索中フラグを変更する処理 //===========================//
    case 'TableLogChangeSearchLogding': {
      draft.searchLoading = action.searchLoading!
      return
    }
    // 表示ページ外のデータも含めた件数を変更する処理 //===========================//
    case 'TableLogChangeAllCount': {
      draft.allCount = action.allCount!
      return
    }
    // ログリストを変更する処理 //===========================//
    case 'TableLogChangeLog': {
      draft.logList = action.logList!
      return
    }
    // すべて初期化する処理
    case 'TableLogFormat': {
      for (const key in draft) {
        const tempKey: keyof TableLogState = key as keyof TableLogState
        ;(draft as any)[tempKey] = initialState[tempKey]
      }
      return
    }
  }
}, initialState)
