/* eslint-disable react/jsx-pascal-case */
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import * as LoginAction from 'actions/LoginAction' // 共通部分が多いActionはActionCreaterにまとめる
import T_FormOnly from 'components/4_Templates/T_FormOnly'
import O_SearchBoxCreatePassword from 'components/3_Organisms/Groups/O_SearchBoxCreatePassword'

const P_CreatePassword = ({ title }: { title: string }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(LoginAction.format())
  }, [])

  return (
    <T_FormOnly>
      <O_SearchBoxCreatePassword title={title} />
    </T_FormOnly>
  )
}
export default P_CreatePassword
