import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import { TableContainer } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { jogaiItemTable } from 'assets/TableHeader'
import M_TableCell from 'components/1_Atoms/2_Molecules/M_TableCellBig'
import { numToStr } from 'utils/stringUtil'
import { organismLog } from 'utils/logUtil'
import { responseJokenRitsuJogaiItem } from 'types/ResponseTypes'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(1),
    },
    table: {
      backgroundColor: '#00FFFF',
    },
    container: {
      maxHeight: 415,
    },
  }),
)

const width = jogaiItemTable.width
const label = jogaiItemTable.label

const O_TableShohinCode = () => {
  useEffect(() => organismLog('O_TableShohinCode'))
  const classes = useStyles()
  const modalShohin = useSelector((state) => state.ModalShohinReducer.modalShohin)
  const shohinInfo = modalShohin.shohinInfo

  return (
    <TableContainer className={classes.container}>
      <Table stickyHeader size="small" aria-label="customized table">
        <TableHead>
          <TableRow hover role="checkbox" tabIndex={-1} className={classes.table}>
            <M_TableCell category="data" header={true} align={'left'} width={width.JAN_CD} title={label.JAN_CD} data={label.JAN_CD} />
            <M_TableCell category="data" header={true} align={'left'} width={width.JP_CD} title={label.JP_CD} data={label.JP_CD} />
            <M_TableCell category="data" header={true} align={'left'} width={width.SHOHIN_KIKAKU} title={label.SHOHIN_KIKAKU} data={label.SHOHIN_KIKAKU} />
          </TableRow>
        </TableHead>
        <TableBody>
          {shohinInfo.map((shohin: responseJokenRitsuJogaiItem, index) => {
            const isChecked = true
            const SHOHIN_KIKAKU = shohin.SHOHIN_KIKAKU
            return (
              <TableRow hover role="checkbox" aria-checked={isChecked} tabIndex={-1} key={index} selected={isChecked}>
                <M_TableCell category="data" align={'left'} width={width.JAN_CD} title={shohin.JAN_CD} data={shohin.JAN_CD} />
                <M_TableCell category="data" align={'left'} width={width.JP_CD} title={numToStr(shohin.JP_CD)} data={numToStr(shohin.JP_CD)} />
                <M_TableCell category="data" alert={SHOHIN_KIKAKU === ' ' ? 'ALERT' : ''} align={'left'} width={width.JP_CD} title={SHOHIN_KIKAKU} data={SHOHIN_KIKAKU} />
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default React.memo(O_TableShohinCode)
