/* eslint-disable react/jsx-pascal-case */
import React, { useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import A_IconMust from '../A_IconMust'
import { moleculeLog } from 'utils/logUtil'

type Props = {
  label?: string
  key1?: number
  tab1?: number
  type?: string
  must?: boolean
  value1?: number | string
  min1?: string
  max1?: string
  disabled1?: boolean
  focus?: boolean
  change1?: (e: React.ChangeEvent<HTMLInputElement>) => void
  register1: {
    onKeyDown({ key }: { key: string }): void
    ref(element: HTMLInputElement | null): void
  }
  key2?: number
  tab2?: number
  value2?: number | string
  min2?: string
  max2?: string
  disabled2?: boolean
  change2?: (e: React.ChangeEvent<HTMLInputElement>) => void
  register2: {
    onKeyDown({ key }: { key: string }): void
    ref(element: HTMLInputElement | null): void
  }
}

// const WIDTH = 140
const WIDTH = '100%'
// const HEIGHT = '27px' // 入力フォームの高さ

const M_FieldDateTimeSetNew = ({
  label = '日時', //
  key1 = 0,
  tab1 = -1,
  type = 'datetime-local',
  must = false,
  value1,
  min1 = type === 'date' ? '1000-01-01' : '1000-01-01T00:00',
  max1 = type === 'date' ? '9999-12-31' : '9999-12-31T23:59',
  disabled1 = false,
  change1 = undefined,
  focus,
  register1,
  key2 = 1,
  tab2 = -1,
  value2,
  min2 = type === 'date' ? '1000-01-01' : '1000-01-01T00:00',
  max2 = type === 'date' ? '9999-12-31' : '9999-12-31T23:59',
  disabled2 = false,
  change2 = undefined,
  register2,
}: Props) => {
  useEffect(() => {
    moleculeLog('M_FieldDateSetNew')
  })
  return (
    <Grid container justify="flex-start">
      <Grid item xs={12} sm={2}>
        <Typography variant="body1" display="block" gutterBottom>
          {label}
          <A_IconMust must={must} />
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Grid container justify="flex-start">
          <input
            key={key1} //
            tabIndex={tab1}
            type={type}
            style={{ width: WIDTH }}
            // placeholder={type === 'month' ? 'yyyy/mm' : 'yyyy/mm/dd'}
            defaultValue={value1}
            min={min1}
            max={max1}
            onChange={change1}
            autoFocus={focus}
            {...register1}
            onBlur={(e) => {
              if (!e.target.checkValidity() && change1) {
                e.target.value = ''
                change1!(e)
              }
            }}
            disabled={disabled1}
          />
        </Grid>
      </Grid>
      <Typography variant="body1" display="block" gutterBottom>
        <b style={{ padding: 5 }}>{'～'}</b>
      </Typography>
      <Grid item xs={12} sm={4}>
        <Grid container justify="flex-start">
          <input
            key={key2} //
            tabIndex={tab2}
            type={type}
            style={{ width: WIDTH }}
            // placeholder={type === 'month' ? 'yyyy/mm' : 'yyyy/mm/dd'}
            defaultValue={value2}
            min={min2}
            max={max2}
            onChange={change2}
            autoFocus={focus}
            {...register2}
            onBlur={(e) => {
              if (!e.target.checkValidity() && change2) {
                e.target.value = ''
                change2!(e)
              }
            }}
            disabled={disabled2}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
export default React.memo(M_FieldDateTimeSetNew)
