import React, { useEffect } from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import M_FieldText from 'components/1_Atoms/2_Molecules/M_FieldTextNew'
import M_FieldDateSet from 'components/1_Atoms/2_Molecules/M_FieldDateSetNew'
import M_FieldSelect from 'components/1_Atoms/2_Molecules/M_FieldSelectNew'
import M_ButtonSubmit from 'components/1_Atoms/2_Molecules/M_ButtonSubmit'
import M_FieldJotai from 'components/1_Atoms/2_Molecules/M_FieldJotai'
import { organismGroupLog } from 'utils/logUtil'
import { baseInfo } from 'types/SearchTypes'
import { useBusho, useDate, useJigyosho, useKanriNo, useKen, useSearch, useShiiresaki, useShohin, useTanto, useTokuisaki } from 'hooks/useHanJoken'
import { postFetcher } from 'SWRProvider'
import { useQuery } from 'react-query'
import { commonMsg, msgCode } from 'assets/MessageCode'

const manualMode = process.env.REACT_APP_MANUAL_MODE === '1'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      // height: window.innerHeight * 0.3,
    },
    grid: {
      flexGrow: 1,
    },
    box: {
      textAlign: 'left',
    },
    impotant: {
      color: '#FF0000',
    },
  }),
)

type Props = {
  info: baseInfo
}

const O_SearchBox = ({ info }: Props) => {
  useEffect(() => organismGroupLog('O_SearchBoxShiiresaki'))
  const classes = useStyles()

  const [TEKIYO_START_DT, TEKIYO_END_DT, changeStartDt, changeEndDt] = useDate()
  const [SIIRE_CD, changeSiire] = useShiiresaki()
  const [TOKUI_CD, changeTokui] = useTokuisaki()
  const [JIGYO_CD, changeJigyo] = useJigyosho()
  const [BUSHO_CD, changeBusho] = useBusho()
  const [TANTO_CD, changeTanto] = useTanto()
  const [KEN_NM, changeKen] = useKen()
  const [JOKEN_KANRI_NO, changeKanriNo] = useKanriNo()
  const [mishonin, shonin, hinin, _JochoShonin, _jochoMishonin, _hininMikakunin, _jochoHininMikakunin, _hininkakuninzumi, _jochoHininKakuninzumi, changeMishonin, changeShonin, changeHinin, _changeJochoShonin, _changeJochoMishonin, _changeHininMikakunin, _changeJochoHininMikakunin, _changeHininkakuninzumi, _changeJochoHininkakuninzumi] = useShohin()
  const [detailView, clickReset, clickInputCSV] = useSearch()

  // 検索条件初期化ボタンの文言を取得
  const fetcher = postFetcher('/getMessage', msgCode.SHIIRESAKI_RESET_BUTTON)
  const { data, isFetching, isLoading, error } = useQuery(['postResetButtonWordShiiresaki', []], fetcher)
  const resetButton = error ? commonMsg.ERROR_MSG : isFetching || isLoading ? 'Loading...' : data.body

  const getTextOrText = (label: string, valueCode: string, change?: (e: React.ChangeEvent<HTMLInputElement>) => void, blur?: (e: React.FocusEvent<HTMLInputElement>) => void, type: string = 'text') => {
    if (detailView) return <M_FieldText label={label} valueCode={valueCode} disabled={true} type={type} />
    return <M_FieldText label={label} valueCode={valueCode} max={40} change={change} blurFunc={blur} type={type} />
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        {/* //////////////////// 1 行目 //////////////////// */}
        <Grid item xs={12} sm={4} className={classes.grid}>
          {/* ==== 年月 ==== */}
          <M_FieldDateSet
            label="年月日"
            key1={1} //
            value1={TEKIYO_START_DT}
            blurFunc1={changeStartDt}
            focus={false}
            min1={'1000-01-01'}
            max1={'9999-12-31'}
            disabled1={detailView}
            key2={2}
            value2={TEKIYO_END_DT}
            blurFunc2={changeEndDt}
            min2={'1000-01-01'}
            max2={'9999-12-31'}
            disabled2={detailView}
            type={'date'}
          />
        </Grid>
        <Grid item xs={12} sm={4} className={classes.grid}>
          {/* ==== 仕入先 ==== */}
          <M_FieldSelect //
            label="仕入先"
            valueCode={SIIRE_CD}
            valueSet={info.siireList}
            event={changeSiire}
            disabled={detailView}
            blackout={manualMode}
          />
        </Grid>
        <Grid item xs={12} sm={4}></Grid>

        {/* //////////////////// 2 行目 //////////////////// */}
        <Grid item xs={12} sm={4} className={classes.grid}>
          {/* ==== 得意先 ==== */}
          <M_FieldSelect //
            label="得意先"
            valueCode={TOKUI_CD}
            valueSet={info.tokuiList}
            event={changeTokui}
            disabled={detailView}
            blackout={manualMode}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          {/* ==== 事業所 ==== */}
          <M_FieldSelect //
            label="事業所"
            valueCode={JIGYO_CD}
            valueSet={info.jigyoList}
            event={changeJigyo}
            disabled={detailView}
            blackout={manualMode}
          />
        </Grid>
        <Grid item xs={12} sm={4}></Grid>

        {/* //////////////////// 3 行目 //////////////////// */}
        <Grid item xs={12} sm={4} className={classes.grid}>
          {/* ==== 課 ==== */}
          <M_FieldSelect //
            label="課"
            valueCode={BUSHO_CD}
            valueSet={info.bushoList}
            event={changeBusho}
            disabled={detailView}
            blackout={manualMode}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          {/* ==== 担当者 ==== */}
          <M_FieldSelect //
            label="担当者"
            valueCode={TANTO_CD}
            valueSet={info.tantoList}
            event={changeTanto}
            disabled={detailView}
            blackout={manualMode}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          {/* -------- [ CSV登録ボタン ] -------- */}
          <Grid container justifyContent="flex-end">
            <M_ButtonSubmit label={'CSV登録'} event={clickInputCSV} />
          </Grid>
        </Grid>

        {/* //////////////////// 4 行目 //////////////////// */}
        <Grid item xs={12} sm={4} className={classes.grid}>
          {/* ==== 入力担当者 ==== */}
          {/* ==== 件名 ==== */}
          {getTextOrText('件名', KEN_NM, undefined, changeKen)}
        </Grid>
        <Grid item xs={12} sm={4}>
          {/* ==== 管理No ==== */}
          {getTextOrText('管理No', JOKEN_KANRI_NO, undefined, changeKanriNo, 'number')}
        </Grid>
        <Grid item xs={12} sm={4}>
          {/* -------- [ 検索条件リセットボタン ] -------- */}
          <Grid container justifyContent="flex-end">
            {detailView ? null : <M_ButtonSubmit label={resetButton} event={clickReset} disabled={detailView} />}
          </Grid>
        </Grid>

        {/* //////////////////// 5 行目 //////////////////// */}
        <Grid item xs={12} sm={4} className={classes.grid}>
          {/* ==== 状態 ==== */}
          <M_FieldJotai
            label={'状態'} //
            check1={mishonin}
            check2={shonin}
            check3={hinin}
            event1={changeMishonin}
            event2={changeShonin}
            event3={changeHinin}
            disabled={detailView}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="subtitle2" display="block" color="error">
            {'背景色グレーは当日作業分以外なので変更できません'}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}></Grid>
      </Grid>
    </div>
  )
}
export default React.memo(O_SearchBox)
