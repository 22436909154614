import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { clickF, inputF, inputQueryF, voidF } from 'types/FunctionTypes'
import * as ModalUserAction from 'actions/ModalUserAction'
import * as PageAction from 'actions/PageAction'
import { tokuisaki, user } from 'types/ReferTypes'
import { postInfo, search2 } from 'utils/apiUtil'
import { convertNumber } from 'utils/numberUtil'
import { requestUserInsert } from 'types/RequestTypes'
import { commonMsg } from 'assets/MessageCode'

/**
 * 項目変更/取得処理
 * @param param0
 * @returns
 */
export const useInput = (): [inputF, inputQueryF, inputF, inputQueryF] => {
  const dispatch = useDispatch()
  const loading = useSelector((state) => state.ModalUserReducer.loading)
  const modalUser = useSelector((state) => state.ModalUserReducer.modalUser)
  const changeErrMsg = () => dispatch(ModalUserAction.changeMessage(commonMsg.ERROR_MSG)) //
  const loaded = () => dispatch(ModalUserAction.changeLoading(false)) //

  // 得意先コード入力処理 //================================//
  const changeTokuiCd = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault()
      if (!loading) {
        dispatch(ModalUserAction.changeTokuiCode(convertNumber(e.target.valueAsNumber, 5)))
      }
    },
    [modalUser, loading],
  )

  // 得意先コード決定処理 //================================//
  const submitTokui = useCallback(
    async (e: React.KeyboardEvent<HTMLInputElement>) => {
      const key = e.key
      switch (key) {
        case 'Enter':
        case 'Tab':
          if (!loading) {
            dispatch(ModalUserAction.changeLoading(true))
            dispatch(ModalUserAction.deleteMessage())
            await postInfo<tokuisaki>(
              '/postTokuisakiInfo',
              { TOKUI_CD: modalUser.TOKUI_CD },
              (r) => {
                dispatch(ModalUserAction.changeTokuiName(r.TOKUI_NM))
                dispatch(ModalUserAction.changeLoading(false))
              },
              changeErrMsg,
              loaded,
            )
          }
          break
      }
    },
    [modalUser],
  )

  // メールアドレス入力処理 //================================//
  const changeMail = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault()
      if (!loading) {
        dispatch(ModalUserAction.changeMail(e.target.value.toLowerCase()))
      }
    },
    [modalUser, loading],
  )

  // メールアドレス決定処理 //================================//
  const submitMail = useCallback(
    async (e: React.KeyboardEvent<HTMLInputElement>) => {
      const key = e.key
      switch (key) {
        case 'Enter':
        case 'Tab':
          if (!loading) {
            dispatch(ModalUserAction.changeLoading(true))
            dispatch(ModalUserAction.deleteMessage())
            await postInfo<user>(
              '/postUserInfo',
              { MAIL: modalUser.MAIL },
              (r) => {
                dispatch(ModalUserAction.changeName(r.NAME ?? ''))
                dispatch(ModalUserAction.changeLoading(false))
              },
              changeErrMsg,
              loaded,
            )
          }
          break
      }
    },
    [modalUser],
  )

  return [changeTokuiCd, submitTokui, changeMail, submitMail]
}

/**
 * ボタン押下処理
 * @param param0
 * @returns
 */
export const useButtons = (refetch: voidF): [clickF, voidF] => {
  const dispatch = useDispatch()
  const loading = useSelector((state) => state.ModalUserReducer.loading)
  const modalUser = useSelector((state) => state.ModalUserReducer.modalUser)
  const TANTO_CD = useSelector((state) => state.LoginReducer.TANTO_CD)
  const request = {
    TANTO_CD: TANTO_CD,
    JIGYO_CD: modalUser.JIGYO_CD,
    SIIRE_CD: modalUser.SIIRE_CD,
    TOKUI_CD: modalUser.TOKUI_CD,
    INP_MAIL: modalUser.MAIL,
  }

  // 追加ボタン押下処理 //================================//
  const clickInsert = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault()
      if (!loading) {
        dispatch(PageAction.deleteMessage())
        dispatch(ModalUserAction.deleteMessage())
        // ローディング開始
        dispatch(ModalUserAction.changeLoading(true))
        await search2<requestUserInsert, any>(
          '/postJokenTokuiDetailInsertBulk',
          request,
          (res) => {
            if (res.error) dispatch(ModalUserAction.changeMessage(res.viewMsg))
            else {
              dispatch(PageAction.changeMessage(res.viewMsg, 'blue'))
              dispatch(ModalUserAction.close())
              refetch()
            }
          },
          () => dispatch(ModalUserAction.changeMessage(commonMsg.ERROR_MSG)),
          () => dispatch(ModalUserAction.changeLoading(false)),
        )
      }
    },
    [request, refetch],
  )

  // 閉じるボタン押下処理 //================================//
  const clickClose = useCallback(() => {
    if (!loading) {
      dispatch(PageAction.deleteMessage())
      dispatch(ModalUserAction.close())
      refetch()
    }
  }, [refetch])

  return [clickInsert, clickClose]
}
