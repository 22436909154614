import React, { useCallback, useEffect } from 'react'
import TableCell from '@material-ui/core/TableCell'
import { HtmlToolTip } from 'assets/ToolTips'
import { getStyle } from 'assets/TableHeader'
import Typography from '@material-ui/core/Typography'
import { responseSummaryJokenKura } from 'types/ResponseTypes'
import { getTimestampFromNumber } from 'utils/dateUtil'
import { jokenKuraTableSummary } from 'assets/TableHeader'
import M_TableCell from 'components/1_Atoms/2_Molecules/M_TableCell'
import { organismLog } from 'utils/logUtil'
import { useDispatch, useSelector } from 'react-redux'
import * as ModalTokuiAction from 'actions/ModalTokuiAction'
import * as TableAction from 'actions/TableActions/TableJokenKuraAction'
import * as SearchAction from 'actions/SearchActions/SearchJokenKuraAction' // 共通部分が多いActionはActionCreaterにまとめる
import * as PageAction from 'actions/PageAction'
import { Link } from 'react-router-dom'
import { checkListSummary } from 'types/SearchTypes'
import { useUser } from 'hooks/useLogin'
import { requestKuraJogaiTokui } from 'types/RequestTypes'
import { search } from 'utils/apiUtil'
import { tokuisaki } from 'types/ReferTypes'

const width = jokenKuraTableSummary.width
const manualMode = process.env.REACT_APP_MANUAL_MODE === '1'

type Props = {
  joken: responseSummaryJokenKura
  isChecked: boolean
  no: number
}

const O_TableRowJokenKuraSummary = ({ joken, isChecked, no }: Props) => {
  useEffect(() => organismLog('O_TableRowJokenKuraSummary'))
  const dispatch = useDispatch()
  const loading = useSelector((state) => state.PageReducer.loading)
  const userKind = useUser()

  // 明細画面開示処理 //================================//
  const openDetail = useCallback(() => {
    if (!loading) {
      dispatch(PageAction.deleteMessage())
      dispatch(TableAction.clickJoken(joken.JOKEN_KANRI_NO))
      dispatch(SearchAction.openDetail(joken))
    }
  }, [loading])

  //除外得意先押下処理 //================================//
  const onClickJogaiTokuisaki = useCallback(async (JOGAI_TOKUI_CD: string) => {
    return await search<requestKuraJogaiTokui, tokuisaki>(
      '/postJokenJogaiTokui',
      {
        TOKUI_CDS: JOGAI_TOKUI_CD.split('-').map((r) => {
          return Number(r)
        }),
      },
      (res) => {
        if (res.error) dispatch(PageAction.changeMessage(res.viewMsg))
        else {
          dispatch(ModalTokuiAction.open(res.body))
        }
      },
      () => dispatch(PageAction.changeErrorMessage()),
      () => dispatch(PageAction.changeLoading(false)),
    )
  }, [])

  // チェック処理 //================================//
  const handleRowClick = useCallback((isChecked: boolean, joken: responseSummaryJokenKura) => {
    const data: checkListSummary = {
      JOKEN_KANRI_NO: joken.JOKEN_KANRI_NO,
      TOKUI_CD: joken.TOKUI_CD,
      JOTAI_KBN_0: joken.JOTAI_KBN_0,
      JOTAI_KBN_1: joken.JOTAI_KBN_1,
      JOTAI_KBN_2_1: joken.JOTAI_KBN_2_1,
    }
    dispatch(TableAction.checkJoken(isChecked, data))
  }, [])

  const TableData = (width: number | string, title: string, align: 'inherit' | 'left' | 'center' | 'right' | 'justify' | undefined, click: React.MouseEventHandler<HTMLSpanElement>) => {
    return (
      <TableCell style={getStyle(width)} align={align}>
        <HtmlToolTip
          title={
            <Typography variant="caption" color="inherit">
              {title}
            </Typography>
          }
        >
          <Typography variant="caption" onClick={click}>
            <Link to={loading ? `kuraJoken` : `kuraJoken/${title}`}>{title}</Link>
          </Typography>
        </HtmlToolTip>
      </TableCell>
    )
  }

  return (
    <>
      {/* No */}
      <M_TableCell category="number" data={no} width={width.NO} />
      {/* チェックボックス */}
      <M_TableCell category="checkbox" isChecked={isChecked} width={width.CHECK} click={() => handleRowClick(isChecked, joken)} />
      {/* 状態 */}
      <M_TableCell
        category="data" //
        width={width.JOTAI}
        align={'left'}
        title={joken.JOTAI}
        data={joken.JOTAI}
      />
      {
        /* 上長承認状態 */
        userKind === 0 ? (
          <M_TableCell
            category="data" //
            width={width.JOCHO_JOTAI}
            align={'left'}
            title={joken.JOCHO_JOTAI}
            data={joken.JOCHO_JOTAI}
          />
        ) : (
          <></>
        )
      }
      {/* 管理No */}
      {TableData(width.KANRI_NO, joken.JOKEN_KANRI_NO, 'left', openDetail)}
      {/* 件名 */}
      <M_TableCell
        category="data" //
        width={width.KEN}
        align={'left'}
        title={joken.KEN_NM}
        data={joken.KEN_NM}
        blackout={manualMode}
      />
      {/* 適用期間(開始) */}
      <M_TableCell
        category="data" //
        width={width.STRT_DT}
        align={'left'}
        title={getTimestampFromNumber(joken.TEKIYO_START_DT, '/')}
        data={getTimestampFromNumber(joken.TEKIYO_START_DT, '/')}
        blackout={manualMode}
      />
      {/* 適用期間(終了) */}
      <M_TableCell
        category="data" //
        width={width.END_DT}
        align={'left'}
        title={getTimestampFromNumber(joken.TEKIYO_END_DT, '/')}
        data={getTimestampFromNumber(joken.TEKIYO_END_DT, '/')}
        blackout={manualMode}
      />
      {/* 事業所 */}
      <M_TableCell
        category="data" //
        width={width.JIGYO}
        align={'left'}
        title={joken.JIGYO_CD === 0 ? '全国' : joken.JIGYO_NM}
        data={joken.JIGYO_NM}
        blackout={manualMode}
      />
      {/* 全国 */}
      <M_TableCell
        category="data" //
        width={width.ZENKOKU}
        align={'left'}
        title={joken.ZENKOKU_KBN === 1 ? '全国' : ''}
        data={joken.ZENKOKU_KBN === 1 ? '全国' : ''}
        blackout={manualMode}
      />
      {/* 課 */}
      <M_TableCell
        category="data" //
        width={width.BUSHO}
        align={'left'}
        title={joken.BUSHO_NM}
        data={joken.BUSHO_NM}
        blackout={manualMode}
      />
      {/* 除外得意先 */}
      <M_TableCell
        category="data" //
        width={width.JOGAI_TOKUI}
        align={'left'}
        title={joken.JOGAI_TOKUI}
        data={
          <b>
            <u>{joken.JOGAI_TOKUI}</u>
          </b>
        }
        click={() => {
          onClickJogaiTokuisaki(joken.JOGAI_TOKUI_CD)
        }}
        blackout={manualMode}
      />
      {/* 仕入先 */}
      <M_TableCell
        category="data" //
        width={width.SIIRE}
        align={'left'}
        title={joken.SIIRE_NM}
        data={joken.SIIRE_NM}
        blackout={manualMode}
      />
      {/* 担当者 */}
      {/* <M_TableCell
        category="data" //
        width={width.TANTO}
        align={'left'}
        title={joken.TANTO_NM}
        data={joken.TANTO_NM}
      /> */}
      {
        /* 入力担当者 */
        userKind === 0 ? (
          <M_TableCell
            category="data" //
            width={width.ADD_TANTO}
            align={'left'}
            title={joken.ADD_TANTO_NM}
            data={joken.ADD_TANTO_NM}
            blackout={manualMode}
          />
        ) : (
          <></>
        )
      }
      {/* 直送除外 */}
      <M_TableCell
        category="checkbox" //
        width={width.CYOKUSO_JOGAI}
        align={'center'}
        isChecked={joken.CYOKUSO_JOGAI === 1}
        disabled={true}
      />
    </>
  )
}

export default React.memo(O_TableRowJokenKuraSummary)
