import { useEffect } from 'react'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { atomLog } from 'utils/logUtil'

type Props = {
  variant: any
  color: any
  label: string
  disabled?: boolean
  event: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const A_Button = ({ variant, color, label, event, disabled }: Props) => {
  useEffect(() => atomLog('A_Button'))
  return (
    <Button variant={variant} color={color} onClick={event} size="small" disabled={disabled}>
      <Typography variant="caption">{label}</Typography>
    </Button>
  )
}

export default A_Button
