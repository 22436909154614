import { SearchUserAction } from 'reducers/SearchReducers/SearchUserReducer'
import { responseSummaryUser } from 'types/ResponseTypes'
import { fixNullValue } from 'utils/numberUtil'

// 検索後にメッセージを消さないフラグを変更する処理 //===========================//
export const changeIsDeleteMessage = (isNotDeleteMessage: boolean): SearchUserAction => {
  return {
    type: 'SearchUserChangeIsDeleteMessage',
    isNotDeleteMessage: isNotDeleteMessage,
  }
}

// 明細画面表示を変更する処理 //===========================//
export const changeDetailView = (detailView: boolean): SearchUserAction => {
  return {
    type: 'SearchUserChangeDetailView',
    detailView: detailView,
  }
}
// 事業所処理 //===========================//
export const changeJigyoCode = (JIGYO_CD: string): SearchUserAction => {
  return {
    type: 'SearchUserChangeJigyoCode',
    JIGYO_CD: fixNullValue(JIGYO_CD),
  }
}
// 仕入先処理 //===========================//
export const changeSiireCode = (SIIRE_CD: string): SearchUserAction => {
  return {
    type: 'SearchUserChangeSiireCode',
    SIIRE_CD: fixNullValue(SIIRE_CD),
  }
}
// 氏名フォーム処理 //================================//
export const changeName = (name: string): SearchUserAction => {
  return {
    type: 'SearchUserChangeName',
    name: name,
  }
}
// メールアドレスフォーム処理 //================================//
export const changeMail = (mail: string): SearchUserAction => {
  return {
    type: 'SearchUserChangeMail',
    mail: mail,
  }
}
// ユーザー登録画面検索情報(明細表示中)を変更する処理 //================================//
export const openDetail = (user: responseSummaryUser): SearchUserAction => {
  return {
    type: 'SearchUserOpenDetail',
    user: user,
  }
}
// ユーザー登録画面検索情報(明細表示中)を変更する処理 //===========================//
export const closeDetail = (): SearchUserAction => {
  return {
    type: 'SearchUserCloseDetail',
  }
}
// ユーザー一覧初期化 //===========================//
export const format = (): SearchUserAction => {
  return {
    type: 'SearchUserFormat',
  }
}
