import React, { useEffect } from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import M_FieldDateTimeSet from 'components/1_Atoms/2_Molecules/M_FieldDateTimeSetNew'
import M_FieldSelect from 'components/1_Atoms/2_Molecules/M_FieldSelect'
import M_FieldCodeBox from 'components/1_Atoms/2_Molecules/M_FieldCodeBox'
import M_FieldTanto from 'components/1_Atoms/2_Molecules/M_FieldTanto'
import M_ButtonSubmit from 'components/1_Atoms/2_Molecules/M_ButtonSubmit'
import M_FieldLogKbn from 'components/1_Atoms/2_Molecules/M_FieldLogKbn'
import { organismGroupLog } from 'utils/logUtil'
import { useDateTime, useKbn, useJigyoshoCode, useJigyoshoName, useShiiresakiCode, useShiiresakiName, useTokuisakiCode, useTokuisakiName, useTantoCode, useTantoName, useMail, useName, useSearch, useJigyoList } from 'hooks/useLog'
import { useEnterKeyFocusControl } from 'utils/focusControlUtil'
import { unDisplayNumber } from 'utils/numberUtil'
import { useSelector } from 'react-redux'
import { select } from 'types/SearchTypes'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      // height: window.innerHeight * 0.3,
    },
    grid: {
      flexGrow: 1,
    },
    box: {
      textAlign: 'left',
    },
    impotant: {
      color: '#FF0000',
    },
  }),
)

const O_SearchBox = () => {
  useEffect(() => organismGroupLog('O_SearchBox'))
  const classes = useStyles()
  const register = useEnterKeyFocusControl()

  const [DT_FROM, DT_TO, changeDateTimeFrom, changeDateTimeTo] = useDateTime()
  const [torokuCheckbox, sakujoCheckbox, changeToroku, changeSakujo] = useKbn()
  const [JIGYO_CD, changeJigyoCode, changeJigyoCodePulldown] = useJigyoshoCode()
  const [JIGYO_NM, submitJigyosho] = useJigyoshoName()
  const [SIIRE_CD, changeShiiresakiCode] = useShiiresakiCode()
  const [SIIRE_NM, submitShiiresaki] = useShiiresakiName()
  const [TOKUI_CD, changeTokuisakiCode] = useTokuisakiCode()
  const [TOKUI_NM, submitTokuisaki] = useTokuisakiName()
  const [TANTO_CD, changeTantoCode] = useTantoCode()
  const [TANTO_NM, submitTanto] = useTantoName()
  const [MAIL, changeMail] = useMail()
  const [NAME, submitMail] = useName()
  const [_, clickSearchButton] = useSearch()
  const [jigyoList, postJigyoList] = useJigyoList()
  const JIGYO_ERROR = useSelector((state) => state.SearchLogReducer.JIGYO_ERROR)
  const SIIRE_ERROR = useSelector((state) => state.SearchLogReducer.SIIRE_ERROR)
  const TOKUI_ERROR = useSelector((state) => state.SearchLogReducer.TOKUI_ERROR)
  const TANTO_ERROR = useSelector((state) => state.SearchLogReducer.TANTO_ERROR)
  const MAIL_ERROR = useSelector((state) => state.SearchLogReducer.MAIL_ERROR)
  const isSuperUser = useSelector((state) => state.LoginReducer.isSuperUser)
  // スーパーユーザーはテキスト、それ以外はプルダウン
  const jigyoshoInpType = isSuperUser ? 'text' : 'pulldown'
  const jigyoshoDisabled =
    jigyoList.filter((val: select) => {
      return val.value !== '-1'
    }).length === 1
      ? true
      : false
  useEffect(() => {
    if (jigyoshoInpType === 'pulldown') {
      // プルダウンを表示するユーザーの場合、プルダウンを取得
      postJigyoList()
    }
    if (jigyoshoInpType === 'text') {
      // テキスト入力のユーザーの場合、初期設定の事業所で検索
      submitJigyosho()
    }
  }, [])

  return (
    <div className={classes.root}>
      <Grid container>
        {/* //////////////////// 1 行目 //////////////////// */}
        <Grid item xs={12} sm={6} className={classes.grid}>
          {/* 年月日 */}
          <M_FieldDateTimeSet type={'date'} label={'年月日'} key1={1} tab1={1} change1={changeDateTimeFrom} value1={DT_FROM} register1={register(1, 'number')} change2={changeDateTimeTo} value2={DT_TO} key2={2} tab2={2} register2={register(2, 'number')} />
        </Grid>
        <Grid item xs={12} sm={5} className={classes.grid}>
          {/* 事業所 */}
          {jigyoshoInpType === 'text' ? (
            <M_FieldCodeBox key={3} tab={3} error={JIGYO_ERROR} label={'事業所'} valueCode={unDisplayNumber(JIGYO_CD)} valueName={JIGYO_NM} change={changeJigyoCode} register={register(3, 'number', clickSearchButton, submitJigyosho)} />
          ) : (
            <M_FieldSelect label={'事業所'} valueCode={unDisplayNumber(JIGYO_CD)} valueSet={jigyoList} event={changeJigyoCodePulldown} lib={false} disabled={jigyoshoDisabled} />
          )}
        </Grid>
        <Grid item xs={12} sm={1} className={classes.grid}></Grid>

        {/* //////////////////// 2 行目 //////////////////// */}
        <Grid item xs={12} sm={3} className={classes.grid}>
          {/* 登録区分 */}
          <M_FieldLogKbn label1={'区分'} check1={torokuCheckbox} check2={sakujoCheckbox} event1={changeToroku} event2={changeSakujo} disabled={false} />
        </Grid>
        <Grid item xs={12} sm={3} className={classes.grid}></Grid>
        <Grid item xs={12} sm={5} className={classes.grid}>
          {/* 仕入先 */}
          <M_FieldCodeBox
            key={4} //
            tab={4}
            error={SIIRE_ERROR}
            label={'仕入先'}
            valueCode={unDisplayNumber(SIIRE_CD)}
            valueName={SIIRE_NM}
            change={changeShiiresakiCode}
            register={register(4, 'number', clickSearchButton, submitShiiresaki)}
          />
        </Grid>
        <Grid item xs={12} sm={1} className={classes.grid}></Grid>

        {/* //////////////////// 3 行目 //////////////////// */}
        <Grid item xs={12} sm={4} className={classes.grid}>
          {/* 担当者 */}
          <M_FieldTanto
            key={5} //
            tab={5}
            error={TANTO_ERROR}
            label={'担当者'}
            valueCode={unDisplayNumber(TANTO_CD)}
            valueName={TANTO_NM}
            change={changeTantoCode}
            register={register(5, 'number', clickSearchButton, submitTanto)}
          />
          {/*
          <M_FieldCodeBox
            key={5} //
            tab={5}
            error={TANTO_ERROR}
            label={'担当者'}
            valueCode={unDisplayNumber(TANTO_CD)}
            valueName={TANTO_NM}
            change={changeTantoCode}
            register={register(5, 'number', clickSearch, submitTanto)}
          /> */}
        </Grid>
        <Grid item xs={12} sm={2} className={classes.grid}></Grid>
        <Grid item xs={12} sm={5} className={classes.grid}>
          {/* 得意先 */}
          <M_FieldCodeBox
            key={6} //
            tab={6}
            error={TOKUI_ERROR}
            label={'得意先'}
            valueCode={unDisplayNumber(TOKUI_CD)}
            valueName={TOKUI_NM}
            change={changeTokuisakiCode}
            register={register(6, 'number', clickSearchButton, submitTokuisaki)}
          />
        </Grid>
        <Grid item xs={12} sm={1} className={classes.grid}></Grid>

        {/* //////////////////// 4 行目 //////////////////// */}
        <Grid item xs={12} sm={6} className={classes.grid}></Grid>
        <Grid item xs={12} sm={5} className={classes.grid}>
          {/* メールアドレス */}
          <M_FieldCodeBox
            type={'text'}
            key={7} //
            tab={7}
            error={MAIL_ERROR}
            label={'ﾒｰﾙｱﾄﾞﾚｽ'}
            valueCode={MAIL}
            valueName={NAME}
            change={changeMail}
            register={register(7, 'text', clickSearchButton, submitMail)}
          />
        </Grid>
        <Grid item xs={12} sm={1} className={classes.grid}></Grid>

        {/* //////////////////// 5 行目 //////////////////// */}
        <Grid item xs={12} sm={6} className={classes.grid}></Grid>
        <Grid item xs={12} sm={4} className={classes.grid}></Grid>
        <Grid item xs={12} sm={2} className={classes.grid}>
          {/* 検索ボタン */}
          <Grid container justify="flex-end">
            <M_ButtonSubmit label={'検索'} event={clickSearchButton} disabled={false} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
export default React.memo(O_SearchBox)
