import { Reducer } from 'redux'
import produce from 'immer'
import { responseDetailUser, responseSummaryUser } from 'types/ResponseTypes'
import { F_NUM, F_STR } from 'assets/FormatNumbers'
import { checkListDetailUser, checkListSummaryUser } from 'types/SearchTypes'
import { isUniqueUser, isUniqueUserSummary } from 'utils/booleanUtil'

export type TableUserState = {
  siireList: responseSummaryUser[]
  detailList: responseDetailUser[]
  checkListSummary: checkListSummaryUser[] // サマリで選択しているユーザーを保持
  checkListDetail: checkListDetailUser[] // 明細で選択しているユーザーを保持
  page: number
  perPage: number
  message: string
  JIGYO_CD: number // クリックした事業所コードを保持
  SIIRE_CD: number // クリックした仕入先コードを保持
}

const initialState: TableUserState = {
  siireList: [],
  detailList: [],
  checkListSummary: [], // 選択しているユーザーを保持
  checkListDetail: [], // 選択しているユーザーを保持
  page: 0,
  perPage: 50,
  message: F_STR,
  JIGYO_CD: F_NUM, // クリックした事業所コードを保持
  SIIRE_CD: F_NUM, // クリックした事業所コードを保持
}

export type TableUserType =
  | 'TableUserRegistDetail' // 検索結果を登録する処理
  | 'TableUserAllCheckUser' // ユーザーを全チェックする処理
  | 'TableUserCheckTokui' // ユーザーをチェックする処理
  | 'TableUserChangeMessage'
  | 'TableUserFormat'
  | 'TableUserClickUser' // ユーザーを選択する処理
  | 'TableUserChangeUser' // 選択ユーザー情報(キー項目以外)を変更する処理
  | 'TableUserChangeUserKey' // 選択ユーザー情報(キー項目)を変更する処理
  | 'TableUserChangeCheckUserDetail' // 商品チェックリストを変更する処理
  | 'TableUserCheckSummary' // サマリユーザーをチェックする処理
  | 'TableUserAllCheckUserSummary' // サマリユーザーを全チェックする処理
  | 'TableUserChangeUserSummary' // 選択ユーザー情報(キー項目以外)を変更する処理
  | 'TableUserChangeCheckUserSummary' // サマリユーザーチェックリストを変更する処理
  | 'TableUserFormatCheckSummary' // サマリチェックリストを初期化する処理
  | 'TableUserFormatCheckDetail' // 明細チェックリストを初期化する処理
  | 'dummy'

export type TableUserAction = {
  type: TableUserType
  siireList?: responseDetailUser[]
  tokuiList?: responseDetailUser[]
  isChecked?: boolean
  JIGYO_CD?: number // 事業所コード
  SIIRE_CD?: number // 仕入先コード
  TOKUI_CD?: number // 得意先コード
  checkUser?: checkListDetailUser
  message?: string
  page?: number
  checkData?: checkListSummaryUser
  checkDataList?: checkListSummaryUser[]
  checkUserList?: checkListDetailUser[]
  siireSummaryList?: responseSummaryUser[]
}

export const TableUserReducer: Reducer<TableUserState, TableUserAction> = produce((draft: TableUserState, action: TableUserAction) => {
  switch (action.type) {
    // サマリユーザーチェックリストを変更する処理 //=========================//
    case 'TableUserChangeCheckUserSummary': {
      draft.checkListSummary = action.checkDataList!
      return
    }
    // 選択 サマリユーザー情報(キー項目以外)を変更する処理 //===========================//
    case 'TableUserChangeUserSummary': {
      const checkData = action.checkData!
      draft.checkListSummary = draft.checkListSummary.filter((r) => !isUniqueUserSummary(r, checkData))
      draft.checkListSummary.push(checkData)
      return
    }
    // サマリユーザーを全チェックする処理 //================================//
    case 'TableUserAllCheckUserSummary': {
      if (action.isChecked!) {
        // チェック除去
        draft.checkListSummary = []
      } else {
        // チェック付与
        const originList: checkListSummaryUser[] = []
        action.siireSummaryList!.forEach((r) => {
          originList.push({
            ...r,
            INP_MAIL: r.MAIL,
          })
        })
        draft.checkListSummary = originList
      }
      return
    }
    // サマリユーザーをチェックする処理 //================================//
    case 'TableUserCheckSummary': {
      const checkData = action.checkData!
      if (action.isChecked!) {
        // チェック除去
        draft.checkListSummary = draft.checkListSummary.filter((r) => !isUniqueUserSummary(r, checkData))
      } else {
        // チェック付与
        draft.checkListSummary.push(checkData)
      }
      return
    }
    // 検索結果を登録する処理 //================================//
    case 'TableUserRegistDetail': {
      draft.detailList = action.tokuiList!
      return
    }
    // ユーザーを全チェックする処理 //================================//
    case 'TableUserAllCheckUser': {
      if (action.isChecked!) {
        // チェック除去
        draft.checkListDetail = []
      } else {
        // チェック付与
        const originList: checkListDetailUser[] = []
        action.siireList!.forEach((r) => {
          originList.push({
            JIGYO_CD: draft.JIGYO_CD,
            SIIRE_CD: draft.SIIRE_CD,
            TOKUI_CD: r.TOKUI_CD,
            INP_TOKUI_CD: r.TOKUI_CD,
            TOKUI_NM: r.TOKUI_NM,
            INP_MAIL: r.INP_MAIL,
            MAIL: r.MAIL,
            NAME: r.NAME,
          })
        })
        draft.checkListDetail = originList
      }
      return
    }
    // ユーザーをチェックする処理 //================================//
    case 'TableUserCheckTokui': {
      const checkData = action.checkUser!
      if (action.isChecked!) {
        // チェック除去
        draft.checkListDetail = draft.checkListDetail.filter((r) => !isUniqueUser(r, checkData))
      } else {
        // チェック付与
        draft.checkListDetail.push(checkData)
      }
      return
    }
    // 選択ユーザー情報(キー項目以外)を変更する処理 //===========================//
    case 'TableUserChangeUser': {
      const checkUser = action.checkUser!
      draft.checkListDetail = draft.checkListDetail.filter((r) => !isUniqueUser(r, checkUser))
      draft.checkListDetail.push(checkUser)
      return
    }
    // 選択ユーザー情報(キー項目)を変更する処理 //===========================//
    case 'TableUserChangeUserKey': {
      const keys = { JIGYO_CD: draft.JIGYO_CD, SIIRE_CD: draft.SIIRE_CD, TOKUI_CD: action.TOKUI_CD! }
      draft.checkListDetail = draft.checkListDetail.filter((r) => !isUniqueUser(r, keys))
      draft.checkListDetail.push(action.checkUser!)
      return
    }
    // ユーザーチェックリストを変更する処理 //=========================//
    case 'TableUserChangeCheckUserDetail': {
      draft.checkListDetail = action.checkUserList!
      return
    }
    // サマリチェックリストを初期化する処理 //================================//
    case 'TableUserFormatCheckSummary': {
      draft.checkListSummary = initialState.checkListSummary
      return
    }
    // 明細チェックリストを初期化する処理 //================================//
    case 'TableUserFormatCheckDetail': {
      draft.checkListDetail = initialState.checkListDetail
      return
    }
    case 'TableUserFormat': {
      for (const key in draft) {
        const tempKey: keyof TableUserState = key as keyof TableUserState
        ;(draft as any)[tempKey] = initialState[tempKey]
      }
      return
    }
    // 条件を選択する処理 //===========================//
    case 'TableUserClickUser': {
      draft.SIIRE_CD = action.SIIRE_CD!
      draft.JIGYO_CD = action.JIGYO_CD!
      draft.checkListDetail = initialState.checkListDetail
      return
    }
  }
}, initialState)
