import { TableUserAction } from 'reducers/TableReducers/TableUserReducer'
import { responseDetailUser, responseSummaryUser } from 'types/ResponseTypes'
import { checkListDetailUser, checkListSummaryUser } from 'types/SearchTypes'

// サマリユーザーチェックリストを変更する処理 //================================//
export const changeCheckUserSummary = (checkDataList: checkListSummaryUser[]): TableUserAction => {
  return {
    type: 'TableUserChangeCheckUserSummary',
    checkDataList: checkDataList,
  }
}

// 選択サマリユーザー情報(キー項目以外)を変更する処理 //================================//
export const changeUserSummary = (checkData: checkListSummaryUser): TableUserAction => {
  return {
    type: 'TableUserChangeUserSummary',
    checkData: checkData,
  }
}

// サマリを全チェックする処理 //================================//
export const allCheckUserSummary = (isChecked: boolean, siireSummaryList: responseSummaryUser[]): TableUserAction => {
  return {
    type: 'TableUserAllCheckUserSummary',
    isChecked: isChecked,
    siireSummaryList: siireSummaryList,
  }
}

// サマリをチェックする処理 //================================//
export const checkUserSummary = (isChecked: boolean, checkData: checkListSummaryUser): TableUserAction => {
  return {
    type: 'TableUserCheckSummary',
    isChecked: isChecked,
    checkData: checkData,
  }
}

// 検索結果を登録する処理 //================================//
export const registDetail = (tokuiList: responseDetailUser[]): TableUserAction => {
  return {
    type: 'TableUserRegistDetail',
    tokuiList: tokuiList,
  }
}

// ユーザーを全チェックする処理 //================================//
export const allCheckUser = (isChecked: boolean, siireList: responseDetailUser[]): TableUserAction => {
  return {
    type: 'TableUserAllCheckUser',
    isChecked: isChecked,
    siireList: siireList,
  }
}

// ユーザーを選択する処理 //===========================//
export const clickUser = (JIGYO_CD: number, SIIRE_CD: number): TableUserAction => {
  return {
    type: 'TableUserClickUser',
    JIGYO_CD: JIGYO_CD,
    SIIRE_CD: SIIRE_CD,
  }
}

// 明細をチェックする処理 //================================//
export const checkDetail = (isChecked: boolean, checkUser: checkListDetailUser): TableUserAction => {
  return {
    type: 'TableUserCheckTokui',
    isChecked: isChecked,
    checkUser: checkUser,
  }
}

// 選択ユーザー情報(キー項目以外)を変更する処理 //================================//
export const changeUser = (checkUser: checkListDetailUser): TableUserAction => {
  return {
    type: 'TableUserChangeUser',
    checkUser: checkUser,
  }
}

// 選択ユーザー情報(キー項目)を変更する処理 //================================//
export const changeUserKey = (TOKUI_CD: number, checkUser: checkListDetailUser): TableUserAction => {
  return {
    type: 'TableUserChangeUserKey',
    TOKUI_CD: TOKUI_CD,
    checkUser: checkUser,
  }
}

// ユーザーチェックリストを変更する処理 //================================//
export const changeCheckUserDetail = (checkUserList: checkListDetailUser[]): TableUserAction => {
  return {
    type: 'TableUserChangeCheckUserDetail',
    checkUserList: checkUserList,
  }
}

// サマリチェックリストを初期化する処理 //================================//
export const formatCheckSummary = (): TableUserAction => {
  return {
    type: 'TableUserFormatCheckSummary',
  }
}

// 明細チェックリストを初期化する処理 //================================//
export const formatCheckDetail = (): TableUserAction => {
  return {
    type: 'TableUserFormatCheckDetail',
  }
}

// 初期化処理 //================================//
export const format = (): TableUserAction => {
  return {
    type: 'TableUserFormat',
  }
}
