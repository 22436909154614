/* eslint-disable react/jsx-pascal-case */
import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom'
import O_SearchBoxTenyuryokuJapell from 'components/3_Organisms/Groups/O_SearchBoxTenyuryokuJapell'
import O_SearchBoxTenyuryokuShiiresaki from 'components/3_Organisms/Groups/O_SearchBoxTenyuryokuShiiresaki'
import O_TableTenyuryokuSummary from 'components/3_Organisms/Groups/O_TableTenyuryokuSummary'
import O_TableTenyuryokuDetail from 'components/3_Organisms/Groups/O_TableTenyuryokuDetail'
import { templateLog } from 'utils/logUtil'
import { usePageMessage, useLoading } from 'hooks/usePage'
import { useIsNotDeleteMessage, useFetcherSummary, useSearch } from 'hooks/useTenyuryoku'
import { useSearchedFormatBoth } from 'hooks/useTenyuryokuTable'
import { baseInfo } from 'types/SearchTypes'
import O_DialogAlert from 'components/3_Organisms/O_DialogAlert'
import { responseSummaryTenyuryoku } from 'types/ResponseTypes'
import { useErrorLogout, useUser } from 'hooks/useLogin'
import { usePage } from 'hooks/useTable'

const C_Tenyuryoku = (): JSX.Element => {
  useEffect(() => templateLog('C_Tenyuryoku'), [])

  const userKind = useUser()
  const [searchTenyuryoku, fetcher] = useFetcherSummary()
  const searchedFormat = useSearchedFormatBoth()
  const [isNotDeleteMessage, changeIsNotDeleteMessage] = useIsNotDeleteMessage()
  const [changePageErrorMessage, deletePageMessage] = usePageMessage()
  const [page, perPage] = usePage()
  const [backLogin] = useErrorLogout(useHistory())

  const { data, refetch, isFetching, isLoading, error } = useQuery(['postTenyuryokuSummary', searchTenyuryoku, page, perPage], fetcher)
  const [loding, changeLoading] = useLoading()
  const [detailView] = useSearch()
  useEffect(() => {
    if (!detailView && (isLoading || isFetching)) {
      // ロード中、フェッチ中の場合、loadingをtrueにする
      changeLoading(true)
    }
    if (!detailView && !(isLoading || isFetching) && loding) {
      // ロード、フェッチが終わった場合、loadingをfalseにする
      changeLoading(false)
    }
  }, [isLoading, isFetching, detailView])
  useEffect(() => {
    if (!detailView && (isLoading || isFetching) && !isNotDeleteMessage) {
      deletePageMessage()
    }
    if (!detailView && !(isLoading || isFetching) && isNotDeleteMessage) {
      changeIsNotDeleteMessage(false)
    }
  }, [isLoading, isFetching, detailView])

  if (isLoading) {
    searchedFormat()
    return <div>Loading...</div>
  }
  if (isFetching) {
    searchedFormat()
  }
  if (error) {
    backLogin()
    return <></>
  }

  const info: { pullDown: baseInfo; table: responseSummaryTenyuryoku[]; allCount: number } = data.body
  if (data.viewMsg) {
    changePageErrorMessage(data.viewMsg)
  }

  return (
    <>
      {userKind === 0 ? (
        <O_SearchBoxTenyuryokuJapell info={info.pullDown} />
      ) : (
        <O_SearchBoxTenyuryokuShiiresaki info={info.pullDown} /> //
      )}
      <hr />
      <Router>
        <Switch>
          <Route path="/tenyuryoku" exact>
            <O_TableTenyuryokuSummary tenyuryokuList={info.table} allCount={info.allCount} isFetching={isFetching} refetch={refetch} />
          </Route>
          <Route path="/tenyuryoku/:postId" exact component={O_TableTenyuryokuDetail} />
        </Switch>
      </Router>
      {/* アラートダイアログ */}
      <O_DialogAlert />
    </>
  )
}

export default React.memo(C_Tenyuryoku)
