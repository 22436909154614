import React, { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles'
import TablePagination from '@material-ui/core/TablePagination'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'
import * as TableAction from 'actions/TableActions/TableAction'
import { organismLog } from 'utils/logUtil'

const useStyles1 = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  }),
)

interface TablePaginationActionsProps {
  count: number
  page: number
  rowsPerPage: number
  onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void
}

const TablePaginationActions = (props: TablePaginationActionsProps) => {
  const classes = useStyles1()
  const theme = useTheme()
  const { count, page, rowsPerPage, onPageChange } = props

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0)
  }

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1)
  }

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <div className={classes.root}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  )
}

type Props = {
  count: number
  isDetail: boolean
  perPageOptions?: number[]
}

const O_TablePagination = ({ count, isDetail, perPageOptions = [50, 100, 300, 500] }: Props) => {
  useEffect(() => organismLog('O_TablePagination'))
  useEffect(() => {
    const tempPage = isDetail ? pageDetail : page
    const tempPerPage = isDetail ? perPageDetail : perPage
    const newPage = Math.ceil(count / tempPerPage) - 1
    // 最大ページ数が現在のページ数より小さい場合、ページ数を更新する
    if (newPage > -1 && tempPage > newPage) {
      if (isDetail) {
        dispatch(TableAction.changePageDetail(newPage))
      } else {
        dispatch(TableAction.changePage(newPage))
      }
    }
  }, [count])
  const dispatch = useDispatch()
  const page = useSelector((state) => state.TableReducer.page)
  const perPage = useSelector((state) => state.TableReducer.perPage)
  const pageDetail = useSelector((state) => state.TableReducer.pageDetail)
  const perPageDetail = useSelector((state) => state.TableReducer.perPageDetail)
  // const mishoninCheckbox = useSelector((state) => state.SearchJokenReducer.searchJoken.mishoninCheckbox)
  // const shoninCheckbox = useSelector((state) => state.SearchJokenReducer.searchJoken.shoninCheckbox)
  // const hininCheckbox = useSelector((state) => state.SearchJokenReducer.searchJoken.hininCheckbox)
  // const pageView = mishoninCheckbox && !shoninCheckbox && hininCheckbox

  // ページ送り処理 //================================//
  const handleChangePage = useCallback(
    (event: unknown, page: number) => {
      if (event) {
        if (isDetail) {
          dispatch(TableAction.changePageDetail(page))
        } else {
          dispatch(TableAction.changePage(page))
        }
      }
    },
    [page, pageDetail],
  )

  // ページ表示数変更処理 //================================//
  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (isDetail) {
        dispatch(TableAction.changePerPageDetail(parseInt(event.target.value, 10)))
      } else {
        dispatch(TableAction.changePerPage(parseInt(event.target.value, 10)))
      }
    },
    [perPage, perPageDetail],
  )

  // if (pageView || mishoninCheckbox) return <></>
  return (
    <Grid container direction="row" justify="flex-end" alignItems="flex-end">
      <TablePagination
        labelRowsPerPage={'表示件数'}
        backIconButtonText={'前のページ'}
        nextIconButtonText={'次のページ'}
        labelDisplayedRows={({ from, to, count }) => {
          return `${from}-${to} 件／${count !== -1 ? count : `${to}以上`}`
        }}
        rowsPerPageOptions={perPageOptions}
        component="div"
        count={count}
        rowsPerPage={isDetail ? perPageDetail : perPage}
        page={isDetail ? pageDetail : page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </Grid>
  )
}

export default React.memo(O_TablePagination)
