/* eslint-disable react/jsx-pascal-case */
import React, { useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import A_SelectBoxNew from 'components/1_Atoms/A_SelectBoxNew'
import A_IconMust from '../A_IconMust'
import { moleculeLog } from 'utils/logUtil'
import { select } from 'types/SearchTypes'
import { SingleValue } from 'react-select'
import { HtmlToolTip } from 'assets/ToolTips'

type Props = {
  label: string
  must?: boolean
  valueCode: string
  valueSet: select[]
  event?: (targetValue: SingleValue<select>) => void
  disabled?: boolean
  lib?: boolean
  blackout?: boolean // マニュアル作成用に非表示対象を黒塗りする処理 (処理は効かないので注意)
}

const M_FieldSelect = ({ label, must = false, valueCode, valueSet, event = () => {}, disabled = false, lib = true }: Props) => {
  useEffect(() => moleculeLog('M_FieldSelectNew'))
  const newSelect: select = { value: '', label: '' }
  const selected: select =
    valueSet.find((data) => {
      return data.value === valueCode
    }) ?? newSelect

  return (
    <Grid container justifyContent="flex-start" alignItems="center">
      <Grid item xs={12} sm={3}>
        <HtmlToolTip title={<Typography variant="body2">{selected.label}</Typography>} placement="bottom-start">
          <Typography variant="body1" display="block">
            {label}
            <A_IconMust must={must} />
          </Typography>
        </HtmlToolTip>
      </Grid>
      <Grid item xs={12} sm={9} justifyContent="flex-end">
        <A_SelectBoxNew valueSet={valueSet} defValue={selected} event={event} lib={lib} disabled={disabled} />
      </Grid>
    </Grid>
  )
}

export default React.memo(M_FieldSelect)
