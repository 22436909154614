/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import clsx from 'clsx'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { organismLog } from 'utils/logUtil'
import { useExpand } from 'hooks/useTenyuryoku'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      cursor: 'pointer',
    },
    expand: {
      margin: 0,
      padding: 0,
      transform: 'rotate(0deg)',
      // marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
  }),
)

type Props = {
  title: string
  expandMode?: boolean // 検索項目を拡縮できるか
}

const O_ContentHeader = ({ title, expandMode = false }: Props): JSX.Element => {
  useEffect(() => organismLog('O_ContentHeader'))
  const classes = useStyles()
  //エラーメッセージの取得
  const message = useSelector((state) => state.PageReducer.message)
  const messageColor = useSelector((state) => state.PageReducer.messageColor)
  // 検索部分開閉処理
  const [open, changeOpen] = useExpand()
  const handle = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault()
      changeOpen(!open)
    },
    [open],
  )

  return (
    <Grid container direction="row" alignItems="center">
      <Typography className={classes.title} variant="h6" onClick={handle}>
        {title}
      </Typography>
      {expandMode ? (
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: open,
          })}
          onClick={handle}
          aria-expanded={open}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
      ) : (
        <></>
      )}
      <Typography variant="h6">{'　　 '}</Typography>
      <Typography variant="body1">
        <b style={{ color: messageColor }}>{message}</b>
      </Typography>
    </Grid>
  )
}

export default React.memo(O_ContentHeader)
