import { Reducer } from 'redux'
import produce from 'immer'
import { F_NUM, F_STR } from 'assets/FormatNumbers'
import { select } from 'types/SearchTypes'
import { keyInfo } from 'types/ReferTypes'

export type LoginState = {
  loginKbn: 0 | 1 | 2 // ログイン前後判定(0: ログイン前、1: 仮ログイン、2: ログイン後)
  USER_CD: string // ログインユーザーコード(メールアドレス、担当者コード)
  NAME: string // ログインユーザー名
  MAIL: string // メールアドレス
  keyInfo: keyInfo[] // ユーザー情報(事業所コード, 仕入先コード, 得意先コード)
  TANTO_CD: number // 担当者コード(ジャペルログイン時)
  isJocho: boolean // 上長かどうか
  isKoiki: boolean // 広域量販部かどうか
  isSuperUser: boolean // スーパーユーザーかどうLoginChangeInfoか
  message: string // メッセージ
  userKind: number // ログインユーザーの種類(0: ジャペル, 1: 仕入先)
  inputMail: string // パスワード再発行時に入力するメールアドレス
  inputPassword: string // パスワード変更時に入力するパスワード
  inputUserName: string // パスワード変更時に入力するユーザー名
  initMessageFlg: boolean // 初期化時メッセージを初期化するかのフラグ
  // resetButton: string // 条件リセットボタンに出す文字列
  // userTorokuMessage: string // ユーザー登録サマリ画面に出すメッセージ
  isCreatedPassword: boolean // パスワード再発行の前後判定
  isChangedPassword: boolean // パスワード変更の前後判定
  reInputPassword: string // パスワード変更時に入力する再入力パスワード
  manualNumPages: number // マニュアルのPDFのページ数
}

const initialState: LoginState = {
  loginKbn: 0, // ログイン前後判定(0: ログイン前、1: 仮ログイン、2: ログイン後)
  USER_CD: F_STR, // ログインユーザーコード
  NAME: F_STR, // ログインユーザー名
  MAIL: F_STR, // メールアドレス
  keyInfo: [], // ユーザー情報(事業所コード, 仕入先コード, 得意先コード)
  TANTO_CD: F_NUM, // 担当者コード(ジャペルログイン時)
  isJocho: false, // 上長かどうか
  isKoiki: false, // 広域量販部かどうか
  isSuperUser: false, // スーパーユーザーかどうLoginChangeInfoか
  message: F_STR, // メッセージ
  userKind: 0, // ログインユーザーの種類(0: ジャペル, 1: 仕入先)
  inputMail: F_STR, // パスワード再発行時に入力するメールアドレス
  inputPassword: F_STR, // パスワード変更時に入力するパスワード
  inputUserName: F_STR, // パスワード変更時に入力するユーザー名
  initMessageFlg: true, // 初期化時メッセージを初期化するかのフラグ
  // resetButton: F_STR, // 条件リセットボタンに出す文字列
  // userTorokuMessage: F_STR, // ユーザー登録サマリ画面に出すメッセージ
  isCreatedPassword: false, // パスワード再発行の前後判定
  isChangedPassword: false, // パスワード変更の前後判定
  reInputPassword: F_STR, // パスワード変更時に入力する再入力パスワード
  manualNumPages: 0, // マニュアルのPDFのページ数
}

export type LoginType =
  | 'LoginChangeLoginKbn' // ログイン前後判定を変更する処理
  | 'LoginChangeUserCode' // ログインユーザーコードを入力する処理
  | 'LoginChangeInfo' // ログイン後に取得する各情報を入力する処理
  | 'LoginChangeMessage' // メッセージを変更する処理
  | 'LoginDeleteMessage' // メッセージを初期化する処理
  | 'LoginFormat' // ログイン情報を初期化する処理
  | 'LoginChangeUserKind' // ログインユーザーの種類を変更する処理(0: ジャペル, 1: 仕入先)
  | 'LoginChangeInputMail' // パスワード再発行時に入力するメールアドレスを変更する処理
  | 'LoginChangeInputPassword' // パスワード変更時に入力するパスワードを変更する処理
  | 'LoginChangeInputUserName' // パスワード変更時に入力するユーザー名を変更する処理
  | 'LoginChangeInitMessageFlg' // 初期化時メッセージを初期化するかのフラグを変更する処理
  | 'LoginChangeIsCreatedPassword' // パスワード再発行の前後判定を変更する処理
  | 'LoginChangeIsChangedPassword' // パスワード再発行の前後判定を変更する処理
  | 'LoginChangeReInputPassword' // パスワード変更時に入力する再入力パスワードを変更する処理
  | 'LoginChangeManualNumPages' // マニュアルのPDFのページ数を変更する処理
  | 'dummy'

export type LoginAction = {
  type: LoginType
  loginKbn?: 0 | 1 | 2 // ログイン前後判定(0: ログイン前、1: 仮ログイン、2: ログイン後)
  user_code?: string
  user_name?: string
  mail?: string
  keyInfo?: keyInfo[] // ユーザー情報(事業所コード, 仕入先コード, 得意先コード)
  TANTO_CD?: number // 担当者コード(ジャペルログイン時)
  isJocho?: boolean // 上長かどうか
  isKoiki?: boolean // 広域量販部かどうか
  isSuperUser?: boolean // スーパーユーザーかどうか
  message?: string
  userKind?: number // ログインユーザーの種類(0: ジャペル, 1: 仕入先)
  siireSelect?: select[] // プルダウンメニュー仕入先)
  jigyoSelect?: select[] // プルダウンメニュー(事業所)
  tokuiSelect?: select[] // プルダウンメニュー(得意先)
  bushoSelect?: select[] // プルダウンメニュー(課)
  tantoSelect?: select[] // プルダウンメニュー(担当者)
  addTantoSelect?: select[] // プルダウンメニュー(入力担当者)
  inputMail?: string // パスワード再発行時に入力するメールアドレス
  inputPassword?: string // パスワード変更時に入力するパスワード
  inputUserName?: string // パスワード変更時に入力するユーザー名
  initMessageFlg?: boolean // 初期化時メッセージを初期化するかのフラグ
  // resetButton?: string // 条件リセットボタンに出す文字列
  // userTorokuMessage?: string // ユーザー登録サマリ画面に出すメッセージ
  isPassword?: boolean // パスワード変更/再発行の前後判定
  reInputPassword?: string // パスワード変更時に入力する再入力パスワード
  manualNumPages?: number // マニュアルのPDFのページ数
}

export const LoginReducer: Reducer<LoginState, LoginAction> = produce((draft: LoginState, action: LoginAction) => {
  switch (action.type) {
    // マニュアルのPDFのページ数 //===========================//
    case 'LoginChangeManualNumPages': {
      draft.manualNumPages = action.manualNumPages!
      return
    }
    // ログインユーザーの種類を変更する処理 //===========================//
    case 'LoginChangeUserKind': {
      draft.userKind = action.userKind!
      return
    }
    // ログイン前後判定を変更する処理 //===========================//
    case 'LoginChangeLoginKbn': {
      draft.loginKbn = action.loginKbn!
      return
    }
    // ログインユーザーコードを入力する処理 //===========================//
    case 'LoginChangeUserCode': {
      draft.USER_CD = action.user_code!
      return
    }
    // ログイン後に取得する各情報を入力する処理 //===========================//
    case 'LoginChangeInfo': {
      draft.NAME = action.user_name!
      draft.MAIL = action.mail!
      draft.TANTO_CD = action.TANTO_CD!
      draft.keyInfo = action.keyInfo!
      draft.isJocho = action.isJocho!
      draft.isKoiki = action.isKoiki!
      draft.isSuperUser = action.isSuperUser!
      // draft.resetButton = action.resetButton!
      // draft.userTorokuMessage = action.userTorokuMessage!
      draft.USER_CD = initialState.USER_CD
      draft.inputMail = initialState.inputMail
      draft.inputPassword = initialState.inputPassword
      draft.inputUserName = initialState.inputUserName
      return
    }
    // メッセージ変更 //===========================//
    case 'LoginChangeMessage': {
      draft.message = action.message!
      return
    }
    // メッセージ初期化 //===========================//
    case 'LoginDeleteMessage': {
      draft.message = ''
      return
    }
    // パスワード再発行時に入力するメールアドレスを変更する処理 //===========================//
    case 'LoginChangeInputMail': {
      draft.inputMail = action.inputMail!
      return
    }
    // パスワード変更時に入力するパスワードを変更する処理 //===========================//
    case 'LoginChangeInputPassword': {
      draft.inputPassword = action.inputPassword!
      return
    }
    // パスワード変更時に入力するユーザー名を変更する処理 //===========================//
    case 'LoginChangeInputUserName': {
      draft.inputUserName = action.inputUserName!
      return
    }
    // 初期化時メッセージを初期化するかのフラグを変更する処理 //===========================//
    case 'LoginChangeInitMessageFlg': {
      draft.initMessageFlg = action.initMessageFlg!
      return
    }
    // パスワード再発行の前後判定を変更する処理 //===========================//
    case 'LoginChangeIsCreatedPassword': {
      draft.isCreatedPassword = action.isPassword!
      return
    }
    // パスワード再発行の前後判定を変更する処理 //===========================//
    case 'LoginChangeIsChangedPassword': {
      draft.isChangedPassword = action.isPassword!
      return
    }
    // パスワード変更時に入力する再入力パスワードを変更する処理 //===========================//
    case 'LoginChangeReInputPassword': {
      draft.reInputPassword = action.reInputPassword!
      return
    }
    // ログイン情報を初期化する処理 //===========================//
    case 'LoginFormat': {
      const initMessageFlg = draft.initMessageFlg
      for (const key in draft) {
        const tempKey: keyof LoginState = key as keyof LoginState
        if (key === 'message' && !initMessageFlg) continue
        ;(draft as any)[tempKey] = initialState[tempKey]
      }
      return
    }
  }
}, initialState)
