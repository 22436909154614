import Typography from '@material-ui/core/Typography'

type Props = {
  color: string
  message: string
  align?: any
}

const getStyle = (color: string) => {
  switch (color) {
    case 'red':
      return { color: 'red' }
    case 'blue':
      return { color: 'blue' }
    case 'green':
      return { color: 'green' }
  }
  return { color: 'red' }
}

const A_Message = ({ color = 'red', message, align = 'left' }: Props) => {
  return (
    <Typography variant="caption" display="block" align={align} gutterBottom>
      <b style={getStyle(color)}>{message}</b>
    </Typography>
  )
}
export default A_Message
