/* eslint-disable react/jsx-pascal-case */
import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import { useSelector } from 'react-redux'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import M_ButtonNormal from 'components/1_Atoms/2_Molecules/M_ButtonNormal'
import LinearProgress from '@material-ui/core/LinearProgress'
import { organismLog } from 'utils/logUtil'
import { useInitAllReducer, useSetChoice } from 'hooks/useCommon'
import { clickF } from 'types/FunctionTypes'

const manualMode = process.env.REACT_APP_MANUAL_MODE === '1'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#000000',
      backgroundColor: manualMode || process.env.REACT_APP_ENVIRONMENT === 'prod' ? '#66FF99' : '#FF99FF',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      // flexGrow: 1,
      cursor: 'pointer',
    },
    flex: {
      flexGrow: 1,
    },
  }),
)

type Props = {
  sysName: string
  handleOpenMenu: clickF
}

const O_Header = ({ sysName, handleOpenMenu }: Props) => {
  useEffect(() => organismLog('O_Header'))
  const classes = useStyles()
  const loading = useSelector((state) => state.PageReducer.loading)
  const NAME = useSelector((state) => state.LoginReducer.NAME)
  const history = useHistory()
  const location = useLocation()
  const [initAllReducer] = useInitAllReducer()
  const [_, setChoice] = useSetChoice(history, location)

  const onClickTitle = () => {
    if (!loading) {
      if (location.pathname.indexOf('/hanJoken') === -1) setChoice(false, '/hanJoken') // ログイン画面に遷移
    }
  }

  const logout = () => {
    initAllReducer()
    history.push('/') // ログイン画面に遷移
  }

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar variant="dense">
        <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={handleOpenMenu}>
          <MenuIcon />
        </IconButton>
        <Typography className={classes.title} onClick={onClickTitle} variant="h5" noWrap>
          {sysName}
        </Typography>
        <Typography className={classes.flex} variant="h5" noWrap></Typography>
        <Box mx={1}>
          <Typography variant="subtitle1" noWrap style={manualMode ? { backgroundColor: 'black' } : {}}>
            {NAME + '様'}
          </Typography>
        </Box>
        <Box mx={1}>
          <M_ButtonNormal label={'ログアウト'} event={logout} />
        </Box>
      </Toolbar>
      {loading ? <LinearProgress /> : ''}
    </AppBar>
  )
}
export default React.memo(O_Header)
