/* eslint-disable react/jsx-pascal-case */
import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom'
import O_SearchBoxUser from 'components/3_Organisms/Groups/O_SearchBoxUser'
import O_TableUserSummary from 'components/3_Organisms/Groups/O_TableUserSummary'
import O_TableUserDetail from 'components/3_Organisms/Groups/O_TableUserDetail'
import { templateLog } from 'utils/logUtil'
import { baseInfo } from 'types/SearchTypes'
import { responseSummaryUser } from 'types/ResponseTypes'
import { useFetcherSummary, useIsNotDeleteMessage, useUserDetailView } from 'hooks/useUserTorokuForm'
import { useLoading } from 'hooks/usePage'
import O_DialogAlert from 'components/3_Organisms/O_DialogAlert'
import { useSearchedFormatBoth } from 'hooks/useUserTorokuTable'
import { usePageMessage } from 'hooks/usePage'
import { useErrorLogout } from 'hooks/useLogin'

const C_UserToroku = (): JSX.Element => {
  useEffect(() => templateLog('C_UserToroku'), [])
  const [searchUser, fetcherSummary] = useFetcherSummary()
  const searchedFormat = useSearchedFormatBoth()
  const [_, deletePageMessage] = usePageMessage()
  const [isNotDeleteMessage, changeIsNotDeleteMessage] = useIsNotDeleteMessage()
  const [loding, changeLoading] = useLoading()
  const [detailView] = useUserDetailView()
  const [backLogin] = useErrorLogout(useHistory())

  // SWR情報の取得
  const { data: data, refetch: refetchSummary, isFetching: isFetching, isLoading: isLoading, error: error } = useQuery(['postUserSummary', searchUser], fetcherSummary)
  useEffect(() => {
    if (!detailView && (isLoading || isFetching)) {
      changeLoading(true)
    }
    if (!detailView && !(isLoading || isFetching) && loding) {
      changeLoading(false)
    }
  }, [isLoading, isFetching, detailView])

  useEffect(() => {
    if (!detailView && (isLoading || isFetching) && !isNotDeleteMessage) {
      deletePageMessage()
    }
    if (!detailView && !(isLoading || isFetching) && isNotDeleteMessage) {
      changeIsNotDeleteMessage(false)
    }
  }, [isLoading, isFetching, detailView])

  if (isLoading) {
    searchedFormat()
    return <div>Loading...</div>
  }
  if (error) {
    backLogin()
    return <></>
  }
  const info: { pullDown: baseInfo; table: responseSummaryUser[] } = data.body

  return (
    <>
      <O_SearchBoxUser info={info.pullDown} />
      <hr />
      <Router>
        <Switch>
          <Route path="/userToroku" exact>
            <O_TableUserSummary userList={info.table} isFetching={isFetching} refetch={refetchSummary} />
          </Route>
          <Route path="/userToroku/:postId" exact>
            <O_TableUserDetail info={info.pullDown} />
          </Route>
        </Switch>
      </Router>
      {/* アラートダイアログ */}
      <O_DialogAlert />
    </>
  )
}

export default React.memo(C_UserToroku)
