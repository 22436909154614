/**
 * ユーザー登録画面にて特定のユーザーログイン時に表示する事業所
 */
export const kansaiJigyo = [
  {
    value: '15',
    label: '15: 神戸',
  },
  {
    value: '19',
    label: '19: 関西',
  },
]

export const kansaiJochoJigyo = [
  {
    value: '9',
    label: '9: 大阪',
  },
  {
    value: '15',
    label: '15: 神戸',
  },
  {
    value: '19',
    label: '19: 関西',
  },
]

export const nagoyaJochoJigyo = [
  {
    value: '1',
    label: '1: 名古屋',
  },
  {
    value: '3',
    label: '3: 三重物流センター',
  },
]
