/* eslint-disable react/jsx-pascal-case */
import React, { useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { moleculeLog } from 'utils/logUtil'

type Props = {
  label1: string
  check1: boolean
  check2: boolean
  event1: (e: React.ChangeEvent<HTMLInputElement>) => void
  event2: (e: React.ChangeEvent<HTMLInputElement>) => void
  disabled: boolean
}

const useStyles = makeStyles(() =>
  createStyles({
    item: {
      // marginBottom: 10,
    },
    centerPosition: {
      height: '80%',
      border: '1px solid #000000',
      marginRight: 10,
    },
    field: {
      height: '100%',
      width: '50%',
      display: 'inline-block',
      paddingRight: 30,
    },
    cursor: {
      cursor: 'pointer',
    },
  }),
)

const M_FieldLogKbn = ({ label1, check1, check2, event1, event2, disabled }: Props) => {
  useEffect(() => moleculeLog('M_FieldLogKbn'))
  const classes = useStyles()
  return (
    <Grid container justify="flex-start">
      <Grid item xs={12} sm={4}>
        <Typography variant="body1" display="block" gutterBottom>
          {label1}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8} className={classes.item}>
        <div className={classes.centerPosition}>
          <div className={classes.field}>
            <label className={classes.cursor} htmlFor={'toroku'}>
              {'登録'}
            </label>
            <input
              type={'checkbox'}
              checked={check1}
              style={{ backgroundColor: 'white', margin: 0, padding: 0 }} //
              color="primary"
              onChange={event1}
              id={'toroku'}
              disabled={disabled}
              className={classes.cursor}
            />
          </div>
          <div className={classes.field}>
            <label className={classes.cursor} htmlFor={'sakujo'}>
              {'削除'}
            </label>
            <input
              type={'checkbox'}
              checked={check2}
              style={{ backgroundColor: 'white', margin: 0, padding: 0 }} //
              color="primary"
              onChange={event2}
              id={'sakujo'}
              disabled={disabled}
              className={classes.cursor}
            />
          </div>
        </div>
      </Grid>
    </Grid>
  )
}

export default React.memo(M_FieldLogKbn)
