import React, { useEffect } from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import M_FieldText from 'components/1_Atoms/2_Molecules/M_FieldTextNew'
import M_FieldDateSet from 'components/1_Atoms/2_Molecules/M_FieldDateSetNew'
import M_FieldSelect from 'components/1_Atoms/2_Molecules/M_FieldSelectNew'
import M_ButtonSubmit from 'components/1_Atoms/2_Molecules/M_ButtonSubmit'
import M_FieldJotaiJapell from 'components/1_Atoms/2_Molecules/M_FieldJotaiJapell'
import { organismGroupLog } from 'utils/logUtil'
import { baseInfo } from 'types/SearchTypes'
import { useAddTanto, useBusho, useDate, useJigyosho, useKanriNo, useKen, useSearch, useShiiresaki, useShohin, useTanto, useTokuisaki } from 'hooks/useHanJoken'
import { postFetcher } from 'SWRProvider'
import { commonMsg, msgCode } from 'assets/MessageCode'
import { useQuery } from 'react-query'

const manualMode = process.env.REACT_APP_MANUAL_MODE === '1'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      // height: window.innerHeight * 0.3,
    },
    grid: {
      flexGrow: 1,
    },
    box: {
      textAlign: 'left',
    },
    impotant: {
      color: '#FF0000',
    },
  }),
)

type Props = {
  info: baseInfo
}

const O_SearchBox = ({ info }: Props) => {
  useEffect(() => organismGroupLog('O_SearchBox'))
  const classes = useStyles()
  const [TEKIYO_START_DT, TEKIYO_END_DT, changeStartDt, changeEndDt] = useDate()
  const [SIIRE_CD, changeSiire] = useShiiresaki()
  const [TOKUI_CD, changeTokui] = useTokuisaki()
  const [JIGYO_CD, changeJigyo] = useJigyosho()
  const [BUSHO_CD, changeBusho] = useBusho()
  const [TANTO_CD, changeTanto] = useTanto()
  const [ADD_TANTO_CD, changeAddTanto] = useAddTanto()
  const [KEN_NM, changeKen] = useKen()
  const [JOKEN_KANRI_NO, changeKanriNo] = useKanriNo()
  const [mishonin, shonin, _hinin, jochoShonin, jochoMishonin, hininMikakunin, jochoHininMikakunin, hininkakuninzumi, _, changeMishonin, changeShonin, _changeHinin, changeJochoShonin, changeJochoMishonin, changeHininMikakunin, changeJochoHininMikakunin, changeHininkakuninzumi, __] = useShohin()
  const [detailView, clickReset] = useSearch()

  // 検索条件初期化ボタンの文言を取得
  const fetcher = postFetcher('/getMessage', msgCode.JAPELL_RESET_BUTTON)
  const { data, isFetching, isLoading, error } = useQuery(['postResetButtonWordJapell', []], fetcher)
  const resetButton = error ? commonMsg.ERROR_MSG : isFetching || isLoading ? 'Loading...' : data.body

  const getTextOrText = (label: string, valueCode: string, change?: (e: React.ChangeEvent<HTMLInputElement>) => void, blur?: (e: React.FocusEvent<HTMLInputElement>) => void, type: string = 'text') => {
    if (detailView) return <M_FieldText label={label} valueCode={valueCode} disabled={true} type={type} />
    return <M_FieldText label={label} valueCode={valueCode} max={40} change={change} blurFunc={blur} type={type} />
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        {/* //////////////////// 1 行目 //////////////////// */}
        <Grid item xs={12} sm={4} className={classes.grid}>
          {/* ==== 年月 ==== */}
          <M_FieldDateSet
            key1={1} //
            label="年月日"
            value1={TEKIYO_START_DT}
            blurFunc1={changeStartDt}
            focus={false}
            disabled1={detailView}
            key2={2}
            value2={TEKIYO_END_DT}
            blurFunc2={changeEndDt}
            disabled2={detailView}
            type="date"
          />
        </Grid>
        <Grid item xs={12} sm={4} className={classes.grid}>
          {/* ==== 仕入先 ==== */}
          <M_FieldSelect //
            label="仕入先"
            valueCode={SIIRE_CD}
            valueSet={info.siireList}
            event={changeSiire}
            disabled={detailView}
            blackout={manualMode}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          {/* {/* ==== 入力担当者 ==== */}
          <Grid container justifyContent="flex-end">
            <M_FieldSelect //
              label="入力担当者"
              valueCode={ADD_TANTO_CD}
              valueSet={info.addTantoList}
              event={changeAddTanto}
              disabled={detailView}
              blackout={manualMode}
            />
          </Grid>
        </Grid>

        {/* //////////////////// 2 行目 //////////////////// */}
        <Grid item xs={12} sm={4} className={classes.grid}>
          {/* ==== 得意先 ==== */}
          <M_FieldSelect //
            label="得意先"
            valueCode={TOKUI_CD}
            valueSet={info.tokuiList}
            event={changeTokui}
            disabled={detailView}
            blackout={manualMode}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          {/* ==== 事業所 ==== */}
          <M_FieldSelect //
            label="事業所"
            valueCode={JIGYO_CD}
            valueSet={info.jigyoList}
            event={changeJigyo}
            disabled={detailView}
            blackout={manualMode}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          {/* -------- [ CSV登録ボタン ] -------- */}
          {/* <Grid container justifyContent="flex-end">
            <M_ButtonSubmit label={'CSV登録'} event={clickInputCSV} />
          </Grid> */}
        </Grid>

        {/* //////////////////// 3 行目 //////////////////// */}
        <Grid item xs={12} sm={4} className={classes.grid}>
          {/* ==== 課 ==== */}
          <M_FieldSelect //
            label="課"
            valueCode={BUSHO_CD}
            valueSet={info.bushoList}
            event={changeBusho}
            disabled={detailView}
            blackout={manualMode}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          {/* ==== 担当者 ==== */}
          <M_FieldSelect //
            label="担当者"
            valueCode={TANTO_CD}
            valueSet={info.tantoList}
            event={changeTanto}
            disabled={detailView}
            blackout={manualMode}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          {/* -------- [ 確認対象表示ボタン ] -------- */}
          <Grid container justifyContent="flex-end">
            {detailView ? null : <M_ButtonSubmit label={resetButton} event={clickReset} disabled={detailView} />}
          </Grid>
        </Grid>

        {/* //////////////////// 4 行目 //////////////////// */}
        <Grid item xs={12} sm={4} className={classes.grid}>
          {/* ==== 件名 ==== */}
          {getTextOrText('件名', KEN_NM, undefined, changeKen)}
        </Grid>
        <Grid item xs={12} sm={4}>
          {/* ==== 管理No ==== */}
          {getTextOrText('管理No', JOKEN_KANRI_NO, undefined, changeKanriNo, 'number')}
        </Grid>
        <Grid item xs={12} sm={4}></Grid>

        {/* //////////////////// 5 行目 //////////////////// */}
        <Grid item xs={12} sm={8} className={classes.grid}>
          {/* ==== 状態 ==== */}
          <M_FieldJotaiJapell
            label1={'仕入先承認状態'} //
            label2={'上長承認状態'} //
            check1={mishonin}
            check2={shonin}
            check3={hininMikakunin}
            check4={hininkakuninzumi}
            check5={jochoMishonin}
            check6={jochoHininMikakunin}
            // check7={jochoHininKakuninzumi}
            check8={jochoShonin}
            event1={changeMishonin}
            event2={changeShonin}
            event3={changeHininMikakunin}
            event4={changeHininkakuninzumi}
            event5={changeJochoMishonin}
            event6={changeJochoHininMikakunin}
            // event7={changeJochoHininkakuninzumi}
            event8={changeJochoShonin}
            disabled={detailView}
          />
        </Grid>
        <Grid item xs={12} sm={4}></Grid>
      </Grid>
    </div>
  )
}
export default React.memo(O_SearchBox)
