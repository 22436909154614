import { SearchLogAction } from 'reducers/SearchReducers/SearchLogReducer'
import { select } from 'types/SearchTypes'
import { fixNullValue } from 'utils/numberUtil'

// 事業所リストを変更する処理 //================================//
export const changeJigyoList = (jigyoList: select[]): SearchLogAction => {
  return {
    type: 'SearchLogChangeJigyoList',
    jigyoList: jigyoList,
  }
}

// 初期描画フラグを変更する処理 //================================//
export const changeInitView = (initView: number): SearchLogAction => {
  return {
    type: 'SearchLogChangeInitView',
    initView: initView,
  }
}

// メールアドレスを取得できているかを変更する処理 //================================//
export const changeMailError = (mailError: boolean): SearchLogAction => {
  return {
    type: 'SearchLogMailError',
    mailError: mailError,
  }
}

// 担当者コードを取得できているかを変更する処理 //================================//
export const changeTantoError = (tantoError: boolean): SearchLogAction => {
  return {
    type: 'SearchLogTantoError',
    tantoError: tantoError,
  }
}

// 得意先コードを取得できているかを変更する処理 //================================//
export const changeTokuisakiError = (tokuisakiError: boolean): SearchLogAction => {
  return {
    type: 'SearchLogTokuisakiError',
    tokuisakiError: tokuisakiError,
  }
}

// 仕入先コードを取得できているかを変更する処理 //================================//
export const changeShiiresakiError = (shiiresakiError: boolean): SearchLogAction => {
  return {
    type: 'SearchLogShiiresakiError',
    shiiresakiError: shiiresakiError,
  }
}

// 事業所コードを取得できているかを変更する処理 //================================//
export const changeJigyoshoError = (jigyoshoError: boolean): SearchLogAction => {
  return {
    type: 'SearchLogJigyoshoError',
    jigyoshoError: jigyoshoError,
  }
}

// 氏名を変更する処理 //================================//
export const changeName = (name: string): SearchLogAction => {
  return {
    type: 'SearchLogChangeName',
    name: name,
  }
}

// メールアドレスを変更する処理 //================================//
export const changeMail = (mail: string): SearchLogAction => {
  return {
    type: 'SearchLogChangeMail',
    mail: mail,
  }
}

// 得意先名を変更する処理 //================================//
export const changeTokuisakiName = (tokuisakiName: string): SearchLogAction => {
  return {
    type: 'SearchLogChangeTokuisakiName',
    tokuisakiName: tokuisakiName,
  }
}

// 得意先コードを変更する処理 //================================//
export const changeTokuisakiCode = (tokuisakiCode: string): SearchLogAction => {
  return {
    type: 'SearchLogChangeTokuisakiCode',
    tokuisakiCode: fixNullValue(tokuisakiCode),
  }
}

// 仕入先名を変更する処理 //================================//
export const changeShiiresakiName = (shiiresakiName: string): SearchLogAction => {
  return {
    type: 'SearchLogChangeShiiresakiName',
    shiiresakiName: shiiresakiName,
  }
}

// 仕入先コードを変更する処理 //================================//
export const changeShiiresakiCode = (shiiresakiCode: string): SearchLogAction => {
  return {
    type: 'SearchLogChangeShiiresakiCode',
    shiiresakiCode: fixNullValue(shiiresakiCode),
  }
}

// 事業所名を変更する処理 //================================//
export const changeJigyoshoName = (jigyoshoName: string): SearchLogAction => {
  return {
    type: 'SearchLogChangeJigyoshoName',
    jigyoshoName: jigyoshoName,
  }
}

// 事業所コードを変更する処理 //================================//
export const changeJigyoshoCode = (jigyoshoCode: string): SearchLogAction => {
  return {
    type: 'SearchLogChangeJigyoshoCode',
    jigyoshoCode: fixNullValue(jigyoshoCode),
  }
}

// 担当名を変更する処理 //================================//
export const changeTantoName = (tantoName: string): SearchLogAction => {
  return {
    type: 'SearchLogChangeTantoName',
    tantoName: tantoName,
  }
}

// 担当者コードを変更する処理 //================================//
export const changeTantoCode = (tantoCode: string): SearchLogAction => {
  return {
    type: 'SearchLogChangeTantoCode',
    tantoCode: fixNullValue(tantoCode),
  }
}

// 削除チェックボックスを変更する処理 //================================//
export const changeSakujoCheckbox = (): SearchLogAction => {
  return {
    type: 'SearchLogChangeSakujoCheckbox',
  }
}

// 登録チェックボックスを変更する処理 //================================//
export const changeTourokuCheckbox = (): SearchLogAction => {
  return {
    type: 'SearchLogChangeTourokuCheckbox',
  }
}

// 日時TOを変更する処理 //================================//
export const changeDateTimeTo = (dateTimeTo: string): SearchLogAction => {
  return {
    type: 'SearchLogChangeDateTimeTo',
    dateTimeTo: dateTimeTo,
  }
}

// 日時FROMを変更する処理 //================================//
export const changeDateTimeFrom = (dateTimeFrom: string): SearchLogAction => {
  return {
    type: 'SearchLogChangeDateTimeFrom',
    dateTimeFrom: dateTimeFrom,
  }
}

// 初期化する処理 //================================//
export const format = (): SearchLogAction => {
  return {
    type: 'SearchLogFormat',
  }
}
