import React, { useEffect } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import TableRow from '@material-ui/core/TableRow'
import { logTable } from 'assets/TableHeader'
import M_TableCell from 'components/1_Atoms/2_Molecules/M_TableCellHead'
import { organismLog } from 'utils/logUtil'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
    },
  }),
)

const width = logTable.width
const label = logTable.label

const O_TableHeaderLog = () => {
  useEffect(() => organismLog('O_TableHeaderLog'))
  const classes = useStyles()

  return (
    <TableRow hover className={classes.root}>
      {/* 日付 */}
      <M_TableCell category="data" width={width.ADD_YMD} title={label.ADD_YMD} data={label.ADD_YMD} />
      {/* 時間 */}
      <M_TableCell category="data" width={width.ADD_HNS} title={label.ADD_HNS} data={label.ADD_HNS} />
      {/* 登録・削除 */}
      <M_TableCell category="data" width={width.TOROKU_KBN} title={label.TOROKU_KBN} data={label.TOROKU_KBN} />
      {/* 事業所CD */}
      <M_TableCell category="data" width={width.JIGYO_CD} title={label.JIGYO_CD} data={label.JIGYO_CD} />
      {/* 事業所名 */}
      <M_TableCell category="data" width={width.JIGYO_NM} title={label.JIGYO_NM} data={label.JIGYO_NM} />
      {/* 仕入先CD */}
      <M_TableCell category="data" width={width.SIIRE_CD} title={label.SIIRE_CD} data={label.SIIRE_CD} />
      {/* 仕入先名 */}
      <M_TableCell category="data" width={width.SIIRE_NM} title={label.SIIRE_NM} data={label.SIIRE_NM} />
      {/* メールアドレス */}
      <M_TableCell category="data" width={width.MAIL} title={label.MAIL} data={label.MAIL} />
      {/* 氏名 */}
      <M_TableCell category="data" width={width.NAME} title={label.NAME} data={label.NAME} />
      {/* 担当者コード */}
      <M_TableCell category="data" width={width.TANTO_CD} title={label.TANTO_CD} data={label.TANTO_CD} />
      {/* 担当者名 */}
      <M_TableCell category="data" width={width.TANTO_NM} title={label.TANTO_NM} data={label.TANTO_NM} />
      {/* 得意先CD */}
      <M_TableCell category="data" width={width.TOKUI_CD} title={label.TOKUI_CD} data={label.TOKUI_CD} />
      {/* 得意先名 */}
      <M_TableCell category="data" width={width.TOKUI_NM} title={label.TOKUI_NM} data={label.TOKUI_NM} />
    </TableRow>
  )
}

export default React.memo(O_TableHeaderLog)
