import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SingleValue } from 'react-select'
import * as SearchAction from 'actions/SearchActions/SearchTenyuryokuAction' // 共通部分が多いActionはActionCreaterにまとめる
import * as TableCommonAction from 'actions/TableActions/TableAction'
import * as TableAction from 'actions/TableActions/TableJokenAction'
import * as ModalFileAction from 'actions/ModalFileAction'
import * as PageAction from 'actions/PageAction'
import { selectF, voidF, inputFocusF, voidBooleanF, voidS3FileF } from 'types/FunctionTypes'
import { getTimestampFromNumber } from 'utils/dateUtil'
import { convertNumber } from 'utils/numberUtil'
import { zeroPadding } from 'utils/stringUtil'
import { getRequestTenyuryokuDetail, getRequestTenyuryoku } from 'utils/apiUtil'
import { postFetcher } from 'SWRProvider'
import { s3FileInfo, searchTenyuryoku } from 'types/SearchTypes'
import { requestTenyuryokuDetail, requestTenyuryokuSummary } from 'types/RequestTypes'
import { jotai } from 'types/ReferTypes'
import { useGetIsHiddenPagination, useGetIsHiddenPaginationDetail } from './useTenyuryokuTable'

// 検索ボックスを開いているか否か //================================//
export const useExpand = (): [boolean, voidBooleanF] => {
  const dispatch = useDispatch()
  const expand = useSelector((state) => state.SearchTenyuryokuReducer.expand)
  const changeExpand = (expand: boolean) => {
    dispatch(SearchAction.changeExpand(expand))
  }
  return [expand, changeExpand]
}

// 検索後にメッセージを消さないフラグ //================================//
export const useIsNotDeleteMessage = (): [boolean, voidBooleanF] => {
  const dispatch = useDispatch()
  const isNotDeleteMessage = useSelector((state) => state.SearchTenyuryokuReducer.isNotDeleteMessage)
  const changeIsNotDeleteMessage = useCallback(
    (isNotDeleteMessage: boolean) => {
      dispatch(SearchAction.changeIsDeleteMessage(isNotDeleteMessage))
    },
    [isNotDeleteMessage],
  )
  return [isNotDeleteMessage, changeIsNotDeleteMessage]
}

// 処理票日付 //================================//
export const useSyoriYmd = (): [string, string, inputFocusF, inputFocusF] => {
  const dispatch = useDispatch()
  const SYORI_YMD_FROM = useSelector((state) => state.SearchTenyuryokuReducer.SearchTenyuryoku.SYORI_YMD_FROM)
  const SYORI_YMD_TO = useSelector((state) => state.SearchTenyuryokuReducer.SearchTenyuryoku.SYORI_YMD_TO)
  const changeSyoriYmdFrom = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      e.preventDefault()
      dispatch(SearchAction.changeSyoriYmdFrom(e.target.value))
      dispatch(TableCommonAction.changePage(0))
    },
    [SYORI_YMD_FROM],
  )
  const changeSyoriYmdTo = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      e.preventDefault()
      dispatch(SearchAction.changeSyoriYmdTo(e.target.value))
      dispatch(TableCommonAction.changePage(0))
    },
    [SYORI_YMD_TO],
  )
  return [getTimestampFromNumber(convertNumber(SYORI_YMD_FROM, 8)), getTimestampFromNumber(convertNumber(SYORI_YMD_TO, 8)), changeSyoriYmdFrom, changeSyoriYmdTo]
}

// 登録日 //================================//
export const useAddYmd = (): [string, string, inputFocusF, inputFocusF] => {
  const dispatch = useDispatch()
  const ADD_YMD_FROM = useSelector((state) => state.SearchTenyuryokuReducer.SearchTenyuryoku.ADD_YMD_FROM)
  const ADD_YMD_TO = useSelector((state) => state.SearchTenyuryokuReducer.SearchTenyuryoku.ADD_YMD_TO)
  const changeAddYmdFrom = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      e.preventDefault()
      dispatch(SearchAction.changeAddYmdFrom(e.target.value))
      dispatch(TableCommonAction.changePage(0))
    },
    [ADD_YMD_FROM],
  )
  const changeAddYmdTo = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      e.preventDefault()
      dispatch(SearchAction.changeAddYmdTo(e.target.value))
      dispatch(TableCommonAction.changePage(0))
    },
    [ADD_YMD_TO],
  )
  return [getTimestampFromNumber(convertNumber(ADD_YMD_FROM, 8)), getTimestampFromNumber(convertNumber(ADD_YMD_TO, 8)), changeAddYmdFrom, changeAddYmdTo]
}

// 相殺/入金日 //================================//
export const useNyukinYmd = (): [string, string, inputFocusF, inputFocusF] => {
  const dispatch = useDispatch()
  const NYUKIN_YMD_FROM = useSelector((state) => state.SearchTenyuryokuReducer.SearchTenyuryoku.NYUKIN_YMD_FROM)
  const NYUKIN_YMD_TO = useSelector((state) => state.SearchTenyuryokuReducer.SearchTenyuryoku.NYUKIN_YMD_TO)
  const changeNyukinYmdFrom = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      e.preventDefault()
      dispatch(SearchAction.changeNyukinYmdFrom(e.target.value))
      dispatch(TableCommonAction.changePage(0))
    },
    [NYUKIN_YMD_FROM],
  )
  const changeNyukinYmdTo = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      e.preventDefault()
      dispatch(SearchAction.changeNyukinYmdTo(e.target.value))
      dispatch(TableCommonAction.changePage(0))
    },
    [NYUKIN_YMD_TO],
  )
  return [getTimestampFromNumber(convertNumber(NYUKIN_YMD_FROM, 8)), getTimestampFromNumber(convertNumber(NYUKIN_YMD_TO, 8)), changeNyukinYmdFrom, changeNyukinYmdTo]
}

// 仕入先 //================================//
export const useShiiresaki = (): [string, selectF] => {
  const dispatch = useDispatch()
  const SIIRE_CD = useSelector((state) => state.SearchTenyuryokuReducer.SearchTenyuryoku.SIIRE_CD)
  const changeSiire = useCallback(
    (targetValue: SingleValue<any>) => {
      dispatch(SearchAction.changeShiiresakiCode(targetValue!.value))
      dispatch(TableCommonAction.changePage(0))
    },
    [SIIRE_CD],
  )
  return [String(SIIRE_CD), changeSiire]
}

// 事業所 //================================//
export const useJigyosho = (): [string, selectF] => {
  const dispatch = useDispatch()
  const JIGYO_CD = useSelector((state) => state.SearchTenyuryokuReducer.SearchTenyuryoku.JIGYO_CD)
  const changeJigyo = useCallback(
    (targetValue: SingleValue<any>) => {
      dispatch(SearchAction.changeJigyoshoCode(targetValue!.value))
      dispatch(TableCommonAction.changePage(0))
    },
    [JIGYO_CD],
  )
  return [String(JIGYO_CD), changeJigyo]
}

// 担当者 //================================//
export const useTanto = (): [string, selectF] => {
  const dispatch = useDispatch()
  const TANTO_CD = useSelector((state) => state.SearchTenyuryokuReducer.SearchTenyuryoku.TANTO_CD)
  const changeTanto = useCallback(
    (targetValue: SingleValue<any>) => {
      dispatch(SearchAction.changeTantoCode(targetValue!.value))
      dispatch(TableCommonAction.changePage(0))
    },
    [TANTO_CD],
  )
  return [String(TANTO_CD), changeTanto]
}

// 精算内容区分1 //================================//
export const useNaiyoKbn1 = (): [string, selectF] => {
  const dispatch = useDispatch()
  const NAIYO_KBN1 = useSelector((state) => state.SearchTenyuryokuReducer.SearchTenyuryoku.NAIYO_KBN1)
  const changeNaiyoKbn1 = useCallback(
    (targetValue: SingleValue<any>) => {
      dispatch(SearchAction.changeNaiyoKbn1(targetValue!.value))
      dispatch(TableCommonAction.changePage(0))
    },
    [NAIYO_KBN1],
  )
  return [NAIYO_KBN1 > -1 ? zeroPadding(NAIYO_KBN1, 2) : String(NAIYO_KBN1), changeNaiyoKbn1]
}

// 精算内容区分2 //================================//
export const useNaiyoKbn2 = (): [string, selectF] => {
  const dispatch = useDispatch()
  const NAIYO_KBN2 = useSelector((state) => state.SearchTenyuryokuReducer.SearchTenyuryoku.NAIYO_KBN2)
  const changeNaiyoKbn2 = useCallback(
    (targetValue: SingleValue<any>) => {
      dispatch(SearchAction.changeNaiyoKbn2(targetValue!.value))
      dispatch(TableCommonAction.changePage(0))
    },
    [NAIYO_KBN2],
  )
  return [NAIYO_KBN2 > -1 ? zeroPadding(NAIYO_KBN2, 4) : String(NAIYO_KBN2), changeNaiyoKbn2]
}

// 精算項目 //================================//
export const useSeisanKoumoku = (): [string, selectF] => {
  const dispatch = useDispatch()
  const SEISAN_KOUMOKU = useSelector((state) => state.SearchTenyuryokuReducer.SearchTenyuryoku.SEISAN_KOUMOKU)
  const changeSeisanKoumoku = useCallback(
    (targetValue: SingleValue<any>) => {
      dispatch(SearchAction.changeSeisanKoumoku(targetValue!.value))
      dispatch(TableCommonAction.changePage(0))
    },
    [SEISAN_KOUMOKU],
  )
  return [String(SEISAN_KOUMOKU), changeSeisanKoumoku]
}

// 処理No //================================//
export const useDenpyoNo = (): [string, inputFocusF] => {
  const dispatch = useDispatch()
  const DENPYO_NO = useSelector((state) => state.SearchTenyuryokuReducer.SearchTenyuryoku.DENPYO_NO)
  const changeDenpyoNo = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      e.preventDefault()
      dispatch(SearchAction.changeDenpyoNo(e.target.value))
      dispatch(TableCommonAction.changePage(0))
    },
    [DENPYO_NO],
  )
  return [DENPYO_NO, changeDenpyoNo]
}

// 件名 //================================//
export const useSeisanNaiyo = (): [string, inputFocusF] => {
  const dispatch = useDispatch()
  const SEISAN_NAIYO = useSelector((state) => state.SearchTenyuryokuReducer.SearchTenyuryoku.SEISAN_NAIYO)
  const changeSeisanNaiyo = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      e.preventDefault()
      dispatch(SearchAction.changeSeisanNaiyo(e.target.value))
      dispatch(TableCommonAction.changePage(0))
    },
    [SEISAN_NAIYO],
  )
  return [SEISAN_NAIYO, changeSeisanNaiyo]
}

// 状態 //================================//
export const useShohin = (): [boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, voidF, voidF, voidF, voidF, voidF, voidF, voidF, voidF, voidF] => {
  const dispatch = useDispatch()
  const mishonin = useSelector((state) => state.SearchTenyuryokuReducer.SearchTenyuryoku.mishoninCheckbox)
  const shonin = useSelector((state) => state.SearchTenyuryokuReducer.SearchTenyuryoku.shoninCheckbox)
  const hinin = useSelector((state) => state.SearchTenyuryokuReducer.SearchTenyuryoku.hininCheckbox)
  const jochoShonin = useSelector((state) => state.SearchTenyuryokuReducer.SearchTenyuryoku.jochoShoninCheckbox)
  const jochoMishonin = useSelector((state) => state.SearchTenyuryokuReducer.SearchTenyuryoku.jochoMishoninCheckbox)
  const hininMikakunin = useSelector((state) => state.SearchTenyuryokuReducer.SearchTenyuryoku.hininMikakuninCheckbox)
  const jochoHininMikakunin = useSelector((state) => state.SearchTenyuryokuReducer.SearchTenyuryoku.jochoHininMikakuninCheckbox)
  const hininkakuninzumi = useSelector((state) => state.SearchTenyuryokuReducer.SearchTenyuryoku.hininKakuninzumiCheckbox)
  const jochoHininKakuninzumi = useSelector((state) => state.SearchTenyuryokuReducer.SearchTenyuryoku.jochoHininKakuninzumiCheckbox)

  const changeMishonin = useCallback(() => {
    dispatch(SearchAction.changeMishoninCheckbox())
    dispatch(TableCommonAction.changePage(0))
  }, [])
  const changeShonin = useCallback(() => {
    dispatch(SearchAction.changeShoninCheckbox())
    dispatch(TableCommonAction.changePage(0))
  }, [])
  const changeHinin = useCallback(() => {
    dispatch(SearchAction.changeHininCheckbox())
    dispatch(TableCommonAction.changePage(0))
  }, [])
  const changeJochoMishonin = useCallback(() => {
    dispatch(SearchAction.changeJochoMishoninCheckbox())
    dispatch(TableCommonAction.changePage(0))
  }, [])
  const changeJochoShonin = useCallback(() => {
    dispatch(SearchAction.changeJochoShoninCheckbox())
    dispatch(TableCommonAction.changePage(0))
  }, [])
  const changeHininMikakunin = useCallback(() => {
    dispatch(SearchAction.changeHininMikakuninCheckbox())
    dispatch(TableCommonAction.changePage(0))
  }, [])
  const changeJochoHininMikakunin = useCallback(() => {
    dispatch(SearchAction.changeJochoHininMikakuninCheckbox())
    dispatch(TableCommonAction.changePage(0))
  }, [])
  const changeHininkakuninzumi = useCallback(() => {
    dispatch(SearchAction.changeHininkakuninzumiCheckbox())
    dispatch(TableCommonAction.changePage(0))
  }, [])
  const changeJochoHininkakuninzumi = useCallback(() => {
    dispatch(SearchAction.changeJochoHininkakuninzumiCheckbox())
    dispatch(TableCommonAction.changePage(0))
  }, [])
  return [mishonin, shonin, hinin, jochoShonin, jochoMishonin, hininMikakunin, jochoHininMikakunin, hininkakuninzumi, jochoHininKakuninzumi, changeMishonin, changeShonin, changeHinin, changeJochoShonin, changeJochoMishonin, changeHininMikakunin, changeJochoHininMikakunin, changeHininkakuninzumi, changeJochoHininkakuninzumi]
}

// ファイル //================================//
export const useFile = (): [s3FileInfo[], voidS3FileF] => {
  const dispatch = useDispatch()
  const file = useSelector((state) => state.SearchTenyuryokuReducer.SearchTenyuryoku.file)
  const changeFile = useCallback((file: s3FileInfo[]) => {
    dispatch(SearchAction.changeFile(file))
  }, [])
  return [file, changeFile]
}

// ボタン項目押下処理 //================================//
export const useSearch = (): [boolean, voidF, voidF] => {
  const dispatch = useDispatch()
  const detailView = useSelector((state) => state.SearchTenyuryokuReducer.detailView)
  const loading = useSelector((state) => state.PageReducer.loading)
  const TANTO_CD = useSelector((state) => state.LoginReducer.TANTO_CD)
  const keyInfo = useSelector((state) => state.LoginReducer.keyInfo)
  const userKind = useSelector((state) => state.LoginReducer.userKind)
  const isSuperUser = useSelector((state) => state.LoginReducer.isSuperUser)
  const isJocho = useSelector((state) => state.LoginReducer.isJocho)
  const isKoiki = useSelector((state) => state.LoginReducer.isKoiki)

  // CSV登録ボタン押下処理 //================================//
  const clickInputCSV = useCallback(() => {
    if (!loading) dispatch(ModalFileAction.open())
  }, [loading])

  // 検索条件リセットボタン押下処理 //================================//
  const clickReset = useCallback(() => {
    dispatch(PageAction.format())
    dispatch(SearchAction.format())
    if (userKind === 0) {
      // ジャペルユーザーの場合
      dispatch(SearchAction.setInitCode(keyInfo[0].JIGYO_CD, TANTO_CD, isSuperUser, isJocho, isKoiki)) // 所属部署に合わせた初期値をセット
    }
    dispatch(TableAction.format())
    dispatch(TableCommonAction.format())
    dispatch({ type: 'ModalFileFormat' })
  }, [userKind])

  return [detailView, clickReset, clickInputCSV]
}

// サマリフェッチャー //================================//
export const useFetcherSummary = (): [searchTenyuryoku, () => Promise<any>] => {
  const MAIL = useSelector((state) => state.LoginReducer.MAIL)
  const keyInfo = useSelector((state) => state.LoginReducer.keyInfo)
  const TANTO_CD = useSelector((state) => state.LoginReducer.TANTO_CD)
  const SearchTenyuryoku = useSelector((state) => state.SearchTenyuryokuReducer.SearchTenyuryoku)
  const isSuperUser = useSelector((state) => state.LoginReducer.isSuperUser)
  const isJocho = useSelector((state) => state.LoginReducer.isJocho)
  const isKoiki = useSelector((state) => state.LoginReducer.isKoiki)

  const page = useSelector((state) => state.TableReducer.page)
  const perPage = useSelector((state) => state.TableReducer.perPage)

  const detailView = useSelector((state) => state.SearchTenyuryokuReducer.detailView)
  const isHiddenPagination = useGetIsHiddenPagination()
  // 明細時はserachJokenにすべて値が入るため検索結果は1件に絞られる。
  // そのときページ内データ（例えば50-100）のみ取得するとその範囲のデータは0件になりプルダウンが表示できなくなる可能性があるので、明細時は全ページ分取得する
  const request: requestTenyuryokuSummary = getRequestTenyuryoku(MAIL, keyInfo, TANTO_CD, isSuperUser, isJocho, isKoiki, page, perPage, detailView ? true : isHiddenPagination, SearchTenyuryoku)
  return [
    SearchTenyuryoku,
    postFetcher('/postTenyuryokuSummary', request),
    //   MAIL: MAIL,
    //   keyInfo: keyInfo,
    //   TANTO_CD: TANTO_CD,
    //   searchTenyuryoku: getRequestTenyuryoku(SearchTenyuryoku),
    //   isSuperUser: isSuperUser,
    //   isJocho: isJocho,
    //   isKoiki: isKoiki,
    // }),
  ]
}

// 明細フェッチャー
export const useFetcherDetail = (): (() => Promise<any>) => {
  const userKind = useSelector((state) => state.LoginReducer.userKind)
  const TANTO_CD = useSelector((state) => state.LoginReducer.TANTO_CD)
  const MAIL = useSelector((state) => state.LoginReducer.MAIL)
  const DENPYO_NO = useSelector((state) => state.TableTenyuryokuReducer.clickNo)
  const searchTenyuryoku = useSelector((state) => state.SearchTenyuryokuReducer.SearchTenyuryoku)
  const TOKUI_CDS = useSelector((state) => state.TableTenyuryokuReducer.clickTokuiCds)

  const page = useSelector((state) => state.TableReducer.pageDetail)
  const perPage = useSelector((state) => state.TableReducer.perPageDetail)

  const JOTAI: jotai = {
    mishonin: searchTenyuryoku.mishoninCheckbox,
    jochoMishonin: searchTenyuryoku.jochoMishoninCheckbox,
    shonin: searchTenyuryoku.shoninCheckbox,
    jochoShonin: searchTenyuryoku.jochoShoninCheckbox,
    hinin: searchTenyuryoku.hininCheckbox,
    jochoHininMikakunin: searchTenyuryoku.jochoHininMikakuninCheckbox,
    hininMikakunin: searchTenyuryoku.hininMikakuninCheckbox,
    jochoHininKakuninzumi: searchTenyuryoku.jochoHininKakuninzumiCheckbox,
    hininkakuninzumi: searchTenyuryoku.hininKakuninzumiCheckbox,
  }
  const isHiddenPagination = useGetIsHiddenPaginationDetail()
  const request: requestTenyuryokuDetail = getRequestTenyuryokuDetail(userKind, MAIL, TANTO_CD, DENPYO_NO, JOTAI, TOKUI_CDS, page, perPage, isHiddenPagination)

  return postFetcher('/postTenyuryokuDetail', request)
  //   userKind: userKind,
  //   DENPYO_NO: DENPYO_NO,
  //   JOTAI: {
  //     mishonin: searchTenyuryoku.mishoninCheckbox,
  //     jochoMishonin: searchTenyuryoku.jochoMishoninCheckbox,
  //     shonin: searchTenyuryoku.shoninCheckbox,
  //     jochoShonin: searchTenyuryoku.jochoShoninCheckbox,
  //     hinin: searchTenyuryoku.hininCheckbox,
  //     jochoHininMikakunin: searchTenyuryoku.jochoHininMikakuninCheckbox,
  //     hininMikakunin: searchTenyuryoku.hininMikakuninCheckbox,
  //     jochoHininKakuninzumi: searchTenyuryoku.jochoHininKakuninzumiCheckbox,
  //     hininkakuninzumi: searchTenyuryoku.hininKakuninzumiCheckbox,
  //   },
  //   TOKUI_CDS: TOKUI_CDS,
  // })
}

// サマリ初期描画調整 //================================//
export const useInitProcessSummary = () => {
  const dispatch = useDispatch()
  const detailView = useSelector((state) => state.SearchJokenReducer.detailView)
  const initProcess = useCallback(() => {
    dispatch(TableCommonAction.formatPageDetail())
    if (detailView) dispatch(SearchAction.closeDetail())
  }, [detailView])
  return initProcess
}

// 明細初期描画調整 //================================//
export const useInitProcessDetail = (path: string, history: any) => {
  const clickNo = useSelector((state) => state.TableTenyuryokuReducer.clickNo)
  const initProcess = useCallback(() => {
    const DENPYO_NO = path.split('/')[path.split('/').length - 1]
    if (clickNo !== DENPYO_NO) {
      // 管理NOリンク以外からの遷移なのでサマリに戻す
      history.push('/tenyuryoku')
    }
  }, [clickNo])
  return initProcess
}
