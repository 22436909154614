/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Grid from '@material-ui/core/Grid'
import * as TableAction from 'actions/TableActions/TableUserAction'
import O_TableBarUserDetail from 'components/3_Organisms/O_TableBarUserDetail'
import O_TableRowUserDetail from 'components/3_Organisms/O_TableRowUserDetail'
import O_TableHeaderUserDetail from 'components/3_Organisms/O_TableHeaderUserDetail'
import O_AddUser from 'components/3_Organisms/Groups/O_AddUser'
import { organismGroupLog } from 'utils/logUtil'
import { isUniqueUser } from 'utils/booleanUtil'
import { useButtons, useSearchedFormatDetail } from 'hooks/useUserTorokuTable'
import { useFetcherDetail } from 'hooks/useUserTorokuForm'
import { responseDetailUser } from 'types/ResponseTypes'
import { baseInfo } from 'types/SearchTypes'
import { useChangeTableHeight, useChangeInnerHeight } from 'hooks/useCommon'
import { TableVirtuoso } from 'react-virtuoso'
import { useLoading, usePageMessage } from 'hooks/usePage'
import { commonMsg } from 'assets/MessageCode'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
    },
    container: {
      maxHeight: 600,
    },
  }),
)

type Props = {
  info: baseInfo
}

const O_TableUserDetail = ({ info }: Props) => {
  const location = useLocation()
  const history = useHistory()
  const JIGYO_CD = useSelector((state) => state.TableUserReducer.JIGYO_CD)
  const SIIRE_CD = useSelector((state) => state.TableUserReducer.SIIRE_CD)
  useEffect(() => {
    organismGroupLog('O_TableUserDetail')
    const pathParams = location.pathname.split('/')[location.pathname.split('/').length - 1].split('_')
    const JIGYO_CD_PARAM = parseInt(pathParams[0]) ?? -1
    const SIIRE_CD_PARAM = parseInt(pathParams[1]) ?? -1
    if (JIGYO_CD_PARAM !== JIGYO_CD || SIIRE_CD_PARAM !== SIIRE_CD) {
      // 仕入先コードリンク以外からの遷移なのでサマリに戻す
      history.push('/userToroku')
    }
  })
  const classes = useStyles()
  const dispatch = useDispatch()
  const checkList = useSelector((state) => state.TableUserReducer.checkListDetail)
  const page = useSelector((state) => state.TableReducer.page)
  const perPage = useSelector((state) => state.TableReducer.perPage)
  const [clickSendMail, clickAdd, clickUpdate, clickDelete, clickBack] = useButtons(info.jigyoList, info.siireList, history)
  const isChangeTokuiCd = checkList.some((data) => data.TOKUI_CD !== data.INP_TOKUI_CD)
  const isChangeMail = checkList.some((data) => !(data.MAIL && data.MAIL === data.INP_MAIL))
  const searchedFormat = useSearchedFormatDetail()
  const [CODE, fetcherDetail] = useFetcherDetail()
  const [tableHeight, setTableHeight] = useState(window.innerHeight)
  const [currentInnerHeight, setCurrentInnerHeight] = useState(window.innerHeight)
  const [previousInnerHeight, setPreviousInnerHeight] = useState(window.innerHeight)
  const [changeTableHeight] = useChangeTableHeight(tableHeight, currentInnerHeight, previousInnerHeight, setTableHeight)
  const [changeInnerHeight] = useChangeInnerHeight(currentInnerHeight, setCurrentInnerHeight, setPreviousInnerHeight)
  const [changePageErrorMessage] = usePageMessage()

  const { data: data, refetch: refetch, isFetching: isFetching, isLoading: isLoading, error: error } = useQuery(['postUserDetail', CODE], fetcherDetail)
  useEffect(() => {
    // ウィンドウサイズ変更時にchangeInnerHeightを呼び出すようにリスナーに登録
    window.removeEventListener('resize', changeInnerHeight)
    window.addEventListener('resize', changeInnerHeight)
    return () => {
      window.removeEventListener('resize', changeInnerHeight)
    }
  }, [window.innerHeight, currentInnerHeight])
  useEffect(() => {
    // useEffectが呼ばれるのはウィンドウサイズを変えたとき、スクロール含めた高さが変わったとき（初期表示時にも調整するため）
    // ウィンドウサイズを変えると上記2パターンに引っ掛かり2回動くので
    // changeTableHeightを呼ぶのはcurrentInnerHeightを更新した後のみにする
    if (!isFetching && !isLoading && currentInnerHeight === window.innerHeight) changeTableHeight()
  }, [document.documentElement.scrollHeight, currentInnerHeight, isFetching, isLoading])
  const [loding, changeLoading] = useLoading()
  useEffect(() => {
    if (isLoading || isFetching) {
      changeLoading(true)
    }
    if (!(isLoading || isFetching) && loding) {
      changeLoading(false)
    }
  }, [isLoading, isFetching])

  const userList = data?.body
  const list = true ? userList : userList.slice(page * perPage, page * perPage + perPage) // ページネーションを実装する場合は変更すること
  // 全チェック処理 //================================//
  const handleAllClick = (userList: responseDetailUser[]) => {
    return () => {
      dispatch(TableAction.allCheckUser(getIsAllChecked(), userList))
    }
  }
  const getIsAllChecked = () => {
    if (!userList) return false
    if (list.length === 0) return false
    return list.length === checkList.length
  }
  const getIsChecked = (data: responseDetailUser) => {
    if (getIsAllChecked()) return true
    if (checkList.length <= 0) return false
    return checkList.some((r) => isUniqueUser(r, { JIGYO_CD: JIGYO_CD, SIIRE_CD: SIIRE_CD, TOKUI_CD: data.TOKUI_CD }))
  }
  const table = (props: any) => <Table {...props} style={{ borderCollapse: 'separate' }} size="small" aria-label="purchases" />
  const tableRow = (props: any) => <TableRow hover role="checkbox" {...props} selected={getIsChecked(props.item)} />
  const getComponent = useCallback(() => {
    return {
      Scroller: TableContainer,
      // Scroller: React.forwardRef((props, ref) => <TableContainer component={Paper} {...props} className={classes.container} ref={ref} />),
      Table: table,
      TableHead: TableHead,
      TableRow: tableRow,
      // TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
      TableBody: TableBody,
    }
  }, [checkList.length])

  // SWR情報の取得
  if (isLoading) {
    searchedFormat()
    return <div>Loading...</div>
  }
  if (isFetching) {
    searchedFormat()
    return <div>検索中...</div>
  }
  if (error) {
    changePageErrorMessage(commonMsg.ERROR_MSG)
    return <></>
  }

  const convert = (data: responseDetailUser) => {
    const checkData = checkList.filter((r) => isUniqueUser(r, { JIGYO_CD: JIGYO_CD, SIIRE_CD: SIIRE_CD, TOKUI_CD: data.TOKUI_CD }))
    const isChecked = checkData.length > 0
    let detail: responseDetailUser = data
    detail.INP_TOKUI_CD = data.TOKUI_CD
    detail.INP_MAIL = data.MAIL
    if (isChecked) {
      detail = {
        TOKUI_CD: checkData[0].TOKUI_CD,
        INP_TOKUI_CD: checkData[0].INP_TOKUI_CD,
        TOKUI_NM: checkData[0].TOKUI_NM,
        INP_MAIL: checkData[0].INP_MAIL,
        MAIL: checkData[0].MAIL,
        NAME: checkData[0].NAME,
        RIYO_KBN: data.RIYO_KBN,
      }
    }
    return {
      isChecked: isChecked,
      detail: detail,
    }
  }
  const getTableBar = () => {
    const isChecked = checkList.length > 0
    return (
      <O_TableBarUserDetail
        count={list.length} //
        checkedList={[isChecked && !isChangeMail, true, isChecked && (isChangeMail || isChangeTokuiCd), isChecked && !isChangeTokuiCd, true]}
        clickSendMail={clickSendMail}
        clickAdd={clickAdd}
        clickUpdate={clickUpdate}
        clickDelete={clickDelete}
        clickBack={clickBack}
        refetch={refetch}
      />
    )
  }
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item style={{ width: '100%' }}>
          {getTableBar()}
        </Grid>
        <Grid item style={{ width: '100%' }}>
          <TableVirtuoso
            style={{ height: tableHeight }} // 仮想リスト(表示範囲250以降はスクロール毎に描画する)
            data={list}
            components={getComponent()}
            fixedHeaderContent={() => <O_TableHeaderUserDetail click={handleAllClick(list)} isChecked={getIsAllChecked()} />}
            itemContent={(_, user) => {
              const info = convert(user)
              return <O_TableRowUserDetail isChecked={info.isChecked} detail={info.detail} />
            }}
          />
        </Grid>
        <Grid item style={{ width: '100%' }}>
          {getTableBar()}
        </Grid>
      </Grid>
      {/* ユーザー追加ダイアログ */}
      <O_AddUser refetch={refetch} />
    </div>
  )
}

export default React.memo(O_TableUserDetail)
