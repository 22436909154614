import React, { useCallback, useEffect } from 'react'
import TableCell from '@material-ui/core/TableCell'
import { HtmlToolTip } from 'assets/ToolTips'
import { getStyle } from 'assets/TableHeader'
import Typography from '@material-ui/core/Typography'
import { responseSummaryTenyuryoku } from 'types/ResponseTypes'
import { getTimestampFromNumber } from 'utils/dateUtil'
import { tenyuryokuTableSummary } from 'assets/TableHeader'
import M_TableCell from 'components/1_Atoms/2_Molecules/M_TableCell'
import { organismLog } from 'utils/logUtil'
import { useDispatch, useSelector } from 'react-redux'
import * as TableAction from 'actions/TableActions/TableTenyuryokuAction'
import * as SearchAction from 'actions/SearchActions/SearchTenyuryokuAction' // 共通部分が多いActionはActionCreaterにまとめる
import * as PageAction from 'actions/PageAction'
import { Link } from 'react-router-dom'
import { checkListSummaryTenyuryoku, s3FileInfo } from 'types/SearchTypes'
import { useUser } from 'hooks/useLogin'
import { fixPrice } from 'utils/stringUtil'

const width = tenyuryokuTableSummary.width
const manualMode = process.env.REACT_APP_MANUAL_MODE === '1'

type Props = {
  tenyuryoku: responseSummaryTenyuryoku
  isChecked: boolean
  no: number
}

const O_TableRowTenyuryoku = ({ tenyuryoku, isChecked, no }: Props) => {
  useEffect(() => organismLog('O_TableRowTenyuryokuSummary'))
  const dispatch = useDispatch()
  const loading = useSelector((state) => state.PageReducer.loading)
  const userKind = useUser()

  // 明細画面開示処理 //================================//
  const openDetail = useCallback(() => {
    if (!loading) {
      dispatch(PageAction.deleteMessage())
      dispatch(TableAction.clickDenpyo(tenyuryoku.DENPYO_NO, tenyuryoku.TOKUI_CDS))
      dispatch(SearchAction.openDetail(tenyuryoku))
    }
  }, [loading])

  // チェック処理 //================================//
  const handleRowClick = useCallback((isChecked: boolean, tenyuryoku: responseSummaryTenyuryoku) => {
    const data: checkListSummaryTenyuryoku = {
      DENPYO_NO: tenyuryoku.DENPYO_NO,
      JOTAI_KBN_0: tenyuryoku.JOTAI_KBN_0,
      JOTAI_KBN_1: tenyuryoku.JOTAI_KBN_1,
      JOTAI_KBN_2_1: tenyuryoku.JOTAI_KBN_2_1,
      TOKUI_CDS: tenyuryoku.TOKUI_CDS,
    }
    dispatch(TableAction.check(isChecked, data))
  }, [])

  const TableData = (width: number | string, title: string, align: 'inherit' | 'left' | 'center' | 'right' | 'justify' | undefined, click: React.MouseEventHandler<HTMLSpanElement>) => {
    return (
      <TableCell style={getStyle(width)} align={align}>
        <HtmlToolTip
          title={
            <Typography variant="caption" color="inherit">
              {title}
            </Typography>
          }
        >
          <Typography variant="caption" onClick={click}>
            <Link to={loading ? `tenyuryoku` : `tenyuryoku/${title}`}>{title}</Link>
          </Typography>
        </HtmlToolTip>
      </TableCell>
    )
  }

  return (
    <>
      {/* No */}
      <M_TableCell category="number" data={no} width={width.NO} />
      {/* チェックボックス */}
      <M_TableCell category="checkbox" isChecked={isChecked} width={width.CHECK} click={() => handleRowClick(isChecked, tenyuryoku)} />
      {/* 状態 */}
      <M_TableCell
        category="data" //
        width={width.JOTAI}
        align={'left'}
        title={tenyuryoku.JOTAI}
        data={tenyuryoku.JOTAI}
      />
      {
        /* 上長承認状態 */
        userKind === 0 ? (
          <M_TableCell
            category="data" //
            width={width.JOCHO_JOTAI}
            align={'left'}
            title={tenyuryoku.JOCHO_JOTAI}
            data={tenyuryoku.JOCHO_JOTAI}
          />
        ) : (
          <></>
        )
      }
      {/* 処理No */}
      {TableData(width.DENPYO_NO, tenyuryoku.DENPYO_NO, 'left', openDetail)}
      {/* 処理票日付 */}
      <M_TableCell
        category="data" //
        width={width.SYORI_YMD}
        align={'left'}
        title={getTimestampFromNumber(tenyuryoku.SYORI_YMD, '/')}
        data={getTimestampFromNumber(tenyuryoku.SYORI_YMD, '/')}
        blackout={manualMode}
      />
      {/* 件名 */}
      <M_TableCell
        category="data" //
        width={width.SEISAN_NAIYO}
        align={'left'}
        title={tenyuryoku.SEISAN_NAIYO}
        data={tenyuryoku.SEISAN_NAIYO}
        blackout={manualMode}
      />
      {/* 事業所名 */}
      <M_TableCell
        category="data" //
        width={width.JIGYO_NM}
        align={'left'}
        title={tenyuryoku.JIGYO_NM}
        data={tenyuryoku.JIGYO_NM}
        blackout={manualMode}
      />
      {/* 仕入先名 */}
      <M_TableCell
        category="data" //
        width={width.SIIRE_NM}
        align={'left'}
        title={tenyuryoku.SIIRE_NM}
        data={tenyuryoku.SIIRE_NM}
        blackout={manualMode}
      />
      {/* 担当者名 */}
      <M_TableCell
        category="data" //
        width={width.TANTO_NM}
        align={'left'}
        title={tenyuryoku.TANTO_NM}
        data={tenyuryoku.TANTO_NM}
        blackout={manualMode}
      />
      {/* 精算金額 */}
      <M_TableCell
        category="data" //
        width={width.SEISAN_TOTAL_GK}
        align={'right'}
        title={fixPrice(tenyuryoku.SEISAN_TOTAL_GK)}
        data={fixPrice(tenyuryoku.SEISAN_TOTAL_GK)}
        blackout={manualMode}
      />
      {/* ファイル */}
      <M_TableCell
        category="data" //
        width={width.existFile}
        align={'left'}
        title={
          tenyuryoku.files.length > 0
            ? tenyuryoku.files.reduce((prev: string, curr: s3FileInfo) => {
                return prev + curr.name + '\n'
              }, '')
            : ''
        }
        data={tenyuryoku.files.length > 0 ? '有' : ''}
      />
      {/* 備考 */}
      <M_TableCell
        category="data" //
        width={width.BIKOU}
        align={'left'}
        title={tenyuryoku.BIKOU}
        data={tenyuryoku.BIKOU}
        blackout={manualMode}
      />
    </>
  )
}

export default React.memo(O_TableRowTenyuryoku)
