/* eslint-disable react/jsx-pascal-case */
import React, { useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles, createStyles } from '@material-ui/core/styles'
// import A_IconMust from '../A_IconMust'
import { moleculeLog } from 'utils/logUtil'
import A_FieldCheckBox from '../A_FieldCheckBox'
// import { jotaiList } from 'stubs/mockData'

type Props = {
  label: string
  // must?: boolean
  // valueSet: select[]
  check1: boolean
  check2: boolean
  check3: boolean
  event1: (e: React.ChangeEvent<HTMLInputElement>) => void
  event2: (e: React.ChangeEvent<HTMLInputElement>) => void
  event3: (e: React.ChangeEvent<HTMLInputElement>) => void
  // lib?: boolean
  disabled: boolean
}

const useStyles = makeStyles(() =>
  createStyles({
    item: {
      border: '1px solid #000000',
      marginRight: 10,
    },
    centerPosition: {
      display: 'flex',
      // justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
  }),
)

const M_FieldJotai = ({ label, check1, check2, check3, event1, event2, event3, disabled }: Props) => {
  useEffect(() => moleculeLog('M_FieldJotai'))
  const classes = useStyles()
  // type Jotai = {value: string, label: string}
  // type JotaiCheckbox = {value: string, label: string, no?: number, check: boolean}
  // const items = jotaiList.reduce((acc: JotaiCheckbox[], current:Jotai) => {
  //   if(current.value === '0') return acc

  //   const item: JotaiCheckbox = {...current, no: undefined, check: false}
  //   if(current.value === '1') {
  //     item.no = 1;
  //   } else {
  //     item.no = 2;
  //   }

  //   return [...acc, item];
  // },[])
  return (
    <Grid container justify="flex-start">
      <Grid item xs={12} sm={3}>
        <Typography variant="body1" display="block" gutterBottom>
          {label}
          {/* <A_IconMust must={must} /> */}
        </Typography>
      </Grid>
      {/* {
        items.filter((data) => { return data.no === 1}).map((data) => {
          return (
            <Grid item xs={12} sm={2} className={classes.item}>
              <div className={classes.centerPosition}>
                <A_FieldCheckBox label={data.label} check={check} change={() => {}}/>
              </div>
            </Grid>
          )
        })
      }
      <Grid item xs={12} sm={2} className={classes.item}>
        <div className={classes.centerPosition}>
          <div>
          {
            items.filter((data) => { return data.no === 2}).map((data) => {
              return (
                <A_FieldCheckBox label={data.label} check={check} change={() => {}}/>
              )
            })
          }
          </div>
        </div>
      </Grid> */}
      <Grid item xs={12} sm={3} className={classes.item}>
        <div className={classes.centerPosition}>
          <A_FieldCheckBox label="未承認" check={check1} change={event1} disabled={disabled} />
        </div>
      </Grid>
      <Grid item xs={12} sm={3} className={classes.item}>
        <div className={classes.centerPosition}>
          <div>
            <A_FieldCheckBox label="承認" check={check2} change={event2} disabled={disabled} />
            <br />
            <A_FieldCheckBox label="否認" check={check3} change={event3} disabled={disabled} />
          </div>
        </div>
      </Grid>
    </Grid>
  )
}

export default React.memo(M_FieldJotai)
