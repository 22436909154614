import React, { useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import { moleculeLog } from 'utils/logUtil'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { s3FileInfo } from 'types/SearchTypes'
import { HtmlToolTip } from 'assets/ToolTips'

type Props = {
  file: s3FileInfo | File
  DENPYO_NO: string
  delClick?: (file: s3FileInfo | File) => void
  delDisabled: boolean
}

const useStyles = makeStyles(() =>
  createStyles({
    text: {
      width: '100%',
      display: 'block',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    link: {
      color: '#0000ff',
      cursor: 'pointer',
      textDecoration: 'underline',
    },
    button: {
      cursor: 'pointer',
      display: 'inline',
      paddingLeft: '5px',
      paddingRight: '5px',
      color: 'red',
      '&:hover': {
        backgroundColor: '#ffddaa',
      },
    },
  }),
)

const M_TextFile = ({ file, DENPYO_NO, delClick = () => {}, delDisabled = false }: Props) => {
  useEffect(() => moleculeLog('M_TextFile'))
  const classes = useStyles()

  return (
    <div style={{ display: 'flex', width: '100%' }}>
      <HtmlToolTip
        title={
          <Typography variant="caption" color="inherit">
            {file.name}
          </Typography>
        }
      >
        <Typography
          variant="caption"
          className={classes.text + ('isDel' in file ? ' ' + classes.link : '')}
          // onClick={
          //   'isDel' in file
          //     ? () => {
          //         linkClick(file)
          //       }
          //     : undefined
          // }
        >
          {'isDel' in file ? (
            <a href={`https://${window.location.hostname}/files/仕入精算金処理票/${DENPYO_NO}/${file.name}`} target="_blank" rel={'noopener'}>
              {file.name}
            </a>
          ) : (
            <>{file.name}</>
          )}
        </Typography>
      </HtmlToolTip>
      {delDisabled ? (
        <></>
      ) : (
        <Typography
          variant="body1"
          className={classes.button}
          onClick={() => {
            delClick(file)
          }}
        >
          {'×'}
        </Typography>
      )}
    </div>
  )
}

export default React.memo(M_TextFile)
