/* eslint-disable react/jsx-pascal-case */
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import O_FooterModalUser from 'components/3_Organisms/O_FooterModalUser'
import { DialogContent, DialogActions } from 'assets/DialogSet'
import { organismGroupLog } from 'utils/logUtil'
import M_FieldSelect from 'components/1_Atoms/2_Molecules/M_FieldSelectNew'
import { unDisplayNumber } from 'utils/numberUtil'
import M_FieldCodeBox from 'components/1_Atoms/2_Molecules/M_FieldCodeBox'
import { useInput, useButtons } from 'hooks/useUserModal'
import { voidF } from 'types/FunctionTypes'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      // width: '100ch',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  })

type Props = {
  refetch: voidF
}

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string
  children: React.ReactNode
  onClose: () => void
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props
  const loading = useSelector((state) => state.ModalUserReducer.loading)
  const messageColor = useSelector((state) => state.ModalUserReducer.messageColor)
  const message = useSelector((state) => state.ModalUserReducer.message)
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Grid container direction="row" alignItems="center">
        <Typography variant="h6">{children}</Typography>
        <Typography variant="h6">{'　　 '}</Typography>
        <Typography variant="body1">{loading ? <b style={{ color: 'green' }}>検索中...</b> : <b style={{ color: messageColor }}>{message}</b>}</Typography>
      </Grid>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const O_AddUser = ({ refetch }: Props) => {
  useEffect(() => organismGroupLog('O_AddUser'))
  // 表示切替え
  const openUser = useSelector((state) => state.ModalUserReducer.modalUser.openUser)
  const JIGYO_CD = unDisplayNumber(useSelector((state) => state.ModalUserReducer.modalUser.JIGYO_CD))
  const SIIRE_CD = unDisplayNumber(useSelector((state) => state.ModalUserReducer.modalUser.SIIRE_CD))
  const jigyoSet = useSelector((state) => state.ModalUserReducer.jigyoSet)
  const siireSet = useSelector((state) => state.ModalUserReducer.siireSet)
  const TOKUI_CD = unDisplayNumber(useSelector((state) => state.ModalUserReducer.modalUser.TOKUI_CD))
  const TOKUI_NM = useSelector((state) => state.ModalUserReducer.modalUser.TOKUI_NM)
  const MAIL = useSelector((state) => state.ModalUserReducer.modalUser.MAIL)
  const NAME = useSelector((state) => state.ModalUserReducer.modalUser.NAME)
  const status = useSelector((state) => state.ModalFileReducer.status)
  const message = useSelector((state) => state.ModalFileReducer.message)
  // const USER_CD = useSelector((state) => state.LoginReducer.USER_CD)
  const [changeTokuiCd, submitTokui, changeMail, submitMail] = useInput()
  const [clickInsert, clickClose] = useButtons(refetch)

  return (
    <Dialog fullWidth={true} maxWidth="md" onClose={clickClose} aria-labelledby="customized-dialog-title" open={openUser}>
      <DialogTitle id="customized-dialog-title" onClose={clickClose}>
        ユーザー追加
      </DialogTitle>
      <DialogContent dividers>
        <Grid container justify="flex-start" spacing={0}>
          <Grid item xs={12} sm={6}>
            {/* ==== 事業所 ==== */}
            <M_FieldSelect label="事業所" valueCode={JIGYO_CD} valueSet={jigyoSet} disabled={true} />
          </Grid>
          <Grid item xs={12} sm={6}>
            {/* ==== 仕入先 ==== */}
            <M_FieldSelect label="仕入先" valueCode={SIIRE_CD} valueSet={siireSet} disabled={true} />
          </Grid>
          <Grid item xs={12}>
            <br />
          </Grid>
          <Grid item xs={12}>
            {/* ==== 得意先 ==== */}
            <M_FieldCodeBox key={1} tab={1} label="得意先" valueCode={TOKUI_CD} valueName={TOKUI_NM} change={changeTokuiCd} submit={submitTokui} />
          </Grid>
          <Grid item xs={12}>
            {/* ==== メールアドレス ==== */}
            <M_FieldCodeBox key={2} tab={2} label="メールアドレス" valueCode={MAIL} valueName={NAME} change={changeMail} submit={submitMail} type="text" />
          </Grid>
        </Grid>
        {status === 1 ? (
          <Typography variant="body1">
            <b style={{ color: 'blue' }}>{message}</b>
          </Typography>
        ) : status === 0 ? (
          <Typography variant="body1">
            <b style={{ color: 'red' }}>{message}</b>
          </Typography>
        ) : (
          ''
        )}
      </DialogContent>
      <DialogActions>
        <O_FooterModalUser disabled={TOKUI_CD.length <= 0 || MAIL.length <= 0} clickInsert={clickInsert} clickClose={clickClose} />
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(O_AddUser)
