/* eslint-disable react/jsx-pascal-case */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import A_Button from '../1_Atoms/A_Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import * as PageAction from 'actions/PageAction'
import { organismLog } from 'utils/logUtil'

const O_DialogAlert = () => {
  useEffect(() => organismLog('O_DialogAlert'))
  const dispatch = useDispatch()
  const file = useSelector((state) => state.ModalFileReducer.file)
  const loading = useSelector((state) => state.ModalFileReducer.loading)
  const openAlert = useSelector((state) => state.PageReducer.openAlert)
  const error = useSelector((state) => state.PageReducer.error)
  const message = useSelector((state) => state.PageReducer.messageAlert)
  const submitFunction = useSelector((state) => state.PageReducer.submitFunction)
  const submit = async () => {
    if (file) await submitFunction(file)
    dispatch(PageAction.closeAlert())
  }
  const close = () => {
    if (loading) return
    dispatch(PageAction.closeAlert())
  }

  return (
    <div>
      <Dialog open={openAlert} onClose={close} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{ width: 300 }}>
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {loading ? (
            <>
              <Typography variant="body1">
                <b style={{ color: 'red' }}>{'取り込み中...'}</b>
              </Typography>
              <CircularProgress />
              <A_Button variant="contained" color="primary" label={'キャンセル'} event={close} />
              <A_Button variant="contained" color="secondary" label={'OK'} event={submit} disabled />
            </>
          ) : (
            <>
              {!error ? (
                <>
                  <A_Button variant="contained" color="primary" label={'キャンセル'} event={close} />
                  <A_Button variant="contained" color="secondary" label={'OK'} event={submit} />
                </>
              ) : (
                <A_Button variant="contained" color="secondary" label={'OK'} event={close} />
              )}
            </>
          )}
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default React.memo(O_DialogAlert)
