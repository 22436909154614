import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SingleValue } from 'react-select'
import * as SearchAction from 'actions/SearchActions/SearchJokenRitsuAction' // 共通部分が多いActionはActionCreaterにまとめる
import * as TableCommonAction from 'actions/TableActions/TableAction'
import * as TableAction from 'actions/TableActions/TableJokenRitsuAction'
import * as ModalFileAction from 'actions/ModalFileAction'
import * as PageAction from 'actions/PageAction'
import { selectF, voidF, inputFocusF, voidBooleanF } from 'types/FunctionTypes'
import { getRequestJokenRitsu } from 'utils/apiUtil'
import { postFetcher } from 'SWRProvider'
import { requestRitsuSearch } from 'types/RequestTypes'

// 検索後にメッセージを消さないフラグ //================================//
export const useIsNotDeleteMessage = (): [boolean, voidBooleanF] => {
  const dispatch = useDispatch()
  const isNotDeleteMessage = useSelector((state) => state.SearchJokenRitsuReducer.isNotDeleteMessage)
  const changeIsNotDeleteMessage = useCallback(
    (isNotDeleteMessage: boolean) => {
      dispatch(SearchAction.changeIsDeleteMessage(isNotDeleteMessage))
    },
    [isNotDeleteMessage],
  )
  return [isNotDeleteMessage, changeIsNotDeleteMessage]
}

// 年月日 //================================//
export const useDate = (): [number, number, inputFocusF, inputFocusF] => {
  const dispatch = useDispatch()
  const SYORI_YM_START = useSelector((state) => state.SearchJokenRitsuReducer.searchJokenRitsu.SYORI_YM_START)
  const SYORI_YM_END = useSelector((state) => state.SearchJokenRitsuReducer.searchJokenRitsu.SYORI_YM_END)
  const changeStartDate = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      e.preventDefault()
      dispatch(SearchAction.changeSyoriStartDate(e.target.value))
      dispatch(TableCommonAction.changePage(0))
    },
    [SYORI_YM_START],
  )
  const changeEndDate = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      e.preventDefault()
      dispatch(SearchAction.changeSyoriEndDate(e.target.value))
      dispatch(TableCommonAction.changePage(0))
    },
    [SYORI_YM_END],
  )
  return [SYORI_YM_START, SYORI_YM_END, changeStartDate, changeEndDate]
}

// 仕入先 //================================//
export const useShiiresaki = (): [string, selectF] => {
  const dispatch = useDispatch()
  const SIIRE_CD = useSelector((state) => state.SearchJokenRitsuReducer.searchJokenRitsu.SIIRE_CD)
  const changeSiire = useCallback(
    (targetValue: SingleValue<any>) => {
      dispatch(SearchAction.changeShiiresakiCode(targetValue!.value))
      dispatch(TableCommonAction.changePage(0))
    },
    [SIIRE_CD],
  )
  return [String(SIIRE_CD), changeSiire]
}

// 得意先 //================================//
export const useTokuisaki = (): [string, selectF] => {
  const dispatch = useDispatch()
  const TOKUI_CD = useSelector((state) => state.SearchJokenRitsuReducer.searchJokenRitsu.TOKUI_CD)
  const changeTokui = useCallback(
    (targetValue: SingleValue<any>) => {
      dispatch(SearchAction.changeTokuisakiCode(targetValue!.value))
      dispatch(TableCommonAction.changePage(0))
    },
    [TOKUI_CD],
  )
  return [String(TOKUI_CD), changeTokui]
}

// 事業所 //================================//
export const useJigyosho = (): [string, selectF] => {
  const dispatch = useDispatch()
  const JIGYO_CD = useSelector((state) => state.SearchJokenRitsuReducer.searchJokenRitsu.JIGYO_CD)
  const changeJigyo = useCallback(
    (targetValue: SingleValue<any>) => {
      dispatch(SearchAction.changeJigyoshoCode(targetValue!.value))
      dispatch(TableCommonAction.changePage(0))
    },
    [JIGYO_CD],
  )
  return [String(JIGYO_CD), changeJigyo]
}

// 入力担当者 //================================//
export const useAddTanto = (): [string, selectF] => {
  const dispatch = useDispatch()
  const ADD_TANTO_CD = useSelector((state) => state.SearchJokenRitsuReducer.searchJokenRitsu.ADD_TANTO_CD)
  const changeAddTanto = useCallback(
    (targetValue: SingleValue<any>) => {
      dispatch(SearchAction.changeAddTantoCode(targetValue!.value))
      dispatch(TableCommonAction.changePage(0))
    },
    [ADD_TANTO_CD],
  )
  return [String(ADD_TANTO_CD), changeAddTanto]
}

// 状態 //================================//
export const useShohin = (): [boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, voidF, voidF, voidF, voidF, voidF, voidF, voidF, voidF, voidF] => {
  const dispatch = useDispatch()
  const mishonin = useSelector((state) => state.SearchJokenRitsuReducer.searchJokenRitsu.mishoninCheckbox)
  const shonin = useSelector((state) => state.SearchJokenRitsuReducer.searchJokenRitsu.shoninCheckbox)
  const hinin = useSelector((state) => state.SearchJokenRitsuReducer.searchJokenRitsu.hininCheckbox)
  const jochoShonin = useSelector((state) => state.SearchJokenRitsuReducer.searchJokenRitsu.jochoShoninCheckbox)
  const jochoMishonin = useSelector((state) => state.SearchJokenRitsuReducer.searchJokenRitsu.jochoMishoninCheckbox)
  const hininMikakunin = useSelector((state) => state.SearchJokenRitsuReducer.searchJokenRitsu.hininMikakuninCheckbox)
  const jochoHininMikakunin = useSelector((state) => state.SearchJokenRitsuReducer.searchJokenRitsu.jochoHininMikakuninCheckbox)
  const hininkakuninzumi = useSelector((state) => state.SearchJokenRitsuReducer.searchJokenRitsu.hininKakuninzumiCheckbox)
  const jochoHininKakuninzumi = useSelector((state) => state.SearchJokenRitsuReducer.searchJokenRitsu.jochoHininKakuninzumiCheckbox)
  const changeMishonin = useCallback(() => {
    dispatch(SearchAction.changeMishoninCheckbox())
    dispatch(TableCommonAction.changePage(0))
  }, [])
  const changeShonin = useCallback(() => {
    dispatch(SearchAction.changeShoninCheckbox())
    dispatch(TableCommonAction.changePage(0))
  }, [])
  const changeHinin = useCallback(() => {
    dispatch(SearchAction.changeHininCheckbox())
    dispatch(TableCommonAction.changePage(0))
  }, [])
  const changeJochoMishonin = useCallback(() => {
    dispatch(SearchAction.changeJochoMishoninCheckbox())
    dispatch(TableCommonAction.changePage(0))
  }, [])
  const changeJochoShonin = useCallback(() => {
    dispatch(SearchAction.changeJochoShoninCheckbox())
    dispatch(TableCommonAction.changePage(0))
  }, [])
  const changeHininMikakunin = useCallback(() => {
    dispatch(SearchAction.changeHininMikakuninCheckbox())
    dispatch(TableCommonAction.changePage(0))
  }, [])
  const changeJochoHininMikakunin = useCallback(() => {
    dispatch(SearchAction.changeJochoHininMikakuninCheckbox())
    dispatch(TableCommonAction.changePage(0))
  }, [])
  const changeHininkakuninzumi = useCallback(() => {
    dispatch(SearchAction.changeHininkakuninzumiCheckbox())
    dispatch(TableCommonAction.changePage(0))
  }, [])
  const changeJochoHininkakuninzumi = useCallback(() => {
    dispatch(SearchAction.changeJochoHininkakuninzumiCheckbox())
    dispatch(TableCommonAction.changePage(0))
  }, [])
  return [mishonin, shonin, hinin, jochoShonin, jochoMishonin, hininMikakunin, jochoHininMikakunin, hininkakuninzumi, jochoHininKakuninzumi, changeMishonin, changeShonin, changeHinin, changeJochoShonin, changeJochoMishonin, changeHininMikakunin, changeJochoHininMikakunin, changeHininkakuninzumi, changeJochoHininkakuninzumi]
}

// ボタン項目押下処理 //================================//
export const useSearch = (): [voidF, voidF] => {
  const dispatch = useDispatch()
  const loading = useSelector((state) => state.PageReducer.loading)
  const userKind = useSelector((state) => state.LoginReducer.userKind)
  const TANTO_CD = useSelector((state) => state.LoginReducer.TANTO_CD)
  const keyInfo = useSelector((state) => state.LoginReducer.keyInfo)
  const isSuperUser = useSelector((state) => state.LoginReducer.isSuperUser)
  const isJocho = useSelector((state) => state.LoginReducer.isJocho)
  const isKoiki = useSelector((state) => state.LoginReducer.isKoiki)

  // CSV登録ボタン押下処理 //================================//
  const clickInputCSV = useCallback(() => {
    if (!loading) dispatch(ModalFileAction.open())
  }, [loading])

  const clickReset = useCallback(() => {
    dispatch(PageAction.format())
    dispatch(SearchAction.format())
    if (userKind === 0) {
      // ジャペルユーザーの場合
      dispatch(SearchAction.setInitCode(keyInfo[0].JIGYO_CD, TANTO_CD, isSuperUser, isJocho, isKoiki)) // 所属部署に合わせた初期値をセット
    }
    dispatch(TableAction.format())
    dispatch(TableCommonAction.format())
    dispatch({ type: 'ModalFileFormat' })
  }, [userKind])

  return [clickReset, clickInputCSV]
}

// サマリフェッチャー //================================//
export const useFetcherSummary = (): [any, () => Promise<any>] => {
  const dispatch = useDispatch()
  const userKind = useSelector((state) => state.LoginReducer.userKind)
  const trail = useSelector((state) => state.SearchJokenRitsuReducer.trail)
  const keyInfo = useSelector((state) => state.LoginReducer.keyInfo)
  const MAIL = useSelector((state) => state.LoginReducer.MAIL)
  const TANTO_CD = useSelector((state) => state.LoginReducer.TANTO_CD)
  const searchJoken = useSelector((state) => state.SearchJokenRitsuReducer.searchJokenRitsu)
  const isSuperUser = useSelector((state) => state.LoginReducer.isSuperUser)
  const isJocho = useSelector((state) => state.LoginReducer.isJocho)
  const isKoiki = useSelector((state) => state.LoginReducer.isKoiki)

  const page = useSelector((state) => state.TableReducer.page)
  const perPage = useSelector((state) => state.TableReducer.perPage)

  if (trail === 0) {
    // 初回表示またはリロード時
    dispatch(SearchAction.changeTrail(1))
    // 状態をジャペルログイン時の初期状態に更新
    dispatch(SearchAction.checkKakuinTaisyo(userKind === 0 ? (isJocho ? '2' : '1') : '3'))
    dispatch(SearchAction.changeInitCheckbox())
    if (userKind === 0) {
      // リロード時にreducerがinitialに初期化される
      dispatch(SearchAction.setInitCode(keyInfo[0].JIGYO_CD, TANTO_CD, isSuperUser, isJocho, isKoiki)) // 所属部署に合わせた初期値をセット
    }
  }

  const isHiddenPagination = useGetIsHiddenPagination()
  const request: requestRitsuSearch = getRequestJokenRitsu(MAIL, keyInfo, TANTO_CD, isSuperUser, isJocho, isKoiki, page, perPage, isHiddenPagination, searchJoken)
  return [
    searchJoken,
    postFetcher('/postJokenRitsuInfo', request),
    //   MAIL: MAIL,
    //   keyInfo: keyInfo,
    //   TANTO_CD: TANTO_CD,
    //   searchJoken: getRequestJokenRitsu(searchJoken, userKind),
    //   isSuperUser: isSuperUser,
    //   isJocho: isJocho,
    //   isKoiki: isKoiki,
    // }),
  ]
}

// チェック項目をクリアする処理 //================================//
export const useClearCheck = (): voidF => {
  const dispatch = useDispatch()
  const selectJoken = useSelector((state) => state.TableJokenRitsuReducer.selectJoken)
  const clearCheck = () => {
    if (selectJoken.length > 0) dispatch(TableAction.clearCheck())
  }
  return clearCheck
}

// ページネーション非表示判定
export const useGetIsHiddenPagination = () => {
  const userKind = useSelector((state) => state.LoginReducer.userKind)
  const mishoninCheckbox = useSelector((state) => state.SearchJokenRitsuReducer.searchJokenRitsu.mishoninCheckbox)
  const shoninCheckbox = useSelector((state) => state.SearchJokenRitsuReducer.searchJokenRitsu.shoninCheckbox)
  const hininCheckbox = useSelector((state) => state.SearchJokenRitsuReducer.searchJokenRitsu.hininCheckbox)
  const jochoShoninCheckbox = useSelector((state) => state.SearchJokenRitsuReducer.searchJokenRitsu.jochoShoninCheckbox)
  const jochoMishoninCheckbox = useSelector((state) => state.SearchJokenRitsuReducer.searchJokenRitsu.jochoMishoninCheckbox)
  const hininMikakuninCheckbox = useSelector((state) => state.SearchJokenRitsuReducer.searchJokenRitsu.hininMikakuninCheckbox)
  const jochoHininMikakuninCheckbox = useSelector((state) => state.SearchJokenRitsuReducer.searchJokenRitsu.jochoHininMikakuninCheckbox)
  const hininKakuninzumiCheckbox = useSelector((state) => state.SearchJokenRitsuReducer.searchJokenRitsu.hininKakuninzumiCheckbox)
  const jochoHininKakuninzumiCheckbox = useSelector((state) => state.SearchJokenRitsuReducer.searchJokenRitsu.jochoHininKakuninzumiCheckbox)

  const initMishoninCheckbox = useSelector((state) => state.SearchJokenRitsuReducer.initMishoninCheckbox)
  const initShoninCheckbox = useSelector((state) => state.SearchJokenRitsuReducer.initShoninCheckbox)
  const initHininCheckbox = useSelector((state) => state.SearchJokenRitsuReducer.initHininCheckbox)
  const initJochoShoninCheckbox = useSelector((state) => state.SearchJokenRitsuReducer.initJochoShoninCheckbox)
  const initJochoMishoninCheckbox = useSelector((state) => state.SearchJokenRitsuReducer.initJochoMishoninCheckbox)
  const initHininMikakuninCheckbox = useSelector((state) => state.SearchJokenRitsuReducer.initHininMikakuninCheckbox)
  const initJochoHininMikakuninCheckbox = useSelector((state) => state.SearchJokenRitsuReducer.initJochoHininMikakuninCheckbox)
  const initHininkakuninzumiCheckbox = useSelector((state) => state.SearchJokenRitsuReducer.initHininkakuninzumiCheckbox)
  const initJochoHininKakuninzumiCheckbox = useSelector((state) => state.SearchJokenRitsuReducer.initJochoHininKakuninzumiCheckbox)

  const getIsHiddenPagination = useCallback(() => {
    const isHiddenPagination =
      userKind === 0
        ? mishoninCheckbox === initMishoninCheckbox &&
          shoninCheckbox === initShoninCheckbox &&
          jochoShoninCheckbox === initJochoShoninCheckbox &&
          jochoMishoninCheckbox === initJochoMishoninCheckbox &&
          hininMikakuninCheckbox === initHininMikakuninCheckbox &&
          jochoHininMikakuninCheckbox === initJochoHininMikakuninCheckbox &&
          hininKakuninzumiCheckbox === initHininkakuninzumiCheckbox &&
          jochoHininKakuninzumiCheckbox === initJochoHininKakuninzumiCheckbox
        : mishoninCheckbox === initMishoninCheckbox && shoninCheckbox === initShoninCheckbox && hininCheckbox === initHininCheckbox
    return isHiddenPagination
  }, [mishoninCheckbox, shoninCheckbox, hininCheckbox, jochoShoninCheckbox, jochoMishoninCheckbox, hininMikakuninCheckbox, jochoHininMikakuninCheckbox, hininKakuninzumiCheckbox, jochoHininKakuninzumiCheckbox])
  return getIsHiddenPagination()
}
