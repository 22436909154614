import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SingleValue } from 'react-select'
import * as SearchAction from 'actions/SearchActions/SearchUserAction' // 共通部分が多いActionはActionCreaterにまとめる
import { selectF, inputFocusF, voidF, voidBooleanF } from 'types/FunctionTypes'
import * as TableCommonAction from 'actions/TableActions/TableAction'
import * as TableAction from 'actions/TableActions/TableUserAction'
import * as PageAction from 'actions/PageAction'
import { getRequestUser } from 'utils/apiUtil'
import { postFetcher } from 'SWRProvider'
import { isKansaiJigyoUser, isNagoyaJigyoUser } from 'utils/booleanUtil'
import { F_NUM } from 'assets/FormatNumbers'
import { select } from 'types/SearchTypes'
import { kansaiJigyo, kansaiJochoJigyo, nagoyaJochoJigyo } from 'assets/InitJigyosho'

// 検索後にメッセージを消さないフラグ //================================//
export const useIsNotDeleteMessage = (): [boolean, voidBooleanF] => {
  const dispatch = useDispatch()
  const isNotDeleteMessage = useSelector((state) => state.SearchUserReducer.isNotDeleteMessage)
  const changeIsNotDeleteMessage = useCallback(
    (isNotDeleteMessage: boolean) => {
      dispatch(SearchAction.changeIsDeleteMessage(isNotDeleteMessage))
    },
    [isNotDeleteMessage],
  )
  return [isNotDeleteMessage, changeIsNotDeleteMessage]
}

/**
 * ==================================================================================
 * 明細画面モード
 * ==================================================================================
 */
export const useUserDetailView = (): [boolean] => {
  const detailView = useSelector((state) => state.SearchUserReducer.detailView)

  return [detailView]
}

// 事業所 //================================//
export const useJigyosho = (): [string, selectF] => {
  const dispatch = useDispatch()
  const JIGYO_CD = useSelector((state) => state.SearchUserReducer.searchUser.JIGYO_CD)
  const JIGYO_CD0 = useSelector((state) => state.LoginReducer.keyInfo[0].JIGYO_CD)
  if (JIGYO_CD === F_NUM) {
    // 空欄が選ばれていた場合はログイン時に取得した事業所をセットする(初回レンダリングの時)
    //
    dispatch(SearchAction.changeJigyoCode(JIGYO_CD0.toString()))
  }
  const changeJigyo = useCallback(
    (targetValue: SingleValue<any>) => {
      dispatch(SearchAction.changeJigyoCode(targetValue!.value))
    },
    [JIGYO_CD],
  )
  return [String(JIGYO_CD), changeJigyo]
}

// 事業所ドロップダウン //================================//
export const useJigyoList = (jigyoList: select[]) => {
  const JIGYO_CD = useSelector((state) => state.LoginReducer.keyInfo[0].JIGYO_CD)
  const isJocho = useSelector((state) => state.LoginReducer.isJocho)
  if (isKansaiJigyoUser(JIGYO_CD)) {
    if (isJocho) {
      // 関西の上長
      return kansaiJochoJigyo
    }
    // 関西の営業
    return kansaiJigyo
  }
  if (isNagoyaJigyoUser(JIGYO_CD)) {
    if (isJocho) {
      // 名古屋の上長
      return nagoyaJochoJigyo
    }
  }
  return jigyoList
}

// 仕入先 //================================//
export const useShiiresaki = (): [string, selectF] => {
  const dispatch = useDispatch()
  const SIIRE_CD = useSelector((state) => state.SearchUserReducer.searchUser.SIIRE_CD)
  const changeSiire = useCallback(
    (targetValue: SingleValue<any>) => {
      dispatch(SearchAction.changeSiireCode(targetValue!.value))
    },
    [SIIRE_CD],
  )
  return [String(SIIRE_CD), changeSiire]
}

// 氏名 //================================//
export const useName = (): [string, inputFocusF] => {
  const dispatch = useDispatch()
  const USER_NM = useSelector((state) => state.SearchUserReducer.searchUser.USER_NM)
  const changeName = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      e.preventDefault()
      dispatch(SearchAction.changeName(e.target.value))
    },
    [USER_NM],
  )
  return [USER_NM, changeName]
}

// メールアドレス //================================//
export const useMail = (): [string, inputFocusF] => {
  const dispatch = useDispatch()
  const USER_MAIL = useSelector((state) => state.SearchUserReducer.searchUser.USER_MAIL)
  const changeMail = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      e.preventDefault()
      dispatch(SearchAction.changeMail(e.target.value))
    },
    [USER_MAIL],
  )
  return [USER_MAIL, changeMail]
}

// ボタン項目押下処理 //================================//
export const useDetail = (): [boolean, voidF] => {
  const dispatch = useDispatch()
  const detailView = useSelector((state) => state.SearchUserReducer.detailView)

  // 検索条件リセットボタン押下処理 //================================//
  const clickReset = useCallback(() => {
    dispatch(PageAction.format())
    dispatch(SearchAction.format())
    dispatch(TableAction.format())
    dispatch(TableCommonAction.format())
    dispatch({ type: 'ModalUserFormat' })
  }, [])

  return [detailView, clickReset]
}

// サマリフェッチャー //================================//
export const useFetcherSummary = (): [any, () => Promise<any>] => {
  const searchUser = useSelector((state) => state.SearchUserReducer.searchUser)
  const JIGYO_CD = useSelector((state) => state.LoginReducer.keyInfo[0].JIGYO_CD)
  const isSuperUser = useSelector((state) => state.LoginReducer.isSuperUser)
  // ユーザー登録画面で複数の事業所を選択できるユーザーの場合、今選択している事業所を渡す
  //
  return [searchUser, postFetcher('/postUserSummary', { isSuperUser: isSuperUser, searchUser: getRequestUser(searchUser, JIGYO_CD) })]
}

// 明細フェッチャー
export const useFetcherDetail = (): [any, () => Promise<any>] => {
  const TANTO_CD = useSelector((state) => state.LoginReducer.TANTO_CD)
  const JIGYO_CD = useSelector((state) => state.TableUserReducer.JIGYO_CD)
  const SIIRE_CD = useSelector((state) => state.TableUserReducer.SIIRE_CD)
  return [{ JIGYO_CD: JIGYO_CD, SIIRE_CD: SIIRE_CD }, postFetcher('/postUserDetail', { TANTO_CD: TANTO_CD, JIGYO_CD: JIGYO_CD, SIIRE_CD: SIIRE_CD })]
}

// サマリ初期描画調整 //================================//
export const useInitProcessSummary = () => {
  const dispatch = useDispatch()
  const detailView = useSelector((state) => state.SearchUserReducer.detailView)
  const initProcess = useCallback(() => {
    if (detailView) dispatch(SearchAction.closeDetail())
  }, [detailView])
  return initProcess
}
