import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { buttonQueryF, clickF, clickFetchF, inputF, voidF } from 'types/FunctionTypes'
import * as TableAction from 'actions/TableActions/TableUserAction'
import * as ModalUserAction from 'actions/ModalUserAction'
import * as SearchAction from 'actions/SearchActions/SearchUserAction'
import * as PageAction from 'actions/PageAction'
import { responseDetailUser, responseSummaryUser } from 'types/ResponseTypes'
import { tokuisaki, user } from 'types/ReferTypes'
import { getRequestUserUpdate, postInfo, search, search2 } from 'utils/apiUtil'
import { checkListDetailUser, checkListSummaryUser, select } from 'types/SearchTypes'
import { convertNumber } from 'utils/numberUtil'
import { requestUserSendMail, requestUserUpdate } from 'types/RequestTypes'
import { useQueryClient } from 'react-query'

/**
 * ==================================================================================
 * サマリチェックリスト
 * ==================================================================================
 */
type check = (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.MouseEvent<HTMLTableRowElement, MouseEvent>, isChecked: boolean) => void
export const useCheckListSummary = (userList: responseSummaryUser[]): [checkListSummaryUser[], check] => {
  const checkList = useSelector((state) => state.TableUserReducer.checkListSummary)
  const dispatch = useDispatch()
  // 全チェック処理 //================================//
  const allClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.MouseEvent<HTMLTableRowElement, MouseEvent>, isChecked: boolean) => {
      e.preventDefault()
      dispatch(TableAction.allCheckUserSummary(isChecked, userList))
    },
    [userList],
  )
  return [checkList, allClick]
}

/**
 * ==================================================================================
 * サマリ項目変更/取得処理
 * @param param0
 * @returns
 * ==================================================================================
 */
type keyF = (e: React.KeyboardEvent<HTMLInputElement>) => Promise<boolean>
export const useSummary = (data: responseSummaryUser, isChecked: boolean): [clickF, voidF, inputF, keyF] => {
  const dispatch = useDispatch()
  const loading = useSelector((state) => state.PageReducer.loading)

  // 明細画面開示処理 //================================//
  const open = useCallback(
    async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (!loading) {
        e.preventDefault()
        dispatch(PageAction.deleteMessage())
        dispatch(TableAction.clickUser(data.JIGYO_CD, data.SIIRE_CD))
        dispatch(SearchAction.openDetail(data))
        dispatch(SearchAction.changeDetailView(true))
      }
    },
    [data],
  )

  // チェック処理 //================================//
  const check = useCallback(() => {
    if (!loading) {
      dispatch(PageAction.deleteMessage())
      dispatch(
        TableAction.checkUserSummary(isChecked, {
          JIGYO_CD: data.JIGYO_CD,
          JIGYO_NM: data.JIGYO_NM,
          SIIRE_CD: data.SIIRE_CD,
          SIIRE_NM: data.SIIRE_NM,
          INP_MAIL: data.INP_MAIL,
          MAIL: data.MAIL,
          NAME: data.NAME,
          isExist: data.isExist,
          isWithinAYear: data.isWithinAYear,
        }),
      )
    }
  }, [data, isChecked, loading])

  // メールアドレス入力処理 //================================//
  const changeMail = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault()
      if (!loading) {
        dispatch(
          TableAction.changeUserSummary({
            JIGYO_CD: data.JIGYO_CD,
            JIGYO_NM: data.JIGYO_NM,
            SIIRE_CD: data.SIIRE_CD,
            SIIRE_NM: data.SIIRE_NM,
            INP_MAIL: e.target.value.toLowerCase(),
            MAIL: data.MAIL,
            NAME: data.NAME,
            isExist: data.isExist,
            isWithinAYear: data.isWithinAYear,
          }),
        )
      }
    },
    [data],
  )

  // メールアドレス決定処理 //================================//
  const submitMail = useCallback(
    async (e: React.KeyboardEvent<HTMLInputElement>) => {
      const key = e.key
      switch (key) {
        case 'Enter':
        case 'Tab':
          if (!loading) {
            dispatch(PageAction.changeLoading(true))
            dispatch(PageAction.deleteMessage())
            return await postInfo<user>(
              '/postUserInfo',
              { MAIL: data.INP_MAIL },
              (r) => {
                dispatch(
                  TableAction.changeUserSummary({
                    JIGYO_CD: data.JIGYO_CD,
                    JIGYO_NM: data.JIGYO_NM,
                    SIIRE_CD: data.SIIRE_CD,
                    SIIRE_NM: data.SIIRE_NM,
                    INP_MAIL: r.MAIL,
                    MAIL: data.MAIL,
                    NAME: r.NAME,
                    isExist: data.isExist,
                    isWithinAYear: data.isWithinAYear,
                  }),
                )
                dispatch(PageAction.changeLoading(false))
              },
              () => dispatch(PageAction.changeErrorMessage()),
              () => dispatch(PageAction.changeLoading(false)),
            )
          }
          dispatch(PageAction.deleteMessage())
          return true
      }
      return true
    },
    [data],
  )

  return [open, check, changeMail, submitMail]
}

/**
 * ==================================================================================
 * 明細項目変更/取得処理
 * @param param0
 * @returns
 * ==================================================================================
 */
export const useDetail = (detail: responseDetailUser, isChecked: boolean): [voidF, inputF, keyF, inputF, keyF] => {
  const dispatch = useDispatch()
  const loading = useSelector((state) => state.PageReducer.loading)
  const JIGYO_CD = useSelector((state) => state.TableUserReducer.JIGYO_CD)
  const SIIRE_CD = useSelector((state) => state.TableUserReducer.SIIRE_CD)
  const checkListDetail = useSelector((state) => state.TableUserReducer.checkListDetail)

  // チェック処理 //================================//
  const handleRowClick = useCallback(() => {
    if (!loading) {
      dispatch(PageAction.deleteMessage())
      dispatch(
        TableAction.checkDetail(isChecked, {
          JIGYO_CD: JIGYO_CD,
          SIIRE_CD: SIIRE_CD,
          TOKUI_CD: detail.TOKUI_CD,
          INP_TOKUI_CD: detail.TOKUI_CD,
          TOKUI_NM: detail.TOKUI_NM,
          INP_MAIL: detail.INP_MAIL,
          MAIL: detail.MAIL,
          NAME: detail.NAME,
        }),
      )
    }
  }, [isChecked, loading])

  // 得意先コード入力処理 //================================//
  const changeTokuiCd = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault()
      if (!loading) {
        dispatch(
          TableAction.changeUserKey(detail.TOKUI_CD, {
            JIGYO_CD: JIGYO_CD,
            SIIRE_CD: SIIRE_CD,
            TOKUI_CD: detail.TOKUI_CD,
            INP_TOKUI_CD: convertNumber(e.target.valueAsNumber, 5),
            TOKUI_NM: detail.TOKUI_NM,
            INP_MAIL: detail.INP_MAIL,
            MAIL: detail.MAIL,
            NAME: detail.NAME,
          }),
        )
      }
    },
    [detail],
  )

  // 得意先コード決定処理 //================================//
  const submitTokui = useCallback(
    async (e: React.KeyboardEvent<HTMLInputElement>) => {
      const key = e.key
      switch (key) {
        case 'Enter':
        case 'Tab':
          if (!loading) {
            dispatch(PageAction.changeLoading(true))
            dispatch(PageAction.deleteMessage())
            return await postInfo<tokuisaki>(
              '/postTokuisakiInfo',
              { TOKUI_CD: detail.INP_TOKUI_CD },
              (r) => {
                dispatch(
                  TableAction.changeUser({
                    JIGYO_CD: JIGYO_CD,
                    SIIRE_CD: SIIRE_CD,
                    TOKUI_CD: detail.TOKUI_CD,
                    INP_TOKUI_CD: r.TOKUI_CD,
                    TOKUI_NM: r.TOKUI_NM,
                    INP_MAIL: detail.INP_MAIL,
                    MAIL: detail.MAIL,
                    NAME: detail.NAME,
                  }),
                )
                dispatch(PageAction.changeLoading(false))
              },
              () => dispatch(PageAction.changeErrorMessage()),
              () => dispatch(PageAction.changeLoading(false)),
            )
          }
          dispatch(PageAction.deleteMessage())
          return true
      }
      return true
    },
    [detail, checkListDetail],
  )

  // メールアドレス入力処理 //================================//
  const changeMail = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault()
      if (!loading) {
        dispatch(
          TableAction.changeUser({
            JIGYO_CD: JIGYO_CD,
            SIIRE_CD: SIIRE_CD,
            TOKUI_CD: detail.TOKUI_CD,
            INP_TOKUI_CD: detail.INP_TOKUI_CD!,
            TOKUI_NM: detail.TOKUI_NM,
            INP_MAIL: e.target.value.toLowerCase(),
            MAIL: detail.MAIL,
            NAME: detail.NAME,
          }),
        )
      }
    },
    [detail],
  )

  // メールアドレス決定処理 //================================//
  const submitMail = useCallback(
    async (e: React.KeyboardEvent<HTMLInputElement>) => {
      const key = e.key
      switch (key) {
        case 'Enter':
        case 'Tab':
          if (!loading) {
            dispatch(PageAction.changeLoading(true))
            dispatch(PageAction.deleteMessage())
            return await postInfo<user>(
              '/postUserInfo',
              { MAIL: detail.INP_MAIL },
              (r) => {
                dispatch(
                  TableAction.changeUser({
                    JIGYO_CD: JIGYO_CD,
                    SIIRE_CD: SIIRE_CD,
                    TOKUI_CD: detail.TOKUI_CD,
                    INP_TOKUI_CD: detail.INP_TOKUI_CD!,
                    TOKUI_NM: detail.TOKUI_NM,
                    INP_MAIL: r.MAIL,
                    MAIL: detail.MAIL,
                    NAME: r.NAME,
                  }),
                )
                dispatch(PageAction.changeLoading(false))
              },
              () => dispatch(PageAction.changeErrorMessage()),
              () => dispatch(PageAction.changeLoading(false)),
            )
          }
          dispatch(PageAction.deleteMessage())
          return true
      }
      return true
    },
    [detail],
  )

  return [handleRowClick, changeTokuiCd, submitTokui, changeMail, submitMail]
}

/**
 * ==================================================================================
 * サマリボタン押下処理(メール再送・更新)
 * 追加・削除・戻るボタンは押せないため関数を返さない
 * @returns
 * ==================================================================================
 */
export const useButtonsSummary = (): [buttonQueryF, clickFetchF] => {
  const dispatch = useDispatch()
  const loading = useSelector((state) => state.PageReducer.loading)
  const JIGYO_CD = useSelector((state) => state.SearchUserReducer.searchUser.JIGYO_CD)
  const TOKUI_CD = 0
  const TANTO_CD = useSelector((state) => state.LoginReducer.TANTO_CD)
  const checkListSummary = useSelector((state) => state.TableUserReducer.checkListSummary)
  const queryClient = useQueryClient()

  const changeErrMsg = () => dispatch(PageAction.changeErrorMessage()) //
  const loaded = () => dispatch(PageAction.changeLoading(false)) //

  // 登録メール再送ボタン押下処理 //================================//
  const clickSendMail = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    if (!loading) {
      dispatch(PageAction.deleteMessage())
      // ローディング開始
      dispatch(PageAction.changeLoading(true))
      await search<requestUserSendMail[], checkListSummaryUser>(
        '/postJokenUserSendMailBulk',
        checkListSummary.map((data) => {
          return {
            MAIL: data.INP_MAIL,
            JIGYO_CD: data.JIGYO_CD,
            SIIRE_CD: data.SIIRE_CD,
            TOKUI_CD: TOKUI_CD,
          }
        }),
        (res) => {
          if (res.error) dispatch(PageAction.changeMessage(res.viewMsg))
          else {
            dispatch(TableAction.changeCheckUserSummary(res.body))
            dispatch(PageAction.changeMessage(res.viewMsg, 'blue'))
          }
        },
        changeErrMsg,
        loaded,
      )
    }
  }

  // 更新ボタン押下処理 //================================//
  const clickUpdate = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, refetch: voidF) => {
    let isConfirmed = false
    e.preventDefault()
    const update = async (refetch: voidF) => {
      if (!loading) {
        dispatch(PageAction.deleteMessage())
        // ローディング開始
        dispatch(PageAction.changeLoading(true))
        await search2<requestUserUpdate, { isNeedDialog: boolean; data: checkListSummaryUser[] }>(
          '/postJokenUserUpdateBulk',
          getRequestUserUpdate(JIGYO_CD, TOKUI_CD, TANTO_CD, checkListSummary, !isConfirmed),
          (res) => {
            if (res.error) dispatch(PageAction.changeMessage(res.viewMsg))
            else {
              if (res.body.isNeedDialog) {
                dispatch(PageAction.openAlert(res.viewMsg, () => update(refetch), false))
              } else {
                if (isConfirmed) {
                  // 明細のデータも削除したので明細のキャッシュを削除して取得しなおすようにする
                  queryClient.invalidateQueries(['postUserDetail'])
                }
                dispatch(TableAction.changeCheckUserSummary(res.body.data))
                dispatch(PageAction.changeMessage(res.viewMsg, 'blue'))
                //refetch後メッセージを削除されなくする
                dispatch(SearchAction.changeIsDeleteMessage(true))
                refetch()
              }
            }
          },
          changeErrMsg,
          loaded,
        )
      }
      isConfirmed = true
    }
    update(refetch)
  }

  return [clickSendMail, clickUpdate]
}

/**
 * ==================================================================================
 * 明細ボタン押下処理(メール再送・追加・更新・削除・戻る)
 * @returns
 * ==================================================================================
 */
export const useButtons = (jigyoSet: select[], siireSet: select[], history: any): [buttonQueryF, clickFetchF, clickFetchF, clickFetchF, voidF] => {
  const dispatch = useDispatch()
  const loading = useSelector((state) => state.PageReducer.loading)
  const checkListDetail = useSelector((state) => state.TableUserReducer.checkListDetail)
  const JIGYO_CD = useSelector((state) => state.TableUserReducer.JIGYO_CD)
  const SIIRE_CD = useSelector((state) => state.TableUserReducer.SIIRE_CD)
  const TANTO_CD = useSelector((state) => state.LoginReducer.TANTO_CD)

  type detailReq = { checkListDetailUser: checkListDetailUser[] }
  const request = {
    checkListDetailUser: checkListDetail,
    TANTO_CD: TANTO_CD,
  }
  const regist = (refetch: voidF = () => {}) => {
    return (res: any) => {
      if (res.error) dispatch(PageAction.changeMessage(res.viewMsg))
      else {
        dispatch(TableAction.changeCheckUserDetail(res.body))
        dispatch(PageAction.changeMessage(res.viewMsg, res.alert ? 'red' : 'blue'))
        refetch()
      }
    }
  }
  const changeErrMsg = () => dispatch(PageAction.changeErrorMessage()) //
  const loaded = () => dispatch(PageAction.changeLoading(false)) //

  // 登録メール再送ボタン押下処理 //================================//
  const clickSendMail = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    if (!loading) {
      dispatch(PageAction.deleteMessage())
      // ローディング開始
      dispatch(PageAction.changeLoading(true))
      const requst = checkListDetail.map((data) => {
        return {
          MAIL: data.INP_MAIL,
          JIGYO_CD: data.JIGYO_CD,
          SIIRE_CD: data.SIIRE_CD,
          TOKUI_CD: data.TOKUI_CD,
        }
      })
      await search<requestUserSendMail[], checkListDetailUser>('/postJokenUserSendMailBulk', requst, regist(), changeErrMsg, loaded)
    }
  }

  // 追加ボタン押下処理 //================================//
  const clickAdd = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    if (!loading) {
      dispatch(ModalUserAction.open(jigyoSet, siireSet, JIGYO_CD, SIIRE_CD))
    }
  }

  // 更新ボタン押下処理 //================================//
  const clickUpdate = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, refetch: voidF) => {
    e.preventDefault()
    if (!loading) {
      dispatch(PageAction.deleteMessage())
      // ローディング開始
      dispatch(PageAction.changeLoading(true))
      await search<detailReq, checkListDetailUser>('/postJokenTokuiDetailUpdateBulk', request, regist(refetch), changeErrMsg, loaded)
    }
  }

  // 削除ボタン押下処理 //================================//
  const clickDelete = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, refetch: voidF) => {
    e.preventDefault()
    if (!loading) {
      dispatch(PageAction.deleteMessage())
      // ローディング開始
      dispatch(PageAction.changeLoading(true))
      await search<detailReq, checkListDetailUser>('/postJokenTokuiDetailDeleteBulk', request, regist(refetch), changeErrMsg, loaded)
    }
  }

  // 戻るボタン押下処理 //================================//
  const clickBack = useCallback(() => {
    if (!loading) {
      dispatch(PageAction.deleteMessage())
      dispatch(SearchAction.closeDetail())
      dispatch(TableAction.format())
      history.push('/userToroku')
    }
  }, [loading])

  return [clickSendMail, clickAdd, clickUpdate, clickDelete, clickBack]
}

// 検索後フォーマット処理 //================================//
export const useSearchedFormatSummary = (): voidF => {
  const dispatch = useDispatch()
  const summaryFormat = () => {
    dispatch(TableAction.formatCheckSummary())
  }
  return summaryFormat
}

export const useSearchedFormatDetail = (): voidF => {
  const dispatch = useDispatch()
  const detailFormat = () => {
    dispatch(TableAction.formatCheckDetail())
  }
  return detailFormat
}

export const useSearchedFormatBoth = (): voidF => {
  const dispatch = useDispatch()
  const bothFormat = () => {
    dispatch(TableAction.formatCheckSummary())
    dispatch(TableAction.formatCheckDetail())
  }
  return bothFormat
}
