import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import M_ButtonNormal from 'components/1_Atoms/2_Molecules/M_ButtonNormal'
import O_TablePagination from './O_TablePagination'
import { organismLog } from 'utils/logUtil'
import { useGetIsHiddenPagination } from 'hooks/useKuraJokenTable'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    hit: {
      marginRight: theme.spacing(1),
      // width: 250
    },
    buttons: {
      // flexGrow: 1,
      // width: 190
    },
    paddingRight0: {
      paddingRight: '0px',
    },
    backButton: {
      flexGrow: 1,
      display: 'flex',
      justifyContent: 'flex-end',
    },
  }),
)
const margin = 0
const paddingX = 0.5
const paddingY = 0

type Props = {
  count?: number
  checkedList?: boolean[]
  clickCSV: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  clickShohin: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  clickKakuninzumi: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  clickJochoShonin: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const O_TableBarKuraSummary = ({ count = 0, checkedList = [false, false, false], clickCSV, clickShohin, clickKakuninzumi, clickJochoShonin }: Props) => {
  useEffect(() => organismLog('O_TableBarKuraSummary'))
  const classes = useStyles()
  const userKind = useSelector((state) => state.LoginReducer.userKind)
  const isJocho = useSelector((state) => state.LoginReducer.isJocho)
  const isHiddenPagination = useGetIsHiddenPagination()

  return (
    <div className={classes.root}>
      <Toolbar variant="dense" className={classes.paddingRight0}>
        <Typography className={classes.hit} color="inherit" variant="subtitle1" component="div">
          {count}件 見つかりました。
        </Typography>
        <Box className={classes.buttons} m={margin} px={paddingX} py={paddingY}>
          <M_ButtonNormal label={'CSV出力'} event={clickCSV} disabled={checkedList[0]} />
        </Box>
        <Box className={classes.buttons} m={margin} px={paddingX} py={paddingY}>
          {userKind === 0 ? <M_ButtonNormal label={'確認済'} event={clickKakuninzumi} disabled={checkedList[1]} /> : <M_ButtonNormal label={'承認'} event={clickShohin} disabled={checkedList[1]} />}
        </Box>
        <Box className={classes.buttons} m={margin} px={paddingX} py={paddingY}>
          {isJocho ? <M_ButtonNormal label={'上長承認'} event={clickJochoShonin} disabled={checkedList[2]} /> : null}
        </Box>
        <Box className={classes.root}></Box>
        <Box>{isHiddenPagination ? null : <O_TablePagination count={count} isDetail={false} />}</Box>
      </Toolbar>
    </div>
  )
}

export default React.memo(O_TableBarKuraSummary)
