import React, { useEffect, useRef, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import A_IconMust from '../A_IconMust'
import { moleculeLog } from 'utils/logUtil'
import TextField from '@material-ui/core/TextField'

type Props = {
  key?: number
  tab?: number
  must?: boolean
  type?: string
  label: string
  valueCode?: string
  max?: number
  change?: (e: React.ChangeEvent<HTMLInputElement>) => void
  blurFunc?: (e: React.FocusEvent<HTMLInputElement>) => void
  register?: {
    onKeyDown({ key }: { key: string }): void
    ref(element: HTMLInputElement | null): void
  }
  disabled?: boolean
  focus?: boolean
  autoComplete?: string
}

const WIDTH = '90%' // 入力フォームの幅
const HEIGHT = '27px' // 入力フォームの高さ

const M_FieldTextLong = ({
  key = 0, //
  tab = -1,
  type = 'text',
  must = false,
  label,
  max = 40,
  valueCode,
  change = undefined,
  blurFunc = undefined,
  register,
  disabled = false,
  focus = false,
  autoComplete = '', // new-passwordで自動入力されない
}: Props) => {
  // blurでreducerを変更する場合について
  // chageValueで入力中の値を保持、フォーカスアウトでreducerを更新
  // この入力フォーム以外でreducerが更新されてもchangeValueを変えないと入力フォームの値が変わらないので
  // reducerの値とprevValueRefに持たせた前の値と比較して、変わっていたらchageValueを更新
  const [changeValue, setValue] = useState(valueCode)
  const prevValueRef = useRef<string | undefined>(valueCode)
  useEffect(() => {
    moleculeLog('M_FieldTextNew')
    if (prevValueRef.current !== valueCode) {
      setValue(valueCode)
      prevValueRef.current = valueCode
    }
  })
  const isBlur = blurFunc !== undefined
  return (
    <Grid container justify="flex-start">
      <Grid item xs={12} sm={4}>
        <Typography variant="body1" display="block" gutterBottom>
          {label}
          <A_IconMust must={must} />
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8}>
        <TextField
          key={key} //
          tabIndex={tab}
          type={type}
          value={isBlur ? changeValue : valueCode}
          // maxLength={max}
          inputProps={{
            maxlength: max,
            autoComplete: autoComplete,
            style: {
              // width: WIDTH,
              height: HEIGHT,
              paddingTop: 0,
              paddingBottom: 0,
              backgroundColor: disabled ? '#D8D8D8' : '#FFFFFF',
            },
          }}
          style={{ width: WIDTH }}
          onChange={
            isBlur
              ? (e) => {
                  setValue(e.target.value)
                }
              : change
          }
          onBlur={blurFunc}
          autoFocus={focus}
          {...register}
          variant="outlined"
          size="small"
          disabled={disabled}
        />
      </Grid>
    </Grid>
  )
}
export default React.memo(M_FieldTextLong)
