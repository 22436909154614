/* eslint-disable react/jsx-pascal-case */
import React, { useEffect } from 'react'
import A_Button from '../A_Button'
import { moleculeLog } from 'utils/logUtil'

type Props = {
  label: string
  disabled?: boolean
  event: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const M_ButtonNormal = ({ label, event, disabled = false }: Props) => {
  useEffect(() => moleculeLog('M_ButtonNormal'))
  return <A_Button variant="contained" color="primary" label={label} event={event} disabled={disabled} />
}
export default React.memo(M_ButtonNormal)
