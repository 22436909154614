import { commonMsg } from 'assets/MessageCode'
import { PageAction } from 'reducers/PageReducer'

// メッセージ変更処理 //================================//
export const changeMessage = (message: string, color: string = 'red'): PageAction => {
  return {
    type: 'PageChangeMessage',
    message: message,
    color: color,
  }
}

// 検索失敗メッセージ取得処理 //================================//
export const changeErrorMessage = (msgCode: string = commonMsg.ERROR_MSG): PageAction => {
  return {
    type: 'PageChangeMessage',
    message: msgCode,
    color: 'red',
  }
}

// ファイル出力失敗メッセージ取得処理 //================================//
export const changeErrorMessageFile = (msgCode: string = commonMsg.ERROR_CSV_MSG): PageAction => {
  return {
    type: 'PageChangeMessage',
    message: msgCode,
    color: 'red',
  }
}

// アラートを開く処理 //===========================//
export const openAlert = (message: string, func: (file?: File) => Promise<void>, err: boolean = false): PageAction => {
  return {
    type: 'PageAlertOpen',
    message: message,
    func: func,
    err: err,
  }
}

// アラートを閉じる処理 //===========================//
export const closeAlert = (): PageAction => {
  return {
    type: 'PageAlertClose',
  }
}

// ローディング処理 //===========================//
export const changeLoading = (loading: boolean): PageAction => {
  return {
    type: 'PageChangeLoading',
    loading: loading,
  }
}

// メッセージ削除処理 //================================//
export const deleteMessage = (): PageAction => {
  return {
    type: 'PageDeleteMessage',
  }
}

// 日付入力変更処理 //===========================//
export const changeInitView = (initView: number): PageAction => {
  return {
    type: 'PageChangeInitView',
    initView: initView,
  }
}

// 初期化処理 //===========================//
export const format = (): PageAction => {
  return {
    type: 'PageFormat',
  }
}
