import React, { useEffect } from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import TableRow from '@material-ui/core/TableRow'
import { userTableSummary } from 'assets/TableHeader'
import M_TableCell from 'components/1_Atoms/2_Molecules/M_TableCellHead'
import { organismLog } from 'utils/logUtil'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
    },
  }),
)

const width = userTableSummary.width
const label = userTableSummary.label

type Props = {
  click: (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.MouseEvent<HTMLTableRowElement, MouseEvent>, isChecked: boolean) => void
  isChecked: boolean
}

const O_TableHeaderUserSummary = ({ click, isChecked }: Props) => {
  useEffect(() => organismLog('O_TableHeaderUserSummary'))
  const classes = useStyles()

  return (
    <TableRow hover className={classes.root} onClick={(e) => click(e, isChecked)}>
      {/* No */}
      {/* <M_TableCell category="number" width={width.NO} title={label.NO} data={label.NO} /> */}
      {/* チェックボックス */}
      <M_TableCell category="checkbox" isChecked={isChecked} width={width.CHECK} />
      {/* 仕入先コード */}
      <M_TableCell category="data" width={width.SIIRE_CD} title={label.SIIRE_CD} data={label.SIIRE_CD} />
      {/* 仕入先名 */}
      <M_TableCell category="data" width={width.SIIRE_NM} title={label.SIIRE_NM} data={label.SIIRE_NM} />
      {/* メールアドレス */}
      <M_TableCell category="data" width={width.MAIL} title={label.MAIL} data={label.MAIL} />
      {/* 氏名 */}
      <M_TableCell category="data" width={width.NAME} title={label.NAME} data={label.NAME} />
    </TableRow>
  )
}

export default React.memo(O_TableHeaderUserSummary)
