import { memo, useEffect } from 'react'
import TableCell from '@material-ui/core/TableCell'
import { getStyle } from 'assets/TableHeader'
import Typography from '@material-ui/core/Typography'
import { responseSummaryUser } from 'types/ResponseTypes'
import { userTableSummary } from 'assets/TableHeader'
import M_TableCell from 'components/1_Atoms/2_Molecules/M_TableCell'
import { organismLog } from 'utils/logUtil'
import { Link } from 'react-router-dom'
import { useSummary } from 'hooks/useUserTorokuTable'

const width = userTableSummary.width
const manualMode = process.env.REACT_APP_MANUAL_MODE === '1'

type Props = {
  user: responseSummaryUser
  linkTo: string
  isChecked: boolean
  focus: boolean
}

const O_TableRowUserSummary = ({ user, linkTo, isChecked, focus }: Props) => {
  useEffect(() => organismLog('O_TableRowUserSummary'), [])
  const [open, check, changeMail, submitMail] = useSummary(user, isChecked)

  return (
    <>
      {/* チェックボックス */}
      <M_TableCell category="checkbox" isChecked={isChecked} width={width.CHECK} click={check} />
      {/* 仕入先コード */}
      <TableCell style={getStyle(width.SIIRE_CD)} align={'left'}>
        {user.JIGYO_CD != null ? (
          <Typography variant="caption" onClick={open}>
            <Link to={linkTo}>{user.SIIRE_CD}</Link>
          </Typography>
        ) : (
          <Typography variant="caption">{user.SIIRE_CD}</Typography>
        )}
      </TableCell>
      {/* 仕入先名 */}
      <M_TableCell
        category="data" //
        width={width.SIIRE_NM}
        align={'left'}
        title={user.SIIRE_NM}
        data={user.SIIRE_NM}
        blackout={manualMode}
      />
      {/* メールアドレス */}
      <M_TableCell
        category="formString" //
        width={width.MAIL}
        align={'left'}
        title={user.INP_MAIL}
        data={user.INP_MAIL}
        change={changeMail}
        disabled={!isChecked}
        submit={submitMail}
        focus={focus}
        blackout={manualMode}
      />
      {/* 氏名 */}
      <M_TableCell
        category="data" //
        width={width.NAME}
        align={'left'}
        title={user.NAME}
        data={user.NAME}
        blackout={manualMode}
      />
    </>
  )
}

export default memo(O_TableRowUserSummary)
