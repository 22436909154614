/* eslint-disable react/jsx-pascal-case */
import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import O_SearchBoxJokenKuraJapell from 'components/3_Organisms/Groups/O_SearchBoxJokenKuraJapell'
import O_SearchBoxJokenKuraShiiresaki from 'components/3_Organisms/Groups/O_SearchBoxJokenKuraShiiresaki'
import O_TableJokenKuraSummary from 'components/3_Organisms/Groups/O_TableJokenKuraSummary'
import O_TableJokenKuraDetail from 'components/3_Organisms/Groups/O_TableJokenKuraDetail'
import O_DialogAlert from 'components/3_Organisms/O_DialogAlert'
import { templateLog } from 'utils/logUtil'
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom'
import { baseInfo } from 'types/SearchTypes'
import { responseSummaryJokenKura } from 'types/ResponseTypes'
import { useFetcherSummary, useIsNotDeleteMessage, useSearch } from 'hooks/useKuraJoken'
import { usePageMessage, useLoading } from 'hooks/usePage'
import { useSearchedFormatBoth } from 'hooks/useKuraJokenTable'
import { useErrorLogout, useUser } from 'hooks/useLogin'
import O_CodeHaritsukeTokui from 'components/3_Organisms/Groups/O_CodeHaritsukeTokui'
import { usePage } from 'hooks/useTable'

const C_KuraJoken = (): JSX.Element => {
  useEffect(() => templateLog('C_KuraJoken'))
  const userKind = useUser()
  const [searchJoken, fetcher] = useFetcherSummary()
  const searchedFormat = useSearchedFormatBoth()
  const [changePageErrorMessage, deletePageMessage] = usePageMessage()
  const [isNotDeleteMessage, changeIsNotDeleteMessage] = useIsNotDeleteMessage()
  const [page, perPage] = usePage()
  const [backLogin] = useErrorLogout(useHistory())

  // SWR情報の取得
  const [detailView] = useSearch()
  const { data, refetch, isFetching, isLoading, error } = useQuery(['postJokenKuraSummary', searchJoken, page, perPage], fetcher)

  const [loding, changeLoading] = useLoading()
  useEffect(() => {
    if (!detailView && (isLoading || isFetching)) {
      changeLoading(true)
    }
    if (!detailView && !(isLoading || isFetching) && loding) {
      changeLoading(false)
    }
  }, [isLoading, isFetching, detailView])
  useEffect(() => {
    if (!detailView && (isLoading || isFetching) && !isNotDeleteMessage) {
      deletePageMessage()
    }
    if (!detailView && !(isLoading || isFetching) && isNotDeleteMessage) {
      changeIsNotDeleteMessage(false)
    }
  }, [isLoading, isFetching, detailView])

  if (isLoading) {
    searchedFormat()
    return <div>Loading...</div>
  }
  if (isFetching) {
    searchedFormat()
  }
  if (error) {
    backLogin()
    return <></>
  }
  const info: { pullDown: baseInfo; table: responseSummaryJokenKura[]; allCount: number } = data.body

  if (data.viewMsg) {
    changePageErrorMessage(data.viewMsg)
  }
  return (
    <>
      {userKind === 0 ? (
        <O_SearchBoxJokenKuraJapell info={info.pullDown} /> //
      ) : (
        <O_SearchBoxJokenKuraShiiresaki info={info.pullDown} /> //
      )}
      <hr />
      <Router>
        <Switch>
          <Route path="/kuraJoken" exact>
            <O_TableJokenKuraSummary jokenList={info.table} allCount={info.allCount} isFetching={isFetching} refetch={refetch} />
          </Route>
          <Route path="/kuraJoken/:postId" exact component={O_TableJokenKuraDetail} />
        </Switch>
      </Router>
      {/* 得意先コード画面のモーダル */}
      <O_CodeHaritsukeTokui />
      {/* アラートダイアログ */}
      <O_DialogAlert />
    </>
  )
}

export default React.memo(C_KuraJoken)
