/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TableVirtuoso } from 'react-virtuoso'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import { responseJokenRitsu } from 'types/ResponseTypes'
import * as TableAction from 'actions/TableActions/TableJokenRitsuAction'
import O_TableBarRitsu from '../O_TableBarRitsu'
import O_TableHeaderJokenRitsu from '../O_TableHeaderJokenRitsu'
import O_TableRowJokenRitsu from '../O_TableRowJokenRitsu'
import { organismGroupLog } from 'utils/logUtil'
import { voidF } from 'types/FunctionTypes'
import { useGetIsHiddenPagination } from 'hooks/useRitsuJoken'
import { useButtons, useSearchedFormat } from 'hooks/useRitsuJokenTable'
import { isUniqueJokenRitsuDetail } from 'utils/booleanUtil'
import { useChangeTableHeight, useChangeInnerHeight, useFormatDetail } from 'hooks/useCommon'
import { useLocation } from 'react-router-dom'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
    },
    table: {
      width: '120%',
    },
    container: {
      maxHeight: 700,
    },
    renkeizumi: {
      backgroundColor: '#D8D8D8',
    },
    selectedRenkeizumi: {
      backgroundColor: '#AAAAAA',
    },
  }),
)

type Props = {
  jokenList: responseJokenRitsu[]
  allCount: number
  refetch: voidF
  isFetching: boolean
}

const O_TableJokenRitsu = ({ jokenList, allCount, refetch, isFetching }: Props) => {
  useEffect(() => {
    organismGroupLog('O_TableJokenRitsu')
  })
  const location = useLocation()
  const [formatDetail] = useFormatDetail(location.pathname)
  useEffect(() => {
    formatDetail()
  }, [])
  const classes = useStyles()
  const dispatch = useDispatch()
  const selectJoken = useSelector((state) => state.TableJokenRitsuReducer.selectJoken).filter((r) => r.isChecked)
  const selectJokenAll = useSelector((state) => state.TableJokenRitsuReducer.selectJoken)
  const page = useSelector((state) => state.TableReducer.page)
  const perPage = useSelector((state) => state.TableReducer.perPage)
  const isHiddenPagination = useGetIsHiddenPagination()
  const list = jokenList
  const userKind = useSelector((state) => state.LoginReducer.userKind)
  const isJocho = useSelector((state) => state.LoginReducer.isJocho)
  const [checkedList, clickShonin, clickHinin, clickKakuninzumi, clickJochoShonin, clickJochoHinin, clickCSV] = useButtons(jokenList.length, allCount)
  const searchedFormat = useSearchedFormat()
  const [tableHeight, setTableHeight] = useState(window.innerHeight)
  const [currentInnerHeight, setCurrentInnerHeight] = useState(window.innerHeight)
  const [previousInnerHeight, setPreviousInnerHeight] = useState(window.innerHeight)
  const [changeTableHeight] = useChangeTableHeight(tableHeight, currentInnerHeight, previousInnerHeight, setTableHeight)
  const [changeInnerHeight] = useChangeInnerHeight(currentInnerHeight, setCurrentInnerHeight, setPreviousInnerHeight)
  useEffect(() => {
    // ウィンドウサイズ変更時にchangeInnerHeightを呼び出すようにリスナーに登録
    window.removeEventListener('resize', changeInnerHeight)
    window.addEventListener('resize', changeInnerHeight)
    return () => {
      window.removeEventListener('resize', changeInnerHeight)
    }
  }, [window.innerHeight, currentInnerHeight])
  useEffect(() => {
    // useEffectが呼ばれるのはウィンドウサイズを変えたとき、スクロール含めた高さが変わったとき（初期表示時にも調整するため）
    // ウィンドウサイズを変えると上記2パターンに引っ掛かり2回動くので
    // changeTableHeightを呼ぶのはcurrentInnerHeightを更新した後のみにする
    if (currentInnerHeight === window.innerHeight) changeTableHeight()
  }, [document.documentElement.scrollHeight, currentInnerHeight])

  // 全チェック処理 //================================//
  const handleAllClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.MouseEvent<HTMLTableRowElement, MouseEvent>, isChecked: boolean) => {
      e.preventDefault()
      dispatch(TableAction.allCheck(isChecked, jokenList))
    },
    [selectJoken],
  )

  const getIsAllChecked = () => {
    if (jokenList.length === 0 || selectJoken.length === 0) return false
    return jokenList.length === selectJoken.length
  }
  const getChecked = (joken: responseJokenRitsu): boolean => {
    if (getIsAllChecked()) return true
    if (selectJoken.length <= 0) return false
    return selectJoken.some((r) => isUniqueJokenRitsuDetail(r, joken))
  }

  const table = (props: any) => <Table {...props} style={{ width: window.innerWidth, borderCollapse: 'separate' }} size="small" aria-label="purchases" />
  const tableRow = (props: any) => {
    let isChecked = getChecked(props.item)
    let isCheckedTemp = isChecked
    let setClass = undefined
    if (userKind === 1 && props.item.RENKEI_KBN === 1) {
      if (isCheckedTemp) {
        setClass = classes.selectedRenkeizumi
        isCheckedTemp = false
      } else {
        setClass = classes.renkeizumi
      }
    }
    return <TableRow hover role="checkbox" {...props} selected={isCheckedTemp} className={setClass} />
  }
  const getComponent = useCallback(() => {
    return {
      Scroller: TableContainer,
      // Scroller: React.forwardRef((props, ref) => <TableContainer component={Paper} {...props} className={classes.container} ref={ref} />),
      Table: table,
      TableHead: TableHead,
      TableRow: tableRow,
      // TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
      TableBody: TableBody,
    }
  }, [selectJoken.length])

  if (isFetching) {
    searchedFormat()
    return <div>検索中...</div>
  }

  const getTableBar = () => {
    return (
      <O_TableBarRitsu
        count={allCount} //
        checkedList={checkedList}
        clickShonin={clickShonin}
        clickHinin={clickHinin}
        clickKakuninzumi={clickKakuninzumi}
        clickJochoShonin={clickJochoShonin}
        clickJochoHinin={clickJochoHinin}
        clickCSV={clickCSV}
        refetch={refetch}
      />
    )
  }
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item style={{ width: '100%' }}>
          {getTableBar()}
        </Grid>
        <TableVirtuoso
          style={{ height: tableHeight }} // 仮想リスト(表示範囲320以降はスクロール毎に描画する)
          data={list}
          components={getComponent()}
          fixedHeaderContent={() => <O_TableHeaderJokenRitsu click={handleAllClick} isChecked={getIsAllChecked()} />}
          itemContent={(index, joken) => {
            const no = isHiddenPagination ? index + 1 : index + page * perPage + 1
            const isChecked = getChecked(joken)
            const checkedJoken = selectJokenAll.filter((r) => isUniqueJokenRitsuDetail(r, joken))
            let HININ_RIYU = ''
            if (checkedJoken.length > 0) {
              HININ_RIYU = isJocho ? checkedJoken[0].JOCHO_HININ_RIYU : checkedJoken[0].HININ_RIYU
            } else {
              HININ_RIYU = isJocho ? joken.JOCHO_HININ_RIYU : joken.HININ_RIYU
            }
            return <O_TableRowJokenRitsu joken={joken} isChecked={isChecked} no={no} HININ_RIYU={HININ_RIYU} />
          }}
        />
        <Grid item style={{ width: '100%' }}>
          {getTableBar()}
        </Grid>
      </Grid>
    </div>
  )
}

export default React.memo(O_TableJokenRitsu)
