import React, { useEffect } from 'react'
import { moleculeLog } from 'utils/logUtil'

type Props = {
  key?: number
  tab?: number
  label: string
  event: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  disabled?: boolean
}

const M_ButtonSubmit = ({ key = 100, tab = -1, label, event, disabled = false }: Props) => {
  useEffect(() => moleculeLog('M_ButtonSubmit'))
  return (
    <>
      {disabled ? (
        <button key={key} tabIndex={tab} style={{ width: 160, backgroundColor: '#DDDDDD' }} onClick={event} disabled>
          {label}
        </button>
      ) : (
        <button key={key} tabIndex={tab} style={{ width: 160, backgroundColor: '#0FF', cursor: 'pointer' }} onClick={event}>
          {label}
        </button>
      )}
    </>
  )
}
export default React.memo(M_ButtonSubmit)
