/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useEffect, useState } from 'react'
import { TableVirtuoso } from 'react-virtuoso'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import O_TableBarSummary from 'components/3_Organisms/O_TableBarSummary'
import O_TableRowJokenSummary from 'components/3_Organisms/O_TableRowJokenSummary'
import O_TableHeaderJokenSummary from 'components/3_Organisms/O_TableHeaderJokenSummary'
import O_FileUpload from 'components/3_Organisms/Groups/O_FileUpload'
import { useButtonsSummary, useCheckListSummary, useSearchedFormatSummary } from 'hooks/useHanJokenTable'
import { useGetIsHiddenPagination, usePage } from 'hooks/useTable'
import { useLoading } from 'hooks/usePage'
import { organismGroupLog } from 'utils/logUtil'
import { isUniqueJoken } from 'utils/booleanUtil'
import { responseSummaryJoken } from 'types/ResponseTypes'
import { voidF } from 'types/FunctionTypes'
import { useChangeInnerHeight, useChangeTableHeight, useFormatDetail } from 'hooks/useCommon'
import { useLocation } from 'react-router-dom'

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      display: 'flex',
    },
    container: {
      // maxHeight: 600,
    },
  }),
)

type Props = {
  jokenList: responseSummaryJoken[]
  allCount: number
  refetch: voidF
  isFetching: boolean
}

const O_TableJokenSummary = ({ jokenList, allCount, refetch, isFetching }: Props) => {
  const [loding, changeLoading] = useLoading()
  useEffect(() => {
    organismGroupLog('O_TableJokenSummary')
  })
  useEffect(() => {
    if (isFetching) {
      changeLoading(true)
    }
    if (!isFetching && loding) {
      changeLoading(false)
    }
  }, [isFetching])
  const location = useLocation()
  const [formatDetail] = useFormatDetail(location.pathname)
  useEffect(() => {
    formatDetail()
  }, [])
  const classes = useStyles()
  const [checkListJoken, allCheck] = useCheckListSummary(jokenList)
  const [page, perPage] = usePage()
  const isHiddenPagination = useGetIsHiddenPagination()
  const [checkedList, clickCSV, clickShonin, clickKakunin, clickJochoShonin] = useButtonsSummary(refetch, jokenList.length, allCount)
  const searchedFormat = useSearchedFormatSummary()
  const list = jokenList

  const isAllChecked = jokenList.length === 0 ? false : jokenList.length === checkListJoken.length
  const [tableHeight, setTableHeight] = useState(window.innerHeight)
  const [currentInnerHeight, setCurrentInnerHeight] = useState(window.innerHeight)
  const [previousInnerHeight, setPreviousInnerHeight] = useState(window.innerHeight)
  const [changeTableHeight] = useChangeTableHeight(tableHeight, currentInnerHeight, previousInnerHeight, setTableHeight)
  const [changeInnerHeight] = useChangeInnerHeight(currentInnerHeight, setCurrentInnerHeight, setPreviousInnerHeight)
  useEffect(() => {
    // ウィンドウサイズ変更時にchangeInnerHeightを呼び出すようにリスナーに登録
    window.removeEventListener('resize', changeInnerHeight)
    window.addEventListener('resize', changeInnerHeight)
    return () => {
      window.removeEventListener('resize', changeInnerHeight)
    }
  }, [window.innerHeight, currentInnerHeight])
  useEffect(() => {
    // useEffectが呼ばれるのはウィンドウサイズを変えたとき、スクロール含めた高さが変わったとき（初期表示時にも調整するため）
    // ウィンドウサイズを変えると上記2パターンに引っ掛かり2回動くので
    // changeTableHeightを呼ぶのはcurrentInnerHeightを更新した後のみにする
    if (currentInnerHeight === window.innerHeight) changeTableHeight()
  }, [document.documentElement.scrollHeight, currentInnerHeight])

  const getChecked = (joken: responseSummaryJoken) => (isAllChecked ? true : checkListJoken.length <= 0 ? false : checkListJoken.some((r) => isUniqueJoken(r, joken)))
  const table = (props: any) => <Table {...props} style={{ borderCollapse: 'separate' }} size="small" aria-label="purchases" />
  const tableRow = (props: any) => <TableRow hover role="checkbox" {...props} selected={getChecked(props.item)} />
  const getComponent = useCallback(() => {
    return {
      Scroller: TableContainer,
      // Scroller: React.forwardRef((props, ref) => <TableContainer component={Paper} {...props} className={classes.container} ref={ref} />),
      Table: table,
      TableHead: TableHead,
      TableRow: tableRow,
      // TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
      TableBody: TableBody,
    }
  }, [checkListJoken.length])

  if (isFetching) {
    searchedFormat()
    return <div>検索中...</div>
  }
  const getTableBar = () => {
    return (
      <O_TableBarSummary
        count={allCount} //
        checkedList={checkedList}
        clickShohin={clickShonin}
        clickKakuninzumi={clickKakunin}
        clickCSV={clickCSV}
        clickJochoShonin={clickJochoShonin}
      />
    )
  }
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item style={{ width: '100%' }}>
          {getTableBar()}
        </Grid>
        <Grid item style={{ width: '100%' }}>
          <TableVirtuoso
            style={{ height: tableHeight }} // 仮想リスト(表示範囲250以降はスクロール毎に描画する)
            data={list}
            components={getComponent()}
            fixedHeaderContent={() => <O_TableHeaderJokenSummary click={allCheck} isChecked={isAllChecked} />}
            itemContent={(index, joken) => {
              const no = isHiddenPagination ? index + 1 : index + page * perPage + 1
              const isChecked = getChecked(joken)
              return <O_TableRowJokenSummary joken={joken} isChecked={isChecked} no={no} />
            }}
          />
        </Grid>
        <Grid item style={{ width: '100%' }}>
          {getTableBar()}
        </Grid>
      </Grid>
      {/* ファイル取込画面のモーダル */}
      <O_FileUpload INP_KBN={1} refetch={refetch} />
    </div>
  )
}

export default React.memo(O_TableJokenSummary)
