/* eslint-disable react/jsx-pascal-case */
import React, { useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { moleculeLog } from 'utils/logUtil'
import A_FieldCheckBox from '../A_FieldCheckBox'

type Props = {
  label1: string
  label2: string
  check1: boolean
  check2: boolean
  check3: boolean
  check4: boolean
  check5: boolean
  check6: boolean
  // check7: boolean
  check8: boolean
  event1: (e: React.ChangeEvent<HTMLInputElement>) => void
  event2: (e: React.ChangeEvent<HTMLInputElement>) => void
  event3: (e: React.ChangeEvent<HTMLInputElement>) => void
  event4: (e: React.ChangeEvent<HTMLInputElement>) => void
  event5: (e: React.ChangeEvent<HTMLInputElement>) => void
  event6: (e: React.ChangeEvent<HTMLInputElement>) => void
  // event7: (e: React.ChangeEvent<HTMLInputElement>) => void
  event8: (e: React.ChangeEvent<HTMLInputElement>) => void
  disabled: boolean
}

const useStyles = makeStyles(() =>
  createStyles({
    item: {
      marginBottom: 10,
    },
    centerPosition: {
      // display: 'flex',
      // alignItems: 'center',
      height: '100%',
    },
    fieldLeft: {
      width: 'auto',
      display: 'inline-block',
      border: '1px solid #000000',
      borderRight: 'none',
      paddingRight: 20,
    },
    fieldRight: {
      width: 'auto',
      display: 'inline-block',
      border: '1px solid #000000',
      borderLeft: 'none',
      paddingRight: 20,
    },
    fieldMiddle: {
      width: 'auto',
      display: 'inline-block',
      border: '1px solid #000000',
      borderLeft: 'none',
      borderRight: 'none',
      paddingRight: 20,
    },
  }),
)

const M_FieldJotaiJapell = ({ label1, label2, check1, check2, check3, check4, check5, check6, check8, event1, event2, event3, event4, event5, event6, event8, disabled }: Props) => {
  useEffect(() => moleculeLog('M_FieldJotaiJapell'))
  const classes = useStyles()
  return (
    <Grid container justify="flex-start">
      <Grid item xs={12} sm={2}>
        <Typography variant="body1" display="block" gutterBottom>
          {label1}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={10} className={classes.item}>
        <div className={classes.centerPosition}>
          <div>
            <div className={classes.fieldLeft}>
              <A_FieldCheckBox label="承認" check={check2} change={event2} disabled={disabled} />
            </div>
            <div className={classes.fieldMiddle}>
              <A_FieldCheckBox label="未承認" check={check1} change={event1} disabled={disabled} />
            </div>
            <div className={classes.fieldMiddle}>
              <A_FieldCheckBox label="否認(未確認)" check={check3} change={event3} disabled={disabled} />
            </div>
            <div className={classes.fieldRight}>
              <A_FieldCheckBox label="否認(確認済)" check={check4} change={event4} disabled={disabled} />
            </div>
          </div>
        </div>
      </Grid>
      <Grid item xs={12} sm={2}>
        <Typography variant="body1" display="block" gutterBottom>
          {label2}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={10}>
        <div className={classes.centerPosition}>
          <div>
            <div className={classes.fieldLeft}>
              <A_FieldCheckBox label="承認" check={check8} change={event8} disabled={disabled} />
            </div>
            <div className={classes.fieldMiddle}>
              <A_FieldCheckBox label="未承認" check={check5} change={event5} disabled={disabled} />
            </div>
            <div className={classes.fieldRight}>
              <A_FieldCheckBox label="否認" check={check6} change={event6} disabled={disabled} />
            </div>
            {/* <div className={classes.fieldRight}>
              <A_FieldCheckBox label="否認(確認済)" check={check7} change={event7} disabled={disabled} />
            </div> */}
          </div>
        </div>
      </Grid>
    </Grid>
  )
}

export default React.memo(M_FieldJotaiJapell)
