import { TableJokenRitsuAction } from 'reducers/TableReducers/TableJokenRitsuReducer'
import { responseJokenRitsu } from 'types/ResponseTypes'
import { checkListRitsu } from 'types/SearchTypes'

// チェックリストを変更する処理 //================================//
export const changeCheck = (checkDataList: checkListRitsu[]): TableJokenRitsuAction => {
  return {
    type: 'TableJokenRitsuChangeCheck',
    checkDataList: checkDataList,
  }
}

// 上長否認理由フォーム処理 //================================//
export const changeJochoHininRiyu = (checkData: checkListRitsu): TableJokenRitsuAction => {
  return {
    type: 'TableJokenRitsuChangeJochoHininRiyu',
    checkData: checkData,
  }
}

// 否認理由フォーム処理 //================================//
export const changeHininRiyu = (checkData: checkListRitsu): TableJokenRitsuAction => {
  return {
    type: 'TableJokenRitsuChangeHininRiyu',
    checkData: checkData,
  }
}

// 全チェック処理 //================================//
export const allCheck = (isChecked: boolean, jokenList: responseJokenRitsu[]): TableJokenRitsuAction => {
  return {
    type: 'TableJokenRitsuAllCheck',
    isChecked: isChecked,
    jokenList: jokenList,
  }
}

// チェック処理 //================================//
export const check = (isChecked: boolean, checkData: checkListRitsu): TableJokenRitsuAction => {
  return {
    type: 'TableJokenRitsuCheck',
    isChecked: isChecked,
    checkData: checkData,
  }
}

// チェックリストを初期化する処理 //================================//
export const clearCheck = (): TableJokenRitsuAction => {
  return {
    type: 'TableJokenRitsuClearCheck',
  }
}

// ページ送り処理 //================================//
export const changePage = (newPage: number): TableJokenRitsuAction => {
  return {
    type: 'TableJokenRitsuChangePage',
    page: newPage,
  }
}

// ページ表示数変更処理 //================================//
export const changePerPage = (page: number): TableJokenRitsuAction => {
  return {
    type: 'TableJokenRitsuChangePerPage',
    page: page,
  }
}

// 初期化処理 //================================//
export const format = (): TableJokenRitsuAction => {
  return {
    type: 'TableJokenRitsuFormat',
  }
}
