import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SingleValue } from 'react-select'
import * as SearchAction from 'actions/SearchActions/SearchJokenAction' // 共通部分が多いActionはActionCreaterにまとめる
import { selectF } from 'types/FunctionTypes'

// 詳細画面のページ //================================//
export const usePageDetail = (): [number, number] => {
  const pageDetail = useSelector((state) => state.TableReducer.pageDetail)
  const perPageDetail = useSelector((state) => state.TableReducer.perPageDetail)
  return [pageDetail, perPageDetail]
}

// ページ //================================//
export const usePage = (): [number, number] => {
  const page = useSelector((state) => state.TableReducer.page)
  const perPage = useSelector((state) => state.TableReducer.perPage)
  return [page, perPage]
}

// ページネーション非表示判定
export const useGetIsHiddenPagination = () => {
  const userKind = useSelector((state) => state.LoginReducer.userKind)
  const mishoninCheckbox = useSelector((state) => state.SearchJokenReducer.searchJoken.mishoninCheckbox)
  const shoninCheckbox = useSelector((state) => state.SearchJokenReducer.searchJoken.shoninCheckbox)
  const hininCheckbox = useSelector((state) => state.SearchJokenReducer.searchJoken.hininCheckbox)
  const jochoShoninCheckbox = useSelector((state) => state.SearchJokenReducer.searchJoken.jochoShoninCheckbox)
  const jochoMishoninCheckbox = useSelector((state) => state.SearchJokenReducer.searchJoken.jochoMishoninCheckbox)
  const hininMikakuninCheckbox = useSelector((state) => state.SearchJokenReducer.searchJoken.hininMikakuninCheckbox)
  const jochoHininMikakuninCheckbox = useSelector((state) => state.SearchJokenReducer.searchJoken.jochoHininMikakuninCheckbox)
  const hininKakuninzumiCheckbox = useSelector((state) => state.SearchJokenReducer.searchJoken.hininKakuninzumiCheckbox)
  const jochoHininKakuninzumiCheckbox = useSelector((state) => state.SearchJokenReducer.searchJoken.jochoHininKakuninzumiCheckbox)

  const initMishoninCheckbox = useSelector((state) => state.SearchJokenReducer.initMishoninCheckbox)
  const initShoninCheckbox = useSelector((state) => state.SearchJokenReducer.initShoninCheckbox)
  const initHininCheckbox = useSelector((state) => state.SearchJokenReducer.initHininCheckbox)
  const initJochoShoninCheckbox = useSelector((state) => state.SearchJokenReducer.initJochoShoninCheckbox)
  const initJochoMishoninCheckbox = useSelector((state) => state.SearchJokenReducer.initJochoMishoninCheckbox)
  const initHininMikakuninCheckbox = useSelector((state) => state.SearchJokenReducer.initHininMikakuninCheckbox)
  const initJochoHininMikakuninCheckbox = useSelector((state) => state.SearchJokenReducer.initJochoHininMikakuninCheckbox)
  const initHininkakuninzumiCheckbox = useSelector((state) => state.SearchJokenReducer.initHininkakuninzumiCheckbox)
  const initJochoHininKakuninzumiCheckbox = useSelector((state) => state.SearchJokenReducer.initJochoHininKakuninzumiCheckbox)

  const getIsHiddenPagination = useCallback(() => {
    const isHiddenPagination =
      userKind === 0
        ? mishoninCheckbox === initMishoninCheckbox &&
          shoninCheckbox === initShoninCheckbox &&
          jochoShoninCheckbox === initJochoShoninCheckbox &&
          jochoMishoninCheckbox === initJochoMishoninCheckbox &&
          hininMikakuninCheckbox === initHininMikakuninCheckbox &&
          jochoHininMikakuninCheckbox === initJochoHininMikakuninCheckbox &&
          hininKakuninzumiCheckbox === initHininkakuninzumiCheckbox &&
          jochoHininKakuninzumiCheckbox === initJochoHininKakuninzumiCheckbox
        : mishoninCheckbox === initMishoninCheckbox && shoninCheckbox === initShoninCheckbox && hininCheckbox === initHininCheckbox
    return isHiddenPagination
  }, [mishoninCheckbox, shoninCheckbox, hininCheckbox, jochoShoninCheckbox, jochoMishoninCheckbox, hininMikakuninCheckbox, jochoHininMikakuninCheckbox, hininKakuninzumiCheckbox, jochoHininKakuninzumiCheckbox])
  return getIsHiddenPagination()
}

// 詳細画面ページネーション非表示判定
export const useGetIsHiddenPaginationDetail = () => {
  const userKind = useSelector((state) => state.LoginReducer.userKind)
  const mishoninCheckbox = useSelector((state) => state.SearchJokenReducer.searchJoken.mishoninCheckbox)
  const shoninCheckbox = useSelector((state) => state.SearchJokenReducer.searchJoken.shoninCheckbox)
  const hininCheckbox = useSelector((state) => state.SearchJokenReducer.searchJoken.hininCheckbox)
  const jochoShoninCheckbox = useSelector((state) => state.SearchJokenReducer.searchJoken.jochoShoninCheckbox)
  const jochoMishoninCheckbox = useSelector((state) => state.SearchJokenReducer.searchJoken.jochoMishoninCheckbox)
  const hininMikakuninCheckbox = useSelector((state) => state.SearchJokenReducer.searchJoken.hininMikakuninCheckbox)
  const jochoHininMikakuninCheckbox = useSelector((state) => state.SearchJokenReducer.searchJoken.jochoHininMikakuninCheckbox)
  const hininKakuninzumiCheckbox = useSelector((state) => state.SearchJokenReducer.searchJoken.hininKakuninzumiCheckbox)
  const jochoHininKakuninzumiCheckbox = useSelector((state) => state.SearchJokenReducer.searchJoken.jochoHininKakuninzumiCheckbox)

  const initMishoninCheckbox = useSelector((state) => state.SearchJokenReducer.initMishoninCheckbox)
  const initShoninCheckbox = useSelector((state) => state.SearchJokenReducer.initShoninCheckbox)
  const initHininCheckbox = useSelector((state) => state.SearchJokenReducer.initHininCheckbox)
  const initJochoShoninCheckbox = useSelector((state) => state.SearchJokenReducer.initJochoShoninCheckbox)
  const initJochoMishoninCheckbox = useSelector((state) => state.SearchJokenReducer.initJochoMishoninCheckbox)
  const initHininMikakuninCheckbox = useSelector((state) => state.SearchJokenReducer.initHininMikakuninCheckbox)
  const initJochoHininMikakuninCheckbox = useSelector((state) => state.SearchJokenReducer.initJochoHininMikakuninCheckbox)
  const initHininkakuninzumiCheckbox = useSelector((state) => state.SearchJokenReducer.initHininkakuninzumiCheckbox)
  const initJochoHininKakuninzumiCheckbox = useSelector((state) => state.SearchJokenReducer.initJochoHininKakuninzumiCheckbox)

  const getIsHiddenPagination = useCallback(() => {
    const isHiddenPagination =
      userKind === 0
        ? mishoninCheckbox === initMishoninCheckbox &&
          shoninCheckbox === initShoninCheckbox &&
          jochoShoninCheckbox === initJochoShoninCheckbox &&
          jochoMishoninCheckbox === initJochoMishoninCheckbox &&
          hininMikakuninCheckbox === initHininMikakuninCheckbox &&
          jochoHininMikakuninCheckbox === initJochoHininMikakuninCheckbox &&
          hininKakuninzumiCheckbox === initHininkakuninzumiCheckbox &&
          jochoHininKakuninzumiCheckbox === initJochoHininKakuninzumiCheckbox
        : mishoninCheckbox === initMishoninCheckbox && shoninCheckbox === initShoninCheckbox && hininCheckbox === initHininCheckbox
    return isHiddenPagination
  }, [mishoninCheckbox, shoninCheckbox, hininCheckbox, jochoMishoninCheckbox, jochoMishoninCheckbox, hininMikakuninCheckbox, jochoHininMikakuninCheckbox, hininKakuninzumiCheckbox, jochoHininKakuninzumiCheckbox])
  return getIsHiddenPagination()
}

// 仕入先 //================================//
export const useShiiresaki = (): [string, selectF] => {
  const dispatch = useDispatch()
  const SIIRE_CD = useSelector((state) => state.SearchJokenReducer.searchJoken.SIIRE_CD)
  const changeSiire = useCallback(
    (targetValue: SingleValue<any>) => {
      dispatch(SearchAction.changeShiiresakiCode(targetValue!.value))
    },
    [SIIRE_CD],
  )
  return [String(SIIRE_CD), changeSiire]
}
