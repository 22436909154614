import { SearchTenyuryokuAction } from 'reducers/SearchReducers/SearchTenyuryokuReducer'
import { responseSummaryTenyuryoku } from 'types/ResponseTypes'
import { s3FileInfo } from 'types/SearchTypes'
import { fixNullValue } from 'utils/numberUtil'

// 検索ボックスを開いているか否かを変更する処理 //================================//
export const changeExpand = (expand: boolean): SearchTenyuryokuAction => {
  return {
    type: 'SearchTenyuryokuChangeExpand',
    expand: expand,
  }
}

// ファイルを変更する処理 //================================//
export const changeFile = (file: s3FileInfo[]): SearchTenyuryokuAction => {
  return {
    type: 'SearchTenyuryokuChangeFile',
    file: file,
  }
}

// 上長否認　確認済チェックボックスを変更する処理 //================================//
export const changeJochoHininkakuninzumiCheckbox = (): SearchTenyuryokuAction => {
  return {
    type: 'SearchTenyuryokuChangeJochoHininkakuninzumiCheckbox',
  }
}

// メーカー否認　確認済チェックボックスを変更する処理 //================================//
export const changeHininkakuninzumiCheckbox = (): SearchTenyuryokuAction => {
  return {
    type: 'SearchTenyuryokuChangeHininkakuninzumiCheckbox',
  }
}

// 上長否認　未確認チェックボックスを変更する処理 //================================//
export const changeJochoHininMikakuninCheckbox = (): SearchTenyuryokuAction => {
  return {
    type: 'SearchTenyuryokuChangeJochoHininMikakuninCheckbox',
  }
}

// メーカー否認　未確認チェックボックスを変更する処理 //================================//
export const changeHininMikakuninCheckbox = (): SearchTenyuryokuAction => {
  return {
    type: 'SearchTenyuryokuChangeHininMikakuninCheckbox',
  }
}

// 上長未承認チェックボックスを変更する処理 //================================//
export const changeJochoMishoninCheckbox = (): SearchTenyuryokuAction => {
  return {
    type: 'SearchTenyuryokuChangeJochoMishoninCheckbox',
  }
}

// 上長承認チェックボックスを変更する処理 //================================//
export const changeJochoShoninCheckbox = (): SearchTenyuryokuAction => {
  return {
    type: 'SearchTenyuryokuChangeJochoShoninCheckbox',
  }
}

// 検索後にメッセージを消さないフラグを変更する処理 //===========================//
export const changeIsDeleteMessage = (isNotDeleteMessage: boolean): SearchTenyuryokuAction => {
  return {
    type: 'SearchTenyuryokuChangeIsDeleteMessage',
    isNotDeleteMessage: isNotDeleteMessage,
  }
}

// 初期状態のチェックボックスを変更する処理 //===========================//
export const changeInitCheckbox = (): SearchTenyuryokuAction => {
  return {
    type: 'SearchTenyuryokuChangeInitCheckbox',
  }
}

// 確認対象表示状態にする処理 //===========================//
export const checkKakuinTaisyo = (initJotaiKbn: string): SearchTenyuryokuAction => {
  return {
    type: 'SearchTenyuryokuChangeKakunintaisyoCheckbox',
    initJotaiKbn: initJotaiKbn,
  }
}

// 否認チェックボックスフォーム処理 //================================//
export const changeHininCheckbox = (): SearchTenyuryokuAction => {
  return {
    type: 'SearchTenyuryokuChangeHininCheckbox',
  }
}

// 承認チェックボックスフォーム処理 //================================//
export const changeShoninCheckbox = (): SearchTenyuryokuAction => {
  return {
    type: 'SearchTenyuryokuChangeShoninCheckbox',
  }
}

// 未承認チェックボックスフォーム処理 //================================//
export const changeMishoninCheckbox = (): SearchTenyuryokuAction => {
  return {
    type: 'SearchTenyuryokuChangeMishoninCheckbox',
  }
}

// 明細画面表示を変更する処理 //================================//
export const changeDetailView = (detailView: boolean): SearchTenyuryokuAction => {
  return {
    type: 'SearchTenyuryokuChangeDetailView',
    detailView: detailView,
  }
}

// 販売条件照会画面検索情報(明細表示中)を変更する処理 ※仮処理 //================================//
export const openDetail = (joken: responseSummaryTenyuryoku): SearchTenyuryokuAction => {
  return {
    type: 'SearchTenyuryokuOpenDetail',
    joken: joken,
  }
}

// 販売条件照会画面検索情報(明細表示中)を変更する処理 ※仮処理 //================================//
export const closeDetail = (): SearchTenyuryokuAction => {
  return {
    type: 'SearchTenyuryokuCloseDetail',
  }
}

// 件名フォーム処理 //================================//
export const changeSeisanNaiyo = (seisanNaiyo: string): SearchTenyuryokuAction => {
  return {
    type: 'SearchTenyuryokuChangeSeisanNaiyo',
    seisanNaiyo: seisanNaiyo,
  }
}

// 処理Noフォーム処理 //================================//
export const changeDenpyoNo = (denpyoNo: string): SearchTenyuryokuAction => {
  return {
    type: 'SearchTenyuryokuChangeDenpyoNo',
    denpyoNo: denpyoNo,
  }
}

// 精算項目フォーム処理 //================================//
export const changeSeisanKoumoku = (seisanKoumoku: string): SearchTenyuryokuAction => {
  return {
    type: 'SearchTenyuryokuChangeSeisanKoumoku',
    seisanKoumoku: fixNullValue(seisanKoumoku),
  }
}

// 精算内容区分2フォーム処理 //================================//
export const changeNaiyoKbn2 = (naiyoKbn2: string): SearchTenyuryokuAction => {
  return {
    type: 'SearchTenyuryokuChangeNaiyoKbn2',
    naiyoKbn2: fixNullValue(naiyoKbn2),
  }
}

// 精算内容区分1フォーム処理 //================================//
export const changeNaiyoKbn1 = (naiyoKbn1: string): SearchTenyuryokuAction => {
  return {
    type: 'SearchTenyuryokuChangeNaiyoKbn1',
    naiyoKbn1: fixNullValue(naiyoKbn1),
  }
}

// 担当者フォーム処理 //================================//
export const changeTantoCode = (tantoCode: string): SearchTenyuryokuAction => {
  return {
    type: 'SearchTenyuryokuChangeTantoCode',
    tantoCode: fixNullValue(tantoCode),
  }
}

// 事業所フォーム処理 //================================//
export const changeJigyoshoCode = (jigyoshoCode: string): SearchTenyuryokuAction => {
  return {
    type: 'SearchTenyuryokuChangeJigyoshoCode',
    jigyoshoCode: fixNullValue(jigyoshoCode),
  }
}

// 仕入先フォーム処理 //================================//
export const changeShiiresakiCode = (shiiresakiCode: string): SearchTenyuryokuAction => {
  return {
    type: 'SearchTenyuryokuChangeShiiresakiCode',
    shiiresakiCode: fixNullValue(shiiresakiCode),
  }
}

// 相殺/入金日TOを変更する処理 //================================//
export const changeNyukinYmdTo = (nyukinYmdTo: string): SearchTenyuryokuAction => {
  return {
    type: 'SearchTenyuryokuChangeNyukinYmdTo',
    nyukinYmdTo: nyukinYmdTo,
  }
}

// 相殺/入金日FROMを変更する処理 //================================//
export const changeNyukinYmdFrom = (nyukinYmdFrom: string): SearchTenyuryokuAction => {
  return {
    type: 'SearchTenyuryokuChangeNyukinYmdFrom',
    nyukinYmdFrom: nyukinYmdFrom,
  }
}

// 登録日TOを変更する処理 //================================//
export const changeAddYmdTo = (addYmdTo: string): SearchTenyuryokuAction => {
  return {
    type: 'SearchTenyuryokuChangeAddYmdTo',
    addYmdTo: addYmdTo,
  }
}

// 登録日FROMを変更する処理 //================================//
export const changeAddYmdFrom = (addYmdFrom: string): SearchTenyuryokuAction => {
  return {
    type: 'SearchTenyuryokuChangeAddYmdFrom',
    addYmdFrom: addYmdFrom,
  }
}

// 処理票日付TOを変更する処理 //================================//
export const changeSyoriYmdTo = (syoriYmdTo: string): SearchTenyuryokuAction => {
  return {
    type: 'SearchTenyuryokuChangeSyoriYmdTo',
    syoriYmdTo: syoriYmdTo,
  }
}

// 処理票日付FROMを変更する処理 //================================//
export const changeSyoriYmdFrom = (syoriYmdFrom: string): SearchTenyuryokuAction => {
  return {
    type: 'SearchTenyuryokuChangeSyoriYmdFrom',
    syoriYmdFrom: syoriYmdFrom,
  }
}

// 初期フォームセット //================================//
export const setInitCode = (jigyoshoCode: number, tantoCode: number, isSuperUser: boolean, isJocho: boolean, isKoiki: boolean): SearchTenyuryokuAction => {
  return {
    type: 'SearchTenyuryokuChangeInitCode',
    jigyoshoCode: jigyoshoCode,
    tantoCode: tantoCode,
    isSuperUser: isSuperUser,
    isJocho: isJocho,
    isKoiki: isKoiki,
  }
}

// 状態以外の条件初期化 //===========================//
export const formatExceptJotai = (): SearchTenyuryokuAction => {
  return {
    type: 'SearchTenyuryokuFormatExceptJotai',
  }
}

// 条件初期化 //===========================//
export const format = (): SearchTenyuryokuAction => {
  return {
    type: 'SearchTenyuryokuFormat',
  }
}

// 条件完全初期化 //===========================//
export const allFormat = (): SearchTenyuryokuAction => {
  return {
    type: 'SearchTenyuryokuAllFormat',
  }
}
