/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles, createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import O_FooterModalFile from 'components/3_Organisms/O_FooterModalFile'
import * as ModalFileAction from 'actions/ModalFileAction'
import * as PageAction from 'actions/PageAction'
import { DialogContent, DialogActions } from 'assets/DialogSet'
import { putFile2 } from 'utils/apiUtil'
import { organismGroupLog } from 'utils/logUtil'
import { CSVHeaderPreHanJokenSiire, CSVHeaderPreKuraJokenSiire, CSVHeaderPreRitsuJokenSiire, CSVHeaderTenyuryokuSiire } from 'assets/CSVHeaders'
import { createCSV } from 'utils/fileUtil'
import { voidF } from 'types/FunctionTypes'
import { commonMsg } from 'assets/MessageCode'
import { HAN_KBN, KURA_KBN } from 'assets/FormatNumbers'

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
      // width: '100ch',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  })

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fileButton: {
      opacity: 0,
      appearance: 'none',
      position: 'absolute',
    },
    viewButton: {
      color: theme.palette.grey[600],
      cursor: 'pointer',
    },
  }),
)

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string
  children: React.ReactNode
  onClose: () => void
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const getFormat = (INP_KBN: number) => {
  switch (INP_KBN) {
    case 1:
      return CSVHeaderPreHanJokenSiire.length
    case 2:
      return CSVHeaderPreRitsuJokenSiire.length
    case 3:
      return CSVHeaderTenyuryokuSiire.length
    case 4:
      return CSVHeaderPreKuraJokenSiire.length
  }
  return 0
}

const getUri = (INP_KBN: number) => {
  switch (INP_KBN) {
    case 1:
      return '/postHanJokenFileInfo'
    case 2:
      return '/postRitsuJokenFileInfo'
    case 3:
      return '/postSiireNyuryokuCSVInput'
    case 4:
      return '/postHanJokenFileInfo'
  }
  return '/'
}

const getInpKbn = (INP_KBN: number) => {
  switch (INP_KBN) {
    case 1:
      return HAN_KBN
    case 4:
      return KURA_KBN
  }
  return -1
}

type Props = {
  INP_KBN: number
  refetch: voidF
}

const O_FileUpload = ({ INP_KBN, refetch }: Props) => {
  useEffect(() => organismGroupLog('O_FileUpload'))
  const classes = useStyles()
  const dispatch = useDispatch()
  // 得意先コード貼り付け画面の表示切替え
  const openFile = useSelector((state) => state.ModalFileReducer.openFile)
  const file = useSelector((state) => state.ModalFileReducer.file)
  const fileName = useSelector((state) => state.ModalFileReducer.fileName)
  const status = useSelector((state) => state.ModalFileReducer.status)
  const message = useSelector((state) => state.ModalFileReducer.message)
  const MAIL = useSelector((state) => state.LoginReducer.MAIL)
  const userKind = useSelector((state) => state.LoginReducer.userKind)
  const TANTO_CD = useSelector((state) => state.LoginReducer.TANTO_CD)
  const csvData = useSelector((state) => state.ModalFileReducer.csvData)
  const messageColor = useSelector((state) => state.ModalFileReducer.messageColor)

  const closeFileInput = useCallback(() => {
    dispatch(PageAction.deleteMessage())
    dispatch(ModalFileAction.close())
    dispatch(ModalFileAction.changeStatus(-1))
    refetch()
  }, [])

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return
    if (e.target.files[0].size === 0) return
    dispatch(ModalFileAction.changeFile(e.target.files[0]))
  }

  const postFileInfo = async (file: File) => {
    if (!file || file.size === 0) return
    const data = new FormData()
    data.append('file', file)
    data.append('user', MAIL)
    data.append('userKind', userKind.toString())
    data.append('TANTO_CD', TANTO_CD.toString())
    data.append('format', getFormat(INP_KBN).toString())
    data.append('INP_KBN', getInpKbn(INP_KBN).toString())
    dispatch(ModalFileAction.changeLoading(true))
    await putFile2<{ data: any[]; isImportFailed: boolean }>(
      getUri(INP_KBN),
      data,
      (res) => {
        dispatch(ModalFileAction.changeMessage(res.viewMsg, res.body.isImportFailed ? 'red' : 'blue'))
        dispatch(ModalFileAction.changeStatus(res.error ? 0 : 1))
        dispatch(ModalFileAction.changeCSV(res.body.data))
      },
      () => {
        dispatch(ModalFileAction.changeMessage(commonMsg.ERROR_CSV_UPLOAD, 'red'))
        dispatch(ModalFileAction.changeStatus(0))
      },
      () => {
        dispatch(ModalFileAction.deleteFile())
        dispatch(ModalFileAction.changeLoading(false))
      },
    )
  }
  const outputCSV = () => {
    if (!csvData) return
    let header
    switch (INP_KBN) {
      case 1:
        header = CSVHeaderPreHanJokenSiire
        break
      case 2:
        header = CSVHeaderPreRitsuJokenSiire
        break
      case 3:
        header = CSVHeaderTenyuryokuSiire
        break
      case 4:
        header = CSVHeaderPreKuraJokenSiire
        break
    }
    if (header) createCSV(header, csvData, fileName, false)
  }

  return (
    <div>
      <Dialog fullWidth={true} maxWidth="md" onClose={closeFileInput} aria-labelledby="customized-dialog-title" open={openFile}>
        <DialogTitle id="customized-dialog-title" onClose={closeFileInput}>
          CSV登録
        </DialogTitle>
        <DialogContent dividers>
          <Grid container justify="flex-start" spacing={0}>
            <Grid item xs={12}>
              <Button variant="contained" className={classes.viewButton} size="small">
                ファイルを選択
                <input
                  type="file"
                  className={classes.fileButton}
                  onChange={handleOnChange}
                  onClick={(e) => {
                    e.currentTarget.value = ''
                  }}
                />
              </Button>
            </Grid>
            <Grid item xs={12}>
              {!file || file.size === 0 ? <Typography>{'　'}</Typography> : <Typography>{fileName}</Typography>}
            </Grid>
          </Grid>
          {status === 1 ? (
            <Typography variant="body1">
              <b style={{ color: messageColor }}>{message}</b>
            </Typography>
          ) : status === 0 ? (
            <Typography variant="body1">
              <b style={{ color: 'red' }}>{message}</b>
            </Typography>
          ) : (
            ''
          )}
        </DialogContent>
        <DialogActions>
          <O_FooterModalFile status={status} func={postFileInfo} func2={outputCSV} close={closeFileInput} />
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default React.memo(O_FileUpload)
