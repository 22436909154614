import React, { useEffect, useCallback } from 'react'
import { responseDetailJokenKura } from 'types/ResponseTypes'
import { jokenKuraTableDetail } from 'assets/TableHeader'
import M_TableCell from 'components/1_Atoms/2_Molecules/M_TableCell'
import * as TableAction from 'actions/TableActions/TableJokenKuraAction'
import { organismLog } from 'utils/logUtil'
import { useDispatch, useSelector } from 'react-redux'
import { fixPrice } from 'utils/stringUtil'

const width = jokenKuraTableDetail.width
const manualMode = process.env.REACT_APP_MANUAL_MODE === '1'

type Props = {
  detail: responseDetailJokenKura
  isChecked: boolean
  HININ_RIYU: string
  no: number
}

const O_TableRowJokenKuraDetail = ({ detail, isChecked, HININ_RIYU, no }: Props) => {
  useEffect(() => organismLog('O_TableRowJokenKuraDetail'))
  const dispatch = useDispatch()
  const userKind = useSelector((state) => state.LoginReducer.userKind)
  const isJocho = useSelector((state) => state.LoginReducer.isJocho)

  // チェック処理 //================================//
  const handleRowClick = useCallback((isChecked: boolean, detail: responseDetailJokenKura) => {
    dispatch(
      TableAction.checkDetail(isChecked, {
        isChecked: !isChecked,
        JOTAI_KBN: detail.JOTAI_KBN,
        KAKUNIN_KBN: detail.KAKUNIN_KBN,
        ADD_YMD: detail.ADD_YMD,
        ADD_HNS: detail.ADD_HNS,
        JP_CD: detail.JP_CD,
        NET: detail.NET,
        HININ_RIYU: detail.HININ_RIYU,
        JOCHO_HININ_RIYU: detail.JOCHO_HININ_RIYU,
      }),
    )
  }, [])

  // 否認理由入力処理 //================================//
  const inputHininRiyu = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault()
      dispatch(
        TableAction.changeHininRiyu({
          isChecked: true,
          JOTAI_KBN: detail.JOTAI_KBN,
          KAKUNIN_KBN: detail.KAKUNIN_KBN,
          ADD_YMD: detail.ADD_YMD,
          ADD_HNS: detail.ADD_HNS,
          JP_CD: detail.JP_CD,
          NET: detail.NET,
          HININ_RIYU: e.target.value,
          JOCHO_HININ_RIYU: detail.JOCHO_HININ_RIYU,
        }),
      )
    },
    [HININ_RIYU],
  )

  // 上長否認理由入力処理 //================================//
  const inputJochoHininRiyu = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault()
      dispatch(
        TableAction.changeJochoHininRiyu({
          isChecked: true,
          JOTAI_KBN: detail.JOTAI_KBN,
          KAKUNIN_KBN: detail.KAKUNIN_KBN,
          ADD_YMD: detail.ADD_YMD,
          ADD_HNS: detail.ADD_HNS,
          JP_CD: detail.JP_CD,
          NET: detail.NET,
          HININ_RIYU: detail.JOCHO_HININ_RIYU,
          JOCHO_HININ_RIYU: e.target.value,
        }),
      )
    },
    [HININ_RIYU],
  )

  return (
    <>
      {/* No */}
      <M_TableCell category="number" data={no} width={width.NO} />
      {/* チェックボックス */}
      <M_TableCell category="checkbox" isChecked={isChecked} width={width.CHECK} click={() => handleRowClick(isChecked, detail)} />
      {/* 状態 */}
      <M_TableCell
        category="data" //
        width={width.JOTAI}
        align={'left'}
        title={detail.JOTAI}
        data={detail.JOTAI}
      />
      {
        /* 上長承認状態 */
        userKind === 0 ? (
          <M_TableCell
            category="data" //
            width={width.JOCHO_JOTAI}
            align={'left'}
            title={!isJocho && detail.JOCHO_KBN === 2 ? detail.JOCHO_HININ_RIYU : detail.JOCHO_JOTAI}
            data={detail.JOCHO_JOTAI}
          />
        ) : (
          <></>
        )
      }
      {/* 得意先名 */}
      <M_TableCell
        category="data" //
        width={width.JAN_CD}
        align={'left'}
        title={detail.JAN_CD}
        data={detail.JAN_CD}
        blackout={manualMode}
      />
      {/* メールアドレス */}
      <M_TableCell
        category="data" //
        width={width.JP_CD}
        align={'left'}
        title={detail.JP_CD}
        data={detail.JP_CD}
        blackout={manualMode}
      />
      {/* 氏名 */}
      <M_TableCell
        category="data" //
        width={width.SHOHIN_KIKAKU}
        align={'left'}
        title={detail.SHOHIN_KIKAKU}
        data={detail.SHOHIN_KIKAKU}
        blackout={manualMode}
      />
      {/* 仕入単価 */}
      <M_TableCell
        category="data" //
        width={width.SIIRE_TAN}
        align={'right'}
        title={fixPrice(detail.SIIRE_TAN)}
        data={fixPrice(detail.SIIRE_TAN)}
        blackout={manualMode}
      />
      {/* 倉出Net */}
      <M_TableCell
        category="data" //
        width={width.NET}
        align={'right'}
        title={fixPrice(detail.NET)}
        data={fixPrice(detail.NET)}
        blackout={manualMode}
      />
      {/* 明細備考 */}
      <M_TableCell
        category="data" //
        width={width.MEISAI_BIKO}
        align={'left'}
        title={detail.MEISAI_BIKO}
        data={detail.MEISAI_BIKO}
        blackout={manualMode}
      />
      {
        /* 否認理由・上長否認理由 */
        isJocho ? (
          <M_TableCell
            category="form" //
            width={width.JOCHO_HININ_RIYU}
            align={'left'}
            title={HININ_RIYU}
            data={HININ_RIYU}
            change={inputJochoHininRiyu}
            disabled={!isChecked}
            blackout={manualMode}
          />
        ) : (
          <M_TableCell
            category="form" //
            width={width.HININ_RIYU}
            align={'left'}
            title={HININ_RIYU}
            data={HININ_RIYU}
            change={inputHininRiyu}
            disabled={userKind === 0 ? true : !isChecked}
            blackout={manualMode}
          />
        )
      }
    </>
  )
}

export default React.memo(O_TableRowJokenKuraDetail)
