/**
 * レベルによってコンポーネント呼び出しログを表示する関数
 */
export const componentLog = (component: string) => {
  if (process.env.REACT_APP_LOGLEVEL !== 'prod') {
    console.log('Called  ::::  ' + component)
  }
}
export const apiLog = (api: string) => {
  if (process.env.REACT_APP_LOGLEVEL !== 'prod') {
    console.log('complete :: ' + api)
  }
}
export const pageLog = (component: string) => {
  switch (`${process.env.REACT_APP_LOGLEVEL}`) {
    case 'page':
    case 'template':
    case 'organismGroup':
    case 'organism':
    case 'molecule':
    case 'atom':
      console.log('Called  ::::  ' + component)
      break
  }
}
export const templateLog = (component: string) => {
  switch (`${process.env.REACT_APP_LOGLEVEL}`) {
    case 'template':
    case 'organismGroup':
    case 'organism':
    case 'molecule':
    case 'atom':
      console.log('Called  ::::  ' + component)
      break
  }
}
export const organismGroupLog = (component: string) => {
  switch (`${process.env.REACT_APP_LOGLEVEL}`) {
    case 'organismGroup':
    case 'organism':
    case 'molecule':
    case 'atom':
      console.log('Called  ::::  ' + component)
      break
  }
}
export const organismLog = (component: string) => {
  switch (`${process.env.REACT_APP_LOGLEVEL}`) {
    case 'organism':
    case 'molecule':
    case 'atom':
      console.log('Called  ::::  ' + component)
      break
  }
}
export const moleculeLog = (component: string) => {
  switch (`${process.env.REACT_APP_LOGLEVEL}`) {
    case 'molecule':
    case 'atom':
      console.log('Called  ::::  ' + component)
      break
  }
}
export const atomLog = (component: string) => {
  console.log(`${process.env.REACT_APP_LOGLEVEL}`)
  switch (`${process.env.REACT_APP_LOGLEVEL}`) {
    case 'atom':
      console.log('Called  ::::  ' + component)
      break
  }
}
