import { TableLogAction } from 'reducers/TableReducers/TableLogReducer'
import { responseLog } from 'types/ResponseTypes'

// 検索中フラグを変更する処理 //================================//
export const changeSearchLogding = (searchLoading: boolean): TableLogAction => {
  return {
    type: 'TableLogChangeSearchLogding',
    searchLoading: searchLoading,
  }
}

// 表示ページ外のデータも含めた件数を変更する処理 //================================//
export const changeLogAllCount = (allCount: number): TableLogAction => {
  return {
    type: 'TableLogChangeAllCount',
    allCount: allCount,
  }
}

// ログリストを変更する処理 //================================//
export const changeLogList = (responseLog: responseLog[]): TableLogAction => {
  return {
    type: 'TableLogChangeLog',
    logList: responseLog,
  }
}
// 初期化する処理 //================================//
export const format = (): TableLogAction => {
  return {
    type: 'TableLogFormat',
  }
}
