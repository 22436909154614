/* eslint-disable react/jsx-pascal-case */
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { legacy_createStore as createStore, combineReducers } from 'redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'
import { persistStore, persistReducer } from 'redux-persist'
import { PersistGate } from 'redux-persist/integration/react'
import sessionStorage from 'redux-persist/lib/storage/session'
import { createTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import P_Login from 'components/5_Pages/P_Login'
import P_CreatePassword from 'components/5_Pages/P_CreatePassword'
import P_ChangePassword from 'components/5_Pages/P_ChangePassword'
import P_HanJoken from 'components/5_Pages/P1_HanJoken'
import P_RitsuJoken from 'components/5_Pages/P2_RitsuJoken'
import P_Tenyuryoku from 'components/5_Pages/P3_Tenyuryoku'
import P_UserToroku from 'components/5_Pages/P4_UserToroku'
import P_Log from 'components/5_Pages/P5_Log'
// import P_Sample from 'components/5_Pages/P_Sample' // サンプルページ(デバッグ用)
import { LoginReducer } from 'reducers/LoginReducer'
import { PageReducer } from 'reducers/PageReducer'
import { ModalUserReducer } from 'reducers/ModalUserReducer'
import { ModalShohinReducer } from 'reducers/ModalShohinReducer'
import { ModalTokuiReducer } from 'reducers/ModalTokuiReducer'
import { ModalFileReducer } from 'reducers/ModalFileReducer'
import { SearchJokenReducer } from 'reducers/SearchReducers/SearchJokenReducer'
import { SearchJokenKuraReducer } from 'reducers/SearchReducers/SearchJokenKuraReducer'
import { SearchJokenRitsuReducer } from 'reducers/SearchReducers/SearchJokenRitsuReducer'
import { SearchUserReducer } from 'reducers/SearchReducers/SearchUserReducer'
import { SearchLogReducer } from 'reducers/SearchReducers/SearchLogReducer'
import { SearchTenyuryokuReducer } from 'reducers/SearchReducers/SearchTenyuryokuReducer'
import { TableReducer } from 'reducers/TableReducers/TableReducer'
import { TableJokenReducer } from 'reducers/TableReducers/TableJokenReducer'
import { TableJokenKuraReducer } from 'reducers/TableReducers/TableJokenKuraReducer'
import { TableJokenRitsuReducer } from 'reducers/TableReducers/TableJokenRitsuReducer'
import { TableUserReducer } from 'reducers/TableReducers/TableUserReducer'
import { TableLogReducer } from 'reducers/TableReducers/TableLogReducer'
import { TableTenyuryokuReducer } from 'reducers/TableReducers/TableTenyuryokuReducer'
import { postFetcher } from 'SWRProvider'
import { menu } from 'types/ReferTypes'
import { getTitle } from 'utils/stringUtil'
import P_KuraJoken from 'components/5_Pages/P6_KuraJoken'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      // suspense: true,  // ここでsuspenseモードを全体で有効化する
      // staleTime: 300000,
    },
  },
})

const authPersistConfig = {
  key: 'auth',
  storage: sessionStorage,
}

const postPersistConfig = {
  key: 'post',
  storage: sessionStorage,
}

const jokenPersistConfig = {
  key: 'joken',
  storage: sessionStorage,
}

const postKuraPersistConfig = {
  key: 'postKura',
  storage: sessionStorage,
}

const jokenKuraPersistConfig = {
  key: 'jokenKura',
  storage: sessionStorage,
}

const postUserPersistConfig = {
  key: 'postUser',
  storage: sessionStorage,
}

const userPersistConfig = {
  key: 'user',
  storage: sessionStorage,
}

const postTenyuryokuPersistConfig = {
  key: 'postTenyuryoku',
  storage: sessionStorage,
}

const tenyuryokuPersistConfig = {
  key: 'tenyuryoku',
  storage: sessionStorage,
}

const reducers = combineReducers({
  LoginReducer: persistReducer(authPersistConfig, LoginReducer),
  PageReducer: PageReducer,
  ModalUserReducer: ModalUserReducer,
  ModalShohinReducer: ModalShohinReducer,
  ModalTokuiReducer: ModalTokuiReducer,
  ModalFileReducer: ModalFileReducer,
  SearchJokenReducer: persistReducer(jokenPersistConfig, SearchJokenReducer),
  SearchJokenKuraReducer: persistReducer(jokenKuraPersistConfig, SearchJokenKuraReducer),
  SearchJokenRitsuReducer: SearchJokenRitsuReducer,
  SearchUserReducer: persistReducer(userPersistConfig, SearchUserReducer),
  SearchLogReducer: SearchLogReducer,
  SearchTenyuryokuReducer: persistReducer(tenyuryokuPersistConfig, SearchTenyuryokuReducer),
  TableReducer: TableReducer,
  TableJokenReducer: persistReducer(postPersistConfig, TableJokenReducer),
  TableJokenKuraReducer: persistReducer(postKuraPersistConfig, TableJokenKuraReducer),
  TableJokenRitsuReducer: TableJokenRitsuReducer,
  TableUserReducer: persistReducer(postUserPersistConfig, TableUserReducer),
  TableLogReducer: TableLogReducer,
  TableTenyuryokuReducer: persistReducer(postTenyuryokuPersistConfig, TableTenyuryokuReducer),
})

const store = createStore(reducers, undefined, process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION__?.() : undefined)
// ログイン情報は永続化させる
const persistor = persistStore(store) // 永続化Reducer

const theme = createTheme({
  overrides: {
    MuiTableHead: {
      root: {
        '&.MuiTableHead-root': {
          backgroundColor: '#93FFAB',
        },
      },
    },
    MuiTableRow: {
      root: {
        // '&.MuiTableRow-head': {
        //   backgroundColor: '#93FFAB'
        // },
        // '&.MuiTableRow-root': {
        //   backgroundColor: '#E6FFE9',
        // },
        '&.MuiTableRow-selected:root': {
          backgroundColor: '#00CCCC',
        },
        '&.MuiTableRow-hover:hover': {
          cursor: 'pointer',
          backgroundColor: '#99CCFF',
        },
      },
    },
  },
})

const SubComponent = () => {
  // 全ページ共通項目はここで取得する
  const datas = useQuery(['getCommonTitles', []], postFetcher('/getCommonTitles'))
  const titles: string[] = datas.error || datas.isFetching || datas.isLoading ? '…' : datas.data.body.titles
  const sideMenus: menu[] = datas.error || datas.isFetching || datas.isLoading ? [] : datas.data.body.sideMenus
  const labels = {
    sysName: getTitle(titles[0]),
    sideMenus: sideMenus,
  }

  return (
    <Router>
      <Switch>
        <Route path="/" exact>
          <P_Login title={getTitle(titles[0])} />
        </Route>
        <Route path="/createPassword" exact>
          <P_CreatePassword title={getTitle(titles[1])} />
        </Route>
        <Route path="/changePassword" exact>
          <P_ChangePassword title={getTitle(titles[2])} />
        </Route>
        <Route path="/hanJoken">
          <P_HanJoken labels={labels} />
        </Route>
        <Route path="/kuraJoken">
          <P_KuraJoken labels={labels} />
        </Route>
        <Route path="/ritsuJoken" exact>
          <P_RitsuJoken labels={labels} />
        </Route>
        <Route path="/userToroku">
          <P_UserToroku labels={labels} />
        </Route>
        <Route path="/log">
          <P_Log labels={labels} />
        </Route>
        <Route path="/tenyuryoku">
          <P_Tenyuryoku labels={labels} />
        </Route>
        {/* <Route path="/sample" exact component={P_Sample} /> */}
      </Switch>
    </Router>
  )
}

const App = () => {
  return (
    <div className="app">
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <ThemeProvider theme={theme}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <SubComponent />
            </PersistGate>
          </Provider>
        </ThemeProvider>
      </QueryClientProvider>
    </div>
  )
}

export default App
